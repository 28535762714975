import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Pagination } from 'semantic-ui-react';
import { messagesActions } from '../../features/messages';
import * as helper from '../../utils/helper';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import MessageItem from './MessageItem';

interface Props {
  getMessagesList: typeof messagesActions.getMessagesList;
  setActivePage: typeof messagesActions.setActivePage;
  setRoomType: typeof messagesActions.setRoomType;
  messages: object[];
  totalMessages: number;
  activePage: number;
}

interface State {
  messages: object[];
  totalMessages: number;
}

class MessagesList extends React.Component<Props, State> {
  public state = {
    messages: [],
    totalMessages: 0,
  };

  public getListItems = async () => {
    this.props.getMessagesList();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.messages !== this.props.messages) {
      this.setState({ messages: [] }, () => this.setState({ messages: this.props.messages }));
    }
    if (prevProps.totalMessages !== this.props.totalMessages) {
      this.setState({ totalMessages: this.props.totalMessages });
    }
  };

  public handlePagination = (e: any, data: any) => {
    this.props.setActivePage(data.activePage);
    this.getListItems();
  };

  public render() {
    const { messages } = this.state;
    const { activePage } = this.props;
    const totalPages = helper.getTotalPage(this.props.totalMessages, 10);
    return (
      <div className="msg-list-page">
        <LoadingSpiner area="messages-list-area" />
        {messages.length === 0 ? (
          <NoResults />
        ) : (
          <div>
            {messages.map((message: object, index: number) => {
              return <MessageItem key={index} item={message} setType={this.props.setRoomType} />;
            })}
          </div>
        )}

        {totalPages > 1 && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Pagination
              totalPages={totalPages}
              boundaryRange={1}
              activePage={activePage}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                activePage === 1
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                activePage === totalPages
                  ? false
                  : {
                      'aria-label': 'Next item',

                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={this.handlePagination}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  messages: state.messages.messages.messages,
  totalMessages: state.messages.messages.totalMessages,
  activePage: state.messages.messages.activePage,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMessagesList: messagesActions.getMessagesList,
      setActivePage: messagesActions.setActivePage,
      setRoomType: messagesActions.setRoomType,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesList);
