import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Dropdown, Pagination } from 'semantic-ui-react';
import '../../assets/styles/Staff.css';
import AdjustersListHeader from '../../components/adjuster-list/AdjustersListHeader';
import AdjustersListTable from '../../components/adjuster-list/AdjustersListTable';
import MapView from '../../components/adjuster-list/MapView';
import { LoadingSpiner } from '../../components/loading-spinner/LoadingSpinner';
import { NoResults } from '../../components/no-results-placeholder/NoResults';
import { adjustersActions } from '../../features/adjusters';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import * as helper from '../../utils/helper';
import AdjustersFilter from './../../components/table-filters/AdjustersFilter';

interface Props {
  fetchAdjustersList: typeof adjustersActions.getAdjustersList;
  getMapAdjusters: typeof adjustersActions.getMapAdjusters;
  setActiveListTab: typeof adjustersActions.setActiveListTab;
  setActivePageAdjList: typeof adjustersActions.setActivePageAdjList;
  showInviteModal: typeof standbyActions.showInviteModal;
  user: IUser;
  activeTab: string;
  activePage: number;
  adjusters: object[];
  adjustersMap: object[];
  count: number;
  distances: object;
  // eventLocationCoords: object;
}

interface State {
  activeTab: string;
  adjusters: object[];
  activePage: number;
  totalAdjusters: number;
  totalPages: number;
  filter: boolean;
  filterState: object;
  listView: boolean;
  pagination: string;
}

const initialFilter = {
  availableForClaims: '',
  catCity: '',
  catStateProvince: '',
  certification: '',
  city: '',
  commercialTeam: '',
  deployed: '',
  equipment: '',
  experience: '',
  expertise: '',
  gender: '',
  language: '',
  license: '',
  largeLossTeam: '',
  name: '',
  position: '',
  softwareSkill: '',
  search: '',
  state: '',
  xactnetAddress: '',
  zip: '',
  catZip: '',
  eventLocation: '',
  authorityLevelFrom: '',
  authorityLevelTo: '',
  race: '',
  registrationType: '',
  sort: 'updatedAt,desc',
  allPaperworkCompleted: '',
  isAdmin: '',
};

const paginationOptions = ['20', '50', '100', '200'].map(c => ({
  key: c,
  text: c,
  value: c,
}));

class AdjustersList extends React.Component<Props, State> {
  public filtersFormRef = React.createRef();

  public state = {
    adjusters: [],
    totalAdjusters: 0,
    totalPages: 1,
    filter: false,
    filterState: {
      ...initialFilter,
    },
    listView: true,
    pagination: '20',
  };

  public changeListView = (show: boolean) => {
    this.setState({ listView: show });
  };

  public componentDidMount() {
    this.props.setActivePageAdjList(get(history, 'state.state.activePage', 1));
    this.setState(
      {
        pagination: get(history, 'state.state.itemsPerPageCount', 20),
        adjusters: this.props.adjusters,
        totalPages: helper.getTotalPage(this.props.count, +this.state.pagination),
        filterState:
          location.hash !== ''
            ? {
                ...this.state.filterState,
                ...JSON.parse(decodeURI(location.hash.slice(1))),
              }
            : this.state.filterState,
        filter: location.hash !== '' && location.hash.includes('sort') ? true : this.state.filter,
      },
      () => this.fetchAdjusters(this.state.filterState)
    );
  }

  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.adjusters !== this.props.adjusters) {
      this.setState({ adjusters: [] }, () =>
        this.setState(
          {
            adjusters: this.props.adjusters,
            totalPages: helper.getTotalPage(this.props.count, +this.state.pagination),
          },
          () => {
            if (this.props.activePage > this.state.totalPages) {
              this.handlePagination({}, { activePage: 1 });
            }
          }
        )
      );
    }
    if (prevState.listView !== this.state.listView) {
      this.fetchAdjusters(this.state.filterState);
    }
    if (
      !isEqual(
        pick(prevProps, ['activePage', 'activeTab']),
        pick(this.props, ['activePage', 'activeTab'])
      )
    ) {
      this.fetchAdjusters(this.state.filterState, true);
    }
  };

  public componentWillUnmount() {
    this.props.setActivePageAdjList(1);
  }

  public handleTab = (name: string) => {
    this.props.setActiveListTab(name);
    this.props.setActivePageAdjList(1);
  };

  public getIsAdminFilter(params: { [key: string]: any } = {}): boolean | undefined {
    if (this.props.activeTab === 'Admins') {
      return true;
    }

    const isAdminExists = params.hasOwnProperty('isAdmin');
    if (isAdminExists) {
      return params.isAdmin;
    }

    if (
      this.props.activeTab === 'All' ||
      this.props.activeTab === 'Archived' ||
      this.props.activeTab === 'Deleted'
    ) {
      return undefined;
    }

    return false;
  }

  public fetchAdjusters = async (params?: any, forceUpdate: boolean = false) => {
    if (params) {
      const dataModel = Object.assign({}, params);
      for (const key in dataModel) {
        if (dataModel[key] === '') {
          delete dataModel[key];
        } else if (key === 'standbyListDeployments' || key === 'eventDeployments') {
          dataModel[key] = dataModel[key].map(f => (f === 'exclude' ? 'exclude' : f.id));
        }
      }
      params = dataModel;
    }

    const filterProps = {
      ...params,
      status:
        this.props.activeTab === 'All' || this.props.activeTab === 'Admins'
          ? undefined
          : this.props.activeTab,
      isAdmin: this.getIsAdminFilter((params = {})),
      page: this.props.activePage - 1,
      pageSize: +this.state.pagination,
      unreadStatus: 1,
    };

    this.setState({ adjusters: [] }, () => {
      const keys = [
        'state',
        'licenseStates',
        'certificationTypes',
        'experience',
        'position',
        'equipment',
        'expertise',
        'softwareSkill',
        'catStateProvince',
        'language',
        'registrationType',
        'standbyListLocations',
        'deploymentsFilters',
      ];
      let newFilterProps = { ...filterProps };

      for (const key of keys) {
        if (newFilterProps[key] && newFilterProps[key].length) {
          newFilterProps = {
            ...newFilterProps,
            [key]: newFilterProps[key],
          };
        }
      }

      if (this.state.listView) {
        this.props.fetchAdjustersList(newFilterProps);
      } else {
        delete newFilterProps.page;
        delete newFilterProps.pageSize;
        this.props.getMapAdjusters(newFilterProps);
      }

      if (forceUpdate && this.filtersFormRef.current) {
        this.filtersFormRef.current.formsyForm.submit();
      }
    });
  };

  public handleFilterReset = () => {
    this.setState(
      {
        filterState: {
          ...initialFilter,
        },
      },
      () => {
        console.log('handleFilterReset');
        location.hash = '';
        this.fetchAdjusters(this.state.filterState);
        this.handlePagination({}, { activePage: 1 });
      }
    );
  };

  public handleFilterChange = (data: any) => {
    console.log('adjuster filter', this.state.filterState);
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          ...data,
        },
      },
      () => {
        const filters = this.cleanFilters(this.state.filterState);
        history.replaceState(null, '', `${location.pathname}#${JSON.stringify(filters)}`);
        this.fetchAdjusters(this.state.filterState);
      }
    );
  };

  public cleanFilters(filters: { [key: string]: any }) {
    const parsedFilter = { ...filters };
    for (const key in parsedFilter) {
      if (parsedFilter.hasOwnProperty(key)) {
        let shouldDeleteKey = false;
        // array filter
        if (Array.isArray(parsedFilter[key]) && !parsedFilter[key].length) {
          shouldDeleteKey = true;
        } else if (
          parsedFilter[key] === '' ||
          parsedFilter[key] === null ||
          parsedFilter[key] === undefined
        ) {
          shouldDeleteKey = true;
        }

        if (shouldDeleteKey) {
          delete parsedFilter[key];
        }
      }
    }

    return parsedFilter;
  }

  public handleExportList = async () => {
    const filters = this.cleanFilters(this.state.filterState);
    for (const filter in filters) {
      if (filter === 'standbyListDeployments' || filter === 'eventDeployments') {
        filters[filter] = filters[filter].map(f => {
          return f === 'exclude' ? 'exclude' : f.id;
        });
      }
    }

    const response = await services.api.finance.exportAdjustersList({
      ...filters,
      status:
        this.props.activeTab === 'All' || this.props.activeTab === 'Admins'
          ? undefined
          : this.props.activeTab,
      isAdmin: this.getIsAdminFilter(),
      page: this.props.activePage - 1,
      pageSize: +this.state.pagination,
      unreadStatus: 1,
    });

    if (response.error) {
      console.log(response);
    }
  };

  public showFilter = (show: boolean) => {
    this.setState({ filter: show });
  };

  public handlePagination = (e: any, data: any) => {
    this.props.setActivePageAdjList(data.activePage);
  };

  public handlePageSizeChange = (value: string) => {
    this.setState(
      { pagination: value, totalPages: helper.getTotalPage(this.props.count, +value) },
      () => {
        this.fetchAdjusters(this.state.filterState, true);
        window.scrollTo(0, 0);
      }
    );
  };

  public render() {
    const { totalPages, listView, adjusters, filterState } = this.state;
    return (
      <div className="admin-adjusters-list-page-container">
        <AdjustersListHeader
          user={this.props.user}
          filter={this.state.filter}
          export={this.handleExportList}
          getTab={this.handleTab}
          showFilters={this.showFilter}
          toogleView={this.changeListView}
          activeTab={this.props.activeTab}
          adjusters={adjusters}
          showMsgModal={this.props.showInviteModal}
        />
        <div>
          {this.state.filter && (
            <AdjustersFilter
              reset={this.handleFilterReset}
              filter={this.state.filterState}
              filterLogs={this.handleFilterChange}
              formRef={this.filtersFormRef}
              activePage={this.props.activeTab}
            />
          )}

          {listView ? (
            <>
              <LoadingSpiner area="adjusters-list" />
              <LoadingSpiner area="adjusters-profile-view" />
              {this.state.adjusters.length === 0 ? (
                <NoResults />
              ) : (
                <AdjustersListTable
                  activeTab={this.props.activeTab}
                  itemsPerPageCount={this.state.pagination}
                  fetchList={this.fetchAdjusters}
                  filter={this.state.filterState}
                  adjusters={adjusters}
                  onPageChange={this.handlePagination}
                  page={this.props.activePage}
                />
              )}
            </>
          ) : (
            <MapView
              key={this.props.activeTab}
              adjustersMap={this.props.adjustersMap}
              eventLocation={filterState.eventLocation}
              // eventLocationCoords={this.props.eventLocationCoords}
              distances={this.props.distances}
            />
          )}
        </div>

        {totalPages > 1 && listView && (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Dropdown
              placeholder={this.state.pagination}
              className="pagesCountDropdown"
              selection={true}
              options={paginationOptions}
              onChange={(event, data) => this.handlePageSizeChange(data.value)}
            />
            <Pagination
              totalPages={totalPages}
              boundaryRange={1}
              activePage={this.props.activePage}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                this.props.activePage === 1
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                this.props.activePage === totalPages
                  ? false
                  : {
                      'aria-label': 'Next item',

                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={this.handlePagination}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  adjusters: state.adjusters.adjusters.adjustersList || [],
  adjustersMap: state.adjusters.adjusters.adjustersMap,
  activeTab: state.adjusters.adjusters.activeTab,
  activePage: state.adjusters.adjusters.activePage,
  count: state.adjusters.adjusters.count,
  distances: state.adjusters.adjusters.distances,
  // eventLocationCoords: state.adjusters.adjusters.eventLocationCoords,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAdjustersList: adjustersActions.getAdjustersList,
      getMapAdjusters: adjustersActions.getMapAdjusters,
      setActiveListTab: adjustersActions.setActiveListTab,
      setActivePageAdjList: adjustersActions.setActivePageAdjList,
      showInviteModal: standbyActions.showInviteModal,
    },
    dispatch
  );

export default connect<Props, State>(mapStateToProps, mapDispatchToProps)(AdjustersList);
