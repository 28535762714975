import React from 'react';
import { searchFolder } from '../../../services/api/folderApi';
import { Search } from 'semantic-ui-react';

function FolderInputTag(props: { title: string; onDelete: () => any }) {
  return (
    <div className="tag">
      <div className="title">{props.title}</div>
      <div className="close-icn" onClick={props.onDelete}>
        x
      </div>
    </div>
  );
}

interface Props {
  title: string;
  folders: any[];
  setFolders: (folders: string[]) => void;
}

interface State {
  folders: any[];
  loading: boolean;
  searchValue: string;
}

class FolderInputTags extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      folders: [],
      loading: false,
      searchValue: '',
    };
  }

  public onSearchChange = async (e: any, obj: any) => {
    this.setState({ loading: true, searchValue: obj.value });
    const searchQuery = obj.value;
    const results = await searchFolder(searchQuery);
    const data = results.data.data.map((item: any) => {
      return {
        id: item.folder_id,
        title: item.folder_name,
      };
    });

    this.setState({ folders: data });
  };

  public onFolderSelect = (folder: any) => {
    const exists = this.props.folders.find((f) => f.id === folder.id);
    if (exists) {
      this.setState({ searchValue: '' });
      return;
    }

    this.setState({ searchValue: '' });
    this.props.setFolders(this.props.folders.concat(folder))
  };

  public onFolderDelete = (folder: any) => {
    this.props.setFolders(this.props.folders.filter((f) => f.id !== folder.id));
  };

  render() {
    return (
      <div className="folder-input-tags">
        <div className="title">{this.props.title}</div>
        <div className="input-container">
          {this.props.folders.length > 0 && (
            <div className="tags">
              {this.props.folders.map((folder, i) => (
                <FolderInputTag 
                  key={i}
                  title={folder.title}
                  onDelete={() => this.onFolderDelete(folder)}
                />
              ))}
            </div>
          )}
          <Search
            className="form-input"
            loading={this.state.loading}
            onResultSelect={(e, data) => this.onFolderSelect(data.result)}
            onSearchChange={this.onSearchChange}
            results={this.state.folders}
            value={this.state.searchValue}
            placeholder="Start Typing..."
            icon={null}
          />
        </div>
      </div>
    );
  }
}

export default FolderInputTags;
