import React, { useEffect, useState } from 'react';
import { Button, Image, Modal, Popup, Table } from 'semantic-ui-react';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { trainingCenterActions } from '../../features/training-center';
import {
  AccessModel,
  PaginationInfo,
} from '../../features/training-center/trainingCenterReducer';
import moment from 'moment';
import CustomIconicButton from '../adjuster-list/CustomIconicButton';
import assignIcon from '../../assets/images/Blue/assign.svg';
import AssignFolderAdjuster from '../adjuster-profile/AdjusterAssignedFolder/AssignFolderAdjuster';
import arrowIcon from '../../assets/images/Blue/arrow-r.svg';
import noAvatarIcon from '../../assets/images/no-avatar.svg';
import { IUser } from '../../models';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import './trainingCenterAccessTab.css';
import checkIconW from '../../assets/images/White/check.svg';
import checkIconB from '../../assets/images/Blue/check.svg';
import { uploads } from '../../config';

interface TrainingCenterAccessTabProps {
  user: IUser;
  pagingInfo: PaginationInfo;
  selectedAccess: Array<number>;
  setSelectedAccess: typeof trainingCenterActions.setSelectedAccess;
  removeSelectedAccess: typeof trainingCenterActions.removeSelectedAccess;
  searchAccess(pagingInfo: PaginationInfo): void;
}

interface StateActions {
  adjusters: object[];
  activeUserId: any;
}

const TrainingCenterAccessTab = (props: TrainingCenterAccessTabProps) => {
  const [records, setRecords] = useState<AccessModel[]>([]);
  const [actions, setActions] = useState<StateActions>({
    adjusters: [],
    activeUserId: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    props.searchAccess({ ...props.pagingInfo, activePage: 1, resetSelect: true });
  }, []);

  useEffect(() => {
    setRecords(props.pagingInfo.records);
  }, [props.pagingInfo]);

  const renderTableHeader = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: 50 }} />
          <Table.HeaderCell className="table-avatar" />
          <Table.HeaderCell>Last Name</Table.HeaderCell>
          <Table.HeaderCell>First Name</Table.HeaderCell>
          <Table.HeaderCell>Folders</Table.HeaderCell>
          <Table.HeaderCell>Last Updated On</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  };

  const renderAssignFolderModal = () => {
    return (
      <>
        {actions.activeUserId !== 0 && (
          <Modal
            size="mini"
            className="create-custom-event-modal"
            open={true}
            closeIcon={
              <Image src={closeIcon} className="icon" onClick={() => setIsModalOpen(false)} />
            }
          >
            <Modal.Header>Assign Folder</Modal.Header>
            <Modal.Content>
              <div style={{ display: 'flex' }}>
                <AssignFolderAdjuster
                  adjusterId={actions.activeUserId}
                  className="AssignFolderAdjuster"
                />
              </div>
            </Modal.Content>
            <div className="modal-button-footer">
              <Button
                type="button"
                style={{ width: '115px' }}
                className="secondary-button"
                secondary={true}
                onClick={() => setIsModalOpen(false)}
              >
                CLOSE
              </Button>
            </div>
          </Modal>
        )}
      </>
    );
  };

  const selectAccess = (accessRecord: { id: number }) => {
    const isSelected = props.selectedAccess.find((el) => el === accessRecord.id);
    if (!isSelected) {
      props.setSelectedAccess(accessRecord.id);
    } else {
      props.removeSelectedAccess(accessRecord.id);
    }
  };

  const renderSelectCheckBox = (accessRecord: AccessModel) => {
    const { selectedAccess } = props;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Popup
          trigger={
            <Button
              type="button"
              className={
                selectedAccess.find((el) => el === accessRecord.id)
                  ? 'check-button active iconic-button-custom'
                  : 'check-button iconic-button-custom'
              }
              onClick={(event) => {
                selectAccess(accessRecord);
              }}
            >
              <Image
                src={selectedAccess.find((el) => el === accessRecord.id) ? checkIconW : checkIconB}
              />
            </Button>
          }
          basic={true}
          size="mini"
          content={selectedAccess.find((el) => el === accessRecord.id) ? 'Deselect' : 'Select'}
        />
      </div>
    );
  };

  const checkIfRowSelected = (id: number) => {
    return props.selectedAccess.find((el) => el === id) ? 'selected' : '';
  };

  const renderTableBody = (records: Array<AccessModel> = []) => {
    return (
      <>
        <Table.Body>
          {records.map((accessRecord, i: number) => (
            <Table.Row key={accessRecord.id} className={checkIfRowSelected(accessRecord.id)}>
              <Table.Cell className="table-date" style={{ width: 50 }}>
                {renderSelectCheckBox(accessRecord)}
              </Table.Cell>
              <Table.Cell className="table-avatar">
                <Image
                  circular={true}
                  src={
                    accessRecord.avatar
                      ? uploads.s3BucketAddress + accessRecord.avatar
                      : noAvatarIcon
                  }
                  avatar={true}
                />
              </Table.Cell>
              <Table.Cell className="table-last-name">{accessRecord.lastName}</Table.Cell>
              <Table.Cell className="table-first-name" style={{ width: 529 }}>
                {accessRecord.firstName}
              </Table.Cell>
              <Table.Cell className="table-adjusters-assigned">
                {accessRecord.categoryCount} Assigned
              </Table.Cell>
              <Table.Cell className="table-updated-at">
                {moment(accessRecord.updatedAt).format('MM/DD/YYYY, hh:mm A')}
              </Table.Cell>
              <Table.Cell className="table-actions">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {!props.user.isReadOnlyAdmin && (
                    <>
                      <CustomIconicButton
                        onPress={() => {
                          setIsModalOpen(true);
                          setActions({ ...actions, activeUserId: accessRecord.id });
                        }}
                        popup={'Assign Folders'}
                        image={assignIcon}
                      />
                      {/*<CustomIconicButton*/}
                      {/*  onPress={() => {*/}
                      {/*  }}*/}
                      {/*  popup={'Actions'}*/}
                      {/*  image={gearIcon}*/}
                      {/*/>*/}
                    </>
                  )}
                  <CustomIconicButton
                    onPress={() => {
                      window.open(`/adjusters/${accessRecord.id}`, '_blank');
                    }}
                    popup={'View Profile'}
                    image={arrowIcon}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {isModalOpen && renderAssignFolderModal()}
      </>
    );
  };

  return (
    <Table
      compact={true}
      striped={true}
      stackable={true}
      singleLine={true}
      className="data-table access-table"
    >
      {renderTableHeader()}
      {renderTableBody(records)}
    </Table>
  );
};
const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pagingInfo: state.trainingCenter.access.pagingInfo,
  selectedAccess: state.trainingCenter.access.pagingInfo.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchAccess: trainingCenterActions.searchAccess,
      setSelectedAccess: trainingCenterActions.setSelectedAccess,
      removeSelectedAccess: trainingCenterActions.removeSelectedAccess,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TrainingCenterAccessTab);
