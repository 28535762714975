import moment from 'moment';

export const getCurrentDay = () => {
  return moment().format('MM/DD/YYYY');
};

export const parseDateFromApi = (value: string | Date) => {
  return moment(value).format('MM/DD/YYYY');
};

export const parseDateToApi = (value: string | Date) => {
  value = typeof value === 'string' ? value : moment(value).format('YYYY-MM-DD');
  return moment(value.substring(0, 10), 'YYYY-MM-DD').format('MM/DD/YYYY');
};

export const parseDateToDateStringWithFormat = (
  dateValue: string,
  format: string = 'YYYY-MM-DD'
) => {
  return moment(dateValue, format).format(format);
};
