import { filter } from 'lodash';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Button, Dropdown, Icon, Image, Menu, Search } from 'semantic-ui-react';
import searchIcon from '../../../assets/images/Blue/icn-search-blue.svg';
import AdjustersFilter from './../../table-filters/AdjustersFilter';

interface Props {
  selected: number;
  standby: object | boolean;
  global: boolean;
  inviteModalMode: string;
  pageSize: number;
  setPageSize: (page: any) => void;
  searchAdjustersValue: (value: string) => void;
  getUsers: (filter: any, standbyId?: any) => void;
  getTab: (name: string) => void;
  resetPaginationAdj: (page: number) => void;
  changeFilter: (filter: any) => void;
}

interface State {
  activeTab: string;
  showFilters: boolean;
  filter: boolean;
  filterState: object;
}

const initialFilter = {
  availableForClaims: '',
  catCity: '',
  catStateProvince: '',
  certification: '',
  city: '',
  commercialTeam: '',
  deployed: '',
  equipment: '',
  experience: '',
  expertise: '',
  language: '',
  license: '',
  largeLossTeam: '',
  milesAway: '',
  name: '',
  position: '',
  softwareSkill: '',
  state: '',
  xactnetAddress: '',
  zip: '',
  authorityLevelFrom: '',
  authorityLevelTo: '',
  registrationType: '',
  allPaperworkCompleted: '',
  isAdmin: '',
};

class SendMessageTableHeader extends React.Component<Props, State> {
  public state = {
    activeTab: 'Active',
    showFilters: false,
    filter: false,
    filterState: {
      ...initialFilter,
    },
  };

  public componentDidMount = () => {
    this.fetchAdjusters();
    this.handleFilterReset();
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.props.resetPaginationAdj(1);
    this.props.getTab(name);
    if (name === 'Selected') {
      this.setState({ showFilters: false });
    }
    this.setState({ activeTab: name }, () => this.fetchAdjusters(this.state.filterState));
  };

  public fetchAdjusters = async (params?: any) => {
    const { standby = {} } = this.props;
    if (params) {
      const dataModel = Object.assign({}, params);
      for (const key in dataModel) {
        if (dataModel[key] === '') {
          delete dataModel[key];
        } else if (key === 'standbyListDeployments' || key === 'eventDeployments') {
          dataModel[key] = dataModel[key].map((f: any) => (f === 'exclude' ? 'exclude' : f.id));
        }
      }
      params = dataModel;
    }

    const keys = [
      'state',
      'licenseStates',
      'certificationTypes',
      'experience',
      'position',
      'equipment',
      'expertise',
      'softwareSkill',
      'catStateProvince',
      'language',
      'registrationType',
    ];

    let filterProps = {
      ...params,
    };

    if (this.state.activeTab === 'Admins') {
      filterProps.isAdmin = true;
    } else if (this.state.activeTab !== 'All') {
      filterProps.isAdmin = false;
    }

    for (const key of keys) {
      if (filterProps[key] && filterProps[key].length) {
        filterProps = {
          ...filterProps,
          [key]: filterProps[key],
        };
      }
    }

    const initialTabs = ['All', 'Selected', 'Active', 'Inactive', 'Pending'];
    initialTabs.includes(this.state.activeTab)
      ? this.props.getUsers(filterProps)
      : this.props.getUsers(filterProps, standby.id);
  };

  public handleFilterReset = () => {
    this.props.resetPaginationAdj(1);
    this.props.changeFilter({ ...initialFilter });
    this.setState(
      {
        filterState: {
          ...initialFilter,
        },
      },
      () => this.fetchAdjusters(this.state.filterState)
    );
  };

  public handleFilterChange = (data: any) => {
    this.props.resetPaginationAdj(1);
    this.props.changeFilter({
      ...this.state.filterState,
      ...data,
    });
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          ...data,
        },
      },
      () => this.fetchAdjusters(this.state.filterState)
    );
  };

  public handleSearch = (e: any, data: any) => {
    if (this.state.activeTab === 'Selected') {
      this.props.searchAdjustersValue(data.value);
      this.props.resetPaginationAdj(1);
      this.setState({
        filterState: {
          ...this.state.filterState,
          search: data.value,
        },
      });
    } else {
      this.props.resetPaginationAdj(1);
      this.setState(
        {
          filterState: {
            ...this.state.filterState,
            search: data.value,
          },
        },
        () => {
          this.props.changeFilter({ ...this.state.filterState });
          this.fetchAdjusters(this.state.filterState);
        }
      );
    }
  };

  public handlePaginationCounter = (e: any, { value }: any) => {
    this.props.setPageSize(value);
    this.props.resetPaginationAdj(1);
    this.fetchAdjusters(this.state.filterState);
    const promise1 = () => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 2500);
      });
    };
    trackPromise(promise1(), 'invite-modal-users');
  };

  public render() {
    const { activeTab } = this.state;
    const { global, inviteModalMode } = this.props;
    return (
      <>
        <div className="adjusters-list-header sbl-details-table-header">
          <Menu text={true} className="tabs-menu">
            <Menu.Item
              name="Selected"
              active={activeTab === 'Selected'}
              onClick={this.handleItemClick}
            >
              Selected {`(${this.props.selected})`}
            </Menu.Item>
            {inviteModalMode === 'edit' && !global && (
              <Menu.Item
                name="Applied"
                active={activeTab === 'Applied'}
                onClick={this.handleItemClick}
              >
                Applied
              </Menu.Item>
            )}
            {inviteModalMode === 'edit' && !global && (
              <Menu.Item
                name="Invited"
                active={activeTab === 'Invited'}
                onClick={this.handleItemClick}
              >
                Invited
              </Menu.Item>
            )}
            {inviteModalMode === 'edit' && !global && (
              <Menu.Item
                name="Contacted"
                active={activeTab === 'Contacted'}
                onClick={this.handleItemClick}
              >
                Contacted
              </Menu.Item>
            )}
            <Menu.Item name="Active" active={activeTab === 'Active'} onClick={this.handleItemClick}>
              Active
            </Menu.Item>
            <Menu.Item
              name="Inactive"
              active={activeTab === 'Inactive'}
              onClick={this.handleItemClick}
            >
              Inactive
            </Menu.Item>
            <Menu.Item
              name="Pending"
              active={activeTab === 'Pending'}
              onClick={this.handleItemClick}
            >
              Pending
            </Menu.Item>
            <Menu.Item name="All" active={activeTab === 'All'} onClick={this.handleItemClick}>
              All
            </Menu.Item>
            <Menu.Item name="Admins" active={activeTab === 'Admins'} onClick={this.handleItemClick}>
              Admins
            </Menu.Item>
          </Menu>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="pagination-items-counter">
              Items on page:{' '}
              <Dropdown
                inline={true}
                options={[
                  { text: '10', value: 10 },
                  { text: '15', value: 15 },
                  { text: '25', value: 25 },
                  { text: '50', value: 50 },
                  { text: '100', value: 100 },
                ]}
                defaultValue={15}
                onChange={this.handlePaginationCounter}
              />
            </div>

            <Search
              input={{ iconPosition: 'left' }}
              icon={
                <Icon>
                  <Image src={searchIcon} />
                </Icon>
              }
              placeholder="Search..."
              value={this.state.filterState.search}
              onSearchChange={this.handleSearch}
              showNoResults={false}
            />
            {activeTab !== 'Selected' && (
              <Button
                primary={true}
                onClick={() => this.setState({ showFilters: !this.state.showFilters })}
              >
                Filter
              </Button>
            )}
          </div>
        </div>
        {this.state.showFilters && (
          <AdjustersFilter
            filter={this.state.filterState}
            filterLogs={this.handleFilterChange}
            reset={this.handleFilterReset}
            activePage={activeTab}
          />
        )}
      </>
    );
  }
}
export default SendMessageTableHeader;
