import React from 'react';
import { Button, Image, Menu, MenuItemProps, Popup } from 'semantic-ui-react';
import listBlue from '../../assets/images/Tabs/list-blue.svg';
import listWhite from '../../assets/images/Tabs/list-white.svg';
import mapBlue from '../../assets/images/Tabs/map-blue.svg';
import mapWhite from '../../assets/images/Tabs/map-white.svg';
import downloadIcon from '../../assets/images/White/download.svg';
import messageIcon from '../../assets/images/White/message.svg';
import services from '../../services';
import StandbyInviteModal from '../standby-layout/modals/StandbyInviteModal';
import { IUser } from './../../models/user';
import CustomPopupButton from './CustomPopupButton';
import '../../assets/styles/Adjusters.css';

interface Props {
  user: IUser;
  filter: boolean;
  getTab: (name: any) => void;
  export: () => void;
  adjusters: object[];
  showFilters: (show: boolean) => void;
  toogleView: (show: boolean) => void;
  activeTab: string;
  showMsgModal: (show: boolean, mode: string) => void;
}

interface State {
  activeItem: string | undefined;
  listView: boolean;
}

class AdjustersListHeader extends React.Component<Props, State> {
  public state = {
    activeItem: 'Pending',
    listView: true,
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.props.getTab(name);
    this.setState({ activeItem: name });
  };

  public render() {
    const { listView } = this.state;
    const { user, activeTab } = this.props;
    return (
      <>
        <div className="adjusters-list-header sbl-details-table-header">
          <Menu text={true} className="tabs-menu">
            <Menu.Item
              name="Pending"
              active={activeTab === 'Pending'}
              onClick={this.handleItemClick}
            >
              Pending
            </Menu.Item>
            <Menu.Item
              name="To Review"
              active={activeTab === 'To Review'}
              onClick={this.handleItemClick}
            >
              To Review
            </Menu.Item>
            <Menu.Item name="Active" active={activeTab === 'Active'} onClick={this.handleItemClick}>
              Active
            </Menu.Item>
            <Menu.Item
              name="Inactive"
              active={activeTab === 'Inactive'}
              onClick={this.handleItemClick}
            >
              Inactive
            </Menu.Item>
            <Menu.Item
              name="Archived"
              active={activeTab === 'Archived'}
              onClick={this.handleItemClick}
            >
              Archived
            </Menu.Item>
            <Menu.Item
              name="Deleted"
              active={activeTab === 'Deleted'}
              onClick={this.handleItemClick}
            >
              Deleted
            </Menu.Item>
            <Menu.Item name="All" active={activeTab === 'All'} onClick={this.handleItemClick}>
              All
            </Menu.Item>
            <Menu.Item name="Admins" active={activeTab === 'Admins'} onClick={this.handleItemClick}>
              Admins
            </Menu.Item>
            <Menu.Item
              name="QuickApplied"
              active={activeTab === 'QuickApplied'}
              onClick={this.handleItemClick}
            >
              Quick Signed
            </Menu.Item>
          </Menu>
          <div className="adjuster-header-actions">
            <div className="map-list-toogle">
              <CustomPopupButton
                classNm={listView ? 'yes-btn' : 'no-btn'}
                onPress={() => {
                  this.setState({ listView: true });
                  this.props.toogleView(true);
                }}
                popup="List View"
                image={listView ? listWhite : listBlue}
              />
              <CustomPopupButton
                classNm={!listView ? 'yes-btn' : 'no-btn'}
                onPress={() => {
                  this.setState({ listView: false });
                  this.props.toogleView(false);
                }}
                popup="Map View"
                image={!listView ? mapWhite : mapBlue}
              />
            </div>
            {user.role !== 'Read Only Office Admin' && (
              <Popup
                trigger={
                  <Button
                    primary={true}
                    className="adjuster-action-button"
                    onClick={() => this.props.showMsgModal(true, 'edit')}
                  >
                    <Image src={messageIcon} />
                  </Button>
                }
                basic={true}
                size="mini"
                content={'Send Message'}
              />
            )}
            <Popup
              trigger={
                <Button
                  primary={true}
                  disabled={this.props.adjusters.length === 0}
                  className="adjuster-action-button"
                  onClick={this.props.export}
                >
                  <Image src={downloadIcon} />
                </Button>
              }
              basic={true}
              size="mini"
              content={'Export list'}
            />

            <Button primary={true} onClick={() => this.props.showFilters(!this.props.filter)}>
              Filter
            </Button>
            {user.role !== 'Read Only Office Admin' && (
              <Button
                className="add-admin-button"
                onClick={() => services.router.goto('/create-adjuster')}
              >
                Create
              </Button>
            )}
          </div>
        </div>
        <StandbyInviteModal standby={false} global={true} updateData={() => console.log()} />
      </>
    );
  }
}

export default AdjustersListHeader;
