import { toast } from 'react-toastify';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.FETCH_FINANCE_LIST, fetchFinanceListSaga);
  yield takeEvery(constants.UPLOAD_FINANCIAL_FILE, uploadFinancialFileSaga);
  yield takeEvery(constants.REMOVE_UPLOAD_FINANCIAL_FILE, removeUploadFinancialFileSaga);
  yield takeEvery(constants.FETCH_FINANCE_ADJUSTER_LIST, fetchFinanceAdjusterListSaga);
  yield takeEvery(constants.FETCH_FINANCE_ADMIN_LIST, fetchFinanceAdminListSaga);
  yield takeEvery(constants.EXPORT_FINANCE_LIST, exportFinanceListSaga);
  yield takeEvery(constants.DELETE_FINANCIAL_FILE, deleteFinanceFileSaga);
  yield takeEvery(constants.MATCH_FINANCES_ADJUSTER, matchFinancesAdjusterSaga);
  yield takeEvery(constants.FETCH_INITIAL_ADJUSTERS, fetchInitialAdjustersSaga);
  yield takeEvery(constants.SAVE_FINANCES, saveFinancesSaga);
  yield takeEvery(constants.DOWNLOAD_FINANCE, downloadFinanceSaga);
  yield takeEvery(constants.DOWNLOAD_ADJUSTER_FINANCE, downloadAdjusterFinanceSaga);
}

function* fetchFinanceListSaga(action: ActionType<typeof actions.fetchFinanceList>) {
  const { params, sort } = action.payload;
  try {
    const response = yield call(services.api.finance.fetchFinanceList, params, sort);
    if (!response.isSuccess) {
      yield put(actions.fetchFinanceListDone(response.getError(), null));
    } else {
      yield all([
        call(services.api.finance.getFinanceUploaderAvatarUrl, response.data.list),
        call(services.api.finance.getFinanceAdjusterAvatarUrl, response.data.list),
      ]);
      yield put(actions.fetchFinanceAdjusterList());
      yield put(actions.fetchFinanceAdminList());
      yield put(actions.fetchFinanceListDone(null, response.data));
    }
  } catch (err) {
    console.error('fetchFinanceListSaga error', err);
    yield put(actions.fetchFinanceListDone(err.message, null));
  }
}

function* uploadFinancialFileSaga(action: ActionType<typeof actions.uploadFinancialFile>) {
  const { file, index } = action.payload;

  try {
    const uploadResponse = yield call(
      services.api.finance.uploadFinanceFile,
      file.file,
      file.matchedAdjuster.id,
      file.matchedStatus !== 'matching' ? file.matchedAdjuster.financeID : ''
    );

    if (!uploadResponse.isSuccess) {
      throw new Error(uploadResponse.getError());
    }

    yield put(actions.uploadFinancialFileDone(index, null, uploadResponse.data[0]));
  } catch (err) {
    console.error('uploadFinancialFileSaga error', err);
    yield put(actions.uploadFinancialFileDone(index, err.message, null));
  }
}

function* removeUploadFinancialFileSaga(
  action: ActionType<typeof actions.removeUploadFinancialFile>
) {
  const { uploadFiles } = yield select(state => state.finance);
  const { index } = action.payload;
  const file = uploadFiles[index];

  if (!file) {
    return;
  }

  if (file.data) {
    try {
      yield call(services.api.finance.deleteFinancialFile, file.data.id);
      yield put(actions.removeUploadFinancialFileDone(index));
    } catch (err) {
      console.error('removeUploadFinancialFileSaga error', err);
      yield put(actions.removeUploadFinancialFileDone(index));
    }
  } else {
    yield put(actions.removeUploadFinancialFileDone(index));
  }
}

function* fetchFinanceAdjusterListSaga(
  action: ActionType<typeof actions.fetchFinanceAdjusterList>
) {
  try {
    const response = yield call(services.api.finance.fetchAdjusterList);
    if (!response.isSuccess) {
      yield put(actions.fetchFinanceAdjusterListDone([]));
    } else {
      const data = response.data;
      yield put(actions.fetchFinanceAdjusterListDone(data));
    }
  } catch (err) {
    console.error('fetchFinanceAdjusterListSaga err', err);
    yield put(actions.fetchFinanceAdjusterListDone([]));
  }
}

function* fetchFinanceAdminListSaga(action: ActionType<typeof actions.fetchFinanceAdminList>) {
  try {
    const response = yield call(services.api.finance.fetchAdminList);
    if (!response.isSuccess) {
      yield put(actions.fetchFinanceAdminListDone([]));
    } else {
      const data = response.data;
      yield put(actions.fetchFinanceAdminListDone(data));
    }
  } catch (err) {
    console.error('fetchFinanceAdminListSaga err', err);
    yield put(actions.fetchFinanceAdminListDone([]));
  }
}

function* exportFinanceListSaga(action: ActionType<typeof actions.exportFinanceList>) {
  const { params, sort } = action.payload;
  const result = yield call(services.api.finance.exportFinanceList, params, sort);
  if (result !== true) {
    toast.error(result.error ? result.error : 'Failed to export finances.');
  }
  yield put(actions.exportFinanceListDone());
}

function* deleteFinanceFileSaga(action: ActionType<typeof actions.deleteFinancialFile>) {
  const { id } = action.payload;
  try {
    const response = yield call(services.api.finance.deleteFinancialFile, id);
    if (!response.isSuccess) {
      yield put(actions.deleteFinancialFileDone(response.getError()));
    } else {
      yield put(actions.deleteFinancialFileDone(null));
    }
  } catch (err) {
    console.error('deleteFinanceFileSaga err', err);
    yield put(actions.deleteFinancialFileDone(err.message));
  }
}

function* matchFinancesAdjusterSaga(action: ActionType<typeof actions.matchFinancesAdjuster>) {
  try {
    const { uploadFiles } = yield select(state => state.finance);
    const matchesData = yield all(
      uploadFiles.map(async (file: any) => {
        if (!file.error) {
          try {
            const response = await services.api.finance.matchAdjutser(file.name);
            if (!response.isSuccess) {
              return {
                error: response.getError(),
              };
            } else {
              return response.data;
            }
          } catch (err) {
            return {
              error: err.message,
            };
          }
        }

        return null;
      })
    );

    yield put(actions.fetchInitialAdjusters());
    yield take(constants.FETCH_INITIAL_ADJUSTERS_DONE);

    yield put(actions.matchFinancesAdjusterDone(matchesData));
  } catch (err) {
    console.error('matchFinancesAdjusterSaga err', err);
    yield put(actions.matchFinancesAdjusterDone([]));
  }
}

function* fetchInitialAdjustersSaga() {
  try {
    const { initialAdjusters } = yield select(state => state.finance);
    if (initialAdjusters.length) {
      yield put(actions.fetchInitialAdjustersDone(null));
      return;
    }

    const adjusters = yield call(services.api.adjuster.fetchAdjuster, {
      pageSize: 30,
      sort: 'firstName,asc',
    });
    yield put(actions.fetchInitialAdjustersDone(adjusters.data.list));
  } catch (err) {
    console.error('fetchInitialAdjustersSaga err', err);
    yield put(actions.fetchInitialAdjustersDone([]));
  }
}

function* saveFinancesSaga() {
  try {
    const { uploadFiles } = yield select(state => state.finance);
    const unUploadFiles = uploadFiles.filter((e) => !Boolean(e.data))

    console.log('uploadFiles', uploadFiles, unUploadFiles)

    for (let i = 0; i < uploadFiles.length; i++) {
      if (uploadFiles[i].data) continue;

      yield put(actions.uploadFinancialFile(i, uploadFiles[i]));
      yield take(constants.UPLOAD_FINANCIAL_FILE_DONE);
    }

    const latestUploadFiles = yield select(state => state.finance.uploadFiles);

    const fileError = latestUploadFiles.filter((file) => file.error)

    yield put(actions.saveFinancesDone(fileError.length > 0 ? "Failed to upload finance file" : undefined));
  } catch (err) {
    console.error('saveFinancesSaga err', err);
    yield put(actions.saveFinancesDone(err.message));
  }
}

function* downloadFinanceSaga(action: ActionType<typeof actions.downloadFinance>) {
  const { index } = action.payload;
  const { finances } = yield select(state => state.finance);
  const finance = finances[index];
  if (!finance.file || !finance.file.key) {
    toast.error('File does not exist!');
    return;
  }

  try {
    const fileUrl = yield call(services.api.aws.getDownloadFileUrl, finance.file.key);
    if (!fileUrl) {
      toast.error(`Failed to get file download url for finance file "${finance.file.name}"!`);
      return;
    }
    services.api.file.downloadFile(fileUrl, finance.file.name);
  } catch (err) {
    console.error('downloadFinanceSaga err', err);
    toast.error(`Failed to download finance file "${finance.file.name}"!`);
  }
}

function* downloadAdjusterFinanceSaga(action: ActionType<typeof actions.downloadAdjusterFinance>) {
  const { file } = action.payload;

  try {
    const fileUrl = yield call(services.api.aws.getDownloadFileUrl, file.key);
    if (!fileUrl) {
      toast.error(`Failed to get file download url for finance file "${file.name}"!`);
      return;
    }
    services.api.file.downloadFile(fileUrl, file.name);
  } catch (err) {
    console.error('downloadFinanceSaga err', err);
    toast.error(`Failed to download finance file "${file.name}"!`);
  }
}
