import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Adjusters {
  activeAdj: object[];
  inactiveAdj: object[];
  pendingAdj: object[];
  toReviewAdj: object[];
  activeCount: number | undefined;
  inactiveCount: number | undefined;
  pendingCount: number | undefined;
  toReviewCount: number | undefined;
  unreadMessages: object[];
}

export const createAdjustersState: Adjusters = {
  activeAdj: [],
  inactiveAdj: [],
  pendingAdj: [],
  toReviewAdj: [],
  activeCount: 0,
  inactiveCount: 0,
  pendingCount: 0,
  toReviewCount: 0,
  unreadMessages: [],
};

export interface State {
  adjusters: Adjusters;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  adjusters: (state = createAdjustersState, action) => {
    switch (action.type) {
      case constants.GET_DASHBOARD_ADJUSTERS:
        return { ...state };
      case constants.SUBMIT_DASHBOARD_DATA:
        return {
          ...state,
          pendingAdj: action.payload.data.adjustersPending,
          pendingCount: action.payload.data.pendingAdjustersCount,
          inactiveCount: action.payload.data.inactiveAdjustersCount,
          activeCount: action.payload.data.activeAdjustersCount,
          toReviewAdj: action.payload.data.adjustersToReview,
          unreadMessages: action.payload.data.unreadMessages
            ? action.payload.data.unreadMessages
            : [],
        };

      default:
        return state;
    }
  },
});
