import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Confirm, Grid, Icon, Image, Menu, Popup, Search } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import trashIconW from '../../assets/images/White/trash.svg';
import { adminActions } from '../../features/admin';
import { IUser } from '../../models';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';
import { NoResults } from '../no-results-placeholder/NoResults';
import { LoadingSpiner } from './../../components/loading-spinner/LoadingSpinner';
import CreateMsgTemplate from './../msg-teplates-layout/CreateMsgTemplate';

interface Props {
  user: IUser;
  getTemplates: typeof adminActions.getAllMsgTemplates;
  showCreateModal: (show: boolean) => void;
  showModal: boolean;
  templates: object[];
}

interface State {
  msgNameList: object[];
  initialList: object[];
  activeListItem: number;
  activeTemplate: object;
  activeTemplateIndex: number;
  showConfirm: boolean;
}

class MsgTemplate extends React.Component<Props, State> {
  public state = {
    msgNameList: [],
    initialList: [],
    activeListItem: 0,
    activeTemplate: {},
    activeTemplateIndex: 0,
    showConfirm: false,
  };

  public componentDidMount() {
    this.props.getTemplates();
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.templates !== this.props.templates) {
      if (this.props.templates.length === 0) {
        this.setState({ msgNameList: [], activeTemplate: [] });
      } else if (prevProps.templates.length === 0 && this.props.templates.length !== 0) {
        this.setState({
          msgNameList: this.props.templates,
          initialList: this.props.templates,
          activeListItem: this.props.templates[0].id,
          activeTemplate: this.props.templates[0],
        });
      } else {
        this.setState({
          msgNameList: this.props.templates,
          initialList: this.props.templates,
          activeListItem: this.props.templates[this.state.activeTemplateIndex].id,
          activeTemplate: this.props.templates[this.state.activeTemplateIndex],
        });
      }
    }
  }

  public handleSearchList = (event: React.SyntheticEvent, data: any) => {
    const { initialList } = this.state;
    const filtered = initialList.filter((el: any) =>
      el.name.toLowerCase().includes(data.value.toLowerCase())
    );
    this.setState({ msgNameList: filtered });
  };

  public handleItemClick = (index: number) => {
    const msgIndex = this.state.msgNameList.findIndex((el: any) => el.id === index);
    this.setState({ activeListItem: index, activeTemplate: this.state.msgNameList[msgIndex] });
  };

  public handleTemplateChange = (e: React.SyntheticEvent, name: any) => {
    this.setState({
      activeTemplate: {
        ...this.state.activeTemplate,
        [name]: e.target.value,
      },
    });
  };

  public deleteList = async () => {
    this.setState({ activeTemplateIndex: 0 }, async () => {
      const response = await services.api.settings.deleteTemplate(this.state.activeTemplate.id);
      if (response.isSuccess) {
        toast.dismiss();
        toast.success(
          `Message Template ${this.state.activeTemplate.name} was successfully deleted!`
        );
        this.props.getTemplates();
      } else {
        toast.dismiss();
        toast.error(response.data.errors[0].msg);
      }
    });
  };

  public setActiveTemplate = (id: number) => {
    const { msgNameList } = this.state;
    const index = msgNameList.findIndex(el => el.id === id);
    if (index === -1) {
      this.setState({ activeTemplateIndex: msgNameList.length - 1 });
    } else {
      this.setState({ activeTemplateIndex: index });
    }
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    const { msgNameList, activeListItem, activeTemplate } = this.state;

    return (
      <React.Fragment>
        <Grid>
          <LoadingSpiner area="message-templates-area" />

          <Grid.Row className="questions-grid-row">
            <Grid.Column className="questionnarie-list-column">
              <Menu secondary={true} vertical={true} className="questionnarie-list-container">
                <Search
                  input={{ iconPosition: 'left' }}
                  icon={
                    <Icon>
                      <Image src={searchIcon} />
                    </Icon>
                  }
                  placeholder="Search..."
                  onSearchChange={this.handleSearchList}
                  showNoResults={false}
                />

                {msgNameList.length !== 0 ? (
                  msgNameList.map((el: any, index: number) => {
                    return (
                      <Menu.Item
                        name={el.name}
                        active={activeListItem === el.id}
                        onClick={(e: any, { name }: any) => this.handleItemClick(el.id)}
                        key={index}
                      />
                    );
                  })
                ) : (
                  <NoResults />
                )}
              </Menu>
            </Grid.Column>

            <Grid.Column className="questions-list-container">
              <div className="manage-questions-header">
                <div>Details</div>
                <div className="questions-actions-buttons">
                  <Popup
                    trigger={
                      <Button
                        disabled={
                          this.props.user.role === 'Read Only Office Admin' ||
                          msgNameList.length === 0
                        }
                        primary={true}
                        onClick={() => this.setState({ showConfirm: true })}
                      >
                        <Image src={trashIconW} />
                      </Button>
                    }
                    size="mini"
                    basic={true}
                    content="Delete"
                  />
                  <Button
                    disabled={
                      this.props.user.role === 'Read Only Office Admin' || msgNameList.length === 0
                    }
                    form="template-form"
                    style={{ width: '115px' }}
                    primary={true}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
              {msgNameList.length !== 0 ? (
                <Form
                  id="template-form"
                  onValidSubmit={async (model: { name: string; title: string; text: string }) => {
                    const response = await services.api.settings.editMsgTemplate(
                      this.state.activeTemplate.id,
                      {
                        ...model,
                        name: model.name.trim(),
                        title: model.title.trim(),
                        text: model.text.trim(),
                      }
                    );

                    if (response.isSuccess) {
                      toast.dismiss();
                      toast.success(`Message Template ${model.name} was successfully updated!`);
                      this.setActiveTemplate(this.state.activeTemplate.id);
                    } else {
                      toast.dismiss();
                      toast.error(response.data.errors[0].msg);
                    }

                    this.props.getTemplates();
                  }}
                >
                  <div className="input-field-container">
                    <div className="input-field-label">Name</div>
                    <div className="message-template-input-container">
                      <Form.Input
                        name="name"
                        maxLength={60}
                        disabled={this.props.user.role === 'Read Only Office Admin'}
                        placeholder="Please Enter..."
                        instantValidation={false}
                        required={true}
                        validations={{
                          isOnlySpaces: true,
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '* Name is required field.',
                          isOnlySpaces: '* Name is required field.',
                        }}
                        className="message-template-input"
                        errorLabel={errorLabel}
                        value={activeTemplate.name}
                        onChange={(e: any) => this.handleTemplateChange(e, 'name')}
                      />
                    </div>
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">Title</div>
                    <div className="message-template-input-container">
                      <Form.Input
                        name="title"
                        maxLength={128}
                        disabled={this.props.user.role === 'Read Only Office Admin'}
                        placeholder="Please Enter..."
                        instantValidation={false}
                        required={true}
                        validations={{
                          isOnlySpaces: true,
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '* Title is required field.',
                          isOnlySpaces: '* Title is required field.',
                        }}
                        className="message-template-input"
                        errorLabel={errorLabel}
                        value={activeTemplate.title}
                        onChange={(e: any) => this.handleTemplateChange(e, 'title')}
                      />
                    </div>
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">Message</div>
                    <div className="message-template-input-container">
                      <Form.TextArea
                        name="text"
                        maxLength={1024}
                        disabled={this.props.user.role === 'Read Only Office Admin'}
                        placeholder="Please Enter..."
                        instantValidation={false}
                        required={true}
                        validations={{
                          isOnlySpaces: true,
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '* Message is required field.',
                          isOnlySpaces: '* Message is required field.',
                        }}
                        className="message-template-input"
                        errorLabel={errorLabel}
                        value={activeTemplate.text}
                        onChange={(e: any) => this.handleTemplateChange(e, 'text')}
                      />
                    </div>
                  </div>
                </Form>
              ) : (
                <NoResults />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <CreateMsgTemplate
          showCreateModal={this.props.showCreateModal}
          showModal={this.props.showModal}
          reloadMsg={this.props.getTemplates}
          selectCreated={this.setActiveTemplate}
        />
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={`Are you sure you want to delete message template ${this.state.activeTemplate.name}?`}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => {
            this.deleteList();
            this.setState({ showConfirm: false });
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  templates: state.admin.questions.msgTemplates,
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTemplates: adminActions.getAllMsgTemplates,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MsgTemplate);
