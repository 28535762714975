import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Adjusters {
  adjustersList: object[];
  adjustersMap: object[];
  adminAdjusters: object[];
  count: number;
  distances: object;
  eventLocationCoords: object;
  activeTab: string;
  activePage: number;
}

export const AdjustersDefaultState: Adjusters = {
  adjustersList: [],
  adjustersMap: [],
  adminAdjusters: [],
  distances: {},
  eventLocationCoords: {},
  count: 0,
  activeTab: 'Pending',
  activePage: 1,
};

export interface State {
  adjusters: Adjusters;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  adjusters: (state = AdjustersDefaultState, action) => {
    switch (action.type) {
      case constants.GET_ADJUSTERS_LIST:
        return {
          ...state,
        };

      case constants.SUBMIT_ADJUSTERS_LIST:
        return {
          ...state,
          adjustersList: action.payload.adjusters,
          count: action.payload.count,
          eventLocationCoords: action.payload.eventLocationCoords,
        };

      case constants.SUBMIT_ADJUSTERS_MAP:
        return {
          ...state,
          adjustersMap: action.payload.adjusters,
          distances: action.payload.distances,
          eventLocationCoords: action.payload.eventLocationCoords || {},
        };

      case constants.SET_ADMIN_ADJUSTERS:
        return {
          ...state,
          adminAdjusters: action.payload.adjusters,
        };

      case constants.RESET_ADJUSTERS_LIST:
        return {
          ...state,
          adjustersList: [],
        };

      case constants.SET_ACTIVE_LIST_TAB:
        return {
          ...state,
          activeTab: action.payload.tab,
        };

      case constants.SET_ACTIVE_PAGE_ADJ_LIST:
        return {
          ...state,
          activePage: action.payload.page,
        };

      default:
        return state;
    }
  },
});
