import { Folder } from './trainingCenterReducer';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function* () {
  yield takeEvery(constants.SEARCH_MATERIAL, searchMaterial);
  // yield takeEvery(constants.SEARCH_MATERIAL_SUBFOLDER, searchMaterialSubfolder);
  yield takeEvery(constants.MATERIAL_DELETE, deleteMaterial);
  yield takeEvery(constants.MATERIAL_BULK_DELETE, deleteMaterials);
  yield takeEvery(constants.CREATE_EMBEDDED_VIDEO_MATERIAL, createEmbeddedVideoMaterial);
  yield takeEvery(constants.CREATE_DOCUMENT_MATERIAL, createDocumentMaterial);
  yield takeEvery(constants.EDIT_EMBEDDED_VIDEO_MATERIAL, editEmbeddedVideoMaterial);
  yield takeEvery(constants.EDIT_DOCUMENT_MATERIAL, editDocumentMaterial);
}

export function* createEmbeddedVideoMaterial(
  action: ActionType<typeof actions.uploadEmbeddedVideoMaterial>
) {
  const { data } = action.payload;
  const requestList = yield call(services.api.material.createEmbeddedVideoMaterial, data);
  if (requestList.isSuccess) {
    toast.success(`Embedded video created successfully!`);
    yield put(actions.uploadEmbeddedVideoMaterialDone());
    const { pagingInfo } = yield select((state) => state.trainingCenter.material);
    yield put(actions.searchMaterial({ ...pagingInfo, resetSelect: true }));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* createDocumentMaterial(action: ActionType<typeof actions.uploadDocumentMaterial>) {
  try {
    const materialsToCreate = [];
    for (const material of action.payload.data) {
      materialsToCreate.push(call(services.api.material.uploadDocumentMaterial, material));
    }

    yield all(materialsToCreate);
    toast.success(`Materials created successfully!`);
    yield put(actions.uploadEmbeddedVideoMaterialDone());
    const { pagingInfo } = yield select((state) => state.trainingCenter.material);
    yield put(actions.searchMaterial({ ...pagingInfo, resetSelect: true }));
  } catch (error) {
    toast.error(`There was an error while creating the materials`);
  }
}

export function* editEmbeddedVideoMaterial(
  action: ActionType<typeof actions.editEmbeddedVideoMaterial>
) {
  const { id, data } = action.payload;
  const requestList = yield call(services.api.material.updateEmbeddedVideoMaterial, id, data);
  if (requestList.isSuccess) {
    toast.success(`Embedded video updated successfully!`);
    yield put(actions.editEmbeddedVideoMaterialDone());
    const { pagingInfo } = yield select((state) => state.trainingCenter.material);
    yield put(actions.searchMaterial({ ...pagingInfo, resetSelect: true }));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* editDocumentMaterial(action: ActionType<typeof actions.editDocumentMaterial>) {
  try {
    const { id, data } = action.payload;
    const response = yield call(services.api.material.uploadEditedDocumentMaterial, id, data);
    if (response.isSuccess) {
      toast.success(`Material updated successfully!`);
      yield put(actions.editDocumentMaterialDone());
      const { pagingInfo } = yield select((state) => state.trainingCenter.material);
      yield put(actions.searchMaterial({ ...pagingInfo, resetSelect: true }));
    } else {
      yield put(actions.editDocumentMaterialDone());
      toast.dismiss();
      toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
    }
  } catch (error) {
    toast.error(`There was an error while updating material`);
  }
}

export function* searchMaterial2(action: ActionType<typeof actions.searchMaterial>) {
  const { pagingInfo } = action.payload;
  const { filter } = pagingInfo;
  console.log("search material 2 saga")
  const foldersList: Folder[] = yield select((state) => state.trainingCenter.folderList);
  const folderIds: number[] = [];

  for (const folder of foldersList) {
    if (folder.active) {
      folderIds.push(folder.id as number);
    }

    for (const sub of folder.children) {
      if (sub.active || sub.parentActive) {
        folderIds.push(sub.id as number);
      }
    }
  }

  if (folderIds.length > 0) {
    filter.folderIds = folderIds.join(',');
  } else {
    filter.folderIds = '';
  }

  const requestList = yield call(services.api.material.searchMaterial, pagingInfo, filter);
  if (requestList.isSuccess) {
    yield put(
      actions.setMaterialSearchResult(requestList.data.data.list, requestList.data.data.count)
    );
    if (pagingInfo.resetSelect) {
      // search all with no pagination = false
      filter.noPagination = true;
      const apiAllRequest = yield call(services.api.material.searchMaterial, pagingInfo, filter);
      yield put(actions.setMaterialAllSearchResult(apiAllRequest.data.data.list));
    }
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* searchMaterial(action: ActionType<typeof actions.searchMaterial>) {
  const { pagingInfo } = action.payload;
  const { filter } = pagingInfo;

  const requestList = yield call(services.api.material.searchMaterial, pagingInfo, filter);
  if (requestList.isSuccess) {
    yield put(
      actions.setMaterialSearchResult(requestList.data.data.list, requestList.data.data.count)
    );
    if (pagingInfo.resetSelect) {
      // search all with no pagination = false
      filter.noPagination = true;
      const apiAllRequest = yield call(services.api.material.searchMaterial, pagingInfo, filter);
      yield put(actions.setMaterialAllSearchResult(apiAllRequest.data.data.list));
    }
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}


export function* deleteMaterial(action: ActionType<typeof actions.deleteMaterial>) {
  const { material } = action.payload;

  const requestList = yield call(services.api.material.deleteMaterial, [material.id]);
  if (requestList.isSuccess) {
    toast.success(`File ${material.name} was successfully deleted!`);
    const { pagingInfo } = yield select((state) => state.trainingCenter.material);
    yield put(actions.searchMaterial({ ...pagingInfo, resetSelect: true }));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* deleteMaterials(action: ActionType<typeof actions.bulkDeleteMaterial>) {
  const { ids } = action.payload;

  const requestList = yield call(services.api.material.deleteMaterial, ids);
  if (requestList.isSuccess) {
    toast.success(`${ids.length} files were successfully deleted!`);
    const { pagingInfo } = yield select((state) => state.trainingCenter.material);
    yield put(actions.searchMaterial({ ...pagingInfo, resetSelect: true }));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}
