import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import accountReducer from '../features/account/reducer';
import adjustersReducer from '../features/adjusters/reducer';
import adminReducer from '../features/admin/reducer';
import dashboardReducer from '../features/dashboard/reducer';
import { financeReducer } from '../features/finance';
import loadingReducer from '../features/loading/reducer';
import messagesReducer from '../features/messages/reducer';
import modalReducer from '../features/modal/reducer';
import profileReducer from '../features/profile/reducer';
import standbyReducer from '../features/standby/reducer';
import trainingCenter from '../features/training-center/trainingCenterReducer';

// create browser history
export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  loading: loadingReducer,
  modal: modalReducer,
  account: accountReducer,
  profile: profileReducer,
  admin: adminReducer,
  finance: financeReducer,
  adjusters: adjustersReducer,
  dashboard: dashboardReducer,
  standby: standbyReducer,
  messages: messagesReducer,
  trainingCenter: trainingCenter
});

export default rootReducer;
