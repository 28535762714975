import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { Pagination, Dropdown } from 'semantic-ui-react';
import { PaginationInfo } from '../../../features/training-center/trainingCenterReducer';
import '../../../assets/styles/Staff.css';

const paginationOptions = ['20', '50', '100', '200'].map(c => ({
  key: c,
  text: c,
  value: c,
}));

interface Props {
  pagingInfo?: PaginationInfo;
  handlePagination: any;
  handlePageSize: any;
}

interface State {}

class MaterialPagination extends React.Component<Props, State> {
  public handlePagination = (e: any, data: any) => {
    console.log('handlePagination', { e, data });

    if (data.pageSize) {
      data.activePage = 1;
    }

    this.props.handlePagination(e, data);
  };

  public render() {
    const { pagingInfo } = this.props;
    if (!pagingInfo) {
      return <React.Fragment></React.Fragment>;
    }
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: '10px',
          }}
        >
          <Dropdown
            className="pagesCountDropdown"
            defaultValue={'20'}
            style={{ height: 'auto', minHeight: 'auto' }}
            selection={true}
            options={paginationOptions}
            onChange={(event, data) =>
              this.props.handlePageSize(event, {
                pageSize: data.value,
              })
            }
          />
          {pagingInfo.totalPages > 1 && (
            <Pagination
              totalPages={pagingInfo.totalPages}
              boundaryRange={1}
              activePage={pagingInfo.activePage}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                pagingInfo.activePage === 1
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                pagingInfo.activePage === pagingInfo.totalPages
                  ? false
                  : {
                      'aria-label': 'Next item',
                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={this.handlePagination}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default MaterialPagination;
