import React from 'react';
import { List, Message } from 'semantic-ui-react';

interface Props {
  errors: string[];
}

class ErrorListMessage extends React.Component<Props> {
  public render() {
    const { errors } = this.props;

    if (errors.length === 0) {
      return null;
    }

    if (errors.length === 1) {
      return <Message error={true}>{errors[0]}</Message>;
    }

    return (
      <Message error={true}>
        <List>
          {errors.map(error => {
            return (
              <List.Item key={error}>
                <List.Icon name="warning sign" />
                <List.Content>{error}</List.Content>
              </List.Item>
            );
          })}
        </List>
      </Message>
    );
  }
}

export default ErrorListMessage;
