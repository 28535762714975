import React from 'react';
import { Form } from 'formsy-semantic-ui-react';
import { Button } from 'semantic-ui-react';
import ErrorLabel from '../../error-label/ErrorLabel';
import {EmbeddedVideoData} from '../shared/types';
import FolderInputTags from '../shared/FolderInputTags';
import { toast } from 'react-toastify';

interface Props {
  onFinish(data: EmbeddedVideoData): void;
  setSubmitRef(ref: any): void;
}

interface State {
  url: string;
  description: string;
  name: string;
  selectedFolders: any[];
}

class UploadEmbeddedVideo extends React.Component<Props, State> {
  public submitRef: any = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      url: '',
      description: '',
      name: '',
      selectedFolders: [],
    };
  }

  public componentDidMount() {
    this.props.setSubmitRef(this.submitRef);
  }

  public setFolders = (folders: any[]) => {
    this.setState({ selectedFolders: folders });
  }

  public onValidSubmit = (model: any) => {
    if (this.state.selectedFolders.length === 0) {
      toast.error('No folders assigned');
      return;
    }

    model.folderIds = this.state.selectedFolders.map((f) => f.id);

    this.props.onFinish(model);
  }

  public render() {
    const errorLabel = <ErrorLabel />;

    return (
      <Form
        className="upload-embedded-video"
        onValidSubmit={this.onValidSubmit}
      >
        <div className="input-field-container">
          <div className="input-field-label">URL</div>
          <Form.Input
            className="form-input"
            name="url"
            instantValidation={false}
            required={true}
            placeholder="Please Enter..."
            validationErrors={{
              isDefaultRequiredValue: 'Please provide the video url',
            }}
            errorLabel={errorLabel}
            value={this.state.url}
          />
        </div>
        <div className="input-field-container">
          <div className="input-field-label">Name</div>
          <Form.Input
            className="form-input"
            name="name"
            placeholder="Please Enter..."
            instantValidation={false}
            required={true}
            validationErrors={{
              isDefaultRequiredValue: 'Please provide the video name',
            }}
            errorLabel={errorLabel}
            value={this.state.name}
          />
        </div>
        <div className="input-field-container">
          <div className="input-field-label">Description</div>
          <Form.Input
            className="form-input"
            name="description"
            placeholder="Please Enter..."
            instantValidation={false}
            required={true}
            validationErrors={{
              isDefaultRequiredValue: 'Please provide the video description',
            }}
            errorLabel={errorLabel}
            value={this.state.description}
          />
        </div>
        <div className="spacer" />
        <div className="input-field-container">
          <FolderInputTags
            title="Folder"
            folders={this.state.selectedFolders}
            setFolders={this.setFolders}
          />
        </div>
        <Button
          ref={this.submitRef}
          type="submit"
          formNoValidate={true}
          style={{ display: 'none' }}
        />
      </Form>
    );
  }
}

export default UploadEmbeddedVideo;
