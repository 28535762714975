export interface AdjusterUserDTO {
  authorityLevel: number;
  commercialTeam: boolean;
  deployed: boolean;
  deployments: object[];
  financeID: string;
  internalNote: string;
  largeLossTeam: boolean;
  status: string;
  certificationScore: number;
  fieldInterviewScore: number;
  deskInterviewScore: number;
  qaScore: number;
  id: string;
  availableForClaims: boolean;
  availableForClaimsNotes: boolean;
  avatar: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthday: number;
  compassEmail: string;
  email: string;
  password: string;
  passwordConfirm: string;
  phone: string;
  mobilePhone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  fax: string;
  catCity: string;
  catZip: string;
  catStateProvince: string;
  catExpirationDate: string;
  positions: string[];
  educations: object[];
  references: object[];
  experiences: object[];
  languages: string[];
  softwareSkills: object[];
  equipments: string[];
  expertise: object[];
  climbRoof: boolean;
  milesRange: number;
  xactnetAddress: string;
  emailConfirmed: boolean;
  isSubscribedToCompassMailUpdates: boolean;
  isSubscribedToPersonalMailUpdates: boolean;
  createdAt: number;
  updatedAt: number;
  howDidYouHearAboutUs: string;
  hasUnreadMessages: boolean;
  registrationType: string;
  companyName: string;
  isAdmin: boolean;
  temporaryLocation: boolean;
  availableForClaimsExpirationDate: string;
}

export enum UserGender {
  Male = 'Male',
  Female = 'Female',
  TransgenderNonBinaryOther = 'Transgender/Non-Binary/Other',
  PreferNotToDisclose = 'Prefer not to disclose',
}

export enum UserRace {
  HispanicOrLatino = 'Hispanic or Latino',
  AmericanIndianOrAlaskaNative = 'American Indian or Alaska Native',
  Asian = 'Asian',
  BlackOrAfricanAmerican = 'Black or African American',
  NativeHawaiianOrOtherPacificIslander = 'Native Hawaiian or Other Pacific Islander',
  White = 'White',
  PreferNotToDisclose = 'Prefer not to disclose',
}

export interface AdjusterUser {
  // constructor: {
  //   create(user: AdjusterUserDTO): AdjusterUser;
  // };
  authorityLevel: number;
  commercialTeam: boolean;
  deployed: boolean;
  deployments: object[];
  financeID: string;
  internalNote: string;
  largeLossTeam: boolean;
  status: string;
  certificationScore: number;
  fieldInterviewScore: number;
  deskInterviewScore: number;
  qaScore: number;
  serviceFeePercent?: number;
  tAndEPercent?: number;
  photosPercent?: number;
  miscExpensePercent?: number;
  mileagePercent?: number;
  id: string;
  availableForClaims: boolean;
  availableForClaimsNotes: boolean;
  avatar: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: UserGender;
  race: UserRace;
  birthday: number;
  compassEmail: string;
  email: string;
  password: string;
  passwordConfirm: string;
  phone: string;
  mobilePhone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  catZip: string;
  fax: string;
  catCity: string;
  catStateProvince: string;
  catExpirationDate: string;
  positions: string[];
  educations: object[];
  references: object[];
  experiences: object[];
  languages: string[];
  softwareSkills: object[];
  equipments: string[];
  expertise: object[];
  climbRoof: boolean;
  milesRange: number;
  xactnetAddress: string;
  emailConfirmed: boolean;
  isSubscribedToCompassMailUpdates: boolean;
  isSubscribedToPersonalMailUpdates: boolean;
  createdAt: number;
  updatedAt: number;
  howDidYouHearAboutUs: string;
  hasUnreadMessages: boolean;
  registrationType: string;
  companyName: string;
  isAdmin: boolean;
  temporaryLocation: boolean;
  availableForClaimsExpirationDate: string;
  serialize(): AdjusterUserDTO;
}

export class AdjUser implements AdjusterUser {
  get fullName(): any {
    return `${this.firstName} ${this.lastName}`;
  }

  public static create(dto: AdjusterUserDTO): AdjusterUser {
    const model = new AdjUser(
      dto.authorityLevel,
      dto.commercialTeam,
      dto.deployed,
      dto.deployments,
      dto.financeID,
      dto.internalNote,
      dto.largeLossTeam,
      dto.status,
      dto.certificationScore,
      dto.fieldInterviewScore,
      dto.deskInterviewScore,
      dto.qaScore,
      dto.id,
      dto.availableForClaims,
      dto.availableForClaimsNotes,
      dto.avatar,
      dto.firstName,
      dto.middleName,
      dto.lastName,
      dto.gender,
      dto.race,
      dto.birthday,
      dto.compassEmail,
      dto.email,
      dto.password,
      dto.passwordConfirm,
      dto.phone,
      dto.mobilePhone,
      dto.addressLine1,
      dto.addressLine2,
      dto.city,
      dto.state,
      dto.zip,
      dto.fax,
      dto.catCity,
      dto.catStateProvince,
      dto.catZip,
      dto.catExpirationDate,
      dto.positions,
      dto.educations,
      dto.references,
      dto.experiences,
      dto.languages,
      dto.softwareSkills,
      dto.equipments,
      dto.expertise,
      dto.climbRoof,
      dto.milesRange,
      dto.xactnetAddress,
      dto.emailConfirmed,
      dto.isSubscribedToCompassMailUpdates,
      dto.isSubscribedToPersonalMailUpdates,
      dto.createdAt,
      dto.updatedAt,
      dto.howDidYouHearAboutUs,
      dto.hasUnreadMessages,
      dto.registrationType,
      dto.companyName,
      dto.isAdmin,
      dto.temporaryLocation,
      dto.availableForClaimsExpirationDate
    );
    model.id = dto.id;

    return model;
  }
  // 'constructor': typeof User;

  // public id: string = '_';

  constructor(
    public authorityLevel: number,
    public commercialTeam: boolean,
    public deployed: boolean,
    public deployments: object[],
    public financeID: string,
    public internalNote: string,
    public largeLossTeam: boolean,
    public status: string,
    public id: string = '_',
    public availableForClaims: boolean,
    public availableForClaimsNotes: boolean,
    public avatar: string,
    public firstName: string,
    public middleName: string,
    public lastName: string,
    public gender: UserGender,
    public race: UserRace,
    public birthday: number,
    public compassEmail: string,
    public email: string,
    public password: string,
    public passwordConfirm: string,
    public phone: string,
    public mobilePhone: string,
    public addressLine1: string,
    public addressLine2: string,
    public city: string,
    public state: string,
    public zip: string,
    public fax: string,
    public catCity: string,
    public catZip: string,
    public catStateProvince: string,
    public catExpirationDate: string,
    public positions: string[],
    public educations: object[],
    public references: object[],
    public experiences: object[],
    public languages: string[],
    public softwareSkills: object[],
    public equipments: string[],
    public expertise: object[],
    public climbRoof: boolean,
    public milesRange: number,
    public xactnetAddress: string,
    public emailConfirmed: boolean,
    public isSubscribedToCompassMailUpdates: boolean,
    public isSubscribedToPersonalMailUpdates: boolean,
    public createdAt: number,
    public updatedAt: number,
    public howDidYouHearAboutUs: string,
    public hasUnreadMessages: boolean,
    public registrationType: string,
    public companyName: string,
    public isAdmin: boolean,
    public temporaryLocation: boolean,
    public availableForClaimsExpirationDate: string
  ) {}

  public serialize(): AdjusterUserDTO {
    return {
      authorityLevel: this.authorityLevel,
      commercialTeam: this.commercialTeam,
      deployed: this.deployed,
      deployments: this.deployments,
      financeID: this.financeID,
      internalNote: this.internalNote,
      largeLossTeam: this.largeLossTeam,
      status: this.status,
      id: this.id,
      availableForClaims: this.availableForClaims,
      avatar: this.avatar,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      gender: this.gender,
      race: this.race,
      birthday: this.birthday,
      compassEmail: this.compassEmail,
      email: this.email,
      password: this.password,
      passwordConfirm: this.passwordConfirm,
      phone: this.phone,
      mobilePhone: this.mobilePhone,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      fax: this.fax,
      catCity: this.catCity,
      catZip: this.catZip,
      catStateProvince: this.catStateProvince,
      catExpirationDate: this.catExpirationDate,
      positions: this.positions,
      educations: this.educations,
      references: this.references,
      experiences: this.experiences,
      languages: this.languages,
      softwareSkills: this.softwareSkills,
      equipments: this.equipments,
      expertise: this.expertise,
      climbRoof: this.climbRoof,
      milesRange: this.milesRange,
      xactnetAddress: this.xactnetAddress,
      emailConfirmed: this.emailConfirmed,
      isSubscribedToCompassMailUpdates: this.isSubscribedToCompassMailUpdates,
      isSubscribedToPersonalMailUpdates: this.isSubscribedToPersonalMailUpdates,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      howDidYouHearAboutUs: this.howDidYouHearAboutUs,
      hasUnreadMessages: this.hasUnreadMessages,
      registrationType: this.registrationType,
      companyName: this.companyName,
      isAdmin: this.isAdmin,
      temporaryLocation: this.temporaryLocation,
      availableForClaimsExpirationDate: this.availableForClaimsExpirationDate,
      availableForClaimsNotes: this.availableForClaimsNotes,
    };
  }
}
