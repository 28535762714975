import classnames from 'classnames';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'semantic-ui-react';
import '../../assets/styles/Finances.css';

interface Props {
  onDrop: (files: object[]) => void;
  accept: string;
}

interface State {
  dragging: boolean;
}

class UploadZone extends React.Component<Props, State> {
  public state = {
    dragging: false,
  };

  public handleDrop = (files: any[]) => {
    this.props.onDrop(files);
  };

  public render() {
    return (
      <Dropzone onDrop={this.handleDrop} accept={this.props.accept}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: classnames('main-content'),
            })}
          >
            <input {...getInputProps()} />
            <div className="drop-licenses-area">
              Drop file here or
              <div className="add-question">
                <Button type="button">UPLOAD</Button>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }
}

export default UploadZone;
