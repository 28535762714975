import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Grid } from 'semantic-ui-react';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import services from '../../services';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import LicenseItem from './../register-layout/LicenseItem';

interface Props {
  getSubmitRef: (ref: any) => void;
  user: AdjusterUser;
  admin: IUser;
  download: (file: any) => void;
}

interface State {
  licenses: object[];
  licenseId: number | undefined;
  showEditModal: boolean;
}

class ProfileLicenses extends React.Component<Props, State> {
  public state = {
    licenses: [],
    licenseId: 0,
    showEditModal: false,
  };

  public getLicenses = async () => {
    const response = await trackPromise(
      services.api.admin.listAdjusterLicenses(this.props.user.id),
      'profile-licence'
    );
    if (response.isSuccess) {
      for (const item of response.data) {
        if (item.file) {
          const avatarUrl = await services.api.file.getDownloadFileUrl(item.file.key);
          item.file.file = avatarUrl;
        }
      }
      this.setState({ licenses: response.data });
    }
  };

  public componentDidMount() {
    this.getLicenses();
  }

  public render() {
    return (
      <Grid stackable={true} style={{ width: '100%' }} className="licenses-grid">
        <LoadingSpiner area="profile-licence" />
        <Grid.Row columns={1} only="computer">
          <Grid.Column className="licenses-column">
            <Form
              onValidSubmit={(model: any) => {
                const { licenses } = this.state;
              }}
            >
              {this.state.licenses.length === 0 ? (
                <Form.Input
                  name="licenses"
                  placeholder="Nothing Added"
                  instantValidation={false}
                  className="form-input readonly-input"
                  readOnly={true}
                />
              ) : (
                <div>
                  {this.state.licenses.map((el: any, index: number) => (
                    <LicenseItem
                      download={this.props.download}
                      item={el}
                      key={index}
                      id={index}
                      mode="license"
                    />
                  ))}
                </div>
              )}
              <Form.Input
                name="licenseFile"
                hidden={true}
                style={{ display: 'none' }}
                readOnly={true}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ProfileLicenses;
