import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import gridViewWhite from '../../../assets/images/White/gridView.svg';
import gridViewBlue from '../../../assets/images/Blue/gridView.svg';
import listViewWhite from '../../../assets/images/White/listView.svg';
import listViewBlue from '../../../assets/images/Blue/listView.svg';
import { Button, Popup, Image } from 'semantic-ui-react';
import { trainingCenterActions } from '../../../features/training-center';

interface Props {
  viewType: 'grid' | 'list';
  setViewTypeList: typeof trainingCenterActions.setViewTypeList;
  setViewTypeGrid: typeof trainingCenterActions.setViewTypeGrid;
}

interface State {

}

class SwitchViewMaterial extends React.Component<Props, State> {
  public state = {

  };


  public render() {
    const { viewType } = this.props;

    const listViewIcon = viewType === 'list' ? listViewBlue : listViewWhite;
    const gridViewIcon = viewType === 'grid' ? gridViewBlue : gridViewWhite;
    return (
      <React.Fragment>
        <div className="material-filter-upload-actions">
          <Popup
            trigger={
              <Button
                primary={true}
                className="material-action-button"
                onClick={() => {this.props.setViewTypeList() }}
              >
                <Image src={listViewIcon} />
              </Button>
            }
            basic={true}
            size="mini"
            content={'List View'}
          />
          <Popup
            trigger={
              <Button
                primary={true}
                className="material-action-button"
                onClick={() => { this.props.setViewTypeGrid() }}
              >
                <Image src={gridViewIcon} />
              </Button>
            }
            basic={true}
            size="mini"
            content={'Grid View'}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  viewType: state.trainingCenter.material.viewType,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setViewTypeGrid: trainingCenterActions.setViewTypeGrid,
      setViewTypeList: trainingCenterActions.setViewTypeList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchViewMaterial);
