import { debounce } from 'lodash';
import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import noAvaterIcon from '../../../assets/images/no-avatar.svg';
import { NoResults } from '../../../components/no-results-placeholder/NoResults';
import { FileFinancialIDStatus } from '../../../features/finance/reducer';
import ConfirmModal from '../../confirm-modal/ConfirmModal';
import DataTable from '../../data-table/DataTable';
import FinanceFilesAdjusterFinanceSelectorCells from './FinanceFilesAdjusterFinanceSelectorCells';
import MatcherFilter from './MatcherFilter';
import classNames from 'classnames';

interface Props {
  files: any[];
  adjusters: any[];
  onDeleteFile: (index: number) => void;
  onAdjusterChange: (index: number, adjuster: number) => void;
  onFinancialIdChange: (index: number, financialId: string) => void;
}

interface State {
  filterVisible: boolean;
  filter: any;
  files: any[];
  deleteConfirmVisible: boolean;
}

const initialFilter = {
  filename: '',
  adjuster: '',
  financialId: '',
};
class FinanceFilesMatcher extends React.Component<Props, State> {
  public state = {
    filterVisible: false,
    filter: {
      ...initialFilter,
    },
    files: this.props.files,
    deleteConfirmVisible: false,
  };

  private selectedFile: any = null;

  constructor(props: any) {
    super(props);
    this.handleFinancialIdChange = debounce(this.handleFinancialIdChange, 350);
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.files !== prevProps.files) {
      this.filterFiles(this.state.filter);
    }
  }

  public handleFilterPress = () => {
    this.setState({
      filterVisible: !this.state.filterVisible,
    });
  };

  public handleFilterChange = (data: any) => {
    this.changeFilter(data);
  };

  public handleFilterReset = () => {
    this.changeFilter({ ...initialFilter });
  };

  public handleDeleteFile = (file: any) => {
    this.selectedFile = file;
    this.setState({
      deleteConfirmVisible: true,
    });
  };

  public handleCloseConfirmDelete = () => {
    this.selectedFile = null;
    this.setState({
      deleteConfirmVisible: false,
    });
  };

  public handleConfirmDelete = () => {
    if (this.selectedFile) {
      const index = this.getFileIndex(this.selectedFile);
      if (index !== -1) {
        this.props.onDeleteFile(index);
      }
      this.selectedFile = null;
    }
  };

  public handleAdjusterChange = (file: any, adjuster: number) => {
    const index = this.getFileIndex(file);
    if (index !== -1) {
      this.props.onAdjusterChange(index, adjuster);
    }
  };

  public handleFinancialIdChange = (file: any, financialId: string) => {
    const index = this.getFileIndex(file);
    if (index !== -1) {
      this.props.onFinancialIdChange(index, financialId);
    }
  };

  public getDefaultFinancialIdValueFromFile = (file: any) => {
    let matched = '';
    const fileNameDelimiterCount = 3;
    let fileNameArray = file.name.split('-');
    fileNameArray =
      fileNameArray.length >= fileNameDelimiterCount ? fileNameArray : file.name.split('_');
    fileNameArray =
      fileNameArray.length >= fileNameDelimiterCount ? fileNameArray : file.name.split(' ');

    // fileNameArray[2] is the financeID
    if (fileNameArray[2] !== '' && file.matchedStatus !== FileFinancialIDStatus.MATCHING) {
      matched = fileNameArray[2];
      // console.log('1');
    } else {
      matched = file.matchedAdjuster ? file.matchedAdjuster.financeID : '';
    }

    // console.log('matched', matched);
    return matched;
  };

  public render() {
    const { adjusters } = this.props;
    const { files, filterVisible, filter, deleteConfirmVisible } = this.state;
    return (
      <div className="files-matcher-container">
        <div className="head">
          <Button color="blue" onClick={this.handleFilterPress}>
            FILTER
          </Button>
        </div>
        {filterVisible && (
          <MatcherFilter
            adjusters={adjusters.map(a => ({
              key: a.id,
              value: a.id,
              text: `${a.preferredName ? a.preferredName : a.firstName} ${a.lastName}${
                a.email ? ` (${a.email})` : ''
              } `,
              image: { avatar: true, src: a.avatar ? a.avatar : noAvaterIcon },
            }))}
            filter={filter}
            onFilterChange={this.handleFilterChange}
            onFilterReset={this.handleFilterReset}
          />
        )}
        <div className="matcher-list">
          <DataTable className="finance-files-matcher-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>File Name</Table.HeaderCell>
                <Table.HeaderCell>Adjuster</Table.HeaderCell>
                <Table.HeaderCell>Financial ID</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {files.filter((file) => !file.data).map((file: any, index: number) => (
                <Table.Row key={file.id}>
                  <Table.Cell className="col-name">{`${index + 1}. ${file.name}`}</Table.Cell>
                  <FinanceFilesAdjusterFinanceSelectorCells
                    key={file.id}
                    file={file}
                    adjusters={adjusters}
                    handleAdjusterChange={this.handleAdjusterChange}
                    handleFinancialIdChange={this.handleFinancialIdChange}
                    handleDeleteFile={this.handleDeleteFile}
                    getDefaultFinancialIdValueFromFile={this.getDefaultFinancialIdValueFromFile}
                  />
                </Table.Row>
              ))}
            </Table.Body>
          </DataTable>
          {files.length === 0 && <NoResults />}
        </div>

        {deleteConfirmVisible && this.selectedFile !== null && (
          <ConfirmModal
            visible={deleteConfirmVisible}
            onClose={this.handleCloseConfirmDelete}
            onConfirm={this.handleConfirmDelete}
            message={`Are you sure you want to remove the finance file "${
              this.selectedFile ? this.selectedFile.name : ''
            }"?`}
          />
        )}
      </div>
    );
  }

  private getFileIndex(file: any) {
    return this.props.files.findIndex(f => f === file);
  }

  private changeFilter(filter: any) {
    this.setState({ filter });
    this.filterFiles(filter);
  }

  private filterFiles(filter: any) {
    const filename = filter.filename.toLowerCase();
    const financialId = filter.financialId.toLowerCase();
    const adjuster = filter.adjuster;

    const files = this.props.files.filter((file: any) => {
      if (adjuster) {
        if (!file.matchedAdjuster || file.matchedAdjuster.id !== adjuster) {
          return false;
        }
      }
      if (filename) {
        if (file.name.toLowerCase().search(filename) === -1) {
          return false;
        }
      }
      if (financialId) {
        if (
          !file.matchedAdjuster ||
          !file.matchedAdjuster.financeID ||
          file.matchedAdjuster.financeID.toLowerCase().search(financialId) === -1
        ) {
          return false;
        }
      }

      return true;
    });

    this.setState({ files });
  }
}

export default FinanceFilesMatcher;
