import React from 'react';
import { Button, Menu } from 'semantic-ui-react';
import { IUser } from '../../models';

interface Props {
  getTabName: (name: string) => void;
  showCreateModal: (show: boolean) => void;
  user: IUser;
}

interface State {
  activeTab: string;
}

class SettingsHeader extends React.Component<Props, State> {
  public state = {
    activeTab: 'Professional Data',
  };

  public handleItemClick = (e: any, { name }: any) => {
    this.props.getTabName(name);
    this.setState({ activeTab: name });
  };

  public render() {
    const { activeTab } = this.state;
    return (
      <div className="settings-header-container">
        <Menu text={true} className="tabs-menu">
          <Menu.Item
            name="Professional Data"
            active={activeTab === 'Professional Data'}
            onClick={this.handleItemClick}
          >
            Professional Data
          </Menu.Item>
          <Menu.Item
            name="Twilio Numbers"
            active={activeTab === 'Twilio Numbers'}
            onClick={this.handleItemClick}
          >
            Twilio Numbers
          </Menu.Item>
          <Menu.Item
            name="Questionnaries"
            active={activeTab === 'Questionnaries'}
            onClick={this.handleItemClick}
          >
            Questionnaries
          </Menu.Item>
          <Menu.Item
            name="Message Templates"
            active={activeTab === 'Message Templates'}
            onClick={this.handleItemClick}
          >
            Message Templates
          </Menu.Item>
          <Menu.Item
            name="Custom Events"
            active={activeTab === 'Custom Events'}
            onClick={this.handleItemClick}
          >
            Custom Events
          </Menu.Item>
        </Menu>
        {(activeTab === 'Questionnaries' || activeTab === 'Message Templates') && (
          <Button
            className="add-admin-button"
            disabled={this.props.user.role === 'Read Only Office Admin'}
            onClick={() => this.props.showCreateModal(true)}
          >
            + Create
          </Button>
        )}
      </div>
    );
  }
}

export default SettingsHeader;
