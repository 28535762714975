import React from 'react';
import {Button, Icon, Image, Menu, Search} from 'semantic-ui-react';
import assignIcon from "../../assets/images/White/assign.svg";
import searchIcon from "../../assets/images/Blue/icn-search-blue.svg";
import MyTypes from "MyTypes";
import {bindActionCreators, Dispatch} from "redux";
import {trainingCenterActions} from "../../features/training-center";
import {connect} from "react-redux";
import {PaginationInfo} from '../../features/training-center/trainingCenterReducer';

interface Props {
  pagingInfo: PaginationInfo;
  getTabName: (name: string) => void;
  searchAccess: (pagingInfo: PaginationInfo) => void
  searchEvents: (query?: string) => void
}

interface State {
  activeTab: string;
  inputSearch: string;
}

class TrainingCenterHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 'Files',
      inputSearch: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  public handleItemClick = (e: any, {name}: any) => {
    this.props.getTabName(name);
    this.setState({activeTab: name});
  };

  handleChange(event) {
    const value = event.target.value
    this.setState({inputSearch: value});
    if (value === '') {
      this.onSearch();
    }
  }

  public onSearch = () => {
    const {activeTab} = this.state;
    if (activeTab === 'Access') {
      this.props.searchAccess({
        ...this.props.pagingInfo,
        search: this.state.inputSearch,
      });
    }

    if (activeTab === 'Custom Events') {
      this.props.searchEvents(this.state.inputSearch);
    }
  }

  public render() {
    const {activeTab} = this.state;
    return (
      <div className="training-center-header-container" style={{display: 'flex', 'justifyContent': 'space-between'}}>
        <Menu text={true} className="tabs-menu">
          <Menu.Item
            name="Files"
            active={activeTab === 'Files'}
            onClick={this.handleItemClick}
          >
            Files
          </Menu.Item>
          <Menu.Item
            name="Access"
            active={activeTab === 'Access'}
            onClick={this.handleItemClick}
          >
            Access
          </Menu.Item>
          <Menu.Item
            name="Custom Events"
            active={activeTab === 'Custom Events'}
            onClick={this.handleItemClick}
          >
            Custom Events
          </Menu.Item>
        </Menu>

        {
          activeTab !== 'Files' &&
          (
            <div className="actions" style={{display: 'flex'}}>
              <Search
                input={{iconPosition: 'left'}}
                icon={
                  <Icon>
                    <Image src={searchIcon}/>
                  </Icon>
                }
                placeholder="Search..."
                value={this.state.inputSearch}
                onSearchChange={this.handleChange}
                showNoResults={false}
                style={{marginRight: 10}}
              />
              <Button
                primary={true}
                className="material-action-button"
                onClick={this.onSearch}
              >
                <Image src={assignIcon}/>
              </Button>
            </div>
          )
        }
      </div>
    );
  }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
  pagingInfo: state.trainingCenter.access.pagingInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  searchAccess: trainingCenterActions.searchAccess,
  searchEvents: trainingCenterActions.getCustomEventList,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TrainingCenterHeader);
