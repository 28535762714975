import classnames from 'classnames';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Button, Icon, Image, Loader, Popup } from 'semantic-ui-react';

import cancelIcon from '../../../assets/images/Blue/cancel.svg';
import '../../../assets/styles/Finances.css';

interface Props {
  files: object[];
  accept: string;
  onDrop: (files: object[]) => void;
  onFileDelete: (index: number) => void;
}

interface State {
  dragging: boolean;
}

const FileUploadItem = (props: any) => {
  const { index, filename, loading, onDelete } = props;
  return (
    <div className={classnames('file-item', { loading })}>
      <div className="name">{`${index}. ${filename}`}</div>
      <div className="icon">
        {loading ? (
          <Loader size="tiny" active={true} />
        ) : (
          <Popup
            basic={true}
            size="mini"
            content="Delete"
            trigger={
              <Button icon={true} onClick={onDelete} className="delete-button">
                <Icon>
                  <Image src={cancelIcon} />
                </Icon>
              </Button>
            }
          />
        )}
      </div>
    </div>
  );
};

class UploadZone extends React.Component<Props, State> {
  public state = {
    dragging: false,
  };

  public handleFileListClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  public handleDragEnter = () => {
    this.setState({
      dragging: true,
    });
  };

  public handleDragLeave = () => {
    this.setState({
      dragging: false,
    });
  };

  public handleDrop = (files: any[]) => {
    this.setState({
      dragging: false,
    });
    this.props.onDrop(files);
  };

  public render() {
    const { files, onFileDelete } = this.props;
    const { dragging } = this.state;

    return (
      <Dropzone
        onDrop={this.handleDrop}
        accept={this.props.accept}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: classnames('main-content', { 'empty-files': files.length === 0 }),
            })}
          >
            <input {...getInputProps()} />
            {files.length > 0 && (
              <div className="file-list" onClick={this.handleFileListClick}>
                {files.map((file: any, index: number) => (
                  <FileUploadItem
                    key={file.name + '_' + index}
                    index={index + 1}
                    filename={file.name}
                    loading={file.uploading || file.removing}
                    onDelete={() => onFileDelete(index)}
                  />
                ))}
              </div>
            )}
            <div className="upload-description">
              <div className={classnames({ dragging })}>Drop file(s) here or</div>
              <div style={{ cursor: 'pointer' }}>UPLOAD</div>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }
}

export default UploadZone;
