import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Modal, Segment } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import noAvatarIcon from '../../assets/images/no-avatar.svg';
import '../../assets/styles/Staff.css';
import { uploads } from '../../config';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  id: any;
  show: boolean;
  showNoteModal: (status: boolean) => void;
  avatar: any;
  username: string;
  mode: string;
  update: (id: any) => void;
}

interface State {}

class AddNoteModal extends React.Component<Props, State> {
  public state = {};

  public render() {
    console.log('avatar', this.props.avatar);
    console.log('s3', uploads.s3BucketAddress);

    const errorLabel = <ErrorLabel />;
    return (
      <Modal
        open={this.props.show}
        className="create-admin-modal"
        closeIcon={
          <Image src={closeIcon} className="icon" onClick={() => this.props.showNoteModal(false)} />
        }
        size="mini"
      >
        <Modal.Header>Leave Internal Note</Modal.Header>
        <Modal.Content>
          <Segment className="note-modal-segment">
            <Image src={!this.props.avatar ? noAvatarIcon : this.props.avatar} avatar={true} />
            <p>{this.props.username}</p>
          </Segment>
          <Form
            id="create-note-form"
            onValidSubmit={async (model: any) => {
              const response = await services.api.adjuster.addNote(this.props.id, model.note);
              if (response.isSuccess) {
                toast.dismiss();
                toast.success('Note was successfully added!');
                if (this.props.mode === 'profile') {
                  this.props.update(this.props.id);
                }
                this.props.showNoteModal(false);
              } else {
                console.log(response);
                toast.dismiss();
                toast.error(
                  response.data ? response.data.errors[0].msg : `Error ${response.status}`
                );
              }
            }}
          >
            <label>Your Note</label>
            <Form.TextArea
              name="note"
              placeholder="Please Enter..."
              className="message-template-input"
              required={true}
              validationErrors={{
                isDefaultRequiredValue: '* Note is required field.',
              }}
              errorLabel={errorLabel}
            />
          </Form>
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            style={{ width: '115px' }}
            className="secondary-button"
            secondary={true}
            onClick={() => this.props.showNoteModal(false)}
          >
            CANCEL
          </Button>
          <Button style={{ width: '115px' }} primary={true} form="create-note-form">
            OK
          </Button>
        </div>
      </Modal>
    );
  }
}

export default AddNoteModal;
