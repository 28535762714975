import { action } from 'typesafe-actions';
import * as constants from './constants';

export const getStandbyList = (filterOptions?: object) =>
  action(constants.GET_STANDBY_LIST, { filterOptions });

export const submitStandbyList = (list: object[], count: number) =>
  action(constants.SUBMIT_STANDBY_LIST, { list, count });

export const editStandbyList = (edit: boolean) => action(constants.EDIT_STANDBY_LIST, { edit });

export const setStandbyTab = (tab: string) => action(constants.SET_STANDBY_TAB, { tab });

export const setStandbySort = (sort: any) => action(constants.SET_STANDBY_SORT, { sort });

export const setStandbySearch = (search: string) =>
  action(constants.SET_STANDBY_SEARCH, { search });

export const setStandbyMessagesSearch = (search: string) =>
  action(constants.SET_STANDBY_MESSAGES_SEARCH, { search });

export const getStandbyAdjusters = (id: any, filter?: object) =>
  action(constants.GET_STANDBY_ADJUSTERS, { id, filter });

export const setStandbyAdjusters = (list: object[], count: number) =>
  action(constants.SET_STANDBY_ADJUSTERS, { list, count });

export const getMapStandbyAdjusters = (id: any, filter?: object) =>
  action(constants.GET_MAP_STANDBY_ADJUSTERS, { id, filter });

export const setMapStandbyAdjusters = (list: object[], count: number) =>
  action(constants.SET_MAP_STANDBY_ADJUSTERS, { list, count });

export const setStandbyAdjustersStatus = (status: string) =>
  action(constants.SET_STANDBY_ADJUSTERS_STATUS, { status });

export const setActiveStandby = (id: any) => action(constants.SET_ACTIVE_STANDBY, { id });
export const setActiveStandbyTwilio = (phone: any) =>
  action(constants.SET_ACTIVE_STANDBY_TWILIO, { phone });
export const setActiveAdjuster = (user: any) => action(constants.SET_ACTIVE_ADJUSTER, { user });

export const showInviteMessage = (show: boolean) => action(constants.SHOW_INVITE_MESSAGE, { show });
export const showInviteModal = (show: boolean, mode: string) =>
  action(constants.SHOW_INVITE_MODAL, { show, mode });

export const showCustomEventsModal = (show: boolean, id: number) =>
  action(constants.SHOW_CUSTOM_EVENTS_MODAL, { show, id });

export const getSelectedAdjusters = () => action(constants.GET_SELECTED_ADJUSTERS);

export const setActivePage = (page: number) => action(constants.SET_ACTIVE_PAGE, { page });

export const getInviteModalUsers = (filterOptions?: any, standbyId?: any) =>
  action(constants.GET_INVITE_MODAL_USERS, { filterOptions, standbyId });

export const setInviteModalUsers = (adjusters: object[], adjustersCount?: number) =>
  action(constants.SET_INVITE_MODAL_USERS, { adjusters, adjustersCount });

export const setInviteModalTab = (tab: string) => action(constants.SET_INVITE_MODAL_TAB, { tab });

export const setSelectedAdjusters = (adjusters: object[]) =>
  action(constants.SET_SELECTED_ADJUSTERS, { adjusters });

export const resetSelectedUsers = () => action(constants.RESET_SELECTED_USERS);
export const setActivePageAdj = (page: number) => action(constants.SET_ACTIVE_PAGE_ADJ, { page });
export const setStandbyAdjustersPage = (page: number) =>
  action(constants.SET_STANDBY_ADJUSTERS_PAGE, { page });
export const setStandbyAdjustersPerPage = (perPage: number) =>
  action(constants.SET_STANDBY_ADJUSTERS_PER_PAGE, { perPage });
export const setStandbyMessagesPage = (page: number) =>
  action(constants.SET_STANDBY_MESSAGES_PAGE, { page });

export const showTwilioModal = (show: boolean) => action(constants.SHOW_TWILIO_MODAL, { show });

export const setTempMsgObj = (obj: object) => action(constants.SET_TEMP_MSG_OBJ, { obj });

export const getStandbyMessages = (id: any) => action(constants.GET_STANDBY_MESSAGES, { id });

export const setStandbyMessages = (list: any, count: number) =>
  action(constants.SET_STANDBY_MESSAGES, { list, count });

export const setStandbyHeader = (title: string) => action(constants.SET_STANDBY_HEADER, { title });

export const setInviteMode = (mode: string) => {
  return action(constants.SET_INVITE_MODE, { mode });
};

export const setPageSize = (size: number) => {
  return action(constants.SET_PAGE_SIZE, { size });
};

export const resetStandbyFilter = () => {
  return action(constants.RESET_STANDBY_FILTER);
};

export const exportStandbyAdjusters = (id: any, filter: any) =>
  action(constants.EXPORT_STANDBY_ADJUSTERS, { id, filter });

export const exportStandbyAdjustersApplied = (id: any, filter: any) =>
  action(constants.EXPORT_STANDBY_ADJUSTERS_APPLIED, { id, filter });
