import React from 'react';
import { Button, Image, Menu, Modal } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import '../../../assets/styles/Staff.css';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { trainingCenterActions } from '../../../features/training-center';
import { connect } from 'react-redux';
import './UploadMaterialModal.css';
import UploadDocument from './UploadDocument';
import UploadEmbeddedVideo from './UploadEmbeddedVideo';
import { DocumentData, EmbeddedVideoData } from '../shared/types';

interface Props {
  showModal: boolean;
  isUploading: boolean;
  toggleUploadMaterialModal: () => void;
  uploadEmbeddedVideoMaterial: (data: EmbeddedVideoData) => void;
  uploadDocumentMaterial: (data: DocumentData[]) => void;
}

interface State {
  activeTab: string;
  materials: any[];
  embeddedVideo: any;
  submitRef: any;
}

class UploadMaterialModal extends React.Component<Props, State> {
  public form: any;

  public state = {
    activeTab: 'document',
    materials: [],
    embeddedVideo: {},
    submitRef: null,
  };

  public onTabClick = (e: any, { name }: any) => {
    this.setState({ activeTab: name });
  };

  public onMaterialsUpload = (materialData: DocumentData[]) => {
    this.props.uploadDocumentMaterial(materialData);
  };

  public onVideoUpload = (videoData: EmbeddedVideoData) => {
    this.props.uploadEmbeddedVideoMaterial(videoData);
  };

  public setSubmitRef = (ref: any) => {
    this.setState({ submitRef: ref });
  };

  public onUploadClick = () => {
    console.log(this.state.submitRef);
    // @ts-ignore
    this.state.submitRef.current.ref.current.click();
  };

  public renderSelectedTab = () => {
    if (this.state.activeTab === 'document') {
      return <UploadDocument onFinish={this.onMaterialsUpload} setSubmitRef={this.setSubmitRef} />;
    }

    return <UploadEmbeddedVideo onFinish={this.onVideoUpload} setSubmitRef={this.setSubmitRef} />;
  };

  public closeModal = () => {
    if (this.props.isUploading) {
      return;
    }

    this.props.toggleUploadMaterialModal();
  }

  public render() {
    return (
      <Modal
        open={this.props.showModal}
        className="material-modal upload"
        size="small"
        closeIcon={
          <Image
            disabled={this.props.isUploading}
            src={closeIcon}
            className="icon"
            onClick={this.closeModal}
          />
        }
      >
        <Modal.Header>Upload Material</Modal.Header>
        <Modal.Content>
          <Menu text={true} className="tabs-menu upload-material-tabs" fluid>
            <Menu.Item
              name="document"
              active={this.state.activeTab === 'document'}
              onClick={this.onTabClick}
              disabled={this.props.isUploading}
            >
              Document
            </Menu.Item>
            <Menu.Item
              name="video"
              active={this.state.activeTab === 'video'}
              onClick={this.onTabClick}
              disabled={this.props.isUploading}
            >
              Embedded Video
            </Menu.Item>
          </Menu>
          {this.renderSelectedTab()}
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            style={{ width: '115px' }}
            className="secondary-button"
            secondary={true}
            onClick={this.closeModal}
            disabled={this.props.isUploading}
          >
            CANCEL
          </Button>
          <Button
            loading={this.props.isUploading}
            style={{ width: '115px' }}
            primary={true}
            onClick={this.onUploadClick}
          >
            Upload
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  showModal: state.trainingCenter.uploadMaterial.showModal,
  isUploading: state.trainingCenter.uploadMaterial.isUploading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleUploadMaterialModal: trainingCenterActions.toggleUploadMaterialModal,
      uploadEmbeddedVideoMaterial: trainingCenterActions.uploadEmbeddedVideoMaterial,
      uploadDocumentMaterial: trainingCenterActions.uploadDocumentMaterial,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UploadMaterialModal);
