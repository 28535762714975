import classnames from 'classnames';
// @ts-ignore
import { withFormsy } from 'formsy-react';
import React from 'react';
import { Icon, Image, Input } from 'semantic-ui-react';

import icon from '../../../assets/images/Common/icn-search-grey.svg';
import './styles.css';

interface Props {
  getValue: () => any;
  setValue: (val: any) => void;
  className: string;
  placeholder: string;
}

interface State {}

class SearchInput extends React.Component<Props, State> {
  public handleChange = (event: any, data: any) => {
    const { value } = data;

    this.props.setValue(value);
  };

  public render() {
    const { className, placeholder } = this.props;

    return (
      <div className={classnames('searchinput-container', className)}>
        <Input
          icon={
            <Icon>
              <Image src={icon} />
            </Icon>
          }
          iconPosition="left"
          placeholder={placeholder}
          value={this.props.getValue()}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default withFormsy(SearchInput);
