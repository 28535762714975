import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Icon, Image, Label, Menu, MenuItemProps, Pagination, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import { standbyActions } from '../../features/standby';
import services from '../../services';
import * as helper from '../../utils/helper';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import RecipientItem from './RecipientItem';

interface Props {
  match: object;
  setStandbyHeader: typeof standbyActions.setStandbyHeader;
  standbyHeader: string;
}

interface State {
  message: object;
  activeTab: string;
  recipients: object[];
  activePage: number;
  totalRecipients: number;
  recipientsSearch: string;
  standbyListTitle: string;
}

class GroupMessage extends React.Component<Props, State> {
  public state = {
    message: {},
    activeTab: 'Message',
    recipients: [],
    activePage: 1,
    totalRecipients: 0,
    recipientsSearch: '',
    standbyListTitle: '',
  };

  public componentDidMount = () => {
    this.getList();
    this.getDetails();
  };

  public getList = async () => {
    const response = await services.api.standby.getStandbyListDetails(this.props.match.params.sbid);
    if (response.isSuccess) {
      this.props.setStandbyHeader(`StandBy List Message: ${response.data.title}`);
      this.setState({ standbyListTitle: response.data.title });
    } else {
      console.log(response);
    }
  };

  public getDetails = async () => {
    const response = await services.api.messages.getMessageDetails(this.props.match.params.id);
    if (response.isSuccess) {
      this.setState({ message: response.data });
    } else {
      console.log(response);
      toast.dismiss();
      toast.error(response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`);
    }
    this.getRecipients();
  };

  public getRecipients = async () => {
    const { activePage } = this.state;
    const { message, recipientsSearch } = this.state;
    const recip = await services.api.messages.getMessagesRecipients(
      message.group.id,
      activePage - 1,
      recipientsSearch
    );
    if (recip.isSuccess) {
      this.setState({ recipients: recip.data.list, totalRecipients: recip.data.count });
    } else {
      toast.dismiss();
      toast.error(recip.data.errors ? recip.data.errors[0].msg : `Error ${recip.status}`);
      console.log(recip);
    }
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.setState({ activeTab: name });
  };

  public handlePagination = (e: any, data: any) => {
    this.setState({ activePage: data.activePage }, this.getRecipients);
  };

  public handleSearch = (e: any, { value }: any) => {
    this.setState({ recipientsSearch: value, activePage: 1 }, this.getRecipients);
  };

  public render() {
    const {
      message,
      activeTab,
      recipients,
      activePage,
      totalRecipients,
      standbyListTitle,
    } = this.state;
    const standById = this.props.match.params.sbid;

    const totalPages = helper.getTotalPage(totalRecipients, 10);
    return (
      <div className="group-message-details-page">
        <div className="message-details-header">
          <div className="message-details-title">{message.title}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ overflow: 'hidden', maxWidth: '60%' }}>
              <div className="sb-list-title" />
              {message.recipients &&
                message.recipients.map((el, id) => {
                  if (id > 7) {
                    return '';
                  } else {
                    const fullName =
                      `${el.preferredName ? el.preferredName : el.firstName} ${el.lastName}` +
                      `${id !== 7 && id !== message.recipients.length - 1 ? ', ' : ''}`;
                    return fullName;
                  }
                })}
              {recipients.length > 8 && (
                <span className="more-recipients-info">{` +${message.recipientsCount -
                  8} more`}</span>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'right' }}>
                  <div className="sb-list-title">
                    {standbyListTitle && (
                      <span>
                        {'StandBy: '}
                        <Link to={`/stand-by-lists/${standById}`} className="msg-item-link">
                          {standbyListTitle}
                        </Link>
                      </span>
                    )}
                  </div>
                  <div>Sent: {moment(message.createdAt).format('MM/DD/YYYY, h:mA')}</div>
                  <div style={{ fontWeight: 700, textTransform: 'capitalize' }}>
                    {message.provider &&
                      message.provider
                        .map((el, index) => {
                          if (el === 'twilio') {
                            return 'sms';
                          } else {
                            return el;
                          }
                        })
                        .join(', ')}
                  </div>
                </div>
                <div style={{ textAlign: 'center', margin: '20px 2rem 0 3rem' }}>
                  <div style={{ fontWeight: 700, fontSize: 18 }}>{message.recipientsCount}</div>
                  <div>Sent</div>
                </div>
              </div>
            </div>
          </div>
          {message.bcc && <div>BCC: {message.bcc.join(', ')}</div>}
          {message.cc && <div>CC: {message.cc.join(', ')}</div>}
        </div>

        <div style={{ marginBottom: '1rem' }} className="adjusters-list-header">
          <Menu text={true} className="tabs-menu">
            <Menu.Item
              name="Message"
              active={activeTab === 'Message'}
              onClick={this.handleItemClick}
            >
              Message
            </Menu.Item>
            <Menu.Item
              name="Recipients List"
              active={activeTab === 'Recipients List'}
              onClick={this.handleItemClick}
            >
              Recipients List
              {message.hasUnreadMessages && (
                <Label color="red" floating={true} circular={true} empty={true} />
              )}
            </Menu.Item>
          </Menu>
          {activeTab === 'Recipients List' && (
            <Search
              input={{ iconPosition: 'left' }}
              icon={
                <Icon>
                  <Image src={searchIcon} />
                </Icon>
              }
              placeholder="Search..."
              onSearchChange={this.handleSearch}
              showNoResults={false}
            />
          )}
        </div>
        {activeTab === 'Message' ? (
          <div style={{ marginTop: '20px' }}>{message.text}</div>
        ) : (
          <div className="message-group-recipients">
            <LoadingSpiner area="messages-list-area" />
            {recipients.length === 0 && <NoResults />}
            {recipients.map((el: any, index: number) => {
              return (
                <RecipientItem
                  match={this.props.match}
                  groupId={message.group.id}
                  item={el}
                  key={index}
                />
              );
            })}
            {totalPages > 1 && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Pagination
                  totalPages={totalPages}
                  boundaryRange={1}
                  activePage={activePage}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  prevItem={
                    activePage === 1
                      ? false
                      : {
                          'aria-label': 'Previous item',
                          content: 'PREVIOUS',
                        }
                  }
                  nextItem={
                    activePage === totalPages
                      ? false
                      : {
                          'aria-label': 'Next item',

                          content: 'NEXT',
                        }
                  }
                  siblingRange={1}
                  onPageChange={this.handlePagination}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  standbyHeader: state.standby.messages.standbyHeader,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setStandbyHeader: standbyActions.setStandbyHeader,
    },
    dispatch
  );

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(GroupMessage);
