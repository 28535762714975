import { withFormsy } from 'formsy-react';
import React, { cloneElement } from 'react';
import InputMask from 'react-input-mask';

const PhoneInput = (props: any) => {
  const {
    isValid,
    isPristine,
    errorLabel,
    validationErrors,
    placeholder,
    isFormSubmitted,
    getValue,
    setValue,
    value,
  } = props;
  const error = !isPristine() && !isValid() && isFormSubmitted();

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <div style={{ width: '100%', minWidth: '65%' }} className="phone-input-custom-container">
      <InputMask
        placeholder={placeholder}
        mask="(999) 999-9999"
        maskChar={''}
        disabled={props.disabled}
        required={props.required}
        value={getValue()}
        className={`form-input ${props.className ? props.className : ''}`}
        onChange={handleChange}
      />
      {error && errorLabel && cloneElement(errorLabel, {}, validationErrors.isDefaultRequiredValue)}
    </div>
  );
};

export default withFormsy(PhoneInput);
