import { Form } from 'formsy-semantic-ui-react';
import { debounce } from 'lodash';
import React from 'react';
import { Button } from 'semantic-ui-react';
import noAvaterIcon from '../../../assets/images/no-avatar.svg';
import services from '../../../services';
import SearchInput from '../../form/search-input/SearchInput';
import SearchSelectionInput from '../../form/searchselection-input/SearchSelectionInput';

interface Props {
  filter: any;
  adjusters: any[];
  onFilterChange?: (data: any) => void;
  onFilterReset?: () => void;
}

interface State {
  adjusters: any[];
  isLoading: boolean;
}

class MatcherFilter extends React.PureComponent<Props, State> {
  private form: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      adjusters: props.adjusters,
      isLoading: false,
    };

    this.onAdjusterSearchChange = debounce(this.onAdjusterSearchChange, 350);
  }

  public handleReset = () => {
    this.form.reset();
    if (this.props.onFilterReset) {
      this.props.onFilterReset();
    }
  };

  public handleSubmit = (data: any) => {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(data);
    }
  };

  public onAdjusterSearchChange = async (value: any) => {
    this.setState({ isLoading: true });
    if (!value) {
      this.setState({ adjusters: this.props.adjusters, isLoading: false });
      return;
    }

    try {
      const results = await services.api.adjuster.fetchAdjuster({
        pageSize: 30,
        name: value,
        sort: 'firstName,asc',
      });

      const adjusters = results.data.list.map((a: any) => ({
        key: a.id,
        value: a.id,
        text: `${a.preferredName ? a.preferredName : a.firstName} ${a.lastName}${
          a.email ? ` (${a.email})` : ''
        } `,
        image: { avatar: true, src: a.avatar ? a.avatar : noAvaterIcon },
      }));

      this.setState({ adjusters, isLoading: false });
    } catch (err) {
      console.log(err);
      this.setState({ adjusters: [], isLoading: false });
    }
  };

  public handleClose = () => {
    if (this.state.adjusters.length === 0) {
      this.setState({ adjusters: this.props.adjusters });
    }
  };

  public render() {
    const { filter } = this.props;
    return (
      <div className="filter-container">
        <Form
          className="filter-form"
          ref={(form: any) => (this.form = form)}
          onSubmit={this.handleSubmit}
        >
          <Form.Group inline={true}>
            <Form.Field width={4} />
            <Form.Field width={4}>
              <SearchInput name="filename" placeholder="File name..." value={filter.filename} />
            </Form.Field>
            <Form.Field width={8}>
              <SearchSelectionInput
                name="adjuster"
                placeholder="Adjuster"
                value={filter.adjuster}
                options={this.state.adjusters}
                allowEmpty={true}
                onSearchChange={this.onAdjusterSearchChange}
                loading={this.state.isLoading}
                onClose={this.handleClose}
              />
            </Form.Field>
            <Form.Field width={4}>
              <SearchInput
                name="financialId"
                placeholder="Financial ID..."
                value={filter.financialId}
              />
            </Form.Field>
          </Form.Group>
          <div className="actions">
            <Button type="button" className="reset-button" onClick={this.handleReset}>
              Reset
            </Button>
            <Button type="submit" className="apply-button" color="blue">
              Apply
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default MatcherFilter;
