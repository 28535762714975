import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import MyTypes from 'MyTypes';
import React from 'react';
import { Circle, CircleMarker, Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Label } from 'semantic-ui-react';
import drawIcon from '../../assets/images/draw-route.svg';
import hideIcon from '../../assets/images/hide-route.svg';
import arrowIcon from '../../assets/images/White/arrow-r.svg';
import msgIcon from '../../assets/images/White/message.svg';
import { accountActions } from '../../features/account';
import { IUser } from '../../models';
import services from '../../services';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';
import CustomPopupButton from './CustomPopupButton';
import MapZoom from './MapZoom';
import RoutingMachine from './RoutingMachine';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface Props {
  fetchAdjusterDataAttempt: typeof accountActions.fetchAdjusterDataAttempt;
  adjusters: object[];
  adjustersMap: object[];
  user: IUser;
  distances: object;
  eventLocationCoords: object;
  eventLocation: string;
}

interface State {
  adjusters: object[];
  adjustersInitial: object[];
  showNoteModal: boolean;
  showDeleteConfirm: boolean;
  showRadius: boolean;
  activeUserId: any;
  showEmailModal: boolean;
  viewport: object;
  zip: string;
  mainMarker: [];
  popupCallback: () => void;
}

class MapView extends React.Component<Props, State> {
  public state = {
    adjusters: [],
    adjustersInitial: [],
    showNoteModal: false,
    activeUserId: 0,
    showEmailModal: false,
    showDeleteConfirm: false,
    showRadius: false,
    viewport: {},
    zip: '',
    mainMarker: [],
    position: [],
    fromPosition: [],
    control: null,
    openPopupId: null,
    popupCallback: () => null,
  };

  public componentDidMount = async () => {
    const { eventLocationCoords } = this.props;
    const coords = Object.entries(eventLocationCoords).length
      ? [eventLocationCoords.lat, eventLocationCoords.lng]
      : [];
    this.setState(
      {
        adjusters: this.props.adjustersMap ? this.props.adjustersMap : [],
        adjustersInitial: this.props.adjustersMap ? this.props.adjustersMap : [],
        position: coords,
      },
      () => {
        if (coords.length) {
          this.changeCoords({ zoom: 13, center: coords });
        }
      }
    );

    if (this.map) {
      const control = L.Routing.control({
        waypoints: [],
        lineOptions: {
          styles: [{ color: '#055dff' }],
        },
        createMarker: () => null,
        addWaypoints: false,
        draggableWaypoints: false,
        fitSelectedRoutes: false,
        showAlternatives: true,
      }).addTo(this.map.leafletElement);
      this.setState({ control });
    }
  };

  public componentDidUpdate = (prevProps: Props) => {
    const { eventLocationCoords } = this.props;
    const { position } = this.state;
    const coords = Object.entries(eventLocationCoords).length
      ? [eventLocationCoords.lat, eventLocationCoords.lng]
      : [];
    let activeAdjuster;
    if (this.props.adjusters) {
      if (this.props.adjusters.length < 100) {
        activeAdjuster = this.props.adjusters.find(adj => {
          if (adj.location) {
            return adj.location.lat === this.state.fromPosition[0];
          }
          return false;
        });
      }
    }

    if (coords.length && position[0] !== coords[0]) {
      this.setState({ position: coords }, () => {
        this.changeCoords({ zoom: 13, center: coords });
      });
    }

    if (prevProps.adjustersMap !== this.props.adjustersMap) {
      if (!this.props.eventLocation) {
        this.setState({ position: [], fromPosition: [], openPopupId: null });
      }
      this.state.control.setWaypoints([]);
      this.setState({ adjusters: this.props.adjustersMap ? this.props.adjustersMap : [] });
    }

    if (prevProps.eventLocation !== this.props.eventLocation) {
      this.setState({ position: [], fromPosition: [], openPopupId: null });
      this.state.control.setWaypoints([]);
    }

    if (!activeAdjuster && !!this.state.fromPosition.length) {
      this.setState({ fromPosition: [], openPopupId: null });
      if (this.activeRoute) {
        this.activeRoute.leafletElement.options.leaflet.map.closePopup();
      }
    }
  };

  public componentWillUnmount() {
    this.destroyRouting();
  }

  public destroyRouting() {
    const { control } = this.state;
    if (this.map && control) {
      this.map.leafletElement.removeControl(control);
    }
  }

  public changeCoords = (coords: any) => {
    this.setState({ viewport: { zoom: coords.zoom, center: coords.center } });
  };

  public toggleRadius = (show: boolean) => {
    this.setState({ showRadius: show });
  };

  public handleZip = (value: string) => {
    if (!value) {
      this.setState({ mainMarker: [] });
    }
    this.setState({ zip: value });
  };

  public showHidePopup = item => {
    document.getElementById('map-id').click();
    const { openPopupId } = this.state;
    if (openPopupId && openPopupId === item.id) {
      this.setState({ openPopupId: null });
      this.prevRouteId = item.id;
    } else {
      this.setState({ openPopupId: item.id });
      this.prevRouteId = openPopupId;
    }
    this.showRoute(item.location, item.id);
    if (this.notActiveDot) {
      this.notActiveDot.leafletElement.options.leaflet.map.closePopup();
    }
  };

  public findCoords = async () => {
    const { zip } = this.state;
    const request = await trackPromise(services.api.map.requestCoord(zip), 'adjusters-map');
    if (request.isSuccess) {
      if (request.data.coordinates) {
        this.setState({ mainMarker: [request.data.coordinates.lat, request.data.coordinates.lng] });
        this.changeCoords({
          zoom: 13,
          center: [request.data.coordinates.lat, request.data.coordinates.lng],
        });
      } else {
        this.setState({ mainMarker: [] });
        toast.dismiss();
        toast.error('Wrong ZIP code!');
      }
    }
  };

  public showRoute = (point, id) => {
    const { position, fromPosition } = this.state;
    const isNewRoute = this.prevRouteId !== id;
    if (position.length) {
      if (point.lat === fromPosition[0] && point.long === fromPosition[1] && !isNewRoute) {
        this.setState({ fromPosition: [] }, () => {
          if (this.activeRoute) {
            this.activeRoute.leafletElement.options.leaflet.map.closePopup();
          }
        });
      } else {
        this.setState({ fromPosition: [point.lat, point.long] }, () => {
          this.showRoutePopup([point.lat, point.long]);
        });
      }
    }
  };

  public showPopup = () => {
    if (this.activeDot) {
      this.activeDot.leafletElement.options.leaflet.map.openPopup(
        this.activeDot.leafletElement,
        this.state.fromPosition
      );
    }
  };

  public showRoutePopup = location => {
    if (this.activeRoute) {
      this.activeRoute.leafletElement.options.leaflet.map.openPopup(
        this.activeRoute.leafletElement,
        location
      );
    }
  };

  public closeRoutePopup = e => {
    e.target.options.leaflet.map.closePopup();
  };

  public onPopupToggle = (value, el) => {
    const { openPopupId } = this.state;
    const { distances } = this.props;
    const distance = distances && openPopupId ? distances[openPopupId] : null;
    this.state.popupCallback(value);
    if (!value && distance) {
      this.showRoutePopup([el.location.lat, el.location.long]);
    }
  };

  public render() {
    const { adjusters, viewport, zip, position, fromPosition, openPopupId } = this.state;
    const { distances } = this.props;
    const distance = distances && openPopupId ? distances[openPopupId] : null;

    return (
      <>
        <LoadingSpiner area="adjusters-map" />
        {!!adjusters.length && (
          <div className="adjusters-list-map-counter">Total: {adjusters.length}</div>
        )}
        <div id="map-id">
          <Map
            style={{ height: '100%', width: '100%' }}
            zoom={4}
            center={[37.806862, -96.681679]}
            viewport={viewport}
            ref={map => (this.map = map)}
          >
            <MapZoom
              zip={zip}
              handleZip={this.handleZip}
              findCoords={this.findCoords}
              changeCoords={this.changeCoords}
              showRadius={this.state.showRadius}
              toggleRadius={this.toggleRadius}
            />
            <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {adjusters
              .filter(el => el.location)
              .map((el: any, index: number) => {
                return (
                  <div key={index}>
                    {this.state.showRadius ? (
                      <Circle
                        center={[el.location.lat, el.location.long]}
                        radius={Number(el.milesRange) * 1609.34}
                        color={'rgba(255,38,38, 0.5)'}
                        weight={0}
                        interactive={false}
                      >
                        <CircleMarker
                          color="#ff2626"
                          center={[el.location.lat, el.location.long]}
                          fillOpacity={1}
                          stroke={false}
                          interactive={true}
                          radius={5}
                          className="circle"
                        >
                          <Popup
                            className="adjusters-list-on-marker"
                            ref={dot =>
                              el.id === openPopupId
                                ? (this.activeDot = dot)
                                : (this.notActiveDot = dot)
                            }
                            onOpen={() => {
                              this.onPopupToggle(true, el);
                            }}
                            onClose={() => {
                              this.onPopupToggle(false, el);
                            }}
                          >
                            {adjusters
                              .filter(adj => {
                                if (adj.location && el.location) {
                                  return (
                                    adj.location.lat === el.location.lat &&
                                    adj.location.long === el.location.long
                                  );
                                }
                              })
                              .sort((a: any, b: any) => Number(a.milesRange) - Number(b.milesRange))
                              .reverse()
                              .map((item, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <div className="map-adjuster-popup">
                                      <p className="map-popup-title">
                                        {item.preferredName ? item.preferredName : item.firstName}{' '}
                                        {item.lastName}
                                      </p>
                                      <p className="map-popup-description-line">{item.email}</p>
                                      <p className="map-popup-description-line">
                                        {item.compassEmail}
                                      </p>
                                      <p className="map-popup-description-line">
                                        {item.mobilePhone}
                                      </p>
                                      <p className="map-popup-description-line">
                                        {item.catCity || item.city},{' '}
                                        {item.catStateProvince || item.state},{' '}
                                        {item.catZip || item.zip}
                                      </p>
                                      <p className="map-popup-description-line">
                                        Miles Away: {item.milesRange}
                                      </p>
                                      {Object.keys(distances || {}).length && distances[item.id] ? (
                                        <p className="map-popup-description-line">
                                          Driving Distance: {Number(distances[item.id]).toFixed(2)}{' '}
                                          miles
                                        </p>
                                      ) : null}
                                      <div className="map-popup-actions">
                                        {this.props.user.role !== 'Read Only Office Admin' && (
                                          <div className="message-avatar-container">
                                            <CustomPopupButton
                                              popup="Message"
                                              classNm="yes-btn"
                                              image={msgIcon}
                                              link={{
                                                hash: location.hash,
                                                pathname: `/messages/room/${item.id}`,
                                              }}
                                            />
                                            {item.hasUnreadMessages && (
                                              <Label
                                                color="red"
                                                floating={true}
                                                circular={true}
                                                empty={true}
                                              />
                                            )}
                                          </div>
                                        )}
                                        <CustomPopupButton
                                          popup="View Profile"
                                          classNm="yes-btn"
                                          image={arrowIcon}
                                          link={{
                                            hash: location.hash,
                                            pathname: `/adjusters/${item.id}`,
                                          }}
                                        />
                                        {position.length ? (
                                          <CustomPopupButton
                                            popup={
                                              openPopupId === item.id ? 'Hide Route' : 'Show route'
                                            }
                                            classNm="yes-btn"
                                            image={openPopupId === item.id ? hideIcon : drawIcon}
                                            onPress={() => this.showHidePopup(item)}
                                            setMapCallback={cb => {
                                              this.setState({ popupCallback: cb });
                                            }}
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                    {i !==
                                      adjusters.filter(adj =>
                                        adj.location && (adj.catZip && el.catZip)
                                          ? adj.catZip.substring(0, 5) === el.catZip.substring(0, 5)
                                          : adj.zip.substring(0, 5) === el.zip.substring(0, 5)
                                      ).length -
                                        1 && <hr />}
                                  </React.Fragment>
                                );
                              })}
                          </Popup>
                        </CircleMarker>
                      </Circle>
                    ) : (
                      <CircleMarker
                        className="circle"
                        key={index}
                        color="#ff2626"
                        center={[el.location.lat, el.location.long]}
                        fillOpacity={1}
                        stroke={false}
                        interactive={true}
                        radius={5}
                      >
                        <Popup
                          className="adjusters-list-on-marker"
                          ref={dot =>
                            el.id === openPopupId
                              ? (this.activeDot = dot)
                              : (this.notActiveDot = dot)
                          }
                          onOpen={() => {
                            this.onPopupToggle(true, el);
                          }}
                          onClose={() => {
                            this.onPopupToggle(false, el);
                          }}
                        >
                          {adjusters
                            .filter(adj => {
                              if (adj.location && el.location) {
                                return (
                                  adj.location.lat === el.location.lat &&
                                  adj.location.long === el.location.long
                                );
                              }
                            })
                            .sort((a: any, b: any) => Number(a.milesRange) - Number(b.milesRange))
                            .reverse()
                            .map((item, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <div className="map-adjuster-popup">
                                    <p className="map-popup-title">
                                      {item.preferredName ? item.preferredName : item.firstName}{' '}
                                      {item.lastName}
                                    </p>
                                    <p className="map-popup-description-line">{item.email}</p>
                                    <p className="map-popup-description-line">
                                      {item.compassEmail}
                                    </p>
                                    <p className="map-popup-description-line">{item.mobilePhone}</p>
                                    <p className="map-popup-description-line">
                                      {item.catCity || item.city},{' '}
                                      {item.catStateProvince || item.state},{' '}
                                      {item.catZip || item.zip}
                                    </p>
                                    <p className="map-popup-description-line">
                                      Miles Away: {item.milesRange}
                                    </p>
                                    {Object.keys(distances || {}).length && distances[item.id] ? (
                                      <p className="map-popup-description-line">
                                        Driving Distance: {Number(distances[item.id]).toFixed(2)}{' '}
                                        miles
                                      </p>
                                    ) : null}
                                    <div className="map-popup-actions">
                                      {this.props.user.role !== 'Read Only Office Admin' && (
                                        <div className="message-avatar-container">
                                          <CustomPopupButton
                                            popup="Message"
                                            classNm="yes-btn"
                                            image={msgIcon}
                                            link={{
                                              hash: location.hash,
                                              pathname: `/messages/room/${item.id}`,
                                            }}
                                          />
                                          {item.hasUnreadMessages && (
                                            <Label
                                              color="red"
                                              floating={true}
                                              circular={true}
                                              empty={true}
                                            />
                                          )}
                                        </div>
                                      )}
                                      <CustomPopupButton
                                        popup="View Profile"
                                        classNm="yes-btn"
                                        image={arrowIcon}
                                        link={{
                                          hash: location.hash,
                                          pathname: `/adjusters/${item.id}`,
                                        }}
                                      />
                                      {position.length ? (
                                        <CustomPopupButton
                                          popup={
                                            openPopupId === item.id ? 'Hide Route' : 'Show route'
                                          }
                                          classNm="yes-btn"
                                          image={openPopupId === item.id ? hideIcon : drawIcon}
                                          onPress={() => this.showHidePopup(item)}
                                          setMapCallback={cb => {
                                            this.setState({ popupCallback: cb });
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                  {i !==
                                    adjusters.filter(adj => {
                                      if (adj.location && el.location) {
                                        return (
                                          adj.location.lat === el.location.lat &&
                                          adj.location.long === el.location.long
                                        );
                                      }
                                    }).length -
                                      1 && <hr />}
                                </React.Fragment>
                              );
                            })}
                        </Popup>
                      </CircleMarker>
                    )}
                  </div>
                );
              })}

            {position.length && this.map ? (
              <RoutingMachine
                from={fromPosition}
                to={position}
                map={this.map}
                control={this.state.control}
                showPopup={this.showPopup}
              />
            ) : null}
            {position.length && this.map ? (
              <Marker position={position} onClick={this.closeRoutePopup}>
                {distances && (
                  <Popup className="route-popup" ref={dot => (this.activeRoute = dot)}>
                    <p>Driving Distance: {openPopupId && Number(distance).toFixed(2)} miles</p>
                  </Popup>
                )}
              </Marker>
            ) : null}
          </Map>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  eventLocationCoords: state.adjusters.adjusters.eventLocationCoords,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAdjusterDataAttempt: accountActions.fetchAdjusterDataAttempt,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapView);
