export default [
    {
        label: 'First Name',
        name: 'firstName',
        type: 'text',
        required: true,
        disabled: false,
        className: 'form-input',
    },
    {
        label: 'Preferred Name',
        name: 'preferredName',
        type: 'text',
        required: false,
        disabled: false,
        className: 'form-input',
    },
    {
        label: 'Middle Name',
        name: 'middleName',
        type: 'text',
        required: false,
        disabled: false,
        className: 'form-input',
    },
    {
        label: 'Last Name',
        name: 'lastName',
        type: 'text',
        required: true,
        disabled: false,
        className: 'form-input',
    },
    {
      label: "Gender",
      name: "gender",
      type: "dropdown",
      required: true,
      disabled: false,
      className: "form-input",
      options: [
        {
          "text": "Male",
          "value": "Male"
        },
        {
          "text": "Female",
          "value": "Female"
        },
        {
          "text": "Transgender/Non-Binary/Other",
          "value": "Transgender/Non-Binary/Other"
        },
        {
          "text": "Prefer not to disclose",
          "value": "Prefer not to disclose"
        },
      ]
    },
    {
      label: "Race",
      name: "race",
      type: "dropdown",
      required: true,
      disabled: false,
      className: "form-input",
      options: [
        {
          "text": "Hispanic or Latino",
          "value": "Hispanic or Latino"
        },
        {
          "text": "American Indian or Alaska Native",
          "value": "American Indian or Alaska Native"
        },
        {
          "text": "Asian",
          "value": "Asian"
        },
        {
          "text": "Black or African American",
          "value": "Black or African American"
        },
        {
          "text": "Native Hawaiian or Other Pacific Islander",
          "value": "Native Hawaiian or Other Pacific Islander"
        },
        {
          "text": "White",
          "value": "White"
        },
        {
          "text": "Prefer not to disclose",
          "value": "Prefer not to disclose"
        },
      ]
    },
    {
        label: 'Date of Birth',
        name: 'birthday',
        type: 'date',
        required: true,
        disabled: false,
        className: 'form-input-short',
    },
    {
        label: 'Positions Applying For',
        name: 'positions',
        type: 'area',
        required: true,
        disabled: false,
        className: 'form-input',
    },
    {
        label: 'Compass Email',
        name: 'compassEmail',
        type: 'text',
        required: false,
        disabled: true,
        className: 'form-input adjuster-email',
    },
    {
        label: 'Personal Email',
        name: 'email',
        type: 'text',
        required: true,
        disabled: true,
        className: 'form-input adjuster-email',
    },
    {
        label: 'Phone# (Mobile)',
        name: 'mobilePhone',
        type: 'number',
        required: true,
        disabled: false,
        className: 'form-input',
    },
    {
        label: 'Phone# (Home)',
        name: 'phone',
        type: 'number',
        required: false,
        disabled: false,
        className: 'form-input',
    },
]
