export const DEFAULT_ADJUSTERS_SORT_OPTIONS_QUICK = [
  { text: 'First Name', value: 'firstName,asc' },
  { text: 'Last Name', value: 'lastName,asc' },
  { text: 'State', value: 'state,asc' },
  { text: 'City', value: 'city,asc' },
  { text: 'Email', value: 'email,asc' },
  { text: 'Last Updated', value: 'updatedAt,desc' },
];

export const DEFAULT_ADJUSTERS_SORT_OPTIONS = [
  { text: 'First Name', value: 'firstName,asc' },
  { text: 'Last Name', value: 'lastName,asc' },
  { text: 'State', value: 'state,asc' },
  { text: 'City', value: 'city,asc' },
  { text: 'Email', value: 'email,asc' },
  { text: 'Compass Email', value: 'compassEmail,asc' },
  { text: 'Last Updated', value: 'updatedAt,desc' },
];

export const STANDBYLIST_ADJUSTERS_SORT_OPTIONS = [
  { text: 'First Name', value: 'firstName,asc' },
  { text: 'Last Name', value: 'lastName,asc' },
  { text: 'State', value: 'state,asc' },
  { text: 'City', value: 'city,asc' },
  { text: 'Email', value: 'email,asc' },

  { text: 'Years’ Experience: Ascending', value: 'experiences,asc' },
  { text: 'Years’ Experience: Descending ', value: 'experiences,desc' },

  { text: 'Age: Ascending', value: 'birthday,asc' },
  { text: 'Age: Descending ', value: 'birthday,desc' },

  { text: 'Compass Email', value: 'compassEmail,asc' },
  { text: 'Signed up On: Earliest', value: 'signedUpAt,asc' },
  { text: 'Signed up On: Latest', value: 'signedUpAt,desc' },
  { text: 'Last Updated: Earliest', value: 'updatedAt,asc' },
  { text: 'Last Updated: Latest', value: 'updatedAt,desc' },
];
