import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { alertModalHide, AlertModel } from '../../features/modal/actions';

interface Props {
  isVisible: boolean;
  type?: string;
  model?: AlertModel;
  close: () => void;
}

class AlertModal extends React.Component<Props> {
  public render() {
    if (!this.props.isVisible || !this.props.model || !this.props.type) {
      return null;
    }

    const type: string = this.props.type;
    const model: AlertModel = this.props.model;

    const getTitle = () => {
      switch (type) {
        case 'success':
          return <Header icon="check" content={model.title || 'Success'} />;
        case 'error':
          return <Header icon="times" content={model.title || 'Error'} />;
        case 'warding':
        default:
          return <Header icon="warning sign" content={model.title || 'Warning'} />;
      }
    };

    return (
      <Modal open={true} size="small">
        {getTitle()}
        <Modal.Content>
          <p>{this.props.model.message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => this.props.close()}>
            <Icon name="checkmark" /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  isVisible: state.modal.alert.isVisible,
  type: state.modal.alert.type,
  model: state.modal.alert.model,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: alertModalHide,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertModal);
