import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Pagination } from 'semantic-ui-react';
import { standbyActions } from '../../../features/standby';
import * as helper from '../../../utils/helper';
import { NoResults } from '../../no-results-placeholder/NoResults';
import SendMessageTableAdj from './SMTableAdj';

interface Props {
  show: boolean;
  showInviteModal: typeof standbyActions.showInviteModal;
  getInviteModalUsers: typeof standbyActions.getInviteModalUsers;
  setInviteModalTab: typeof standbyActions.setInviteModalTab;
  setSelectedAdjusters: typeof standbyActions.setSelectedAdjusters;
  adjusters: object[];
  selectedAdjusters: object[];
  admins: object[];
  activeStandbyId: any;
  pageSize: number;
  searchAdjustersValue: string;
}

interface State {
  adjustersSearch: string;
  adminsSearch: string;
  selectedAdjusters: object[];
  initialAdjusters: object[];
  selectedAdjustersPaginated: object[];
  activePageAdj: number;
}

class SendMessageTableSelected extends React.Component<Props, State> {
  public state = {
    adjustersSearch: '',
    adminsSearch: '',
    selectedAdjusters: [],
    initialAdjusters: [],
    selectedAdjustersPaginated: [],
    activePageAdj: 1,
  };

  public handleRemoveItem = (id: any, category: string) => {
    const { selectedAdjusters } = this.props;
    const { selectedAdjustersPaginated } = this.state;

    const copyArray = selectedAdjusters.map(el => el);
    const index = copyArray.findIndex(el => el.id === id);
    const index2 = selectedAdjustersPaginated.findIndex(el => el.id === id);
    copyArray.splice(index, 1);
    selectedAdjustersPaginated.splice(index2, 1);
    if (selectedAdjustersPaginated.length === 0) {
      this.handlePaginationAdj({}, { activePage: 1 });
    }
    this.setState({ selectedAdjustersPaginated }, () => this.props.setSelectedAdjusters(copyArray));
  };

  public componentDidMount = () => {
    this.setState(
      {
        selectedAdjusters: this.props.selectedAdjusters,
        initialAdjusters: this.props.selectedAdjusters,
      },
      () => {
        this.paginateAdjusters();
      }
    );
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.selectedAdjusters !== this.props.selectedAdjusters) {
      this.setState({
        selectedAdjusters: this.props.selectedAdjusters,
        initialAdjusters: this.props.selectedAdjusters,
      });
    }
    if (prevProps.pageSize !== this.props.pageSize) {
      this.handlePaginationAdj({}, { activePage: 1 });
    }
    if (prevProps.searchAdjustersValue !== this.props.searchAdjustersValue) {
      this.handleSearchAdjusters();
    }
  };

  public handleSearchAdjusters = () => {
    const { initialAdjusters } = this.state;
    const { searchAdjustersValue } = this.props;
    const filteredArray = initialAdjusters.filter(el => {
      const fullName = el.firstName + ' ' + el.lastName;
      return fullName.toLowerCase().includes(searchAdjustersValue.toLowerCase());
    });
    this.setState({ selectedAdjusters: filteredArray }, () =>
      this.handlePaginationAdj({}, { activePage: 1 })
    );
  };

  public handlePaginationAdj = (e: any, { activePage }: any) => {
    this.setState({ activePageAdj: activePage }, this.paginateAdjusters);
  };

  public paginateAdjusters = () => {
    const { selectedAdjusters, activePageAdj } = this.state;
    const { pageSize } = this.props;
    const result: object[] = [];
    for (let i = (activePageAdj - 1) * pageSize; i < activePageAdj * pageSize; i++) {
      if (selectedAdjusters[i]) {
        result.push(selectedAdjusters[i]);
      } else {
        continue;
      }
    }
    this.setState({ selectedAdjustersPaginated: result });
  };

  public render() {
    const { activePageAdj, selectedAdjusters } = this.state;
    const { pageSize } = this.props;
    const totalPagesAdj = helper.getTotalPage(selectedAdjusters.length, pageSize);
    return (
      <>
        <div className="selected-adjusters-invite-modal">
          {this.state.selectedAdjustersPaginated.length === 0 ? (
            <NoResults />
          ) : (
            <div>
              <SendMessageTableAdj
                adjusters={this.state.selectedAdjustersPaginated}
                selectedTab={true}
                removeItem={this.handleRemoveItem}
              />
              {totalPagesAdj > 1 && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Pagination
                    totalPages={totalPagesAdj}
                    boundaryRange={1}
                    activePage={activePageAdj}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    prevItem={
                      activePageAdj === 1
                        ? false
                        : {
                            'aria-label': 'Previous item',
                            content: 'PREVIOUS',
                          }
                    }
                    nextItem={
                      activePageAdj === totalPagesAdj
                        ? false
                        : {
                            'aria-label': 'Next item',

                            content: 'NEXT',
                          }
                    }
                    siblingRange={1}
                    onPageChange={this.handlePaginationAdj}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  activeStandbyId: state.standby.standby.activeStandbyId,
  show: state.standby.standby.showInviteModal,
  adjusters: state.standby.inviteModal.adjusters,
  selectedAdjusters: state.standby.inviteModal.selectedAdjusters,
  admins: state.standby.inviteModal.admins,
  pageSize: state.standby.inviteModal.pageSize,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showInviteModal: standbyActions.showInviteModal,
      getInviteModalUsers: standbyActions.getInviteModalUsers,
      setInviteModalTab: standbyActions.setInviteModalTab,
      setSelectedAdjusters: standbyActions.setSelectedAdjusters,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendMessageTableSelected);
