export default [
    {
        label: 'First Name',
        name: 'firstName',
        type: 'text',
        required: true,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'Preferred Name',
        name: 'preferredName',
        type: 'text',
        required: false,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'Middle Name',
        name: 'middleName',
        type: 'text',
        required: false,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'Last Name',
        name: 'lastName',
        type: 'text',
        required: true,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
      label: 'Gender',
      name: 'gender',
      type: 'text',
      required: true,
      disabled: false,
      className: 'form-input readonly-input',
    },
    {
      label: 'Race',
      name: 'race',
      type: 'text',
      required: true,
      disabled: false,
      className: 'form-input readonly-input',
    },
    {
        label: 'Date of Birth',
        name: 'birthday',
        type: 'date',
        required: true,
        disabled: false,
        className: 'form-input readonly-input-short',
    },
    {
        label: 'Positions Applying For',
        name: 'positions',
        type: 'area',
        required: true,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'Compass Email',
        name: 'compassEmail',
        type: 'text',
        required: false,
        disabled: true,
        className: 'form-input adjuster-email',
    },
    {
        label: 'Personal Email',
        name: 'email',
        type: 'text',
        required: true,
        disabled: false,
        className: 'form-input adjuster-email',
    },
    {
        label: 'Phone# (Mobile)',
        name: 'mobilePhone',
        type: 'text',
        required: false,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'Phone# (Home)',
        name: 'phone',
        type: 'text',
        required: false,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'Fax',
        type: 'text',
        name: 'fax',
        required: false,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'Address (Line 1)',
        name: 'addressLine1',
        type: 'text',
        required: true,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'Address (Line 2)',
        name: 'addressLine2',
        type: 'text',
        required: false,
        disabled: false,
        className: 'form-input readonly-input',
    },
    {
        label: 'City',
        type: 'text',
        name: 'city',
        required: true,
        disabled: false,
        className: 'form-input readonly-input',
    },
]
