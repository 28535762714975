export const GET_ADJUSTERS_LIST = 'adjusters/GET_ADJUSTERS_LIST';

export const SUBMIT_ADJUSTERS_LIST = 'adjusters/SUBMIT_ADJUSTERS_LIST';

export const SUBMIT_ADJUSTERS_MAP = 'adjusters/SUBMIT_ADJUSTERS_MAP';

export const RESET_ADJUSTERS_LIST = 'adjusters/RESET_ADJUSTERS_LIST';

export const SET_ACTIVE_LIST_TAB = 'adjusters/SET_ACTIVE_LIST_TAB';

export const SET_ACTIVE_PAGE_ADJ_LIST = 'adjusters/SET_ACTIVE_PAGE_ADJ_LIST';

export const GET_MAP_ADJUSTERS = 'adjusters/GET_MAP_ADJUSTERS';

export const GET_ADMIN_ADJUSTERS = 'adjusters/GET_ADMIN_ADJUSTERS';

export const SET_ADMIN_ADJUSTERS = 'adjusters/SET_ADMIN_ADJUSTERS';
