import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dropdown, Icon, Image, Menu, Search } from 'semantic-ui-react';
import searchIcon from '../../../assets/images/Blue/icn-search-blue.svg';
import { trainingCenterActions } from '../../../features/training-center';
import { PaginationInfo } from '../../../features/training-center/trainingCenterReducer';
import AdjustersFilter from '../../table-filters/AdjustersFilter';

interface Props {
  getPagingInfo: () => PaginationInfo;
  selectedAdjusters: Array<number>;
  selectedCustomEvents: Array<number>;
  searchAssignAccess: typeof trainingCenterActions.searchAssignAccess,
  activeTab: string;
  pagingInfo: { Adjusters: PaginationInfo, CustomEvents: PaginationInfo },
  setActiveTab: typeof trainingCenterActions.setActiveTab,
  filterAdjusters: (params: any, reset?: boolean) => void
}

interface State {
  showFilter: boolean;
  status: string;
}

class AssignAccessTableHeader extends React.Component<Props, State> {
  public filtersFormRef = React.createRef();

  public state = {
    showFilter: false,
    status: 'Active'
  };

  public componentDidMount = () => {
    const pagingInfo = this.props.getPagingInfo();
    this.props.searchAssignAccess(1, pagingInfo.pageSize, pagingInfo.search);
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.props.setActiveTab(name);
    const pagingInfo = this.props.getPagingInfo();
    this.props.searchAssignAccess(1, pagingInfo.pageSize, pagingInfo.search);
  };


  public handleSearch = (e: any, data: any) => {
    const pagingInfo = this.props.getPagingInfo();
    this.props.searchAssignAccess(1, pagingInfo.pageSize, data.value);
  };

  public handlePaginationCounter = (e: any, { value }: any) => {
    const pagingInfo = this.props.getPagingInfo();
    this.props.searchAssignAccess(1, value, pagingInfo.search);
  };

  public showFilters = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  public handleStatusClick = (status: string) => {
    const data = {status};
    this.setState((prevState) => (data), () => this.filterAdjusters(data));
  }

  public filterAdjusters = (params: any = null) => {
    if (params) {
      this.props.filterAdjusters({...params, status: this.state.status});
    } else {
      this.props.filterAdjusters({status: this.state.status}, true);
    }
  }

  public render() {
    let { activeTab } = this.props;
    const { status } = this.state;

    if (activeTab && typeof activeTab === 'object') {
      activeTab = (activeTab as any).activeTab;
    }

    const pagingInfo = this.props.getPagingInfo();
    if (!pagingInfo) {
      return null;
    }
    return (
      <>
        <div className="adjusters-list-header sbl-details-table-header">
          <div style={{ width: '100%' }}>
            <Menu text={true} className="tabs-menu">
              <Menu.Item name="Adjusters" active={activeTab === 'Adjusters'} onClick={this.handleItemClick}>
                Adjusters {`(${this.props.selectedAdjusters.length})`}
              </Menu.Item>

              <Menu.Item
                name="CustomEvents"
                active={activeTab === 'CustomEvents'}
                onClick={this.handleItemClick}
              >
                Custom Events {`(${this.props.selectedCustomEvents.length})`}
              </Menu.Item>
            </Menu>
          </div>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            {activeTab === 'Adjusters' ? (
              <Menu text={true} className="tabs-menu">
                <Menu.Item
                  name="Pending"
                  active={status === 'Pending'}
                  onClick={() => this.handleStatusClick('Pending')}
                >
                  Pending
                </Menu.Item>
                <Menu.Item
                  name="To Review"
                  active={status === 'To Review'}
                  onClick={() => this.handleStatusClick('To Review')}
                >
                  To Review
                </Menu.Item>
                <Menu.Item
                  name="Active"
                  active={status === 'Active'}
                  onClick={() => this.handleStatusClick('Active')}>
                  Active
                </Menu.Item>

                <Menu.Item
                  name="Inactive"
                  active={status === 'Inactive'}
                  onClick={() => this.handleStatusClick('Inactive')}
                >
                  Inactive
                </Menu.Item>
                <Menu.Item
                  name="Archived"
                  active={status === 'Archived'}
                  onClick={() => this.handleStatusClick('Archived')}
                >
                  Archived
                </Menu.Item>
                <Menu.Item
                  name="Deleted"
                  active={status === 'Deleted'}
                  onClick={() => this.handleStatusClick('Deleted')}
                >
                  Deleted
                </Menu.Item>
                <Menu.Item
                  name="All"
                  active={status === 'All'}
                  onClick={() => this.handleStatusClick('All')}>
                  All
                </Menu.Item>
                <Menu.Item
                  name="Admins"
                  active={status === 'Admins'}
                  onClick={() => this.handleStatusClick('Admins')}>
                  Admins
                </Menu.Item>
              </Menu>
            ) : ''}

            <div style={{ display: 'flex', justifyContent: 'space-end', alignItems: 'center' }}>
              {activeTab === 'Adjusters' ? (
                <Button primary={true} onClick={() => this.showFilters()}>
                  Filter
                </Button>
              ) : ''}

              <div className="pagination-items-counter" style={{marginLeft: '10px'}}>
                Items on page:{' '}
                <Dropdown
                  inline={true}
                  options={[
                    { text: '10', value: 10 },
                    { text: '15', value: 15 },
                    { text: '25', value: 25 },
                    { text: '50', value: 50 },
                    { text: '100', value: 100 },
                  ]}
                  defaultValue={15}
                  onChange={this.handlePaginationCounter}
                />
              </div>

              <Search
                input={{ iconPosition: 'left' }}
                icon={
                  <Icon>
                    <Image src={searchIcon} />
                  </Icon>
                }
                placeholder="Search..."
                value={pagingInfo.search}
                onSearchChange={this.handleSearch}
                showNoResults={false}
              />
            </div>
          </div>
        </div>

        <div>
          {this.state.showFilter && (
            <AdjustersFilter
              reset={() => this.filterAdjusters(null)}
              filter={{}}
              filterLogs={this.filterAdjusters}
              formRef={this.filtersFormRef}
              activePage={this.props.activeTab}
            />
          )}
        </div>
      </>
    );
  }
}


const mapStateToProps = (state: MyTypes.RootState) => ({
  activeTab: state.trainingCenter.assignAccess,
  selectedAdjusters: state.trainingCenter.assignAccess.selectedAdjusters,
  selectedCustomEvents: state.trainingCenter.assignAccess.selectedCustomEvents,
  pagingInfo: state.trainingCenter.assignAccess.pagingInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveTab: trainingCenterActions.setActiveTab,
      searchAssignAccess: trainingCenterActions.searchAssignAccess,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignAccessTableHeader);

