import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Confirm, Image, Popup } from 'semantic-ui-react';
import cancelIconB from '../../assets/images/Blue/cancel.svg';
import msgIconB from '../../assets/images/Blue/message.svg';
import trashIconB from '../../assets/images/Blue/trash.svg';
import reopenIconW from '../../assets/images/btn-arrow.svg';
import arrowIcon from '../../assets/images/ui-dropdown.svg';
import editIconW from '../../assets/images/White/edit.svg';
import services from '../../services';
import CopySBLToClipboard from './CopySBLToClipboard';
import SBPrivateLabel from './SBPrivateLabel';

enum ConfirmModalType {
  CLOSE,
  DELETE,
}

interface Props {
  item: object;
  editList: (edit: boolean) => void;
  showModal: (show: boolean, mode: string) => void;
  match: any;
  user: any;
  updateList: () => void;
}

interface State {
  showFullDescription: boolean;
  confirmModal: boolean;
  loading: boolean;
  confirmModalType: ConfirmModalType;
}

class SBDetailsHeader extends React.Component<Props, State> {
  public state = {
    showFullDescription: true,
    confirmModal: false,
    loading: false,
    confirmModalType: ConfirmModalType.CLOSE,
  };

  public onCloseModal = () => !this.state.loading && this.setState({ confirmModal: false });

  public handleConfirm = async () => {
    this.setState({ loading: true });
    const apiEndpoint =
      this.state.confirmModalType === ConfirmModalType.DELETE
        ? services.api.standby.deleteStandby
        : services.api.standby.closeStandby;

    const response = await apiEndpoint(this.props.item.id);
    if (response.isSuccess) {
      toast.dismiss();
      toast.success(
        `Standby List ${this.props.item.title} was successfully ${
          this.state.confirmModalType === ConfirmModalType.DELETE ? 'deleted' : 'closed'
        }!`
      );
      this.setState({ confirmModal: false }, () => {
        this.state.confirmModalType === ConfirmModalType.DELETE
          ? services.router.goto(services.router.url.standby)
          : this.props.updateList();
      });
    } else {
      console.log(response);
      toast.dismiss();
      toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
    }
    this.setState({ loading: false });
  };

  public render() {
    const { item, user } = this.props;
    const { showFullDescription, loading } = this.state;
    return (
      <>
        {!item ? (
          services.router.goto('/stand-by-lists')
        ) : (
          <>
            <div className="details-header-container">
              <div className="details-header-title">{item.title}</div>
              <div className="details-header-subtitle">
                <div className="standbyLocation" style={{ marginTop: '10px' }}>
                  {item.location ? item.location : ''}
                  {item.privacy === 'private' && (
                    <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
                      <SBPrivateLabel />
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="sbl-item-dates-section">
                    {item.closedAt ? (
                      <div>Closed On: {moment(item.closedAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                    ) : (
                      <div>Expires On: {moment(item.expiresAt).format('MM/DD/YYYY')}</div>
                    )}
                    <div>Created On: {moment(item.createdAt).format('MM/DD/YYYY, hh:mm A ')}</div>
                  </div>
                  <div
                    className="sbl-item-title"
                    style={{ textAlign: 'center', marginLeft: '3rem' }}
                  >
                    <div style={{ fontWeight: 700, fontSize: '16px' }}>
                      {item.adjusters ? item.adjusters.invited : 0}
                    </div>
                    <div style={{ fontSize: '13px' }}>Sent</div>
                  </div>
                  <div
                    className="sbl-item-title"
                    style={{ textAlign: 'center', marginLeft: '3rem', marginRight: '3rem' }}
                  >
                    <div style={{ fontWeight: 700, fontSize: '16px' }}>
                      {item.adjusters ? item.adjusters.applied + item.adjusters.contacted : 0}
                    </div>
                    <div style={{ fontSize: '13px' }}>Applied/Contacted</div>
                  </div>

                  <CopySBLToClipboard publicId={item.publicId} />

                  {user.role !== 'Read Only Office Admin' && (
                    <Popup
                      trigger={
                        <Button
                          onClick={() =>
                            this.setState({
                              confirmModal: true,
                              confirmModalType: ConfirmModalType.DELETE,
                            })
                          }
                          className="sbl-action-button"
                        >
                          <Image src={trashIconB} />
                        </Button>
                      }
                      basic={true}
                      size="mini"
                      content="Delete"
                    />
                  )}
                  {user.role !== 'Read Only Office Admin' && (
                    <Popup
                      trigger={
                        <Button
                          disabled={item.closedAt}
                          onClick={() =>
                            this.setState({
                              confirmModal: true,
                              confirmModalType: ConfirmModalType.CLOSE,
                            })
                          }
                          className="sbl-action-button"
                        >
                          <Image src={cancelIconB} />
                        </Button>
                      }
                      basic={true}
                      size="mini"
                      content="Close"
                    />
                  )}
                  {item.closedAt && user.role !== 'Read Only Office Admin' && (
                    <Popup
                      trigger={
                        <Button
                          onClick={() => {
                            this.props.editList(true);
                            console.log(this.props);
                            services.router.goto(
                              `/stand-by-lists/edit/${this.props.match.params.id}#reopen`
                            );
                          }}
                          primary={true}
                          className="sbl-action-button"
                        >
                          <Image src={reopenIconW} />
                        </Button>
                      }
                      basic={true}
                      size="mini"
                      content="Reopen"
                    />
                  )}
                  {!item.closedAt && user.role !== 'Read Only Office Admin' && (
                    <Popup
                      trigger={
                        <Button
                          onClick={() => {
                            this.props.showModal(true, 'edit');
                          }}
                          className="sbl-action-button"
                        >
                          <Image src={msgIconB} />
                        </Button>
                      }
                      basic={true}
                      size="mini"
                      content="Send Message"
                    />
                  )}
                  {!item.closedAt && user.role !== 'Read Only Office Admin' && (
                    <Popup
                      trigger={
                        <Button
                          onClick={() => {
                            this.props.editList(true);
                            console.log(this.props);
                            services.router.goto(
                              `/stand-by-lists/edit/${this.props.match.params.id}`
                            );
                          }}
                          primary={true}
                          className="sbl-action-button"
                        >
                          <Image src={editIconW} />
                        </Button>
                      }
                      basic={true}
                      size="mini"
                      content="Edit"
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                showFullDescription
                  ? 'standby-description-details active'
                  : 'standby-description-details'
              }
            >
              <div
                className="standby-description-toggle"
                onClick={() => this.setState({ showFullDescription: !showFullDescription })}
              >
                Description
                <Image
                  src={arrowIcon}
                  className={
                    showFullDescription
                      ? 'standby-description-details-arrow active'
                      : 'standby-description-details-arrow'
                  }
                />
              </div>
              <div>{item.description}</div>
            </div>
            <Confirm
              size="tiny"
              closeIcon={<Image src={cancelIconB} className="icon" onClick={this.onCloseModal} />}
              open={this.state.confirmModal}
              onCancel={this.onCloseModal}
              header="Confirm"
              content={`Are you sure you want to ${
                this.state.confirmModalType === ConfirmModalType.DELETE ? 'delete' : 'close'
              } standby list "${item.title}"?`}
              cancelButton={{
                content: 'NO',
                disabled: loading,
              }}
              confirmButton={{
                content: 'YES',
                disabled: loading,
                loading,
              }}
              onConfirm={this.handleConfirm}
              className="confirm-modal-custom"
              closeOnDimmerClick={false}
            />
          </>
        )}
      </>
    );
  }
}

export default SBDetailsHeader;
