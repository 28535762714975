import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import editIcon from '../../assets/images/Blue/edit.svg';
import * as helper from '../../utils/helper';
import ErrorLabel from '../error-label/ErrorLabel';
import PhoneInput from '../layout-input/PhoneInput';

interface Props {
  item: object;
  id: number;
  delete: (id: number) => void;
  edit: (id: number, item: object) => void;
  mode: string;
}

interface State {
  showCreate: boolean;
}

class EducationItem extends React.Component<Props, State> {
  public state = {
    showCreate: false,
  };

  public render() {
    const { item, id } = this.props;
    const errorLabel = <ErrorLabel />;
    return (
      <React.Fragment>
        {!this.state.showCreate ? (
          <div className="education-item">
            {this.props.mode === 'create' && (
              <div className="close-education-icon">
                <Popup
                  trigger={
                    <Button className="iconic-button-custom" onClick={() => this.props.delete(id)}>
                      <Image src={closeIcon} />
                    </Button>
                  }
                  size="mini"
                  basic={true}
                  content="Delete"
                />
              </div>
            )}
            {this.props.mode === 'create' && (
              <div className="edit-education-icon">
                <Popup
                  trigger={
                    <Button
                      className="iconic-button-custom"
                      onClick={() => this.setState({ showCreate: true })}
                    >
                      <Image src={editIcon} />
                    </Button>
                  }
                  size="mini"
                  basic={true}
                  content="Edit"
                />
              </div>
            )}
            <div style={{ fontSize: '13px' }}>{item.name}</div>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{item.relation}</div>
            <div style={{ fontSize: '13px' }}>
              {helper.formatPhone(item.phone, '(###) ###-####')}
            </div>
          </div>
        ) : (
          <div className="create-education-container">
            <Form
              ref={ref => (this.form = ref)}
              id="create-reference-form"
              onValidSubmit={model => {
                this.props.edit(id, model);
                this.setState({ showCreate: false });
              }}
            >
              <div className="input-field-container">
                <div className="input-field-label">Reference Name</div>
                <Form.Input
                  name="name"
                  placeholder="Please Select"
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* First Name is required field.`,
                  }}
                  className="form-input"
                  errorLabel={errorLabel}
                  value={item.name}
                />
              </div>

              <div className="input-field-container">
                <div className="input-field-label">Reference Number</div>
                <PhoneInput
                  name="phone"
                  placeholder="Please Enter..."
                  instantValidation={false}
                  value={item.phone}
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* Phone is a required field.!!!!`,
                  }}
                  errorLabel={errorLabel}
                />
              </div>
              <div className="input-field-container">
                <div className="input-field-label">Reference Relationship</div>
                <Form.Input
                  name="relation"
                  placeholder="Please Select"
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: `* Description is required field.`,
                  }}
                  className="form-input"
                  errorLabel={errorLabel}
                  value={item.relation}
                />
              </div>
              <div className="education-button">
                <Button type="button" onClick={() => this.setState({ showCreate: false })}>
                  CANCEL
                </Button>
                <Button
                  type="button"
                  onClick={e => {
                    this.form.formsyForm.submit();
                  }}
                  form="create-education-form"
                >
                  SAVE
                </Button>
              </div>
            </Form>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default EducationItem;
