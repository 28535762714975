import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import PagePlaceholder from '../loading-spinner/PagePlaceholder';
import SBQuestion from './SBQuestion';
import StandbyUserHeader from './SBUserHeader';

interface Props {
  user: IUser;
  activeAdjuster: any;
  loading: boolean;
  setActiveStandby: typeof standbyActions.setActiveStandby;
  getStandbyAdjusters: typeof standbyActions.getStandbyAdjusters;
}

interface State {
  answers: object[];
  adjuster: any;
}

class StandbyAnswers extends React.Component<Props, State> {
  public state = {
    answers: [],
    adjuster: null,
  };
  public componentDidMount = async () => {
    const { filter } = JSON.parse(decodeURI(this.props.location.hash.slice(1) || '{}'));
    const [, adjusterId = ''] = this.props.location.search.split(':');
    if (adjusterId) {
      this.props.getStandbyAdjusters(
        this.props.match.params.id,
        Object.assign({ id: adjusterId }, filter)
      );
      this.props.setActiveStandby(this.props.match.params.id);
      const response = await services.api.standby.getAdjusterAnswers(
        this.props.match.params.id,
        adjusterId
      );
      if (response.isSuccess) {
        const { adjuster, answers } = response.data;
        this.setState({ adjuster, answers });
      }
    } else {
      services.router.goto(`/stand-by-lists/${this.props.match.params.id}${location.hash}`);
    }
  };

  public render() {
    const { answers, adjuster } = this.state;
    if (this.props.loading || !adjuster) {
      return <PagePlaceholder size={4} />;
    }
    return (
      <div className="standby-answers-page">
        <StandbyUserHeader match={this.props.match} adjuster={adjuster} />
        <div style={{ marginTop: '20px' }}>
          {answers.length &&
            answers
              .sort((a: any, b: any) => a.question.order - b.question.order)
              .map((question: any, index: number) => {
                return <SBQuestion question={question} key={index} />;
              })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  activeAdjuster: state.standby.standby.activeAdjuster,
  loading: state.loading.loading.isVisible,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveStandby: standbyActions.setActiveStandby,
      getStandbyAdjusters: standbyActions.getStandbyAdjusters,
    },
    dispatch
  );

export default connect<Props>(
  mapStateToProps,
  mapDispatchToProps
)(StandbyAnswers);
