import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Dimmer, Loader } from 'semantic-ui-react';

export const MessageSpiner = (props: any) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area });
  return (
    <div className="message-spinner">
      {promiseInProgress !== true ? null : (
        <Dimmer active={true} inverted={true}>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};
