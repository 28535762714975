import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Image, Popup } from 'semantic-ui-react';

const CustomPopupButton = (props: any) => {
  const { onPress, popup, image, classNm, link = '', setMapCallback = null } = props;

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (setMapCallback) {
      setMapCallback(setIsOpen);
    }
  }, []);

  const items = (
    <Button type="button" onClick={onPress} className={classNm}>
      <Image src={image} />
    </Button>
  );
  const trigger = link ? (
    <Link to={link} replace={!!onPress}>
      {items}
    </Link>
  ) : (
    items
  );

  if (setMapCallback && !isOpen) {
    return trigger;
  }

  return <Popup trigger={trigger} basic={true} size="mini" content={popup} />;
};

export default CustomPopupButton;
