import { put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.CONFIRMATION_MODAL_CONFIRM, confirmAction);
  yield takeEvery(constants.ALERT_MODAL_HIDE, alertHideAction);
}

export function* confirmAction(action: ActionType<typeof actions.confirmationModalConfirm>) {
  const state = yield select();

  yield put(state.modal.confirmation.model.okAction());
  yield put(actions.confirmationModalHide());
}

export function* alertHideAction(action: ActionType<typeof actions.alertModalHide>) {
  const state = yield select();

  if (state.modal.alert.model && state.modal.alert.model.closeAction) {
    yield put(state.modal.alert.model.closeAction());
  }
}
