import React from 'react';
import { Button, Image, Menu, Modal } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import '../../../assets/styles/Staff.css';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { trainingCenterActions } from '../../../features/training-center';
import { connect } from 'react-redux';
import './EditMaterialModal.css';
import EditDocument from './EditDocument';
import EditEmbeddedVideo from './EditEmbeddedVideo';
import { DocumentData, EmbeddedVideoData } from '../shared/types';

interface Props {
  material: any;
  showModal: boolean;
  isUploading: boolean;
  toggleEditMaterialModal: (material?: any) => void;
  editEmbeddedVideoMaterial: (id: number, data: EmbeddedVideoData) => void;
  editDocumentMaterial: (id: number, data: DocumentData) => void;
}

interface State {
  activeTab: string;
  materials: any[];
  embeddedVideo: any;
  submitRef: any;
}

class EditMaterialModal extends React.Component<Props, State> {
  public form: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: this.props.material ? this.props.material.type : 'document',
      materials: [],
      embeddedVideo: {},
      submitRef: null,
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.material) {
      const tab = this.props.material.type === 'document' ? 'document' : 'video';
      if (tab !== this.state.activeTab) {
        this.setState({ activeTab: tab });
      }
    }
  }

  public onMaterialEdit = (materialData: DocumentData[]) => {
    this.props.editDocumentMaterial(this.props.material.id, materialData);
  };

  public onVideoEdit = (videoData: EmbeddedVideoData) => {
    this.props.editEmbeddedVideoMaterial(this.props.material.id, videoData);
  };

  public setSubmitRef = (ref: any) => {
    this.setState({ submitRef: ref });
  };

  public onEditClick = () => {
    console.log(this.state.submitRef);
    // @ts-ignore
    this.state.submitRef.current.ref.current.click();
  };

  public renderSelectedTab = () => {
    if (this.state.activeTab === 'document') {
      return <EditDocument material={this.props.material} onFinish={this.onMaterialEdit} setSubmitRef={this.setSubmitRef} />;
    }

    return <EditEmbeddedVideo material={this.props.material} onFinish={this.onVideoEdit} setSubmitRef={this.setSubmitRef} />;
  };

  public closeModal = () => {
    if (this.props.isUploading) {
      return;
    }

    this.props.toggleEditMaterialModal(null);
  }

  public render() {
    return this.props.showModal ? (
      <Modal
        open
        className="material-modal edit"
        size="small"
        closeIcon={
          <Image
            disabled={this.props.isUploading}
            src={closeIcon}
            className="icon"
            onClick={this.closeModal}
          />
        }
      >
        <Modal.Header>Edit Material</Modal.Header>
        <Modal.Content>
          <Menu text={true} className="tabs-menu edit-material-tabs" fluid>
            <Menu.Item
              disabled
              name="document"
              active={this.state.activeTab === 'document'}
            >
              Document
            </Menu.Item>
            <Menu.Item
              disabled
              name="video"
              active={this.state.activeTab === 'video'}
            >
              Embedded Video
            </Menu.Item>
          </Menu>
          {this.renderSelectedTab()}
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            style={{ width: '115px' }}
            className="secondary-button"
            secondary={true}
            onClick={this.closeModal}
            disabled={this.props.isUploading}
          >
            CANCEL
          </Button>
          <Button
            loading={this.props.isUploading}
            style={{ width: '115px' }}
            primary={true}
            onClick={this.onEditClick}
          >
            Save
          </Button>
        </div>
      </Modal>
    ) : null;
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  showModal: state.trainingCenter.editMaterial.showModal,
  isUploading: state.trainingCenter.editMaterial.isUploading,
  material: state.trainingCenter.editMaterial.material,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleEditMaterialModal: trainingCenterActions.toggleEditMaterialModal,
      editEmbeddedVideoMaterial: trainingCenterActions.editEmbeddedVideoMaterial,
      editDocumentMaterial: trainingCenterActions.editDocumentMaterial,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditMaterialModal);
