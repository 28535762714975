import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import '../../assets/styles/Staff.css';
import { adminActions } from '../../features/admin';
import ErrorLabel from '../error-label/ErrorLabel';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { trainingCenterActions } from '../../features/training-center';
import { CreateFolderModel, Folder } from '../../features/training-center/trainingCenterReducer';
import dropdownIcon from '../../assets/images/ui-dropdown.svg';
import services from '../../services';

interface Props {
  toggleCreateFolderModal: typeof trainingCenterActions.toggleCreateFolderModal;
  createFolder: typeof trainingCenterActions.createFolder;
  showModal: boolean;
  formData: any;
  folderList: Folder[];
}

interface State {
  parentData: any;
}

class CreateFolderModal extends React.Component<Props, State> {
  public form: any;

  public state = {
    parentData: null,
  };

  public renderCreateFolderForm(errorLabel: any) {
    return (
      <Form.Input
        name="name"
        maxLength={60}
        placeholder="Please Enter Folder Name"
        instantValidation={false}
        required={true}
        validations={{
          isOnlySpaces: true,
        }}
        validationErrors={{
          isDefaultRequiredValue: '* Folder Name is required field.',
          isOnlySpaces: '* Folder Name is required field.',
        }}
        className="form-input subfolder"
        errorLabel={errorLabel}
      />
    );
  }

  public renderCreateSubFolderForm(errorLabel: any) {
    const parentFolderOptions = this.props.folderList.map(t => {
      return {
        key: t.id,
        text: t.name,
        value: t.id,
      };
    });

    const { formData } = this.props;
    const { parentData } = this.state;

    return (
      <React.Fragment>
        <div className="input-field-container">
          <div className="input-field-label-subfolder">Name</div>
          <div className="field-input-container">
            <Form.Input
              name="name"
              maxLength={60}
              placeholder="Please Enter Subfolder Name"
              instantValidation={false}
              required={true}
              validations={{
                isOnlySpaces: true,
              }}
              validationErrors={{
                isDefaultRequiredValue: '* Subfolder Name is required field.',
                isOnlySpaces: '* Subfolder Name is required field.',
              }}
              className="form-input subfolder"
              errorLabel={errorLabel}
            />
          </div>
        </div>

        <div className="input-field-container">
          <div className="input-field-label-subfolder">Folder</div>
          <div className="field-input-container">
            <Form.Input
              name="parentLabel"
              required={false}
              defaultValue={(parentData && parentData.name) || ''}
              className="form-input subfolder"
              errorLabel={errorLabel}
              disabled={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (prevProps.formData.parentId !== this.props.formData.parentId) {
      const { formData } = this.props;
      const parentId = formData.parentId;
      const res = await services.api.folder.getById(parentId);
      console.log({ data: res.data });
      this.setState({ parentData: res.data });
    }
  }
  async componentDidMount(): Promise<void> {}

  public render() {
    const errorLabel = <ErrorLabel />;
    const { showModal, formData, folderList } = this.props;

    const createSubFolder = formData.parentId;
    const modelHeader = createSubFolder ? 'Add Subfolder' : 'Add Folder';

    console.log('createSubFolder', createSubFolder);
    console.log('formData', formData);
    console.log('folderList', folderList);
    console.log('parentData', this.state.parentData);
    console.log('createSubFolder', createSubFolder);
    return (
      <Modal
        className="create-category-modal"
        open={showModal}
        closeIcon={
          <Image
            src={closeIcon}
            className="icon"
            onClick={() => this.props.toggleCreateFolderModal()}
          />
        }
      >
        <Modal.Header>{modelHeader}</Modal.Header>
        <Form
          onValidSubmit={async (model: CreateFolderModel) => {
            console.log('submitting...', model);
            model.name = model.name.trim();
            model.parentId = this.props.formData.parentId;
            const obj = Object.assign({}, model);
            this.props.createFolder(obj);
          }}
          ref={(ref: any) => (this.form = ref)}
        >
          <Modal.Content>
            <LoadingSpiner area="create-admin-modal-area" />
            <Grid>
              <Grid.Column style={{ padding: '35px' }}>
                {!createSubFolder && this.renderCreateFolderForm(errorLabel)}
                {createSubFolder && this.renderCreateSubFolderForm(errorLabel)}
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.props.toggleCreateFolderModal()}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              CREATE
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  showModal: state.trainingCenter.createFolder.showModal,
  formData: state.trainingCenter.createFolder.formData,
  folderList: state.trainingCenter.folderList,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAdminList: adminActions.getAdminList,
      resetAdminList: adminActions.resetAdminList,
      toggleCreateFolderModal: trainingCenterActions.toggleCreateFolderModal,
      createFolder: trainingCenterActions.createFolder,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFolderModal);
