import { Form, RadioGroup } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Image, Popup, Radio } from 'semantic-ui-react';
import approvedPaperworkIcon from '../../assets/images/icn-paperwork-approved.svg';
import pendingPaperworkIcon from '../../assets/images/icn-paperwork-pending.svg';
import noAvatarIcon from '../../assets/images/no-avatar.svg';
import '../../assets/styles/Profile.css';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import services from '../../services';
import * as helper from '../../utils/helper';
import ProfileDetailsFields from '../../utils/ProfileDetailsFields';
import statesUSbyKey from '../../utils/statesUSbyKey';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import ErrorLabel from '../error-label/ErrorLabel';
import SearchMultipleInput from '../form/SearchMultipleInput/SearchMultipleInput';
import InternalNoteBlock from '../register-layout/InternalNoteBlock';
import ReferenceItem from '../register-layout/ReferenceItem';
import AddNoteModal from './../adjuster-list/AddNoteModal';
import AvatarScaled from './../avatar-input/AvatarScaled';
import EducationItem from './../register-layout/EducationItem';
import AssignFolderAdjuster from './AdjusterAssignedFolder/AssignFolderAdjuster';
import RegisterProgress from './RegisterProgress';
import ContactedModal from '../standby-layout/modals/ContactedModal';
import { Link } from 'react-router-dom';

const fields = ProfileDetailsFields;

const howDidYouHearOpt = [
  { text: 'Facebook', value: 'Facebook' },
  { text: 'Lindkedin', value: 'Lindkedin' },
  { text: 'Twitter', value: 'Twitter' },
  { text: 'Instagram', value: 'Instagram' },
  { text: 'Friend of College', value: 'Friend of College' },
  { text: 'Internet Search', value: 'Internet Search' },
  { text: 'Other', value: 'Other' },
];

interface Props {
  getSubmitRef: (ref: any) => void;
  update: (id: any) => void;
  fetchData: any;
  user: AdjusterUser;
  admin: IUser;
}

interface State {
  avatar: string;
  avatarEdited: boolean;
  fieldsUrl: string;
  formData: FormData | {};
  availableForClaims: boolean;
  isAdmin: boolean;
  education: object[];
  references: object[];
  positionsOptions: object[];
  activePositions: string[];
  isSubscribedToCompassMailUpdates: boolean;
  isSubscribedToPersonalMailUpdates: boolean;
  showCompassLabel: string;
  showEmailLabel: string;
  enteredPass: string;
  fullPositions: boolean;
  isChecked: boolean;
  markAsActive: boolean;
  status: string;
  largeLossTeam: boolean;
  commercialTeam: boolean;
  showNoteModal: boolean;
  standbyModalId: number;
  showCompanyName: boolean;
  showScaled: boolean;
  deployed: object[];
  deployedOptions: object[];
  toDeploy: object[];
  certificationScore: number;
  fieldInterviewScore: number;
  deskInterviewScore: number;
  qaScore: number;
  serviceFeePercent?: number;
  tAndEPercent?: number;
  photosPercent?: number;
  miscExpensePercent?: number;
  mileagePercent?: number;
  standbyListNote?: Object[];
}

class ProfileDetails extends React.Component<Props, State> {
  public state = {
    avatar: '',
    avatarEdited: false,
    fieldsUrl: '',
    formData: {},
    availableForClaims: true,
    isSubscribedToCompassMailUpdates: true,
    isSubscribedToPersonalMailUpdates: true,
    education: [],
    references: [],
    positionsOptions: [],
    activePositions: [],
    showCompassLabel: 'readonly-input',
    showEmailLabel: 'readonly-input',
    enteredPass: '',
    fullPositions: false,
    isChecked: false,
    markAsActive: false,
    status: 'Inactive',
    largeLossTeam: true,
    commercialTeam: true,
    showNoteModal: false,
    standbyModalId: 0,
    showCompanyName: false,
    deployed: [],
    deployedOptions: [],
    toDeploy: [],
    showScaled: false,
    isAdmin: false,
    open: '',
    certificationScore: null,
    fieldInterviewScore: null,
    deskInterviewScore: null,
    qaScore: null,
    serviceFeePercent: 60,
    tAndEPercent: 60,
    photosPercent: 60,
    miscExpensePercent: 100,
    mileagePercent: 100,
    standbyListNote: [],
  };

  public submitRef = React.createRef();

  public componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.props.getSubmitRef(this.submitRef);

    const [standbyListNote, request] = await Promise.all([services.api.standby.getStandbyListNoteAdjuster(this.props.user), services.api.account.getSettingsData('position')])

    standbyListNote.data.list.sort((s1,s2) => {
      const firstDateS1 = (s1.note || []).length > 0 ? new Date(s1.note[0] ? s1.note[0].date : 0) : new Date(0)
      const firstDateS2 = (s2.note || []).length > 0 ? new Date(s2.note[0] ? s2.note[0].date : 0) : new Date(0)

      if (firstDateS1 < firstDateS2) return 1
      if (firstDateS1 == firstDateS2) return 0
      return -1
    })

    const positionsOptions = request.data.list.map((el: any, index: any) => {
      const element = {
        label: el.name,
        checked: this.props.user.positions && this.props.user.positions.includes(el.name),
      };
      return element;
    });

    if (this.props.user.registrationType === 'business') {
      this.setState({ showCompanyName: true });
    } else {
      this.setState({ showCompanyName: false });
    }

    if (this.props.user.deployments && this.props.user.deployments.length) {
      const deployments = await services.api.admin.fetchAdjusterDeployment(+this.props.user.id);
      this.updateDeployments(deployments.data);
    }

    this.setState({
      avatar: this.props.user.avatar,
      education: this.props.user.educations ? this.props.user.educations : [],
      positionsOptions,
      activePositions: this.props.user.positions ? this.props.user.positions : [],
      references: this.props.user.references ? this.props.user.references : [],
      availableForClaims: this.props.user.availableForClaims,
      isSubscribedToCompassMailUpdates: this.props.user.isSubscribedToCompassMailUpdates,
      isSubscribedToPersonalMailUpdates: this.props.user.isSubscribedToPersonalMailUpdates,
      isAdmin: this.props.user.isAdmin,
      status: this.props.user.status,
      largeLossTeam: this.props.user.largeLossTeam,
      commercialTeam: this.props.user.commercialTeam,
      standbyListNote: standbyListNote.data.list,
    });
  };

  public showScaledModal = (show: boolean) => {
    this.setState({ showScaled: show });
  };

  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.user !== this.props.user) {
      this.props.user.positions.forEach((el: any) => {
        const index = this.state.positionsOptions.findIndex(item => item.label === el);
        if (index === -1) {
          return;
        } else {
          this.state.positionsOptions[index].checked = true;
        }
      });

      this.setState({
        avatar: this.props.user.avatar,
        education: this.props.user.educations ? this.props.user.educations : [],
        activePositions: this.props.user.positions ? this.props.user.positions : [],
        references: this.props.user.references ? this.props.user.references : [],
        status: this.props.user.status,
        availableForClaims: this.props.user.availableForClaims,
        isSubscribedToCompassMailUpdates: this.props.user.isSubscribedToCompassMailUpdates,
        isSubscribedToPersonalMailUpdates: this.props.user.isSubscribedToPersonalMailUpdates,
        isAdmin: this.props.user.isAdmin,
        showEmailLabel: 'readonly-input',
      });
    }
  };

  public updateDeployments = deployments => {
    const optionsArray = deployments.map((el: any) => {
      const value = el.isStandbyList ? `${el.title} (${el.location})` : el.name;
      return {
        id: el.id,
        deployed: el.deployed,
        text: value,
        value: value,
        isCustomEvent: !el.isStandbyList,
      };
    });
    const toDeploy = optionsArray.filter(o => o.deployed);
    const deployedValues = toDeploy.map(o => o.value);
    this.setState({ deployedOptions: optionsArray, toDeploy, deployed: deployedValues });
  };

  public showNoteModal = (status: boolean) => {
    this.setState({ showNoteModal: status });
  };

  public showStandbyModalId = (status: boolean) => {
    this.setState({ standbyModalId: Number(status) });
  }

  public getAvatar = (key: any, avatarEdited: boolean) => {
    this.setState({ avatar: key, avatarEdited });
  };

  public handlePass = (e: any) => {
    this.setState({ enteredPass: e.target.value });
  };

  public handleTypeDropdown = (e: any, { value }: any) => {
    if (value === 'business') {
      this.setState({ showCompanyName: true });
    } else {
      this.setState({ showCompanyName: false });
    }
  };

  public checkForReadOnly = (field: string) => {
    if (this.props.admin.role === 'Read Only Office Admin') {
      return true;
    } else if (field === 'email' && this.state.showEmailLabel === 'readonly-input') {
      return true;
    } else if (field === 'email' && this.state.showEmailLabel !== 'readonly-input') {
      return false;
    } else if (field === 'compassEmail') {
      return false;
    } else {
      return true;
    }
  };

  public resetField = (name = '') => {
    this.setState({ [name]: [] });
  };

  public setField = (name, obj) => {
    if (obj === '') {
      this.setState({ [name]: [] });
    } else {
      this.setState({ [name]: obj.value });
      if (obj.toDeploy) {
        this.setState({ toDeploy: obj.toDeploy });
      }
    }
  };

  public setOpen = name => this.setState({ open: name });
  public closePopup = () => this.setOpen({ open: '' });

  public getNoteTitle = () => {
    if (!this.state.standbyModalId) return ''
    const find = this.state.standbyListNote.find((v) => v.id === this.state.standbyModalId)
    if (!find) return ''
    return ` for ${find.title}`
  }

  handleDeleteNote = (index: number) => {};

  public render() {
    const { user } = this.props;
    const errorLabel = <ErrorLabel />;
    return (
      <div className="admin-adjuster-profile-page">
        <div className="adjuster-admin-section">
          {user.status === 'To Review' && (
            <div className="profile-updated-section">
              <p>Profile was updated</p>
              <div className="toggle-block">
                <Button
                  type="button"
                  disabled={this.props.admin.role === 'Read Only Office Admin'}
                  className={!this.state.markAsActive ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ markAsActive: false })}
                >
                  Keep as Inactive
                </Button>
                <Button
                  type="button"
                  disabled={this.props.admin.role === 'Read Only Office Admin'}
                  className={this.state.markAsActive ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ markAsActive: true })}
                >
                  Mark as Active
                </Button>
              </div>
            </div>
          )}
          <div className="admin-toggles-section">
            <div
              className="adjuster-avatar-profile-container"
              onDoubleClick={() => {
                !this.props.user.avatar
                  ? console.log('no-avatar')
                  : this.setState({ showScaled: true });
              }}
            >
              <Image
                circular={true}
                className="adjuster-avatar"
                src={this.props.user.avatar ? this.props.user.avatar : noAvatarIcon}
              />
              <Popup
                trigger={
                  <Image
                    className="approved-paperwork-icn"
                    src={user.allPaperworkCompleted ? approvedPaperworkIcon : pendingPaperworkIcon}
                  />
                }
                basic={true}
                size="mini"
                content={`Paperwork ${user.allPaperworkCompleted ? 'Completed' : 'Pending'}`}
              />
            </div>
            {this.state.status !== 'Deleted' &&
              this.state.status !== 'To Review' &&
              this.state.status !== 'Archived' && (
                <div className="admin-toggles-block">
                  <div className="header-label-text" style={{ marginBottom: '8px' }}>
                    Status
                  </div>
                  <div className="toggle-block adjuster-active">
                    <Button
                      type="button"
                      disabled={this.props.admin.role === 'Read Only Office Admin'}
                      className={this.state.status === 'Active' ? 'yes-btn' : 'no-btn'}
                      onClick={() => this.setState({ status: 'Active' })}
                    >
                      Active
                    </Button>
                    <Button
                      type="button"
                      disabled={this.props.admin.role === 'Read Only Office Admin'}
                      className={this.state.status !== 'Active' ? 'yes-btn' : 'no-btn'}
                      onClick={() => this.setState({ status: 'Inactive' })}
                    >
                      Inactive
                    </Button>
                  </div>
                </div>
              )}
            <div className="admin-toggles-block">
              <div className="header-label-text" style={{ marginBottom: '8px' }}>
                Admin
              </div>
              <div className="toggle-block">
                <Button
                  type="button"
                  className={this.state.isAdmin ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ isAdmin: true })}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  className={!this.state.isAdmin ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ isAdmin: false })}
                >
                  No
                </Button>
              </div>
            </div>
            <div className="admin-toggles-block">
              <div className="header-label-text" style={{ marginBottom: '8px' }}>
                Availability for Claims
              </div>
              <div className="toggle-block">
                <Button
                  type="button"
                  disabled={true}
                  className={this.state.availableForClaims ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ availableForClaims: true })}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  disabled={true}
                  className={!this.state.availableForClaims ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ availableForClaims: false })}
                >
                  No
                </Button>
              </div>
              {!user.availableForClaims && (
                <div className="expiration-block">
                  {user.availableForClaimsExpirationDate
                    ? moment(user.availableForClaimsExpirationDate).format('MM-DD-YYYY')
                    : user.availableForClaims
                    ? ''
                    : 'Never Expires'}
                </div>
              )}
            </div>
            <div className="admin-toggles-block">
              <div className="header-label-text" style={{ marginBottom: '8px' }}>
                Large Loss Team
              </div>
              <div className="toggle-block">
                <Button
                  type="button"
                  disabled={this.props.admin.role === 'Read Only Office Admin'}
                  className={this.state.largeLossTeam ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ largeLossTeam: true })}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  disabled={this.props.admin.role === 'Read Only Office Admin'}
                  className={!this.state.largeLossTeam ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ largeLossTeam: false })}
                >
                  No
                </Button>
              </div>
            </div>
            <div className="admin-toggles-block">
              <div className="header-label-text" style={{ marginBottom: '8px' }}>
                Commercial Team
              </div>
              <div className="toggle-block">
                <Button
                  type="button"
                  disabled={this.props.admin.role === 'Read Only Office Admin'}
                  className={this.state.commercialTeam ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ commercialTeam: true })}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  disabled={this.props.admin.role === 'Read Only Office Admin'}
                  className={!this.state.commercialTeam ? 'yes-btn' : 'no-btn'}
                  onClick={() => this.setState({ commercialTeam: false })}
                >
                  No
                </Button>
              </div>
            </div>
            <div className="admin-toggles-block">
              <div className="header-label-text" style={{ marginBottom: '8px' }}>
                Deployed
              </div>
              <div className="filter-block">
                <SearchMultipleInput
                  value={this.state.deployed}
                  formFields={this.state}
                  name="deployed"
                  placeholder="Add Deployments"
                  options={this.state.deployedOptions}
                  showHeader={false}
                  setField={(name, value) => this.setField(name, value)}
                  resetField={() => this.resetField('deployed')}
                  open={this.state.open}
                  setOpen={() => this.setOpen('deployed')}
                  closePopup={this.closePopup}
                  isCustom={true}
                  updateDeployments={this.updateDeployments}
                  {...this.props}
                />
              </div>
            </div>
            <RegisterProgress progress={user.profileScore} />
          </div>
          {!user.availableForClaims && (
            <div className="availabilityNotes">
              <p>
                <strong>Availability Note: </strong>
                {user.availableForClaimsNotes}
              </p>
            </div>
          )}
        </div>

        <Form
          onValidSubmit={async (model: any) => {
            const {
              status,
              availableForClaims,
              largeLossTeam,
              commercialTeam,
              markAsActive,
              isAdmin,
            } = this.state;

            const obj = Object.assign(
              {},
              {
                availableForClaims,
                largeLossTeam,
                commercialTeam,
                isAdmin,
                authorityLevel: Number(model.authorityLevel),
                financeID: model.financeID,
                compassEmail: model.compassEmail,
                registrationType: model.registrationType,
                companyName: model.companyName,
                email: model.email,
                catZip: model.catZip,
                certificationScore: model.certificationScore
                  ? Number(model.certificationScore)
                  : null,
                fieldInterviewScore: model.fieldInterviewScore
                  ? Number(model.fieldInterviewScore)
                  : null,
                deskInterviewScore: model.deskInterviewScore
                  ? Number(model.deskInterviewScore)
                  : null,
                qaScore: model.qaScore ? Number(model.qaScore) : null,
                serviceFeePercent: model.serviceFeePercent ? Number(model.serviceFeePercent) : null,
                tAndEPercent: model.tAndEPercent ? Number(model.tAndEPercent) : null,
                photosPercent: model.photosPercent ? Number(model.photosPercent) : null,
                miscExpensePercent: model.miscExpensePercent
                  ? Number(model.miscExpensePercent)
                  : null,
                mileagePercent: model.mileagePercent ? Number(model.mileagePercent) : null,
              }
            );

            if (
              this.props.user.status === 'To Review' ||
              this.props.user.status === 'Deleted' ||
              this.props.user.status === 'Archived'
            ) {
              this.props.user.status !== 'To Review'
                ? (obj.status = this.props.user.status)
                : markAsActive
                ? (obj.status = 'Active')
                : (obj.status = 'Inactive');
            } else {
              obj.status = status === 'Active' ? 'Active' : 'Inactive';
            }

            const response = await services.api.adjuster.updateAdjusterDetails(
              this.props.user.id,
              obj
            );
            if (response.isSuccess) {
              if (
                this.state.markAsActive ||
                (this.props.user.status !== 'Active' && this.state.status === 'Active')
              ) {
                toast.success(
                  `Adjuster ${this.props.user.firstName} ${this.props.user.lastName} was successfully activated!`
                );
              }
              this.props.update(this.props.user.id);
              toast.dismiss();
              toast.success('Profile was successfully updated!');
            } else {
              toast.dismiss();
              toast.error(
                response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`
              );
            }
          }}
        >
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <div className="input-field-container">
                  <div className={'input-field-label'}>Registration Type</div>
                  <div className="registration-type-dropdown">
                    <RadioGroup
                      name="registrationType"
                      required={true}
                      onChange={this.handleTypeDropdown}
                      className="registration-type-group"
                      value={user.registrationType}
                      validationErrors={{
                        isDefaultRequiredValue: `* Registration Type is required field.`,
                      }}
                      errorLabel={errorLabel}
                    >
                      <Radio
                        label="Individual"
                        value="individual"
                        disabled={this.props.admin.role === 'Read Only Office Admin'}
                      />
                      <Radio
                        label="Business"
                        value="business"
                        disabled={this.props.admin.role === 'Read Only Office Admin'}
                      />
                    </RadioGroup>
                  </div>
                </div>
                {this.state.showCompanyName && (
                  <div className="input-field-container">
                    <div className={'input-field-label'}>Company Name</div>
                    <Form.Input
                      name="companyName"
                      type={'text'}
                      value={helper.htmlEncode(user.companyName)}
                      placeholder="Please Enter..."
                      instantValidation={false}
                      disabled={this.props.admin.role === 'Read Only Office Admin'}
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: `* Company Name is required field.`,
                      }}
                      errorLabel={errorLabel}
                      className={'form-input'}
                    />
                  </div>
                )}
                {fields.map((field: any) => {
                  if (field.name === 'positions') {
                    return (
                      <div key={field.name}>
                        {user.positions &&
                          user.positions.map((el: any, index: number) => {
                            return (
                              <div className="input-field-container" key={index}>
                                <div className="input-field-label">
                                  {index === 0 ? 'Positions' : ''}
                                </div>
                                <Form.Input
                                  name="el"
                                  readOnly={true}
                                  placeholder="N/A"
                                  instantValidation={false}
                                  className="form-input readonly-input"
                                  value={el}
                                />
                              </div>
                            );
                          })}
                      </div>
                    );
                  } else if (field.type === 'date') {
                    return (
                      <div className="input-field-container" key={field.name}>
                        <div className={field.required ? 'input-field-label' : 'input-field-label'}>
                          {field.label}
                        </div>
                        <DatePickerInput
                          name={field.name}
                          readOnly={true}
                          value={
                            this.props.user[field.name] === 0 ? '' : this.props.user[field.name]
                          }
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={`input-field-container ${
                          field.name === 'email' ? 'email-adjuster-change-input' : ''
                        }`}
                        key={field.name}
                      >
                        <div className={'input-field-label'}>{field.label}</div>
                        <Form.Input
                          name={field.name}
                          type={field.type}
                          label={
                            field.name === 'email' &&
                            this.state.showEmailLabel === 'readonly-input' ? (
                              <p
                                className="change-input-label"
                                onClick={() => this.setState({ showEmailLabel: '' })}
                              >
                                CHANGE
                              </p>
                            ) : (
                              false
                            )
                          }
                          labelPosition={field.name === 'email' ? 'right' : undefined}
                          value={
                            field.name === 'phone' ||
                            field.name === 'mobilePhone' ||
                            field.name === 'fax'
                              ? helper.formatPhone(user[field.name], '(###) ###-####')
                              : user[field.name]
                          }
                          placeholder="N/A"
                          instantValidation={false}
                          readOnly={this.checkForReadOnly(field.name)}
                          className={
                            field.name === 'email'
                              ? `${field.className} ${this.state.showEmailLabel}`
                              : field.name === 'compassEmail' &&
                                this.props.admin.role === 'Read Only Office Admin'
                              ? `${field.className} readonly-input`
                              : field.className
                          }
                        />
                      </div>
                    );
                  }
                })}
                <div className="input-field-container state-province-container">
                  <div className="input-field-label">State/Province</div>
                  <div className="zip-form-input">
                    <Form.Input
                      readOnly={true}
                      name="state"
                      placeholder="N/A"
                      instantValidation={false}
                      className="form-input-short readonly-input states-input"
                      value={statesUSbyKey[user.state]}
                    />
                    <div className="zip">
                      <div style={{ paddingTop: '8px', marginRight: '5px' }}>ZIP</div>
                      <Form.Input
                        name="zip"
                        readOnly={true}
                        placeholder="N/A"
                        instantValidation={false}
                        className="form-input-short readonly-input"
                        value={user.zip}
                      />
                    </div>
                  </div>
                </div>
                {user.temporaryLocation && (
                  <>
                    <div className="input-field-container">
                      <div className="input-field-label">CAT Address City</div>
                      <Form.Input
                        name="catCity"
                        readOnly={true}
                        placeholder="N/A"
                        instantValidation={false}
                        className="form-input readonly-input"
                        value={user.catCity}
                        validationErrors={{
                          isDefaultRequiredValue: `* CAT City is required field.`,
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                    <div className="input-field-container">
                      <div className="input-field-label">CAT State/Province</div>
                      <Form.Input
                        name="catStateProvince"
                        readOnly={true}
                        placeholder="N/A"
                        instantValidation={false}
                        className="form-input-short readonly-input states-input"
                        value={statesUSbyKey[user.catStateProvince]}
                      />
                    </div>
                    <div className="input-field-container">
                      <div className="input-field-label">CAT Zip</div>
                      <Form.Input
                        name="catZip"
                        value={user.catZip ? user.catZip : 'N/A'}
                        placeholder="Please Enter..."
                        instantValidation={false}
                        readOnly={true}
                        className="form-input readonly-input"
                        validationErrors={{
                          isDefaultRequiredValue: `* Cat Zip is required field.`,
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                    <div className="input-field-container">
                      <div className="input-field-label">CAT Expiration Date</div>
                      <DatePickerInput
                        name="catExpirationDate"
                        readOnly={true}
                        value={
                          this.props.user.catExpirationDate ? this.props.user.catExpirationDate : ''
                        }
                        validationErrors={{
                          isDefaultRequiredValue: `* CAT Expiration Date is required field.`,
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  </>
                )}
                <div className="input-field-container">
                  <div className="input-field-label">How did you hear about us?</div>
                  <Form.Input
                    readOnly={true}
                    name="howDidYouHearAboutUs"
                    placeholder="N/A"
                    instantValidation={false}
                    value={
                      user.howDidYouHearAboutUs === ''
                        ? undefined
                        : howDidYouHearOpt.filter(el => el.text === user.howDidYouHearAboutUs)
                            .length === 0
                        ? 'Other'
                        : user.howDidYouHearAboutUs
                    }
                    className="form-input readonly-input"
                  />
                </div>
                {howDidYouHearOpt.filter(el => el.text === user.howDidYouHearAboutUs).length ===
                  0 && (
                  <div className="input-field-container">
                    <div className="input-field-label">Other source</div>
                    <Form.Input
                      name="howDidYouHearAboutUs"
                      readOnly={true}
                      value={user.howDidYouHearAboutUs}
                      placeholder="N/A"
                      instantValidation={false}
                      className="form-input readonly-input"
                    />
                  </div>
                )}
              </Grid.Column>
              <Grid.Column>
                <div className="input-field-container">
                  <div className="input-field-label">Authority Level (USD)</div>
                  <Form.Input
                    name="authorityLevel"
                    type="number"
                    placeholder="N/A"
                    instantValidation={false}
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.authorityLevel}
                  />
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Finance ID</div>
                  <Form.Input
                    name="financeID"
                    placeholder="N/A"
                    instantValidation={false}
                    className="form-input"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.financeID}
                  />
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">XACTNET Address</div>
                  <Form.Input
                    name="xactnetAddress"
                    readOnly={true}
                    placeholder="N/A"
                    instantValidation={false}
                    className="form-input readonly-input"
                    value={user.xactnetAddress}
                  />
                </div>
                {!this.state.education || this.state.education.length === 0 ? (
                  <div className="input-field-container">
                    <div className="input-field-label">Education</div>

                    <Form.Input
                      name="education"
                      placeholder="N/A"
                      instantValidation={false}
                      className="form-input readonly-input"
                      readOnly={true}
                    />
                  </div>
                ) : (
                  this.state.education.map((el, index) => (
                    <div className="input-field-container" key={index}>
                      <div className="input-field-label">{index === 0 ? 'Education' : null}</div>
                      <EducationItem
                        edit={this.editEducation}
                        delete={this.deleteEducation}
                        item={el}
                        id={index}
                        mode="read"
                      />
                    </div>
                  ))
                )}
                {!this.state.references || this.state.references.length === 0 ? (
                  <div className="input-field-container">
                    <div className="input-field-label">References</div>
                    <Form.Input
                      name="references"
                      placeholder="N/A"
                      instantValidation={false}
                      className="form-input readonly-input"
                      readOnly={true}
                    />
                  </div>
                ) : (
                  this.state.references.map((el, index) => (
                    <div className="input-field-container" key={index}>
                      <div className="input-field-label">{index === 0 ? 'References' : null}</div>
                      <ReferenceItem
                        edit={this.editReference}
                        delete={this.deleteReference}
                        item={el}
                        id={index}
                        mode="read"
                      />
                    </div>
                  ))
                )}
                <div className="input-field-container">
                  <div className="input-field-label">Internal Note</div>
                  <InternalNoteBlock
                    user={this.props.user}
                    update={this.props.update}
                    item={user.internalNote ? user.internalNote : []}
                  />
                </div>
                {this.props.admin.role !== 'Read Only Office Admin' && (
                  <div className="input-field-container">
                    <div className="input-field-label" />
                    <div className="add-question">
                      <Button type="button" onClick={() => this.setState({ showNoteModal: true })}>
                        + ADD NOTE
                      </Button>
                    </div>
                  </div>
                )}

                {this.state.standbyListNote.map((v) => (
                  <React.Fragment key={v.id}>
                    <div className="input-field-container">
                      <div className="input-field-label">
                        <Link to={`/stand-by-lists/${v.id}`}>
                            {v.title}
                        </Link>
                        {` `}
                        Note
                      </div>
                      <InternalNoteBlock
                        user={this.props.user}
                        update={this.props.update}
                        item={v.note ? v.note : []}
                        standbyId={v.id}
                      />
                    </div>
                    {this.props.admin.role !== 'Read Only Office Admin' && (
                      <div className="input-field-container">
                        <div className="input-field-label" />
                        <div className="add-question">
                          <Button type="button" onClick={() => this.setState({ standbyModalId: v.id })}>
                            + ADD NOTE
                          </Button>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}



                <div className="input-field-container">
                  <div className="input-field-label">Certification Score</div>
                  <Form.Input
                    name="certificationScore"
                    type="number"
                    placeholder="1-100"
                    min="1"
                    max="100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.certificationScore}
                  />
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Field Interview Score</div>
                  <Form.Input
                    name="fieldInterviewScore"
                    type="number"
                    placeholder="1-100"
                    min="1"
                    max="100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.fieldInterviewScore}
                  />
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Desk Interview Score</div>

                  <Form.Input
                    name="deskInterviewScore"
                    type="number"
                    placeholder="1-100"
                    min="1"
                    max="100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.deskInterviewScore}
                  />
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">QA Score</div>
                  <Form.Input
                    name="qaScore"
                    type="number"
                    min="1"
                    max="100"
                    placeholder="1-100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.qaScore}
                  />
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Categories</div>
                  <div className="folderPlaceHolder">
                    <AssignFolderAdjuster adjusterId={user.id} className="AssignFolderAdjuster" />
                  </div>
                </div>

                <div className="input-field-container" style={{ marginTop: '50px' }}>
                  <div className="input-field-label">Service Fee</div>
                  <Form.Input
                    name="serviceFeePercent"
                    type="number"
                    placeholder="1-100"
                    min="1"
                    max="100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.serviceFeePercent}
                  />
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">T & E</div>
                  <Form.Input
                    name="tAndEPercent"
                    type="number"
                    placeholder="1-100"
                    min="1"
                    max="100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.tAndEPercent}
                  />
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Photos</div>
                  <Form.Input
                    name="photosPercent"
                    type="number"
                    placeholder="1-100"
                    min="1"
                    max="100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.photosPercent}
                  />
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Misc. Expense</div>
                  <Form.Input
                    name="miscExpensePercent"
                    type="number"
                    placeholder="1-100"
                    min="1"
                    max="100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.miscExpensePercent}
                  />
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Mileage</div>
                  <Form.Input
                    name="mileagePercent"
                    type="number"
                    placeholder="1-100"
                    min="1"
                    max="100"
                    readOnly={this.props.admin.role === 'Read Only Office Admin'}
                    className={`form-input ${
                      this.props.admin.role === 'Read Only Office Admin' ? 'readonly-input' : ''
                    }`}
                    value={user.mileagePercent}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Button ref={this.submitRef} type="submit" style={{ display: 'none' }} />
        </Form>
        <AddNoteModal
          id={user.id}
          avatar={user ? user.avatar : noAvatarIcon}
          username={user ? `${user.firstName} ${user.lastName}` : ''}
          show={this.state.showNoteModal}
          showNoteModal={this.showNoteModal}
          mode="profile"
          update={this.props.update}
        />

        <ContactedModal
          customTitle={this.getNoteTitle()}
          standbyId={this.state.standbyModalId}
          user={user}
          show={this.state.standbyModalId > 0}
          status={'note'}
          showContactedModal={this.showStandbyModalId}
          updateData={() => this.props.update(user.id)}
          fullUrlAvatar={true}
        />

        <AvatarScaled
          avatar={this.props.user.avatar ? this.props.user.avatar : noAvatarIcon}
          showScaled={this.showScaledModal}
          show={this.state.showScaled}
        />
      </div>
    );
  }
}

export default ProfileDetails;
