import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Confirm, Dropdown, Icon, Image, Menu, Popup, Search } from 'semantic-ui-react';
import { trainingCenterActions } from '../../../features/training-center';
import { IUser } from '../../../models';
import downloadIcon from '../../../assets/images/White/download.svg';
import './MaterialActionMenu.css';
import trashIcon from '../../../assets/images/White/trash.svg';
import checkIconW from '../../../assets/images/White/check.svg';
import checkIconB from '../../../assets/images/Blue/check.svg';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import {
  MaterialModel,
  PaginationInfo,
} from '../../../features/training-center/trainingCenterReducer';
import services from '../../../services';
import { trackPromise } from 'react-promise-tracker';
import UploadMaterialModal from '../upload-material/UploadMaterialModal';
import EditMaterialModal from '../edit-material/EditMaterialModal';
import searchIcon from '../../../assets/images/Blue/icn-search-blue.svg';
import { debounce } from 'lodash';

interface Props {
  user: IUser;
  pagingInfo: PaginationInfo;
  selectedMaterial: number[];
  allMaterials: MaterialModel[];
  toggleUploadMaterialModal: typeof trainingCenterActions.toggleUploadMaterialModal;
  setSelectedMaterial: typeof trainingCenterActions.setSelectedMaterial;
  removeSelectedMaterials: typeof trainingCenterActions.removeSelectedMaterials;
  removeAllSelectedMaterials: typeof trainingCenterActions.removeAllSelectedMaterials;
  bulkDeleteMaterial: typeof trainingCenterActions.bulkDeleteMaterial;
  searchMaterials: typeof trainingCenterActions.searchMaterial;
}

interface State {
  deletedIds: number[];
  filtersOpen: boolean;
  filters: { sort: string; fileType: string; search: string };
}

class MaterialActionMenu extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      deletedIds: [],
      filtersOpen: false,
      filters: {
        sort: 'createdAt,DESC',
        fileType: 'all',
        search: '',
      },
    };

    this.onSearchChange = debounce(this.onSearchChange, 350);
  }

  public isSelectAll() {
    const { allMaterials, selectedMaterial } = this.props;

    if (
      selectedMaterial.length === 0 ||
      allMaterials.length === 0 ||
      selectedMaterial.length !== allMaterials.length
    ) {
      return false;
    }

    for (const selectedId of selectedMaterial) {
      const foundRecord = allMaterials.find(t => t.id === selectedId);
      if (!foundRecord) {
        return false;
      }
    }
    return true;
  }

  public selectAll = async () => {
    if (!this.isSelectAll()) {
      this.props.setSelectedMaterial(this.props.allMaterials.map(t => t.id));

      const urlParams = new URLSearchParams(window.location.search);
      const selectParam = urlParams.get('select');

      if (!selectParam) {
        urlParams.append('select', 'all');
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.pushState({}, '', newUrl);
      }

      return;
    }
    this.props.removeSelectedMaterials(this.props.allMaterials.map(t => t.id));

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('select');
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  public bulkDeleteMaterial(ids: number[]) {
    this.setState({ deletedIds: ids });
  }

  public submitConfirmBulkDelete() {
    this.props.bulkDeleteMaterial(this.state.deletedIds);
    this.setState({ deletedIds: [] });
  }

  public renderFilterUploadButton() {
    const { user } = this.props;
    return (
      <div className="material-filter-upload-actions">
        <Button primary={true} onClick={this.toggleFilters}>
          Filter
        </Button>
        {!user.isReadOnlyAdmin && (
          <Button onClick={this.props.toggleUploadMaterialModal} className="add-admin-button">
            Upload
          </Button>
        )}
      </div>
    );
  }

  public toggleFilters = () => {
    this.setState({ filtersOpen: !this.state.filtersOpen });
  };

  public renderBulkActionMenu() {
    const { user, allMaterials, selectedMaterial } = this.props;
    const isAllSelected = this.isSelectAll();
    const checkboxIcon = isAllSelected ? checkIconW : checkIconB;
    return (
      <Menu text={true} className="tabs-menu">
        <Menu.Item>
          <Popup
            trigger={
              <Button
                type="button"
                className={
                  isAllSelected
                    ? 'check-button active iconic-button-custom'
                    : 'check-button iconic-button-custom'
                }
                onClick={this.selectAll}
              >
                <Image src={checkboxIcon} disabled={allMaterials.length === 0} />
              </Button>
            }
            basic={true}
            size="mini"
            content={isAllSelected ? 'Deselect All' : 'Select All'}
          />
        </Menu.Item>
        {!user.isReadOnlyAdmin && (
          <Menu.Item>
            <Popup
              trigger={
                <Button
                  primary={true}
                  disabled={selectedMaterial.length === 0}
                  className="material-action-button"
                  onClick={() => {
                    this.setState({ deletedIds: this.props.selectedMaterial });
                  }}
                >
                  <Image src={trashIcon} />
                </Button>
              }
              basic={true}
              size="mini"
              content={'Delete files'}
            />
          </Menu.Item>
        )}
        <Menu.Item>
          <Popup
            trigger={
              <Button
                primary={true}
                disabled={selectedMaterial.length === 0}
                className="material-action-button"
                onClick={() => this.bulkDownloadMaterial(this.props.selectedMaterial)}
              >
                <Image src={downloadIcon} />
              </Button>
            }
            basic={true}
            size="mini"
            content={'Download files'}
          />
        </Menu.Item>
      </Menu>
    );
  }

  public handleSortChange = (e: any, data: any) => {
    this.setState(
      {
        filters: { ...this.state.filters, sort: data.value },
      },
      () => {
        this.props.searchMaterials({
          ...this.props.pagingInfo,
          filter: {
            ...this.props.pagingInfo.filter,
            sort: data.value,
          },
        });
      }
    );
  };

  public handleFileTypeChange = (e: any, data: any) => {
    this.setState(
      {
        filters: { ...this.state.filters, fileType: data.value },
      },
      () => {
        this.props.searchMaterials({
          ...this.props.pagingInfo,
          activePage: 1,
          filter: {
            ...this.props.pagingInfo.filter,
            fileType: data.value,
          },
        });
      }
    );
  };

  public handleSearchChange = (e: any, data: any) => {
    this.setState(
      {
        filters: { ...this.state.filters, search: data.value },
      },
      () => this.onSearchChange(data.value)
    );
  };

  public onSearchChange = (search: string) => {
    this.props.searchMaterials({
      ...this.props.pagingInfo,
      activePage: 1,
      filter: {
        ...this.props.pagingInfo.filter,
        search,
      },
    });
  };

  public renderFilters() {
    return (
      <div className="material-actions-header__filters">
        <div className="filter">
          <Dropdown
            inline={true}
            selection={true}
            options={[
              {
                key: 'Creation Date',
                text: 'Creation Date',
                value: 'createdAt,DESC',
              },
              {
                key: 'Last Updated Date',
                text: 'Last Updated Date',
                value: 'updatedAt,DESC',
              },
            ]}
            value={this.state.filters.sort}
            onChange={this.handleSortChange}
          />
        </div>
        <div className="filter">
          <Dropdown
            inline={true}
            selection={true}
            options={[
              {
                key: 'All File Types',
                text: 'All File Types',
                value: 'all',
              },
              {
                key: 'Document',
                text: 'Document',
                value: 'docx',
              },
              {
                key: 'PDF',
                text: 'PDF',
                value: 'pdf',
              },
              {
                key: 'Excel',
                text: 'Excel',
                value: 'xlsx',
              },
              {
                key: 'PowerPoint',
                text: 'PowerPoint',
                value: 'pptx',
              },
              {
                key: 'Video',
                text: 'Video',
                value: 'video',
              },
              {
                key: 'Image',
                text: 'Image',
                value: 'image',
              },
            ]}
            value={this.state.filters.fileType}
            onChange={this.handleFileTypeChange}
          />
        </div>
        <div className="filter">
          <Search
            input={{ iconPosition: 'left' }}
            icon={
              <Icon>
                <Image src={searchIcon} />
              </Icon>
            }
            placeholder="Search..."
            onSearchChange={this.handleSearchChange}
            value={this.state.filters.search}
            showNoResults={false}
          />
        </div>
      </div>
    );
  }

  public async getDownloadLink(fileKey: string) {
    return trackPromise(services.api.file.getDownloadFileUrl(fileKey), 'material-download');
  }

  public async bulkDownloadMaterial(ids: number[]) {
    let fileKey: any = '';
    for (const material of this.props.allMaterials) {
      if (!material.file) {
        continue;
      }
      ids
        ? ids.map(async id => {
            if (id === material.id) {
              fileKey = material.file.key;

              const downloadUrl = await this.getDownloadLink(fileKey);

              if (downloadUrl) {
                services.api.file.downloadFile(downloadUrl, material.fileName, material.name);
              }
            }
          })
        : null;
    }
  }

  public renderDeleteConfirm() {
    const deletedIds = this.state.deletedIds;
    const { selectedMaterial, allMaterials, folderToDelete } = this.props;

    let isShared = false;
    selectedMaterial.forEach((materialId, i) => {
      allMaterials.forEach((item, j) => {
        if (item.id === materialId && item.materialFolders.length > 1) {
          isShared = true;
          return;
        }
      });
    });

    const warningMessage = `Are you sure you want to delete ${deletedIds.length} files? \n
    File(s) are currently shared between multiple Folders / Subfolders`;

    const message = `Are you sure you want to delete ${deletedIds.length} files?`;

    return (
      <Confirm
        size="tiny"
        closeIcon={
          <Image
            src={closeIcon}
            className="icon"
            onClick={() => this.setState({ deletedIds: [] })}
          />
        }
        open={deletedIds.length > 0}
        onCancel={() => this.setState({ deletedIds: [] })}
        header="Confirm"
        content={isShared ? warningMessage : message}
        cancelButton="NO"
        confirmButton="YES"
        onConfirm={() => this.submitConfirmBulkDelete()}
        className="confirm-modal-custom modal-message"
        closeOnDimmerClick={false}
      />
    );
  }

  async componentDidUpdate(prevProps: Props) {
    // Check if the specific prop has changed
    const urlParams = new URLSearchParams(window.location.search);
    const selectParam = urlParams.get('select');

    if (this.props.allMaterials !== prevProps.allMaterials && this.props.allMaterials.length > 0) {
      // Run the callback
      this.props.removeAllSelectedMaterials();

      if (selectParam === 'all') {
        await this.selectAll();
      }
    }
  }

  // async componentDidMount() {
  //   await this.props.removeAllSelectedMaterials();
  // }

  public render() {
    return (
      <React.Fragment>
        <div className="material-actions-header">
          {this.renderBulkActionMenu()}
          {this.renderFilterUploadButton()}
        </div>
        {this.renderDeleteConfirm()}
        {this.state.filtersOpen && this.renderFilters()}
        <UploadMaterialModal />
        <EditMaterialModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pagingInfo: state.trainingCenter.material.pagingInfo,
  folderToDelete: state.trainingCenter.deleteFolder.folder,
  selectedMaterial: state.trainingCenter.material.pagingInfo.selected,
  allMaterials: state.trainingCenter.material.pagingInfo.records,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchMaterials: trainingCenterActions.searchMaterial,
      setSelectedMaterial: trainingCenterActions.setSelectedMaterial,
      toggleUploadMaterialModal: trainingCenterActions.toggleUploadMaterialModal,
      removeSelectedMaterials: trainingCenterActions.removeSelectedMaterials,
      removeAllSelectedMaterials: trainingCenterActions.removeAllSelectedMaterials,
      bulkDeleteMaterial: trainingCenterActions.bulkDeleteMaterial,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaterialActionMenu);
