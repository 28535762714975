import React from 'react';
import { Button, Confirm, Image, Input, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import deployIcon from '../../assets/images/Blue/deployed-blue.svg';
import trashIcon from '../../assets/images/Blue/trash.svg';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';

interface Props {
  item: object;
  index: number;
  deleteItem: (id: number, name: string) => void;
  editItem: (id: number, name: string) => void;
  user: IUser;
  showAdjustersModal: typeof standbyActions.showCustomEventsModal;
}

interface State {
  name: string;
  showConfirm: boolean;
}

class ProfDataItem extends React.Component<Props, State> {
  public state = {
    name: '',
    showConfirm: false,
  };

  public componentDidMount() {
    this.setState({ name: this.props.item.name });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.item !== this.props.item) {
      this.setState({ name: this.props.item.name });
    }
  }

  public handleTextChange = (e, { value }) => {
    if (!!value.trim()) {
      // this?.props?.clearError();
    }
    this.setState({ name: value });
  };

  public render() {
    return (
      <React.Fragment>
        <div className="prof-item-container">
          <Input
            value={this.state.name}
            required={true}
            maxLength={100}
            onChange={this.handleTextChange}
            readOnly={
              this.props.user.role === 'Read Only Office Admin' ||
              this.state.name === 'BG Check Completed'
            }
            className="prof-item-input"
            onBlur={() => this.props.editItem(this.props.index, this.state.name)}
            onKeyPress={(e: any) => {
              if (e.nativeEvent.keyCode === 13) {
                this.props.editItem(this.props.index, this.state.name);
              }
            }}
          />
          {this.props.showAdjustersModal && (
            <div>
              <Popup
                trigger={
                  <div style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
                    <Button
                      className="iconic-button-custom"
                      disabled={
                        this.props.user.role === 'Read Only Office Admin' ||
                        this.state.name === 'BG Check Completed'
                      }
                      onClick={() => {
                        if (this.props.user.role === 'Read Only Office Admin') {
                          return;
                        }
                        this.props.showAdjustersModal();
                      }}
                    >
                      <Image src={deployIcon} />
                    </Button>
                  </div>
                }
                size="mini"
                basic={true}
                content="Show adjusters list"
              />
            </div>
          )}
          <div>
            <Popup
              trigger={
                <div style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
                  <Button
                    className="iconic-button-custom"
                    disabled={
                      this.props.user.role === 'Read Only Office Admin' ||
                      this.state.name === 'BG Check Completed'
                    }
                    onClick={() => {
                      if (this.props.user.role === 'Read Only Office Admin') {
                        return;
                      }
                      this.setState({ showConfirm: true });
                    }}
                  >
                    <Image src={trashIcon} />
                  </Button>
                </div>
              }
              size="mini"
              basic={true}
              content="Delete"
            />
          </div>
        </div>
        {this.props.error && (
          <div
            style={{
              color: 'red',
              fontSize: '13px',
              paddingTop: '5px',
              display: 'inline-block',
            }}
            className="error-label-custom"
          >
            * Name is required field.
          </div>
        )}
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={`Are you sure you want to delete this item?`}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => {
            this.props.deleteItem(this.props.item.id, this.state.name);
            this.setState({ showConfirm: false });
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </React.Fragment>
    );
  }
}

export default ProfDataItem;
