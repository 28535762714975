import classnames from 'classnames';
// @ts-ignore
import { withFormsy } from 'formsy-react';
import React from 'react';
import { Dropdown, Icon, Image } from 'semantic-ui-react';

import icon from '../../../assets/images/Common/icn-search-grey.svg';
import './styles.css';

interface Props {
  getValue: () => any;
  setValue: (val: any) => void;
  onSearchChange?: (val: any) => void;
  onClose?: () => void;
  loading?: boolean;
  className: string;
  placeholder: string;
  options: object[];
  allowEmpty?: boolean;
}

interface State {
  options: object[];
}

const emptyOption = {
  key: '',
  text: '',
  value: '',
};

class SearchSelectionInput extends React.Component<Props, State> {
  public static defaultProps = {
    allowEmpty: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = { options: props.options };
  }

  public handleChange = (_: any, data: any) => {
    this.props.setValue(data.value);
  };

  public onSearchChange = (_: any, data: any) => {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(data.searchQuery);
    }
  };

  public onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  public render() {
    const { className, placeholder, options, allowEmpty } = this.props;
    return (
      <div className={classnames('searchselection-container', className)}>
        <div className="search-icon">
          <Icon>
            <Image src={icon} />
          </Icon>
        </div>
        <Dropdown
          lazyLoad={true}
          selectOnBlur={false}
          inline={true}
          className="searchselection-input"
          value={this.props.getValue()}
          search={true}
          selection={true}
          placeholder={placeholder}
          options={allowEmpty ? [emptyOption, ...options] : options}
          onChange={this.handleChange}
          onSearchChange={this.onSearchChange}
          loading={this.props.loading}
          onClose={this.onClose}
        />
      </div>
    );
  }
}

export default withFormsy(SearchSelectionInput);
