import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Label } from 'semantic-ui-react';

import moment from 'moment';
import '../../assets/styles/Finances.css';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import SearchInput from '../form/search-input/SearchInput';
import SearchSelectionInput from '../form/searchselection-input/SearchSelectionInput';
import SortBySelectionInput from '../form/sortby-selection-input/SortBySelectionInput';

interface Props {
  onFilterChange: (data: any) => void;
  onFilterReset: () => void;
  filter: any;
  adjusters: any[];
  admins: any[];
}

interface State {
  fromMaxDate: string;
  toMinDate: string;
}

const sortOptions = [
  {
    key: 'finance.createdAt',
    text: 'Date',
    value: 'createdAt',
  },
  {
    key: 'adjuster.firstName',
    text: 'Adjuster',
    value: 'adjuster',
  },
  {
    key: 'file.name',
    text: 'File Name',
    value: 'name',
  },
  {
    key: 'uploader.firstName',
    text: 'Uploaded By',
    value: 'uploadedBy',
  },
];

class FinanceFilter extends React.Component<Props, State> {
  private form: any;

  public handleReset = () => {
    this.form.reset();
    if (this.props.onFilterReset) {
      this.props.onFilterReset();
    }
  };

  public handleSubmit = (data: any) => {
    const obj = {
      ...data,
      from: data.from && data.from !== '' ? `${moment(data.from).format()}` : '',
      to: data.to && data.to !== '' ? `${moment(data.to).format()}` : '',
      // from: data.from && data.from !== '' ? `${moment.utc(data.from).format('YYYY-MM-DDTHH:mm:ss')}${moment().utcOffset() > 0 ? '+' : ''}${moment().utcOffset() / 60}:00` : '',
      // to: data.to && data.to !== '' ? `${moment.utc(data.to).format('YYYY-MM-DDTHH:mm:ss')}${moment().utcOffset() > 0 ? '+' : ''}${moment().utcOffset() / 60}:00` : '',
    };
    console.log(obj);
    if (this.props.onFilterChange) {
      this.props.onFilterChange(obj);
    }
  };

  public render() {
    const { filter, adjusters, admins } = this.props;

    return (
      <div className="finance-filter">
        <Form
          className="filter-form"
          ref={(form: any) => (this.form = form)}
          onSubmit={this.handleSubmit}
        >
          <div className="filter-finances-item max-content-input">
            <Label>From</Label>
            <DatePickerInput name="from" value={filter.from} />
          </div>
          <div className="filter-finances-item max-content-input">
            <Label>To</Label>
            <DatePickerInput name="to" value={filter.to} />
          </div>
          <div className="filter-finances-item">
            <SearchSelectionInput
              name="adjusterId"
              placeholder="Adjuster"
              allowEmpty={true}
              value={filter.adjusterId}
              options={adjusters.map(m => ({
                key: m.id,
                text: `${m.preferredName ? m.preferredName : m.firstName} ${m.lastName}`,
                value: m.id,
              }))}
            />
          </div>
          <div className="filter-finances-item max-content-input">
            <SearchInput name="name" placeholder="File name..." value={filter.name} />
          </div>
          <div className="filter-finances-item">
            <SearchSelectionInput
              name="uploaderId"
              placeholder="Uploaded by"
              allowEmpty={true}
              value={filter.uploaderId}
              options={admins.map(m => ({
                key: m.id,
                text: `${m.firstName} ${m.lastName}`,
                value: m.id,
              }))}
            />
          </div>
          <div className="filter-finances-item">
            <SortBySelectionInput name="sort" value={filter.sort} options={sortOptions} />
          </div>
          <div className="filter-finances-item buttons">
            <Button
              type="button"
              style={{ width: '86px', height: '32px', marginRight: '0.5rem' }}
              className="secondary-button"
              onClick={this.handleReset}
            >
              Reset
            </Button>
            <Button type="submit" className="apply-button" color="blue">
              Apply
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default FinanceFilter;
