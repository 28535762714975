import * as api from './api-service';
import * as localStorage from './local-storage-service';
import * as logger from './logger-service';
import * as router from './router-service';

export default {
  api,
  logger,
  localStorage,
  router,
};
