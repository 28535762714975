import * as Formsy from 'formsy-react';
import { Form, RadioGroup } from 'formsy-semantic-ui-react';
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Image, Radio } from 'semantic-ui-react';
import calendarIcon from '../../assets/images/ui-calendar.svg';
import CheckboxGroup from '../../components/checkbox-group/CheckboxGroup';
import DatePickerInput from '../../components/datepicker-input/DatePickerInput';
import ErrorLabel from '../../components/error-label/ErrorLabel';
import PhoneInput from '../../components/layout-input/PhoneInput';
import AddReference from '../../components/register-layout/AddReference';
import EducationItem from '../../components/register-layout/EducationItem';
import ReferenceItem from '../../components/register-layout/ReferenceItem';
import services from '../../services';
import ProfileDetailsFields from '../../utils/CreateAdjusterFields';
import ProfileDetailsFields2 from '../../utils/CreateAdjusterFields2';
import * as datesHelper from '../../utils/datesHelper';
import statesUS from '../../utils/statesUS';
import AvatarInput from './../../components/avatar-input/AvatarInput';
import { LoadingSpiner } from './../../components/loading-spinner/LoadingSpinner';
import AddEducation from './../../components/register-layout/AddEducation';

const fields = ProfileDetailsFields;
const fields2 = ProfileDetailsFields2;

interface Props {}

interface State {
  avatar: string;
  avatarEdited: boolean;
  fieldsUrl: string;
  formData: FormData | {};
  availableForClaims: boolean;
  education: object[];
  references: object[];
  positionsOptions: object[];
  isSubscribedToCompassMailUpdates: boolean;
  isSubscribedToPersonalMailUpdates: boolean;
  showPass: boolean;
  enteredPass: string;
  isChecked: boolean;
  showOtherField: boolean;
  showCompanyName: boolean;
  isAdmin: boolean;
  temporaryLocation: boolean;
}

const howDidYouHearOpt = [
  { text: 'Facebook', value: 'Facebook' },
  { text: 'Lindkedin', value: 'Lindkedin' },
  { text: 'Twitter', value: 'Twitter' },
  { text: 'Instagram', value: 'Instagram' },
  { text: 'Friend of College', value: 'Friend of College' },
  { text: 'Internet Search', value: 'Internet Search' },
  { text: 'Other', value: 'Other' },
];

Formsy.addValidationRule('isChecked', (values: any, value: any) => {
  return value !== undefined && value !== null && value !== '';
});

Formsy.addValidationRule('isValidMax', (values: any, value: any, maxDate: any) => {
  if (moment(value) > maxDate) {
    return false;
  } else {
    return true;
  }
});

Formsy.addValidationRule('isValidMin', (values: any, value: any, minDate: any) => {
  if (moment(value) < minDate) {
    return false;
  } else {
    return true;
  }
});

Formsy.addValidationRule('isValidDate', (values: any, value: any, field?: string) => {
  if (value) {
    if (field === 'cat' && value.length === 0) {
      return true;
    }
    if (value.length < 10) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
});

class CreateAdjuster extends React.Component<Props, State> {
  public state = {
    avatar: '',
    avatarEdited: false,
    fieldsUrl: '',
    formData: {},
    availableForClaims: true,
    isSubscribedToCompassMailUpdates: true,
    isSubscribedToPersonalMailUpdates: true,
    education: [],
    references: [],
    positionsOptions: [],
    showPass: false,
    enteredPass: '',
    isChecked: false,
    showOtherField: false,
    showCompanyName: false,
    isAdmin: false,
    temporaryLocation: false,
  };

  public componentDidMount = async () => {
    const request = await services.api.account.getSettingsData('position');
    const positionsOptions = request.data.list.map((el: any, index: any) => {
      const element = {
        label: el.name,
        checked: false,
      };
      return element;
    });
    this.setState({
      positionsOptions,
    });
  };

  public handleSourceDropdown = (e: any, { value }: any) => {
    if (value === 'Other') {
      this.setState({ showOtherField: true });
    } else {
      this.setState({ showOtherField: false });
    }
  };

  public getAvatar = (key: any, avatarEdited: boolean) => {
    this.setState({ avatar: key, avatarEdited });
  };

  public handlePass = (e: any) => {
    this.setState({ enteredPass: e.target.value });
  };

  public getAvatatFields = (url: string, data: FormData) => {
    this.setState({ fieldsUrl: url, formData: data });
  };

  public getEducation = (item: object) => {
    const { education } = this.state;
    education.push(item);
    this.setState({ education });
  };

  public editEducation = (id: number, item: object) => {
    const { education } = this.state;
    education[id] = item;
    this.setState({ education });
  };

  public deleteEducation = (id: number) => {
    const { education } = this.state;
    education.splice(id, 1);
    this.setState({ education });
  };

  public getReference = (item: object) => {
    const { references } = this.state;
    references.push(item);
    this.setState({ references });
  };

  public editReference = (id: number, item: object) => {
    const { references } = this.state;
    references[id] = item;
    this.setState({ references });
  };

  public deleteReference = (id: number) => {
    const { references } = this.state;
    references.splice(id, 1);
    this.setState({ references });
  };

  public handleTypeDropdown = (e: any, { value }: any) => {
    if (value === 'business') {
      this.setState({ showCompanyName: true });
    } else {
      this.setState({ showCompanyName: false });
    }
  };

  public handleTemporaryLocationRadio = (e: any, { value }: any) => {
    this.setState({ temporaryLocation: value === 'true' });
  };

  public render() {
    const errorLabel = <ErrorLabel />;

    return (
      <>
        <div className="create-adjuster-page">
          <LoadingSpiner area="create-adjuster-page" />
          <Form
            onValidSubmit={async (model: any) => {
              model.birthday = datesHelper.parseDateToDateStringWithFormat(model.birthday);

              if (this.state.avatarEdited) {
                if (this.state.avatar !== '' && this.state.avatar) {
                  const resp = await services.api.file.postFile(
                    this.state.fieldsUrl,
                    this.state.formData
                  );
                  if (resp.status !== 204) {
                    console.log(resp);
                    toast.dismiss();
                    toast.error('Avatar upload error!');
                  }
                }
              }
              const obj = Object.assign(
                {},
                model,
                { avatar: this.state.avatar },
                { educations: this.state.education },
                { references: this.state.references },
                { isAdmin: this.state.isAdmin }
              );
              if (obj.references.length === 0) {
                delete obj.references;
              }
              const response = await services.api.adjuster.createNewAdjuster(obj);
              if (response.isSuccess) {
                toast.dismiss();
                toast.success(
                  `Adjuster ${model.first_name} ${model.last_name} was successfully created!`
                );
                services.router.goto('/adjusters');
              } else {
                console.log(response);
                toast.dismiss();
                toast.error(
                  response.data ? response.data.errors[0].msg : `Error ${response.status}`
                );
              }
            }}
          >
            <Grid stackable={true}>
              <Grid.Row>
                <Grid.Column>
                  <div className="input-field-container avatar-block">
                    <AvatarInput
                      getAvatar={this.getAvatar}
                      getAvatarFields={this.getAvatatFields}
                      // avatar={user.avatar ? user.avatar : avatarPlaceholder}
                      hasToken={true}
                      noEdit={false}
                    />
                    <Button className="add-admin-button">Create</Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <div className="input-field-container">
                    <div className={'input-field-label'}>Registration Type</div>
                    <div className="registration-type-dropdown">
                      <RadioGroup
                        name="registrationType"
                        required={true}
                        onChange={this.handleTypeDropdown}
                        className="registration-type-group"
                        validationErrors={{
                          isDefaultRequiredValue: `* Registration Type is required field.`,
                        }}
                        errorLabel={errorLabel}
                      >
                        <Radio label="Individual" value="individual" />
                        <Radio label="Business" value="business" />
                      </RadioGroup>
                    </div>
                  </div>
                  {this.state.showCompanyName && (
                    <div className="input-field-container">
                      <div className={'input-field-label'}>Company Name</div>
                      <Form.Input
                        name="companyName"
                        type={'text'}
                        placeholder="Please Enter..."
                        instantValidation={false}
                        required={true}
                        validationErrors={{
                          isDefaultRequiredValue: `* Company Name is required field.`,
                        }}
                        errorLabel={errorLabel}
                        className={'form-input'}
                      />
                    </div>
                  )}
                  {fields.map((field: any, index: number) => {
                    if (field.name === 'positions') {
                      return (
                        <div className="input-field-container" key={index}>
                          <div
                            className={
                              field.required ? 'input-field-label' : 'input-field-label disabled'
                            }
                          >
                            {field.label}
                          </div>
                          <CheckboxGroup
                            name="positions"
                            abilityType="position"
                            items={this.state.positionsOptions}
                            validations={{
                              isChecked: true,
                            }}
                            validationErrors={{
                              isChecked: '* Positions Applying For is required field.',
                            }}
                            errorLabel={errorLabel}
                          />
                        </div>
                      );
                    } else if (
                      field.name === 'phone' ||
                      field.name === 'fax' ||
                      field.name === 'mobilePhone'
                    ) {
                      return (
                        <div
                          className="input-field-container phone-field-custom-input"
                          key={field.name}
                        >
                          <div
                            className={
                              field.required ? 'input-field-label' : 'input-field-label disabled'
                            }
                          >
                            {field.label}
                          </div>
                          <PhoneInput
                            name={field.name}
                            placeholder="Please Enter..."
                            instantValidation={false}
                            required={field.required}
                            disabled={field.disabled}
                            validations={field.validations}
                            validationErrors={{
                              isDefaultRequiredValue: `* ${field.label} is required props.`,
                              ...field.validationErrors,
                            }}
                            errorLabel={errorLabel}
                          />
                        </div>
                      );
                    } else if (field.type === 'date') {
                      return (
                        <div className="input-field-container" key={index}>
                          <div
                            className={
                              field.required ? 'input-field-label' : 'input-field-label disabled'
                            }
                          >
                            {field.label}
                          </div>
                          <DatePickerInput
                            name={field.name}
                            required={true}
                            validations={{
                              isValidMax: moment(),
                              isValidDate: true,
                            }}
                            validationErrors={{
                              isDefaultRequiredValue: `* ${field.label} is required field.`,
                              isValidMax: `* Date should not be later than current date.`,
                              isValidDate: `* Date format is invalid.`,
                            }}
                            value={undefined}
                            errorLabel={errorLabel}
                          />
                        </div>
                      );
                    } else if (field.type === 'dropdown') {
                      return (
                        <div className="input-field-container" key={field.name}>
                          <div className="input-field-label">{field.label}</div>
                          <div className="form-input dropdown">
                            <Form.Dropdown
                              search={true}
                              name={field.name}
                              options={field.options}
                              placeholder="Please Select"
                              instantValidation={false}
                              required={true}
                              validationErrors={{
                                isDefaultRequiredValue: `* ${field.label} is required field.`,
                              }}
                              className="form-input-short form-input-dropdown"
                              errorLabel={errorLabel}
                            />
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div className="input-field-container" key={index}>
                          <div
                            className={
                              field.required ? 'input-field-label' : 'input-field-label disabled'
                            }
                          >
                            {field.label}
                          </div>
                          <Form.Input
                            name={field.name}
                            type={field.type}
                            icon={
                              field.type === 'date' ? (
                                <i className={`icon date-icon`}>
                                  <Image src={calendarIcon} />
                                </i>
                              ) : null
                            }
                            placeholder={'Please Enter...'}
                            instantValidation={false}
                            required={field.required}
                            validationErrors={{
                              isDefaultRequiredValue: `* ${field.label} is required field.`,
                            }}
                            onKeyDown={e => {
                              if (field.name === 'phone' || field.name === 'fax') {
                                const test = /^[+()\d]+$/.test(e.nativeEvent.key);
                                if (
                                  !test &&
                                  e.nativeEvent.which !== 46 &&
                                  e.nativeEvent.which !== 18 &&
                                  e.nativeEvent.which !== 8 &&
                                  e.nativeEvent.which !== 9 &&
                                  e.nativeEvent.which !== 16 &&
                                  e.nativeEvent.which !== 36 &&
                                  e.nativeEvent.which !== 37 &&
                                  e.nativeEvent.which !== 39 &&
                                  e.nativeEvent.which !== 35
                                ) {
                                  e.preventDefault();
                                }
                              }
                            }}
                            errorLabel={errorLabel}
                            className={field.className}
                          />
                        </div>
                      );
                    }
                  })}

                  <div className="input-field-container" style={{ marginTop: '10px' }}>
                    <div className="input-field-label">Service Fee</div>
                    <Form.Input
                      name="serviceFeePercent"
                      type="number"
                      placeholder="1-100"
                      min="1"
                      max="100"
                      className='form-input'
                      value='60'
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">T & E</div>
                    <Form.Input
                      name="tAndEPercent"
                      type="number"
                      placeholder="1-100"
                      min="1"
                      max="100"
                      className='form-input'
                      value='60'
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Photos</div>
                    <Form.Input
                      name="photosPercent"
                      type="number"
                      placeholder="1-100"
                      min="1"
                      max="100"
                      className='form-input'
                      value='60'
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Misc. Expense</div>
                    <Form.Input
                      name="miscExpensePercent"
                      type="number"
                      placeholder="1-100"
                      min="1"
                      max="100"
                      className='form-input'
                      value='100'
                    />
                  </div>

                  <div className="input-field-container">
                    <div className="input-field-label">Mileage</div>
                    <Form.Input
                      name="mileagePercent"
                      type="number"
                      placeholder="1-100"
                      min="1"
                      max="100"
                      className='form-input'
                      value='100'
                    />
                  </div>

                </Grid.Column>
                <Grid.Column>
                  {fields2.map((field: any, index: number) => {
                    return (
                      <div className="input-field-container" key={index}>
                        <div
                          className={
                            field.required ? 'input-field-label' : 'input-field-label disabled'
                          }
                        >
                          {field.label}
                        </div>
                        <Form.Input
                          name={field.name}
                          type="text"
                          placeholder={'Please Enter...'}
                          instantValidation={false}
                          required={field.required}
                          validationErrors={{
                            isDefaultRequiredValue: `* ${field.label} is required field.`,
                          }}
                          errorLabel={errorLabel}
                          className={field.className}
                        />
                      </div>
                    );
                  })}
                  <div className="input-field-container">
                    <div className="input-field-label">State/Province</div>
                    <div className="zip-form-input">
                      <Form.Dropdown
                        selectOnBlur={false}
                        search={true}
                        name="state"
                        options={statesUS}
                        placeholder="Please Select"
                        instantValidation={false}
                        required={true}
                        validationErrors={{
                          isDefaultRequiredValue: `* State is required field.`,
                        }}
                        className="form-input-short form-input-dropdown"
                        errorLabel={errorLabel}
                      />
                      <div className="zip">
                        <div style={{ paddingTop: '8px', marginRight: '5px' }}>ZIP</div>
                        <Form.Input
                          name="zip"
                          placeholder="Please Enter..."
                          instantValidation={false}
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: `* Zip is required field.`,
                          }}
                          errorLabel={errorLabel}
                          className="form-input-short"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-field-container temporary-location">
                    <div className="input-field-label">Temporary Location</div>
                    <RadioGroup
                      name="temporaryLocation"
                      required={true}
                      onChange={this.handleTemporaryLocationRadio}
                      className="registration-type-group"
                      value={
                        this.state.temporaryLocation ? this.state.temporaryLocation.toString() : ''
                      }
                      validationErrors={{
                        isDefaultRequiredValue: `* Temporary Location is required field.`,
                      }}
                      errorLabel={errorLabel}
                    >
                      <Radio label="Yes" value={'true'} />
                      <Radio label="No" value={'false'} />
                    </RadioGroup>
                  </div>
                  {this.state.temporaryLocation && (
                    <>
                      <div className="input-field-container">
                        <div className="input-field-label">CAT Address City</div>
                        <Form.Input
                          name="catCity"
                          required={true}
                          placeholder="Please Enter..."
                          instantValidation={false}
                          className="form-input"
                          validationErrors={{
                            isDefaultRequiredValue: `* CAT City is required field.`,
                          }}
                          errorLabel={errorLabel}
                        />
                      </div>
                      <div className="input-field-container">
                        <div className="input-field-label">CAT State/Province</div>
                        <Form.Dropdown
                          selectOnBlur={false}
                          search={true}
                          name="catStateProvince"
                          placeholder="Please Select"
                          options={[{ text: '', value: '' }, ...statesUS]}
                          instantValidation={false}
                          className="form-input form-input-dropdown long"
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: `* CAT State/Province is required field.`,
                          }}
                          errorLabel={errorLabel}
                        />
                      </div>
                      <div className="input-field-container">
                        <div className="input-field-label">CAT Zip</div>
                        <Form.Input
                          name="catZip"
                          placeholder="Please Enter..."
                          instantValidation={false}
                          className="form-input"
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: `* CAT Zip is required field.`,
                          }}
                          errorLabel={errorLabel}
                        />
                      </div>
                      <div className="input-field-container">
                        <div className="input-field-label">CAT Expiration Date</div>
                        <DatePickerInput
                          name="catExpirationDate"
                          required={true}
                          instantValidation={false}
                          validations={{ isValidMin: moment(), isValidDate: 'cat' }}
                          validationErrors={{
                            isValidMin: `* Date should not be earlier than current date.`,
                            isValidDate: `* Date format is invalid.`,
                            isDefaultRequiredValue: `* CAT Expiration Date is required field.`,
                          }}
                          errorLabel={errorLabel}
                        />
                      </div>
                    </>
                  )}
                  {!this.state.education || this.state.education.length === 0 ? (
                    <div className="input-field-container">
                      <div className="input-field-label">Education</div>

                      <Form.Input
                        name="education"
                        placeholder="Nothing Added"
                        instantValidation={false}
                        className="form-input readonly-input"
                        readOnly={true}
                        validationErrors={{
                          isDefaultRequiredValue: `* Education is required field.`,
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  ) : (
                    this.state.education.map((el, index) => (
                      <div className="input-field-container" key={index}>
                        <div className="input-field-label">{index === 0 ? 'Education' : null}</div>
                        <EducationItem
                          edit={this.editEducation}
                          delete={this.deleteEducation}
                          item={el}
                          id={index}
                          mode="create"
                        />
                      </div>
                    ))
                  )}
                  <div className="input-field-container">
                    <div className="input-field-label" />
                    <AddEducation getEducation={this.getEducation} />
                  </div>

                  {!this.state.references || this.state.references.length === 0 ? (
                    <div className="input-field-container">
                      <div className="input-field-label">References</div>

                      <Form.Input
                        name="references"
                        placeholder="Nothing Added"
                        instantValidation={false}
                        className="form-input readonly-input"
                        validationErrors={{
                          isDefaultRequiredValue: `* References is required field.`,
                        }}
                        errorLabel={errorLabel}
                        readOnly={true}
                      />
                    </div>
                  ) : (
                    this.state.references.map((el, index) => (
                      <div className="input-field-container" key={index}>
                        <div className="input-field-label">{index === 0 ? 'References' : null}</div>
                        <ReferenceItem
                          edit={this.editReference}
                          delete={this.deleteReference}
                          item={el}
                          id={index}
                          mode="create"
                        />
                      </div>
                    ))
                  )}

                  <div className="input-field-container">
                    <div className="input-field-label" />
                    <AddReference getReference={this.getReference} />
                  </div>
                  <div className="input-field-container">
                    <div className="input-field-label">How did you hear about us?</div>
                    <Form.Dropdown
                      search={true}
                      selectOnBlur={false}
                      name="howDidYouHearAboutUs"
                      placeholder="Please Select"
                      instantValidation={false}
                      options={howDidYouHearOpt}
                      className="form-input form-input-dropdown long how-did-you-hear"
                      required={!this.state.showOtherField}
                      onChange={this.handleSourceDropdown}
                      validationErrors={{
                        isDefaultRequiredValue: `* This is required field.`,
                      }}
                      errorLabel={errorLabel}
                    />
                  </div>
                  {this.state.showOtherField && (
                    <div className="input-field-container">
                      <div className="input-field-label">Other source</div>
                      <Form.Input
                        name="howDidYouHearAboutUs"
                        placeholder="Please Enter..."
                        instantValidation={false}
                        className="form-input"
                        required={true}
                        validationErrors={{
                          isDefaultRequiredValue: `* This is required field.`,
                        }}
                        errorLabel={errorLabel}
                      />
                    </div>
                  )}
                  <div className="input-field-container">
                    <div className="input-field-label">Admin</div>
                    <div style={{ display: 'flex', width: '65%' }}>
                      <div className="toggle-block">
                        <Button
                          type="button"
                          className={this.state.isAdmin ? 'yes-btn' : 'no-btn'}
                          onClick={() => this.setState({ isAdmin: true })}
                        >
                          YES
                        </Button>
                        <Button
                          type="button"
                          className={!this.state.isAdmin ? 'yes-btn' : 'no-btn'}
                          onClick={() => this.setState({ isAdmin: false })}
                        >
                          NO
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAdjuster);
