import React from 'react';
import { Image } from 'semantic-ui-react';

interface IFilePreviewProps {
  type: string;
  src: string;
}

export const FilePreview = (props: IFilePreviewProps) => {
  if (props.type === 'application/pdf') {
    return <div className="pdf-file-container">PDF</div>;
  }

  if (!props.src) {
    return <div className="no-image-placeholder">No Image</div>;
  }

  return <Image src={props.src} />;
};
