import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import '../../assets/styles/Settings.css';
import CustomEvents from '../../components/settings-layout/CustomEvents';
import MsgTemplates from '../../components/settings-layout/MsgTemplates';
import ProfessionalData from '../../components/settings-layout/ProfessionalData';
import TwilioNumbers from '../../components/settings-layout/Twilio';
import { IUser } from '../../models';
import Questionnaries from './../../components/settings-layout/Questionnaries';
import SettingsHeader from './../../components/settings-layout/SettingsHeader';

interface Props {
  user: IUser;
}

interface State {
  activeTab: string;
  showCreateModal: boolean;
}

class SettingsPage extends React.Component<Props, State> {
  public state = {
    activeTab: 'Professional Data',
    showCreateModal: false,
  };

  public handleTabs = (name: string) => {
    this.setState({ activeTab: name });
  };

  public showCreateModal = (show: boolean) => {
    this.setState({ showCreateModal: show });
  };

  public renderTab = () => {
    const { activeTab } = this.state;

    switch (activeTab) {
      case 'Professional Data':
        return <ProfessionalData />;
      case 'Twilio Numbers':
        return <TwilioNumbers />;
      case 'Questionnaries':
        return (
          <Questionnaries
            showCreateModal={this.showCreateModal}
            showModal={this.state.showCreateModal}
          />
        );
      case 'Message Templates':
        return (
          <MsgTemplates
            showCreateModal={this.showCreateModal}
            showModal={this.state.showCreateModal}
          />
        );
      case 'Custom Events':
        return <CustomEvents />;
      default:
        return <div>No Info</div>;
    }
  };

  public render() {
    return (
      <div className="settings-page">
        <SettingsHeader
          user={this.props.user}
          getTabName={this.handleTabs}
          showCreateModal={this.showCreateModal}
        />
        {this.renderTab()}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPage);
