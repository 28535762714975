import React from 'react';
import { Image } from 'semantic-ui-react';
import lockIcn from '../../assets/images/icn-lock.svg';

const SBPrivateLabel = () => {
  return (
    <span className="sb-private-label-container">
      <Image src={lockIcn} />
      Private
    </span>
  );
};

export default SBPrivateLabel;
