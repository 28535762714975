import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Messages {
  activeTab: string;
  activePage: number;
  messagesSearch: string;
  messagesSort: string;
  messages: object[];
  totalMessages: number;
  roomType: string;
  roomMessages: object[];
  roomMessagesCount: number;
  roomAdjuster: object;
  activeRoomPage: number;
}

export const createMsgState: Messages = {
  activeTab: 'All',
  activePage: 1,
  messagesSearch: '',
  messagesSort: 'unread,DESC',
  messages: [],
  totalMessages: 0,
  roomType: 'standby',
  roomMessages: [],
  roomMessagesCount: 0,
  roomAdjuster: {},
  activeRoomPage: 0,
};

export interface State {
  messages: Messages;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  messages: (state = createMsgState, action) => {
    switch (action.type) {
      case constants.SET_ACTIVE_PAGE:
        return { ...state, activePage: action.payload.page };
      case constants.SET_ACTIVE_ROOM_PAGE:
        return { ...state, activeRoomPage: action.payload.page };
      case constants.SET_MESSAGES_TAB:
        return { ...state, activeTab: action.payload.tab };
      case constants.SET_MESSAGES_SEARCH:
        return { ...state, messagesSearch: action.payload.search };
      case constants.SET_MESSAGES_SORT:
        return { ...state, messagesSort: action.payload.sort };
      case constants.GET_MESSAGES_LIST:
        return { ...state };
      case constants.SUBMIT_MESSAGES_LIST:
        return { ...state, messages: action.payload.list, totalMessages: action.payload.count };
      case constants.SET_ROOM_TYPE:
        return { ...state, roomType: action.payload.type };
      case constants.GET_ROOM_MESSAGES:
        return { ...state };
      case constants.LOAD_ROOM_MESSAGES:
        return { ...state };
      case constants.SET_ROOM_MESSAGES:
        return {
          ...state,
          roomMessages: action.payload.list,
          roomMessagesCount: action.payload.count,
          roomAdjuster: action.payload.adjuster,
        };
      case constants.RESET_MESSAGES_FILTER:
        return {
          ...state,
          messagesSort: 'unread,DESC',
          messagesSearch: '',
          activeTab: 'All',
          activePage: 1,
        };

      default:
        return state;
    }
  },
});
