import React from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Grid, Icon, Image, Modal, Table } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import checkIconB from '../../assets/images/Blue/check.svg';
import checkIconW from '../../assets/images/White/check.svg';
import { IUser } from '../../models';
import services from '../../services';
import statesUS from '../../utils/statesUS';
import { NoResults } from './../no-results-placeholder/NoResults';

interface Props {
  reloadPurchasedNumbers: () => void;
  user: IUser;
}

interface State {
  numbersList: object[];
  allNumbersList: object[];
  initialResponse: object[];
  showModal: boolean;
}

class BuyTwilioNumber extends React.Component<Props, State> {
  public state = {
    numbersList: [],
    allNumbersList: [],
    initialResponse: [],
    showModal: false,
  };

  public getNumbers = async () => {
    const response = await services.api.settings.getAvailableTwilio();
    if (response.isSuccess) {
      const numericArray = new Array();
      for (const items of Object.keys(response.data)) {
        const textId = statesUS.findIndex(
          (el: { text: string; value: string }) => el.value === items
        );
        numericArray.push({
          value: statesUS[textId].value,
          text: statesUS[textId].text,
        });
      }
      this.setState({
        allNumbersList: numericArray.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        }),
        initialResponse: response.data,
      });
    } else {
      toast.dismiss();
      const msg = response.data.errors ? response.data.errors[0].msg : response.data.msg;
      toast.error(msg);
    }
  };

  public handleDropdown = (data: object) => {
    const array = this.state.initialResponse[data.value]
      ? this.state.initialResponse[data.value]
      : [];
    const dataMap = array.map((el: any) => {
      el.active = false;
      return el;
    });
    this.setState({ numbersList: dataMap });
  };

  public handleSelection = (phone: string) => {
    const { numbersList } = this.state;

    const arrayBuff = numbersList.map((el: any) => {
      if (el.phoneNumber === phone) {
        el.active = !el.active;
        return el;
      } else {
        el.active = false;
        return el;
      }
    });

    this.setState({ numbersList: arrayBuff });
  };

  public buyNumber = async () => {
    const { numbersList } = this.state;
    const phone = numbersList.filter(el => el.active === true);

    const response = await services.api.settings.buyTwilioNumber(phone[0].phoneNumber);

    if (response.isSuccess) {
      toast.dismiss();
      toast.success(`Number ${phone[0].phoneNumber} was bought successfully!`);
      this.setState({ numbersList: [], showModal: false }, () =>
        this.props.reloadPurchasedNumbers()
      );
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public onCloseModal = () => {
    this.setState({ showModal: false, numbersList: [] });
  };

  public render() {
    const trigger = (
      <div className="add-question">
        <Button
          disabled={
            this.props.user.role === 'Read Only Office Admin' ||
            this.props.user.role === 'Office Admin'
          }
          type="button"
          onClick={() => {
            this.setState({ showModal: true });
            this.getNumbers();
          }}
        >
          + BUY NUMBER
        </Button>
      </div>
    );

    return (
      <Modal
        trigger={trigger}
        open={this.state.showModal}
        closeOnDimmerClick={false}
        size="tiny"
        closeIcon={<Image src={closeIcon} className="icon" onClick={() => this.onCloseModal()} />}
        className="buy-twilio-modal"
      >
        <Modal.Header>Buy New Twilio Number</Modal.Header>
        <Modal.Content>
          <div className="input-field-container">
            <div className="input-field-label">Location</div>
            <div className="states-field">
              <Dropdown
                name="location"
                options={this.state.allNumbersList}
                placeholder="Select Location"
                onChange={(event: React.SyntheticEvent, data: object) => this.handleDropdown(data)}
              />
            </div>
          </div>

          {this.state.numbersList && this.state.numbersList.length !== 0 ? (
            <div className="numbers-buy-list">
              <Table striped={true} basic={true}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className="header-cell">Number</Table.Cell>
                    <Table.Cell className="header-cell">Price</Table.Cell>
                    <Table.Cell className="header-cell" />
                  </Table.Row>
                  {this.state.numbersList.map((el: any, index: number) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{el.phoneNumber}</Table.Cell>
                        <Table.Cell>$1.00 per month</Table.Cell>
                        <Table.Cell>
                          <Button
                            className={
                              el.active
                                ? 'check-button active iconic-button-custom'
                                : 'check-button iconic-button-custom'
                            }
                            onClick={() => this.handleSelection(el.phoneNumber)}
                          >
                            <Image src={el.active ? checkIconW : checkIconB} />
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <NoResults />
          )}
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            style={{ width: '115px', heigth: '32px' }}
            className="secondary-button"
            secondary={true}
            onClick={() => this.onCloseModal()}
          >
            CANCEL
          </Button>
          <Button
            style={{ width: '115px', heigth: '32px' }}
            primary={true}
            disabled={this.state.numbersList.filter(el => el.active).length === 0}
            onClick={() => this.buyNumber()}
          >
            BUY
          </Button>
        </div>
      </Modal>
    );
  }
}

export default BuyTwilioNumber;
