import { createAction } from 'typesafe-actions';
import { FinanceListFetchParam } from '../../services/api/finance';
import * as constants from './constants';

export const fetchFinanceList = createAction(constants.FETCH_FINANCE_LIST, action => {
  return (params: FinanceListFetchParam, sort?: any) => action({ params, sort });
});

export const fetchFinanceListDone = createAction(constants.FETCH_FINANCE_LIST_DONE, action => {
  return (error: any, data: any) => action({ error, data });
});

export const addUploadFinancialFiles = createAction(
  constants.ADD_UPLOAD_FINANCIAL_FILES,
  action => {
    return (files: any[]) => action({ files });
  }
);

export const uploadFinancialFile = createAction(constants.UPLOAD_FINANCIAL_FILE, action => {
  return (index: number, file: any) => action({ index, file });
});

export const uploadFinancialFileDone = createAction(
  constants.UPLOAD_FINANCIAL_FILE_DONE,
  action => {
    return (index: number, error: any, data: any) => action({ index, error, data });
  }
);

export const removeUploadFinancialFile = createAction(
  constants.REMOVE_UPLOAD_FINANCIAL_FILE,
  action => {
    return (index: number) => action({ index });
  }
);

export const removeUploadFinancialFileDone = createAction(
  constants.REMOVE_UPLOAD_FINANCIAL_FILE_DONE,
  action => {
    return (index: number) => action({ index });
  }
);

export const deleteFinancialFile = createAction(constants.DELETE_FINANCIAL_FILE, action => {
  return (id: number) => action({ id });
});

export const deleteFinancialFileDone = createAction(
  constants.DELETE_FINANCIAL_FILE_DONE,
  action => {
    return (error: any) => action({ error });
  }
);

export const setFinancialFileAdjuster = createAction(
  constants.SET_FINANCIAL_FILE_ADJUSTER,
  action => {
    return (index: number, adjuster: any) => action({ index, adjuster });
  }
);

export const setFinancialFileId = createAction(constants.SET_FINANCIAL_FILE_ID, action => {
  return (index: number, financialId: string) => action({ index, financialId });
});

export const resetFinancialFiles = createAction(constants.RESET_FINANCIAL_FILES, action => {
  return () => action({});
});

export const fetchFinanceAdjusterList = createAction(
  constants.FETCH_FINANCE_ADJUSTER_LIST,
  action => {
    return () => action({});
  }
);

export const fetchFinanceAdjusterListDone = createAction(
  constants.FETCH_FINANCE_ADJUSTER_LIST_DONE,
  action => {
    return (adjusters: any[]) => action({ adjusters });
  }
);

export const fetchFinanceAdminList = createAction(constants.FETCH_FINANCE_ADMIN_LIST, action => {
  return () => action({});
});

export const fetchFinanceAdminListDone = createAction(
  constants.FETCH_FINANCE_ADMIN_LIST_DONE,
  action => {
    return (admins: any[]) => action({ admins });
  }
);

export const exportFinanceList = createAction(constants.EXPORT_FINANCE_LIST, action => {
  return (params: FinanceListFetchParam, sort?: any) => action({ params, sort });
});

export const exportFinanceListDone = createAction(constants.EXPORT_FINANCE_LIST_DONE, action => {
  return () => action({});
});

export const matchFinancesAdjuster = createAction(constants.MATCH_FINANCES_ADJUSTER, action => {
  return () => action({});
});

export const matchFinancesAdjusterDone = createAction(
  constants.MATCH_FINANCES_ADJUSTER_DONE,
  action => {
    return (matchesData: any[]) => action({ matchesData });
  }
);

export const fetchInitialAdjusters = createAction(constants.FETCH_INITIAL_ADJUSTERS, action => {
  return () => action({});
});

export const fetchInitialAdjustersDone = createAction(
  constants.FETCH_INITIAL_ADJUSTERS_DONE,
  action => {
    return (adjusters: any[] | null) => action({ adjusters });
  }
);

export const saveFinances = createAction(constants.SAVE_FINANCES, action => {
  return () => action({});
});

export const saveFinancesDone = createAction(constants.SAVE_FINANCES_DONE, action => {
  return (error?: any) => action({ error });
});

export const downloadFinance = createAction(constants.DOWNLOAD_FINANCE, action => {
  return (index: number) => action({ index });
});

export const downloadAdjusterFinance = createAction(constants.DOWNLOAD_ADJUSTER_FINANCE, action => {
  return (file: any) => action({ file });
});
