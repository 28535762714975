import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';
import icon from '../../assets/images/Common/icn-search-grey.svg';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import SearchSelectionInput from '../form/searchselection-input/SearchSelectionInput';
import SortBySelectionInput from '../form/sortby-selection-input/SortBySelectionInput';

interface Props {
  filterLogs: (params?: any) => void;
  filter: any;
  reset: () => void;
  finances: object[];
}

interface State {}

const sortOptions = [
  { text: 'Date', value: 'finance.createdAt,desc' },
  { text: 'File Name', value: 'file.name,asc' },
  { text: 'Uploaded By', value: 'uploader.firstName,asc,uploader.lastName,asc' },
];

class FinanceFilter extends React.Component<Props, State> {
  public getSearchSelectionOptions = () => {
    const uploadersMap: any = {};
    this.props.finances.forEach(f => (uploadersMap[f.uploader.id] = f.uploader));
    const list = Object.values(uploadersMap).map((uploader: any) => {
      return {
        key: uploader.id,
        text: `${uploader.preferredName ? uploader.preferredName : uploader.firstName} ${
          uploader.lastName
        }`,
        value: uploader.id,
      };
    });

    list.unshift({ key: 0, text: '', value: null });

    return list;
  };

  public render() {
    return (
      <Form
        ref={ref => (this.form = ref)}
        id="finance-filter-form"
        onValidSubmit={(model: any) => {
          this.props.filterLogs(model);
        }}
      >
        <div className="filters-container finance-filter-container">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '33px',
              marginRight: '0.5rem',
              flex: '1',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '0.5rem' }}>
              <div className="filter-label">From</div>
              <DatePickerInput name="from" value={this.props.filter.from} required={false} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="filter-label">To</div>
              <DatePickerInput name="to" value={this.props.filter.to} required={false} />
            </div>
          </div>
          <div className="filter-block">
            <Form.Input
              icon={
                <Icon
                  style={{
                    position: 'absolute',
                    zIndex: 100,
                    top: '8px',
                    left: '8px',
                  }}
                >
                  <Image src={icon} />
                </Icon>
              }
              iconPosition="left"
              placeholder="File Name"
              name="name"
              value={this.props.filter.name}
              className="form-input-short text-filter-input"
            />
          </div>
          <div className="filter-block">
            <SearchSelectionInput
              name="uploaderId"
              value={this.props.filter.uploaderId}
              placeholder="Uploaded by"
              options={this.getSearchSelectionOptions()}
            />
          </div>
          <div className="filter-block sort-by-filter-container">
            <SortBySelectionInput
              name="sort"
              value={this.props.filter.sort}
              options={sortOptions}
            />
          </div>
          <div className="filter-block filter-actions-block">
            <Button
              type="button"
              style={{ width: '86px', height: '32px', marginRight: '0.5rem' }}
              className="secondary-button"
              secondary={true}
              onClick={() => {
                this.form.formsyForm.reset();
                this.props.reset();
              }}
            >
              RESET
            </Button>
            <Button style={{ width: '86px', height: '32px' }} primary={true}>
              APPLY
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export default FinanceFilter;
