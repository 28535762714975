import axios from 'axios';
import { api } from '../../config';
import { get as storageGet, remove as storageRemove } from '../local-storage-service';

export class ApiResponse {
  public status: number;
  public data: any;
  public headers?: any;

  public isSuccess: boolean;
  public isError: boolean;

  constructor(status: number, data: any, headers?: any) {
    this.status = status;
    this.data = data;
    this.headers = headers;

    this.isSuccess = status >= 200 && status <= 299;
    this.isError = !this.isSuccess;
  }

  public get(x: string) {
    return this.data[x];
  }

  public getErrorList() {
    return this.data.errors.map((errorData: any) => {
      return errorData.msg;
    });
  }

  public getError() {
    return this.getErrorList().join(' ');
  }

  public isServerError() {
    return this.status >= 500 && this.status < 600;
  }
}

export const request = (options: any): Promise<ApiResponse> => {
  const headers: any = {
    Accept: 'application/json',
  };

  const accessToken = storageGet('accessToken');
  if (accessToken) {
    headers.Authorization = `JWT ${accessToken}`;
    // headers['Authorization'] = `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzEsInN1YiI6InRlc3Rfc3VwZXJfYWRtaW5AZ21haWwuY29tIiwiaWF0IjoxNTY3NDk4NjMwfQ.NLxrMGt1Xr7oFP9PV_KoniAuN3HkZJdkyQrWDzv49to`;
  }

  return axios
    .request({
      baseURL: api.endpoint,
      responseType: 'json',
      ...options,
      headers: {
        ...headers,
        ...options.headers,
      },
    })
    .then(function(response) {
      // tslint:disable-next-line:no-shadowed-variable
      const { status, data, headers } = response;

      return new ApiResponse(status, data, headers);
    })
    .catch(function(error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status == 401) {
          storageRemove('accessToken');
          window.location.href = '/login';
        }
        
        return new ApiResponse(status, data);
      }
      return new ApiResponse(400, {});
    });
};
