import React from 'react';
import './FolderTags.css';

export default function FolderTags(props: { tags: string[] }) {
  return (
    <div className="folder-tags">
      {props.tags.map((tag, index) => (
        <div key={index} className="tag">
          {tag}
        </div>
      ))}
    </div>
  );
}
