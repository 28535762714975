export default [
    'Certification Types',
    'Experience',
    'Expertise Loss Type',
    'Equipment',
    'Languages',
    'License States',
    'Paperwork',
    'Positions',
    'School Types',
    'Software Skills',
];