import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Icon, Image, Responsive } from 'semantic-ui-react';
import icon from '../../assets/images/Common/icn-search-grey.svg';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import SearchSelectionInput from '../form/searchselection-input/SearchSelectionInput';
import SortBySelectionInput from '../form/sortby-selection-input/SortBySelectionInput';

interface Props {
  filterLogs: (params?: any) => void;
  filter: any;
  reset: () => void;
  logs: any[];
}

interface State {}

const sortOptions = [
  { text: 'Date', value: 'log.createdAt,desc' },
  { text: 'Category', value: 'log.category,asc' },
  { text: 'Logs', value: 'log.description,asc' },
  { text: 'Updated By', value: 'log.id,asc' },
];

class LogsFilter extends React.Component<Props, State> {
  public getSearchSelectionOptions = () => {
    const usersMap: any = {};
    this.props.logs.forEach(l => (usersMap[l.user.id] = l.user));

    const list = Object.values(usersMap).map((user: any) => {
      return {
        key: user.id,
        text: `${user.preferredName ? user.preferredName : user.firstName} ${user.lastName}`,
        value: user.id,
      };
    });

    list.unshift({ key: 0, text: '', value: null });

    return list;
  };

  public render() {
    const { filter } = this.props;
    return (
      <Form
        ref={ref => (this.form = ref)}
        onValidSubmit={(model: any) => {
          model.createdByAdjuster = 0;

          if (model.updatedBy) {
            const logByAdjuster = this.props.logs.find(log => log.user.id === model.updatedBy);
            model.createdByAdjuster = logByAdjuster && !logByAdjuster.user.isAdmin ? 1 : 0;
          }

          this.props.filterLogs(model);
        }}
      >
        <div className="filters-container finance-filter-container logs-filter-container">
          <div
            style={{
              display: 'flex',
              flex: '1',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '33px',
              marginRight: '0.5rem',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '0.5rem' }}>
              <div className="filter-label">From</div>
              <DatePickerInput name="from" value={filter.from} required={false} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="filter-label">To</div>
              <DatePickerInput name="to" value={filter.to} required={false} />
            </div>
          </div>
          <div className="filter-block">
            <Form.Input
              icon={
                <Icon
                  style={{
                    position: 'absolute',
                    zIndex: 100,
                    top: '8px',
                    left: '8px',
                  }}
                >
                  <Image src={icon} />
                </Icon>
              }
              iconPosition="left"
              placeholder="Category"
              name="category"
              value={filter.category}
              className="form-input-short text-filter-input"
            />
          </div>
          <div className="filter-block">
            <Form.Input
              icon={
                <Icon
                  style={{
                    position: 'absolute',
                    zIndex: 100,
                    top: '8px',
                    left: '8px',
                  }}
                >
                  <Image src={icon} />
                </Icon>
              }
              iconPosition="left"
              placeholder="Logs"
              name="description"
              value={filter.description}
              className="form-input-short text-filter-input"
            />
          </div>
          <div className="filter-block">
            <SearchSelectionInput
              name="updatedBy"
              value={filter.updatedBy}
              placeholder="Updated by"
              options={this.getSearchSelectionOptions()}
            />
          </div>
          <div className="filter-block sort-by-filter-container">
            <SortBySelectionInput name="sort" value={filter.sort} options={sortOptions} />
          </div>
          <div className="filter-block filter-actions-block">
            <Button
              type="button"
              style={{ width: '86px', height: '32px', marginRight: '0.5rem' }}
              className="secondary-button"
              secondary={true}
              onClick={() => {
                this.form.formsyForm.reset();
                this.props.reset();
              }}
            >
              RESET
            </Button>
            <Button style={{ width: '86px', height: '32px' }} primary={true}>
              APPLY
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export default LogsFilter;
