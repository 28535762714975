import api from './api';
import common from './common';
import uploads from './uploads';

export { api, common, uploads };

export default {
  api,
  common,
  uploads,
};
