import { action } from 'typesafe-actions';
import * as constants from './constants';

export const getAdjustersList = (filterOptions?: object) =>
  action(constants.GET_ADJUSTERS_LIST, { filterOptions });

export const submitAdjustersList = (
  adjusters: object[],
  count: number,
  eventLocationCoords: object
) => action(constants.SUBMIT_ADJUSTERS_LIST, { adjusters, count, eventLocationCoords });

export const submitAdjustersMap = (data: any) =>
  action(constants.SUBMIT_ADJUSTERS_MAP, {
    adjusters: data.adjusters,
    distances: data.distances,
    eventLocationCoords: data.eventLocation,
  });

export const resetAdjustersList = () => action(constants.RESET_ADJUSTERS_LIST);

export const setActiveListTab = (tab: string) => action(constants.SET_ACTIVE_LIST_TAB, { tab });

export const setActivePageAdjList = (page: number) =>
  action(constants.SET_ACTIVE_PAGE_ADJ_LIST, { page });

export const getMapAdjusters = (params?: object) => action(constants.GET_MAP_ADJUSTERS, { params });

export const getAdminAdjusters = (params?: object) =>
  action(constants.GET_ADMIN_ADJUSTERS, { params });

export const setAdminAdjusters = (adjusters: object[]) =>
  action(constants.SET_ADMIN_ADJUSTERS, { adjusters });
