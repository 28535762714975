import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Image, Message, Popup } from 'semantic-ui-react';
import eyeIcon from '../../assets/images/ui-show-pass.svg';
import ErrorLabel from '../../components/error-label/ErrorLabel';
import PhoneInput from '../../components/layout-input/PhoneInput';
import { profileActions } from '../../features/profile';
import { ProfileSaveAttemptModel } from '../../features/profile/actions';
import { IUser } from '../../models';
import services from '../../services';
import AvatarInput from './../../components/avatar-input/AvatarInput';

interface Props {
  profileSaveAttempt: typeof profileActions.profileSaveAttempt;
  emailVerificationRequestAction: typeof profileActions.emailVerificationRequestAttempt;
  errors: string[];
  isLoading: boolean;
  user: IUser;
  modal: any;
}

interface State {
  avatar: string;
  avatarEdited: boolean;
  showPass: boolean;
  enteredPass: string;
  fieldsUrl: string;
  formData: FormData | {};
}

Formsy.addValidationRule('passConfirm', (values: any, value: any) => {
  if (values.password === value) {
    return true;
  } else if (values.password === '' && value === '') {
    return true;
  } else {
    return false;
  }
});

Formsy.addValidationRule('hasUppercaseProfile', (values: any, value: any) => {
  if (value !== undefined && value.toLowerCase() !== value) {
    return true;
  } else if (value === '' || value === undefined) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasLowercaseProfile', (values: any, value: any) => {
  if (value !== undefined && value.toUpperCase() !== value) {
    return true;
  } else if (value === '' || value === undefined) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasSpecialProfile', (values: any, value: any) => {
  const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  if (value !== undefined && format.test(value)) {
    return true;
  } else if (value === '' || value === undefined) {
    return true;
  } else {
    return false;
  }
});
Formsy.addValidationRule('hasNumbersProfile', (values: any, value: any) => {
  if (value === undefined || value === '') {
    return true;
  } else {
    return /\d/.test(value);
  }
});

class Profile extends React.Component<Props, State> {
  public form: any;

  public state = {
    avatar: '',
    avatarEdited: false,
    showPass: false,
    enteredPass: '',
    fieldsUrl: '',
    formData: {},
  };

  public getAvatar = (key: any, avatarEdited: boolean) => {
    this.setState({ avatar: key, avatarEdited });
  };

  public getAvatatFields = (url: string, data: FormData) => {
    this.setState({ fieldsUrl: url, formData: data });
  };

  public handlePass = (e: any) => {
    this.setState({ enteredPass: e.target.value });
  };

  public render() {
    const { user, modal } = this.props;
    const { fieldsUrl, formData, avatarEdited } = this.state;
    const errorLabel = <ErrorLabel />;
    return (
      <div>
        <Form
          onValidSubmit={async (model: ProfileSaveAttemptModel) => {
            const avatar = { avatar: this.state.avatar };
            model.password = model.password === '' ? undefined : model.password;
            model.passwordConfirm =
              model.passwordConfirm === '' ? undefined : model.passwordConfirm;
            let obj = {};
            if (avatarEdited) {
              obj = Object.assign({}, model, avatar);
              if (this.state.avatar !== '' && this.state.avatar) {
                const resp = await services.api.file.postFile(fieldsUrl, formData);
                if (resp && resp.status === 204) {
                  this.props.profileSaveAttempt(obj);
                  this.setState({ enteredPass: '' });
                } else {
                  console.log(resp);
                }
              } else {
                this.props.profileSaveAttempt(obj);
              }
            } else {
              if (user.avatar !== '' && user.avatar) {
                const url = new URL(user.avatar);
                const key = url.pathname;
                obj = Object.assign({}, model, { avatar: key });
                this.props.profileSaveAttempt(obj);
              } else {
                obj = Object.assign({}, model, { avatar: '' });
                this.props.profileSaveAttempt(obj);
              }
            }
          }}
          ref={(ref: any) => (this.form = ref)}
        >
          <Grid>
            <Grid.Row>
              <AvatarInput
                getAvatar={this.getAvatar}
                getAvatarFields={this.getAvatatFields}
                noEdit={false}
                avatar={user.avatar}
              />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="form-profile-container">
                <div className="input-field-container">
                  <div className="input-field-label">First Name</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="first_name"
                      maxLength={60}
                      placeholder="First Name"
                      instantValidation={false}
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: '* First Name is required field.',
                      }}
                      errorLabel={errorLabel}
                      className="form-input"
                      value={user.firstName}
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Last Name</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="last_name"
                      maxLength={60}
                      placeholder="Last Name"
                      instantValidation={false}
                      required={true}
                      validationErrors={{
                        isDefaultRequiredValue: '* Last Name is required field.',
                      }}
                      errorLabel={errorLabel}
                      className="form-input"
                      value={user.lastName}
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Email</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="email"
                      maxLength={70}
                      placeholder="example@mail.com"
                      instantValidation={false}
                      required={true}
                      disabled={true}
                      validation="isEmail"
                      validationErrors={{
                        isDefaultRequiredValue: 'Enter your name',
                        isEmail: 'Email format is invalid.',
                      }}
                      errorLabel={errorLabel}
                      className="form-input"
                      value={user.email}
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label disabled">Phone# (Mobile)</div>
                  <div className="field-input-container">
                    <PhoneInput
                      name="phone"
                      placeholder="Enter please..."
                      instantValidation={false}
                      value={user.phone}
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label" />
                  <div className="remark-label" style={{ marginTop: '1.5rem' }}>
                    Leave the password field empty, if you do not want to change it.
                  </div>
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Password</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="password"
                      icon={
                        <Popup
                          trigger={
                            <i
                              className={`icon link custom-icon`}
                              onClick={() => this.setState({ showPass: !this.state.showPass })}
                            >
                              <Image
                                src={eyeIcon}
                                className={
                                  this.state.showPass ? 'show-pass-icon' : 'hide-pass-icon'
                                }
                              />
                            </i>
                          }
                          size="mini"
                          basic={true}
                          content={this.state.showPass ? 'Hide Password' : 'View Password'}
                        />
                      }
                      instantValidation={false}
                      type={this.state.showPass ? 'text' : 'password'}
                      validations={{
                        hasLowercaseProfile: true,
                        hasUppercaseProfile: true,
                        hasSpecialProfile: true,
                        hasNumbersProfile: true,
                        minLength: 8,
                      }}
                      validationErrors={{
                        hasLowercaseProfile:
                          'The password must contain at least 1 lowercase character.',
                        hasUppercaseProfile:
                          'The password must contain at least 1 uppercase character.',
                        hasSpecialProfile:
                          'The password must contain at least 1 special character.',
                        hasNumbersProfile: 'The password must contain at least 1 number.',
                        minLength: 'The password must be at least 8 symbols long.',
                      }}
                      errorLabel={errorLabel}
                      onChange={e => this.handlePass(e)}
                      className="form-input"
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Confirm Password</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="passwordConfirm"
                      instantValidation={false}
                      type={this.state.showPass ? 'text' : 'password'}
                      validations="passConfirm"
                      validationErrors={{
                        passConfirm: 'Passwords do not match.',
                      }}
                      errorLabel={errorLabel}
                      className="form-input"
                    />
                  </div>
                </div>

                <div className="input-field-container" style={{ marginTop: '1rem' }}>
                  <div className="input-field-label" />
                  <div className="field-input-container">
                    <Button style={{ width: '120px' }} primary={true}>
                      Save
                    </Button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {modal.isVisible && (
          <Message
            success={modal.type === 'success'}
            warning={modal.type === 'warning'}
            error={modal.type === 'error'}
          >
            {this.props.modal.model.message}
          </Message>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  errors: state.profile.profile.errors,
  user: state.account.account.user,
  isLoading: state.loading.loading.isVisible,
  modal: state.modal.alert,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      profileSaveAttempt: profileActions.profileSaveAttempt,
      emailVerificationRequestAction: profileActions.emailVerificationRequestAttempt,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
