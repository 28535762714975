import React from 'react';
import { Form } from 'formsy-semantic-ui-react';
import { Button } from 'semantic-ui-react';
import ErrorLabel from '../../error-label/ErrorLabel';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import UploadDocumentItem from '../shared/UploadDocumentItem';
import { DocumentData, MAX_FILE_SIZE } from '../shared/types';
import { toast } from 'react-toastify';
import { MaterialModel } from '../../../features/training-center/trainingCenterReducer';
import FolderInputTags from '../shared/FolderInputTags';
import services from "../../../services";

interface Props {
  material: MaterialModel;
  onFinish(data: DocumentData): void;
  setSubmitRef(ref: any): void;
}

interface State {
  file: File | null;
  selectedFolders: any[];
  dragging: boolean;
}

const maxSizeInMB = (MAX_FILE_SIZE / 1024 / 1024).toFixed(2);

class EditDocument extends React.Component<Props, State> {
  public submitRef: any = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      file: null,
      dragging: false,
      selectedFolders: props.material.materialFolders.map((f) => {
        return {
          id: f.folder.id,
          title: f.folder.name,
        }
      })
    };
    if(this.props.material.file){
      this.initialData()
    }
  }

  public initialData=async() => {
    const documentUrl = await services.api.file.getDownloadFileUrl(this.props.material.file.key);
    fetch(documentUrl).then(res => {
      res.arrayBuffer().then(buf => {
        const file = new File([buf], this.props.material.fileName, {type: this.props.material.mimetype})
        this.setState({ dragging: false, file:file })
      })
    })
  }

  public componentDidMount() {
    this.props.setSubmitRef(this.submitRef);
  }

  public handleDrop = (files: any[]) => {
    this.setState({ dragging: false, file: files[0] });
  };

  public handleDropRejected = (files: any[]) => {
    const fileToLargeCode = 'file-too-large';
    for (const file of files) {
      if (file.errors[0].code === fileToLargeCode) {
        toast.error(`${file.file.name}: File is larger than ${maxSizeInMB}MB`);
      } else {
        toast.error(`${file.file.name}: ${file.errors[0].message}`);
      }
    }
  };

  public handleFileListClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  public handleDragEnter = () => {
    this.setState({
      dragging: true,
    });
  };

  public handleDragLeave = () => {
    this.setState({
      dragging: false,
    });
  };

  public onFileDelete = () => {
    this.setState({ file: null });
  };

  public onValidFormSubmit = (model: any) => {
    if (this.state.selectedFolders.length === 0) {
      toast.error('No folders assigned');
      return;
    }

    if (!this.state.file) {
      this.props.onFinish(model);
      return;
    }

    this.props.onFinish({
      ...model,
      file: this.state.file,
      folderIds: this.state.selectedFolders.map((f) => f.id),
    });
  };

  public setFolders = (folders: any[]) => {
    this.setState({ selectedFolders: folders });
  }

  public render() {
    const errorLabel = <ErrorLabel />;

    return (
      <Form className="upload-document" onValidSubmit={this.onValidFormSubmit}>
        <Dropzone
          onDrop={this.handleDrop}
          onDropRejected={this.handleDropRejected}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          maxFiles={1}
          maxSize={MAX_FILE_SIZE}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: classnames('main-content', { 'empty-files': this.state.file === null }),
              })}
            >
              <input {...getInputProps()} />
              {this.state.file && (
                <div className="file-list" onClick={this.handleFileListClick}>
                  <UploadDocumentItem
                    key={this.state.file.name}
                    name={this.state.file.name}
                    onDelete={this.onFileDelete}
                  />
                </div>
              )}
              <div className="description">
                <div>Drop file(s) here or</div>
                <div style={{ cursor: 'pointer' }}>UPLOAD</div>
              </div>
            </div>
          )}
        </Dropzone>
        <React.Fragment>
          <div className="small-spacer" />
          <div className="input-field-container">
            <div className="input-field-label">Filename ({this.props.material.name})</div>
            <Form.Input
              className="form-input"
              name="name"
              instantValidation={false}
              required={true}
              placeholder="Please Enter..."
              validationErrors={{
                isDefaultRequiredValue: 'Please provide the file name',
              }}
              errorLabel={errorLabel}
              value={this.props.material.name}
            />
          </div>
          <div className="input-field-container">
            <div className="input-field-label">Description</div>
            <Form.Input
              className="form-input"
              name="description"
              placeholder="Please Enter..."
              instantValidation={false}
              required={true}
              validationErrors={{
                isDefaultRequiredValue: 'Please provide the document description',
              }}
              errorLabel={errorLabel}
              value={this.props.material.description}
            />
          </div>
        </React.Fragment>
        <div className="small-spacer" />
        <div className="input-field-container">
          <FolderInputTags
            title="Folder"
            folders={this.state.selectedFolders}
            setFolders={this.setFolders}
          />
        </div>
        <Button
          ref={this.submitRef}
          type="submit"
          formNoValidate={true}
          style={{ display: 'none' }}
        />
      </Form>
    );
  }
}

export default EditDocument;
