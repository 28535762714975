import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Pagination } from 'semantic-ui-react';
import { IUser } from '../../models';
import services from '../../services';
import { LoadingSpiner } from './../../components/loading-spinner/LoadingSpinner';
import { NoResults } from './../no-results-placeholder/NoResults';
import BuyTwilioNumber from './../twilio-layout/BuyNumberModal';
import TwilioNumber from './../twilio-layout/TwilioNumber';

interface Props {
  user: IUser;
  isAuthenticated: boolean;
}

interface State {
  numbersList: object[];
  showPagination: boolean;
  pagginationPage: number;
  totalPages: number;
  totalItemsPage: number;
}

class TwilioNumbers extends React.Component<Props, State> {
  public state = {
    numbersList: [],
    showPagination: false,
    pagginationPage: 0,
    totalPages: 0,
    totalItemsPage: 0,
  };

  public componentDidMount() {
    this.getNumbers();
  }

  public getNumbers = async () => {
    const obj = {
      page: this.state.pagginationPage,
      pageSize: 20,
    };
    const response = await services.api.settings.getAllTwilio(obj);
    if (response.isSuccess) {
      this.setState(
        { numbersList: response.data.list, totalItemsPage: response.data.list.length },
        () => this.getPagination()
      );
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public getPagination = async () => {
    const responseBuff = await services.api.settings.getAllTwilio({
      page: '',
      pageSize: 20,
    });
    if (responseBuff.isSuccess) {
      this.setState({
        totalPages: Math.ceil(responseBuff.data.list.length / 20),
        showPagination: responseBuff.data.list.length > 20,
      });
    }
  };

  public deleteNumber = async (id: number, name: string) => {
    const response = await services.api.settings.deleteTwilioNumber(id);

    if (response.isSuccess) {
      toast.dismiss();
      toast.success(`Number ${name} was successsfully released!`);
      this.getNumbers();
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public handlePagination = (e: any, data: any) => {
    this.setState({ pagginationPage: data.activePage - 1 }, () => this.getNumbers());
  };

  public render() {
    return (
      <div className="twilio-numbers-container">
        <LoadingSpiner area="purchased-twilio-numbers" />
        {this.state.numbersList.length !== 0 ? (
          this.state.numbersList.map((el: any, index: number) => {
            return (
              <TwilioNumber
                user={this.props.user}
                item={el}
                key={index}
                deleteNumber={this.deleteNumber}
              />
            );
          })
        ) : (
          <NoResults />
        )}
        <BuyTwilioNumber user={this.props.user} reloadPurchasedNumbers={this.getNumbers} />
        {this.state.showPagination && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <Pagination
              totalPages={this.state.totalPages}
              boundaryRange={1}
              defaultActivePage={1}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                this.state.pagginationPage === 0
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                this.state.pagginationPage === this.state.totalPages - 1
                  ? false
                  : {
                      'aria-label': 'Next item',
                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={this.handlePagination}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  isAuthenticated: state.account.account.isAuthenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(TwilioNumbers);
