import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Alert {
  isVisible: boolean;
  type?: string;
  model?: actions.AlertModel;
}

export const AlertDefaultState: Alert = {
  isVisible: false,
};

export interface Confirmation {
  isVisible: boolean;
  model?: actions.ConformationModel;
}

export const ConfirmationDefaultState: Confirmation = {
  isVisible: false,
};

export interface State {
  alert: Alert;
  confirmation: Confirmation;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  alert: (state = AlertDefaultState, action) => {
    switch (action.type) {
      case constants.ALERT_MODAL_DISPLAY:
        return {
          ...state,
          isVisible: true,
          type: action.payload.type,
          model: action.payload.model,
        };
      case constants.ALERT_MODAL_HIDE:
        return {
          ...state,
          isVisible: false,
          model: undefined,
        };

      // case constants.HIDE_CONFIRMATION_MODAL:
      //   return {...state, errors: action.payload.errors};

      default:
        return state;
    }
  },
  confirmation: (state = ConfirmationDefaultState, action) => {
    switch (action.type) {
      case constants.CONFIRMATION_MODAL_DISPLAY:
        return {
          ...state,
          isVisible: true,
          model: action.payload,
        };
      case constants.CONFIRMATION_MODAL_HIDE:
        return {
          ...state,
          isVisible: false,
          model: undefined,
        };

      // case constants.HIDE_CONFIRMATION_MODAL:
      //   return {...state, errors: action.payload.errors};

      default:
        return state;
    }
  },
});
