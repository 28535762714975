import React from 'react';
import moment from 'moment';
import { Image } from 'semantic-ui-react';
import FolderTags from '../shared/FolderTags';
import DocumentLabel from '../shared/DocumentLabel';
import { api } from '../../../config';
import DocumentIcon from '../shared/DocumentIcon';

const endpoint = api.endpoint;

interface Props {
  name: string;
  description: string;
  lastUpdatedOn: string;
  folders: string[];
  fileType: string;
  thumbnailUrl?: string;
  fileKey?: string;
  mimetype: string;
}

export default function DocumentDetails(props: Props) {
  return (
    <div className="document-details">
      <div className="thumbnail">
        {props.fileType === 'document' &&
          (props.mimetype.includes('image') ? (
            <Image
              src={`${endpoint}public/thumbnail?fileKey=${props.fileKey}`}
              className="material-thumbnail"
            />
          ) : (
            <DocumentIcon type={props.mimetype} />
          ))}
        <DocumentLabel type={props.mimetype} />
      </div>
      <div className="content">
        <div className="label-container">
          <div className="label">File Name</div>
          <div className="value-container">
            <div className="value">{props.name}</div>
          </div>
        </div>
        <div className="label-container">
          <div className="label">Folders</div>
          <div className="value-container tags">
            <FolderTags tags={props.folders} />
          </div>
        </div>
        <div className="label-container">
          <div className="label">Description</div>
          <div className="value-container description">
            <div className="value">{props.description}</div>
          </div>
        </div>
        <div className="label-container">
          <div className="label">Last updated on</div>
          <div className="value-container">
            <div className="value">{moment(props.lastUpdatedOn).format('DD/MM/YYYY, h:mm A')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
