import classnames from 'classnames';
import { debounce } from 'lodash';
import React from 'react';
import noAvaterIcon from '../../../assets/images/no-avatar.svg';
import { FileFinancialIDStatus } from '../../../features/finance/reducer';
import services from '../../../services';
import PersonSelectionInput from '../../person-selection-input/PersonSelectionInput';

interface Props {
  matchedAdjuster: any;
  matchedStatus: string;
  className?: string;
  adjusters: any[];
  disabled?: boolean;
  onChange: (value: any) => void;
}

interface State {
  adjusters: any[];
  isLoading: boolean;
}

const notFoundOption = {
  key: 'notfound',
  text: 'Not found',
  value: 'notfound',
  image: { avatar: true, src: noAvaterIcon },
};

class AdjusterSelectionInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      adjusters:
        props.matchedStatus === FileFinancialIDStatus.MATCHING
          ? [props.matchedAdjuster, ...props.adjusters]
          : props.adjusters,
      isLoading: false,
    };

    this.onSearchChange = debounce(this.onSearchChange, 350);
  }

  public prepareOptions = () => {
    const adjusters = this.state.adjusters;
    if (
      this.props.matchedAdjuster.id &&
      !adjusters.find((a: any) => a.id === this.props.matchedAdjuster.id)
    ) {
      adjusters.unshift(this.props.matchedAdjuster);
    }

    return adjusters.map((a: any) => ({
      key: a.id,
      value: a.id,
      text: `${a.preferredName ? a.preferredName : a.firstName} ${a.lastName}${
        a.email ? ` (${a.email})` : ''
      } `,
      image: { avatar: true, src: a.avatar || noAvaterIcon },
    }));
  };

  public onSearchChange = async (value: string) => {
    this.setState({ isLoading: true });
    try {
      if (!value) {
        this.setState({ adjusters: this.props.adjusters, isLoading: false });
        return;
      }

      const results = await services.api.adjuster.fetchAdjuster({
        pageSize: 30,
        name: value,
        sort: 'firstName,asc',
      });

      this.setState({ adjusters: results.data.list, isLoading: false });
    } catch (err) {
      console.log(err);
      this.setState({ adjusters: [], isLoading: false });
    }
  };

  public onSelectChange = (adjusterID: number) => {
    const adjuster = this.state.adjusters.find(a => a.id === adjusterID);
    if (adjuster) {
      this.props.onChange(adjuster);
    }
  };

  public handleClose = () => {
    if (this.state.adjusters.length === 0) {
      this.setState({ adjusters: this.props.adjusters });
    }
  };

  public render() {
    const { className, matchedAdjuster, matchedStatus, disabled } = this.props;
    const statusClassName = matchedStatus;
    const adjusterId = matchedAdjuster ? matchedAdjuster.id : null;

    const options = this.prepareOptions();
    const inputOptions = adjusterId ? options : [notFoundOption, ...options];

    return (
      <PersonSelectionInput
        className={classnames(className, statusClassName)}
        options={inputOptions}
        value={adjusterId || notFoundOption.value}
        onChange={this.onSelectChange}
        disabled={disabled}
        loading={this.state.isLoading}
        onSearchChange={this.onSearchChange}
        onClose={this.handleClose}
      />
    );
  }
}

export default AdjusterSelectionInput;
