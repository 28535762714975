import React from 'react';
import { toast } from 'react-toastify';
import copyIcon from '../../assets/images/Blue/copy.svg';
import { Button, Popup, Image } from 'semantic-ui-react';
import config from '../../config'

interface Props {
    publicId: string
}

interface State { }

class CopySBLToClipboard extends React.Component<Props, State> {
    fallbackCopyTextToClipboard = (text: string) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        textArea.style.display = 'none';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (err) {
            throw err;
        }

        document.body.removeChild(textArea);
    }

    copyStandbyListLink = async () => {
        const link = `${config.api.app_endpoint}standbylist/quick-sign-up/${this.props.publicId}`;

        try {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(link);
            } else {
                await navigator.clipboard.writeText(link);
            }
            toast.success(`${link} copied to clipboard`);
        } catch (error) {
            console.log(error);
            toast.error(`Error copying ${link} to clipboard`);
        }
    }

    render() {
        return (
            <Popup
                trigger={
                    <Button onClick={this.copyStandbyListLink} className="sbl-action-button">
                        <Image style={{ height: '25px' }} src={copyIcon} />
                    </Button>
                }
                basic={true}
                size="mini"
                content="Copy Unique Link"
            />
        );
    }
}

export default CopySBLToClipboard;
