import React from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  Confirm,
  Dropdown,
  Image,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import avatar from '../../assets/images/no-avatar.svg';
import gearIcon from '../../assets/images/White/gear.svg';
import services from '../../services';
import * as helper from '../../utils/helper';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import EditAdminModal from './EditAdminModal';

interface Props {
  tableData: object[];
  resetAdmins: () => void;
  fetchAdmins: () => void;
  handlePagination: (e?: any, data) => void;
  activePage: any;
  resetSelection: string;
  user: any;
  fetchParams: object;
}

interface State {
  tableData: object[];
  showModal: boolean;
  showConfirm: boolean;
  showEditModal: boolean;
  modalMsg: string;
  confirmStatus: string;
  firstName: string;
  lastName: string;
  userIndex: number;
  bulkId: number[];
  bulkIdLenght: number;
  confirmed: boolean;
  userId: number;
}

class AdminListTable extends React.Component<Props, State> {
  public state = {
    tableData: [{}],
    showModal: false,
    showConfirm: false,
    showEditModal: false,
    modalMsg: '',
    confirmStatus: '',
    firstName: '',
    lastName: '',
    userIndex: 0,
    bulkId: [],
    bulkIdLenght: 0,
    confirmed: false,
    userId: 0,
  };

  public componentDidMount() {
    this.setState({ tableData: this.props.tableData });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.tableData !== this.props.tableData) {
      this.setState({ tableData: this.props.tableData });
    }
    if (prevProps.resetSelection !== this.props.resetSelection) {
      this.setState({ bulkId: [], bulkIdLenght: 0 });
    }
  }

  public handleStatus = async (status: number, id: number, firstName: string, lastName: string) => {
    const fullName = `${firstName} ${lastName}`;
    const response = await services.api.admin.changeStatus(status, id);
    toast.dismiss();
    if (response.isSuccess) {
      this.setState({ bulkIdLenght: 0 });
      toast.success(`Admin ${fullName} status was successfully updated!`);
      this.props.fetchAdmins();
    } else {
      toast.error(response.status + ' ' + response.data.errors[0].msg);
    }
  };

  public handleDelete = async (name: string, id: number) => {
    const response = await services.api.admin.deleteAdmin(id);
    toast.dismiss();
    if (response.isSuccess) {
      this.props.resetAdmins();
      this.setState({ bulkIdLenght: 0 });
      toast.success(`Admin ${name} was successfully deleted!`);
      this.props.fetchAdmins();
      if (this.props.tableData.length === 0 && this.props.activePage !== 1) {
        this.props.handlePagination({}, { activePage: 1 });
      }
    } else {
      toast.error('Error ' + response.status + '! ' + response.data.errors[0].msg);
    }
  };

  public closeEditModal = () => {
    this.setState({ showEditModal: false });
  };

  public handleSelection = (id: number) => {
    const { bulkId } = this.state;
    const newArray: number[] = bulkId;
    const isInArray: boolean = newArray.includes(id);
    if (!isInArray) {
      newArray.push(id);
    } else {
      newArray.splice(newArray.indexOf(id), 1);
    }
    this.setState({ bulkId: newArray, bulkIdLenght: newArray.length });
  };

  public handleBulkStatus = async (status: number) => {
    const { bulkId } = this.state;
    if (status === 0) {
      const request = await services.api.admin.changeBulkStatus(0, bulkId);
      if (request.isError) {
        toast.dismiss();
        toast.error('Error changing status!');
      } else {
        toast.dismiss();
        this.setState({ bulkIdLenght: 0 });
        toast.success('The status for the selected list of admins was successfully updated!');
      }
    } else if (status === 1) {
      const request = await services.api.admin.changeBulkStatus(1, bulkId);
      if (request.isError) {
        toast.dismiss();
        toast.error('Error changing status!');
      } else {
        toast.dismiss();
        this.setState({ bulkIdLenght: 0 });
        toast.success('The status for the selected list of admins was successfully updated!!');
      }
    } else if (status === 2) {
      const request = await services.api.admin.deleteBulkAdmin(bulkId);
      if (request.isError) {
        toast.dismiss();
        toast.error('Error deleting admins!');
      } else {
        toast.dismiss();
        this.setState({ bulkIdLenght: 0 });
        toast.success('The selected list of admins was successfully deleted!');
      }
    }

    this.setState({ bulkId: [] }, () => this.props.fetchAdmins());
  };

  public submitConfirm = () => {
    const { confirmStatus, firstName, lastName, userId } = this.state;
    const obj = {
      'delete admin': () => {
        this.handleDelete(firstName, userId);
      },
      'deactivate admin': () => {
        this.handleStatus(0, userId, firstName, lastName);
      },
      'delete bulk': () => {
        this.handleBulkStatus(2);
      },
      'deactivate bulk': () => {
        this.handleBulkStatus(0);
      },
    };

    const res = obj[confirmStatus];
    if (res) {
      res();
    } else {
      this.handleBulkStatus(1);
    }
    this.setState({ showConfirm: false });
  };

  public render() {
    const { confirmStatus, firstName, lastName, userId, tableData } = this.state;
    const trigger = (
      <Popup
        trigger={
          <Button primary={true} className="gear-button">
            <Image className="icon" src={gearIcon} />
          </Button>
        }
        basic={true}
        size="mini"
        content="Actions"
      />
    );
    return (
      <React.Fragment>
        <div className="admin-list-table">
          <div className="bulk-header">
            Selected: {this.state.bulkIdLenght}
            <Dropdown
              disabled={this.state.bulkId.length === 0}
              trigger={trigger}
              icon={false}
              className="segment-admin-dropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ showConfirm: true, confirmStatus: 'deactivate bulk' })
                  }
                >
                  <span>Deactivate</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ showConfirm: true, confirmStatus: 'activate bulk' })
                  }
                >
                  <span>Activate</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => this.setState({ showConfirm: true, confirmStatus: 'delete bulk' })}
                >
                  <span>Delete</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <LoadingSpiner area="admin-list-table" />
          {tableData.length !== 0 ? (
            <Table collapsing={false}>
              <Table.Body>
                {tableData.map((el: any, index) => {
                  return (
                    <Table.Row key={index} className="admin-list-row">
                      <Table.Cell>
                        <Segment
                          onClick={() => this.setState({ showEditModal: true, userIndex: index })}
                        >
                          <div className="segment-admin-container">
                            <div>
                              <div
                                className="checkbox-custom-container"
                                onClick={e => e.stopPropagation()}
                              >
                                <Checkbox
                                  name={el.firstName}
                                  onChange={e => this.handleSelection(el.id)}
                                  onClick={e => e.stopPropagation()}
                                />
                              </div>
                              <div>
                                <Image
                                  circular={true}
                                  src={el.avatar === '' || !el.avatar ? avatar : el.avatar}
                                  className="admin-list-avatar"
                                />
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="admin-fullname-container">
                                  {el.firstName + ' ' + el.lastName}
                                  <span
                                    className={
                                      el.activeStatus
                                        ? 'status-badge active'
                                        : 'status-badge inactive'
                                    }
                                  >
                                    {el.activeStatus ? 'active' : 'inactive'}
                                  </span>
                                </div>
                                <div />
                                {el.role}{' '}
                                {el.financeAccess
                                  ? '(Finance Access Rights)'
                                  : '(No Finance Access Rights)'}
                              </div>
                            </div>

                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  textAlign: 'right',
                                }}
                              >
                                <div
                                  style={{ fontWeight: 800, fontSize: '16px', color: '#0f1e31' }}
                                >
                                  {helper.formatPhone(el.phone, '(###) ###-####')}
                                </div>
                                <div>{el.email}</div>
                              </div>
                              <Dropdown
                                trigger={trigger}
                                icon={false}
                                className="segment-admin-dropdown"
                              >
                                <Dropdown.Menu>
                                  {el.activeStatus ? (
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.setState({
                                          userId: el.id,
                                          showConfirm: true,
                                          firstName: el.firstName,
                                          lastName: el.lastName,
                                          confirmStatus: 'deactivate admin',
                                        })
                                      }
                                    >
                                      <span>Deactivate</span>
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.handleStatus(1, el.id, el.firstName, el.lastName)
                                      }
                                    >
                                      <span>Activate</span>
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.setState({
                                        userId: el.id,
                                        showConfirm: true,
                                        firstName: el.firstName,
                                        lastName: el.lastName,
                                        confirmStatus: 'delete admin',
                                      })
                                    }
                                  >
                                    <span>Delete</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </Segment>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          ) : (
            <NoResults />
          )}
        </div>
        <EditAdminModal
          user={this.state.tableData[this.state.userIndex]}
          showModal={this.state.showEditModal}
          closeModal={this.closeEditModal}
          fetchParams={this.props.fetchParams}
        />
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showModal: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={
            confirmStatus === 'delete admin'
              ? `Are you sure you want to delete admin “${firstName} ${lastName}”?`
              : confirmStatus === 'deactivate admin'
              ? `Are you sure you want to deactivate admin “${firstName} ${lastName}”?`
              : confirmStatus === 'delete bulk'
              ? 'Are you sure you want to delete the selected list of admins?'
              : confirmStatus === 'deactivate bulk'
              ? 'Are you sure you want to deactivate the selected list of admins?'
              : 'Are you sure you want to activate the selected list of admins?'
          }
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={this.submitConfirm}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </React.Fragment>
    );
  }
}

export default AdminListTable;
