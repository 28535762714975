import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Container, Dropdown, Grid, Segment } from 'semantic-ui-react';
import DatePickerInput from '../../components/datepicker-input/DatePickerInput';
import ErrorLabel from '../../components/error-label/ErrorLabel';
import QuestionFormsy from '../../components/layout-question/QuestionFormsy';
import TwilioModal from '../../components/standby-layout/TwilioModal';
import { adminActions } from '../../features/admin';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import services from '../../services';
import statesUS from '../../utils/statesUS';
import statesUSbyKey from '../../utils/statesUSbyKey';

interface Props {
  user: IUser;
  getQuestions: typeof adminActions.getAllQuestionnarie;
  setActiveStandby: typeof standbyActions.setActiveStandby;
  showInviteMessage: typeof standbyActions.showInviteMessage;
  showTwilioModalFunc: typeof standbyActions.showTwilioModal;
  questionnaries: object[];
  activeStandbyId: any;
  standbyList: object[];
  editStandby: boolean;
  showTwilioModal: boolean;
}

interface State {
  showModal: boolean;
  questions: object[];
  questNameList: object[];
  questList: object[];
  activeQuestions: object[];
  activeQuestionsId: any;
  concatenatedQuest: object[];
  activeList: object;
  noQuestionsError: boolean;
  loading: boolean;
}

class StandbyListsForm extends React.Component<Props, State> {
  public state = {
    showModal: true,
    questions: [],
    questNameList: [],
    questList: [],
    activeQuestions: [],
    activeQuestionsId: 0,
    concatenatedQuest: [],
    activeList: {},
    noQuestionsError: false,
    loading: false,
  };

  public componentDidMount = () => {
    this.props.getQuestions();
    if (this.props.match.url.includes('/edit')) {
      this.props.setActiveStandby(this.props.match.params.id);
      this.selectActiveList();
    }
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.questionnaries !== this.props.questionnaries) {
      if (this.props.questionnaries.length === 0) {
        this.setState({ questNameList: [], questList: [], activeQuestions: [] });
      } else if (prevProps.questionnaries.length === 0 && this.props.questionnaries.length !== 0) {
        const list = this.props.questionnaries.map(
          (el: any) => (el = { name: el.name, id: el.id, questions: el.questions })
        );
        this.setState({
          questList: list,
          questNameList: list.map((el: any) => ({ text: el.name, value: el.id })),
        });
      }
    }
    if (prevProps.editStandby !== this.props.editStandby) {
      this.selectActiveList();
    }
  };

  public selectActiveList = async () => {
    const response = await services.api.standby.getStandbyListDetails(this.props.match.params.id);
    if (response.isSuccess) {
      this.props.setActiveStandby(this.props.match.params.id);
      this.setState({
        activeList: response.data,
        questions: response.data.questions,
        noQuestionsError: false,
      });
    } else {
      console.log(response);
      toast.dismiss();
      toast.error(response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`);
    }
  };

  public onTemplateChange = (e: any, data: any) => {
    this.setState({ activeQuestionsId: data.value, noQuestionsError: false }, () =>
      this.concatArrays(this.state.activeQuestionsId)
    );
  };

  public concatArrays = (value: any) => {
    const { questList, questions } = this.state;
    const item = questList.find((element: any) => element.id === value).questions;

    const modifiedQuest: object[] = [];
    item.forEach((question: object, k) => {
      modifiedQuest.push({
        ...question,
        order: questions.length + question.order,
      });
    });
    const concArray = [...questions, ...modifiedQuest];
    this.setState({ questions: concArray });
  };

  public deleteQuestion = (id: number) => {
    const { questions } = this.state;
    const questIndex = questions.findIndex((el: any) => el.id === id);
    questions.splice(questIndex, 1);
    const array = questions.map((el: any, index: number) => {
      el.order = index;
      return el;
    });
    this.setState({ questions: [] }, () => this.setState({ questions: array }));
  };

  public editQuestion = (id: number, question: object) => {
    const { questions } = this.state;
    const questIndex = questions.findIndex((el: any) => el.id === id);
    questions[questIndex] = question;
    this.setState({ questions });
  };

  public getQuestion = (question: any) => {
    const { questions } = this.state;
    questions.push(question);
    this.setState({ questions });
  };

  public onDragEnd = (result: any) => {
    const { destination, source } = result;
    const { questions } = this.state;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const element = questions[source.index];
    questions.splice(source.index, 1);
    questions.splice(destination.index, 0, element);

    for (let i = 0; i < questions.length; i++) {
      questions[i].order = i;
    }

    this.setState({ questions });
  };

  public render() {
    const { loading } = this.state;
    const data = this.props.match.url.includes('/edit')
      ? this.state.activeList
      : { privacy: 'public' };
    const errorLabel = <ErrorLabel />;
    return (
      <div className="create-sbl-page">
        <Form
          onInvalidSubmit={(model: object) => {
            if (this.state.questions.length === 0) {
              this.setState({ noQuestionsError: true });
              return;
            }
          }}
          onValidSubmit={async (model: any) => {
            if (this.state.questions.length === 0) {
              this.setState({ noQuestionsError: true });
              return;
            }

            this.setState({ loading: true });

            delete model.question;
            const obj = Object.assign(
              {},
              model,
              {
                expiresAt: `${moment(model.expiresAt).format('YYYY-MM-DDT')}${moment().format(
                  'HH:mm:ss'
                )}Z`,
              },
              { questions: this.state.questions },
              { state: statesUSbyKey[model.state] },
              { privacy: model.privacy ? 'private' : 'public' }
            );

            if (this.props.match.url.includes('/edit')) {
              const response = await services.api.standby.editStandby(
                obj,
                this.state.activeList.id
              );

              if (response.isSuccess) {
                if (window.location.hash === '#reopen') {
                  const responseReopen = await services.api.standby.reopenStandby(
                    { expiresAt: obj.expiresAt },
                    this.state.activeList.id
                  );
                  if (responseReopen.data.isNumberRestored) {
                    toast.dismiss();
                    toast.success(`Standby List ${model.title} was reopened!`);
                    services.router.goto('/stand-by-lists');
                  } else {
                    this.setState({ loading: false });
                    this.props.showTwilioModalFunc(true);
                  }
                } else {
                  toast.dismiss();
                  toast.success(`Standby List ${model.title} was updated successfully!`);
                  services.router.goto('/stand-by-lists');
                }
              } else {
                console.log(response);
                toast.dismiss();
                toast.error(
                  response.data ? response.data.errors[0].msg : `Error ${response.status}`
                );
              }
            } else {
              const response = await services.api.standby.createStandby(obj);
              if (response.isSuccess) {
                this.props.showInviteMessage(true);
                services.router.goto(`/stand-by-lists/${response.data.id}`);
              } else {
                console.log(response);
                toast.dismiss();
                toast.error(
                  response.data ? response.data.errors[0].msg : `Error ${response.status}`
                );
              }
            }
            this.setState({ loading: false });
          }}
        >
          <Segment loading={loading} disabled={loading} basic={true}>
            <Container className={'sbl-form-container'}>
              <Grid style={{ paddingLeft: '15px' }}>
                <Grid.Row>
                  <Grid.Column width={10} style={{ maxWidth: '650px' }}>
                    <div className="input-field-container">
                      <div className="input-field-label">Title</div>
                      <div className="field-input-container">
                        <Form.Input
                          name="title"
                          maxLength={128}
                          placeholder="Please Enter..."
                          instantValidation={false}
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: '* Title is required field.',
                          }}
                          errorLabel={errorLabel}
                          className="form-input"
                          value={data.title}
                        />
                      </div>
                    </div>

                    <div className="input-field-container">
                      <div className="input-field-label">Description</div>
                      <div className="field-input-container">
                        <Form.TextArea
                          name="description"
                          maxLength={1024}
                          placeholder="Please Enter..."
                          instantValidation={false}
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: '* Description is required field.',
                          }}
                          errorLabel={errorLabel}
                          className="form-input"
                          value={data.description}
                        />
                      </div>
                    </div>

                    <div className="input-field-container">
                      <div className="input-field-label">Expiration Date</div>
                      <div className="field-input-container">
                        <DatePickerInput
                          name="expiresAt"
                          instantValidation={false}
                          required={true}
                          validations={{ isValidMin: moment(), isValidDate: true }}
                          validationErrors={{
                            isDefaultRequiredValue: '* Expiration date is required field.',
                            isValidMin: `* Date should not be earlier than current date.`,
                            isValidDate: `* Date format is invalid.`,
                          }}
                          errorLabel={errorLabel}
                          className="form-input-short"
                          value={data.expiresAt}
                        />
                      </div>
                    </div>

                    <div className="input-field-container">
                      <div className="input-field-label">Location</div>
                      <div className="field-input-container">
                        <Form.TextArea
                          name="location"
                          maxLength={250}
                          placeholder="Please Enter..."
                          instantValidation={false}
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: '* Location is required field.',
                          }}
                          errorLabel={errorLabel}
                          className="form-input"
                          value={data.location}
                        />
                      </div>
                    </div>

                    <div className="input-field-container private-checkbox-container">
                      <div className="input-field-label">Private StandBy List</div>
                      <div className="field-input-container">
                        {data.privacy && (
                          <Form.Checkbox
                            name="privacy"
                            label={'Yes'}
                            defaultChecked={data.privacy === 'private' ? true : false}
                            className="private-checkbox-field"
                          />
                        )}
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>

            <React.Fragment>
              <div style={{ fontWeight: 700, fontSize: '22px', margin: '22px 0' }}>Questions</div>
              <div
                style={{ maxWidth: '662px', alignItems: 'center' }}
                className="input-field-container standby-predefined"
              >
                <div className="input-field-label">Predefined List</div>
                <div className="field-input-container">
                  <Dropdown
                    search={true}
                    selectOnBlur={false}
                    placeholder="Please Select"
                    className="standby-dropdown"
                    options={this.state.questNameList}
                    onChange={this.onTemplateChange}
                  />
                </div>
              </div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId={`new_list`}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {this.state.questions
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((question: any, index: number) => (
                          <QuestionFormsy
                            name={'question'}
                            key={index}
                            question={question}
                            deleteQuestion={this.deleteQuestion}
                            editQuestion={this.editQuestion}
                            index={index}
                            user={this.props.user}
                          />
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </React.Fragment>

            {this.state.noQuestionsError && (
              <ErrorLabel>* At least one question should be defined.</ErrorLabel>
            )}

            <div className="add-question">
              <Button
                disabled={this.props.user.role === 'Read Only Office Admin'}
                type="button"
                onClick={() => {
                  this.setState({ noQuestionsError: false });
                  this.getQuestion({
                    isNew: true,
                    text: '',
                    answerType: 'Yes/No',
                    order: this.state.questions.length,
                    id: Math.floor(Math.random() * Math.floor(1000)),
                  });
                }}
              >
                + ADD QUESTION
              </Button>
            </div>
          </Segment>
          <div className="save-sbl-button">
            <Button loading={loading} disabled={loading} primary={true}>
              {window.location.hash === '#reopen' ? 'Reopen' : 'Save'}
            </Button>
          </div>
        </Form>
        <TwilioModal
          stbId={this.state.activeList.id}
          show={this.props.showTwilioModal}
          showModal={this.props.showTwilioModalFunc}
          activeList={this.state.activeList}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  questionnaries: state.admin.questions.questionnaries,
  activeStandbyId: state.standby.standby.activeStandbyId,
  standbyList: state.standby.standby.standbyList,
  editStandby: state.standby.standby.editStandby,
  user: state.account.account.user,
  showTwilioModal: state.standby.standby.showTwilioModal,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getQuestions: adminActions.getAllQuestionnarie,
      setActiveStandby: standbyActions.setActiveStandby,
      showInviteMessage: standbyActions.showInviteMessage,
      showTwilioModalFunc: standbyActions.showTwilioModal,
    },
    dispatch
  );

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(StandbyListsForm);
