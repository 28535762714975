import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image, Label, Popup, Segment } from 'semantic-ui-react';
import noAvatar from '../../assets/images/no-avatar.svg';
import msgIconW from '../../assets/images/White/message.svg';
import '../../assets/styles/Messages.css';
import { uploads } from '../../config';

interface Props {
  item: object;
  match: any;
  groupId: any;
}

interface State {}

class RecipientItem extends React.Component<Props, State> {
  public state = {};

  public render() {
    const { item, match, groupId } = this.props;
    const linkTo = match.params.sbid
      ? `/messages/room/${item.id}/${match.params.sbid}`
      : `/messages/room/${item.id}`;
    return (
      <Segment className="msg-item-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="message-avatar-container">
            <Image
              circular={true}
              src={item.avatar ? uploads.s3BucketAddress + item.avatar : noAvatar}
              style={{ width: '36px', height: '36px' }}
              avatar={true}
            />
            {item.hasUnreadMessages && (
              <Label color="red" floating={true} circular={true} empty={true} />
            )}
          </div>
          <div style={{ marginLeft: '1rem', fontWeight: 700 }}>
            {item.preferredName ? item.preferredName : item.firstName} {item.lastName}
          </div>
        </div>

        <div>
          <Popup
            trigger={
              <Button as={Link} to={linkTo} primary={true} className="sbl-action-button">
                <Image src={msgIconW} />
              </Button>
            }
            basic={true}
            size="mini"
            content="Message"
          />
        </div>
      </Segment>
    );
  }
}

export default RecipientItem;
