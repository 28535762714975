import { trackPromise } from 'react-promise-tracker';
import { request } from './base';

export interface EventListFetchParam {
  page?: number;
  pageSize?: number;
  name?: string;
}

export const fetchEvent = async (
  params?: EventListFetchParam,
  fetchArea?: string
) => {
  const response = await trackPromise(
    request({
      method: 'GET',
      url: '/event/search',
      params: {
        ...params,
      },
    }),
    fetchArea ? fetchArea : 'events-list'
  );

  return response;
};

export const getAllEvents = () => {
  return trackPromise(
    request({
      method: 'get',
      url: '/event',
    }),
    'adjusters-profile-view'
  );
};
