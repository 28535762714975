import { trackPromise } from 'react-promise-tracker';
import { request } from './base';

export const createQuestionnarie = (model: {}) => {
  return trackPromise(
    request({
      method: 'post',
      url: '/questionnaire',
      data: {
        ...model,
      },
    }),
    'questions-area'
  );
};

export const createMsgTemplate = (model: {}) => {
  return trackPromise(
    request({
      method: 'post',
      url: '/message-template',
      data: {
        ...model,
      },
    }),
    'message-templates-area'
  );
};

export const getAllQuestionnarie = () => {
  return trackPromise(
    request({
      method: 'get',
      url: '/questionnaire',
    }),
    'questions-area'
  );
};

export const getAllMsgTemplates = () => {
  return trackPromise(
    request({
      method: 'get',
      url: '/message-template',
    }),
    'message-templates-area'
  );
};

export const editQuestionnarie = (id: number, model: {}) => {
  return request({
    method: 'patch',
    url: `/questionnaire/${id}`,
    data: {
      ...model,
    },
  });
};

export const editMsgTemplate = (id: number, model: {}) => {
  return request({
    method: 'patch',
    url: `/message-template/${id}`,
    data: {
      ...model,
    },
  });
};

export const deleteQuestionnarie = (id: number) => {
  return request({
    method: 'delete',
    url: `/questionnaire/${id}`,
    data: { id },
  });
};

export const deleteTemplate = (id: number) => {
  return request({
    method: 'delete',
    url: `/message-template/${id}`,
    data: { id },
  });
};

export const createProfData = (model: {}) => {
  return request({
    method: 'post',
    url: '/ability',
    data: {
      ...model,
    },
  });
};

export const editProfData = (type: string, model: {}) => {
  return request({
    method: 'patch',
    url: `/ability/${type}`,
    data: {
      ...model,
    },
  });
};

export const getActiveItems = (type: string, params: object) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/ability/${type}/?name=${params.name}&page=${params.page}&pageSize=${params.pageSize}`,
    }),
    'prof-items-area'
  );
};

export const deleteProfData = (id: number) => {
  return trackPromise(
    request({
      method: 'delete',
      url: `/ability/${id}`,
    }),
    'prof-items-area'
  );
};

export const getAllTwilio = (params?: {}) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/twilio-number/?page=${params.page}&pageSize=${params.pageSize}`,
    }),
    'purchased-twilio-numbers'
  );
};

export const getAvailableForListTwilio = (params?: {}) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/twilio-number/available?page=${params.page}&pageSize=${params.pageSize}`,
    }),
    'purchased-twilio-numbers'
  );
};

export const getAvailableTwilio = () => {
  return request({
    method: 'get',
    url: `/twilio-number/available/US`,
  });
};

export const buyTwilioNumber = (phone: string) => {
  return trackPromise(
    request({
      method: 'post',
      url: '/twilio-number',
      data: {
        number: phone,
      },
    }),
    'purchased-twilio-numbers'
  );
};

export const deleteTwilioNumber = (id: number) => {
  return trackPromise(
    request({
      method: 'delete',
      url: `/twilio-number/${id}`,
    }),
    'purchased-twilio-numbers'
  );
};
