import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import compassLogo from '../../assets/images/compass-logo-vector.svg';
import '../../assets/styles/Login.css';
import LoginForm from '../../components/login-form/LoginForm';
import RecoveryModal from '../../components/recovery-modal/RecoveryModal';
import { accountActions } from '../../features/account';
import services from '../../services';

import { Grid, Header, Image } from 'semantic-ui-react';

interface Props {
  loginAttempt: typeof accountActions.loginAttempt;
  error: string;
  isLoading: boolean;
}

interface State {}

class Login extends React.Component<Props, State> {
  public componentDidMount = () => {
    if (services.localStorage.get('accessToken')) {
      services.router.goto('/');
    }
  };

  public loginAttempt = (email: string, password: string) => {
    let redirect: any = {}

    if (window.location.search.includes('?redirect_url')) {
      const params = new URLSearchParams(window.location.search);
      redirect.url = params.get('redirect_url');
      redirect.token = params.get('token');
    }

    this.props.loginAttempt(email, password, redirect)
  }

  public render() {
    return (
      <div className="login-page">
        <div className="logo-container">
          <Image src={compassLogo} />
          <h3>
            Compass Adjusting Services Inc. is an affirmative action employer and provides equal
            employment opportunities to all employees and applicants for employment.
          </h3>
        </div>
        <Grid centered={true} style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 300 }}>
            <Header
              as="h5"
              icon={true}
              textAlign="center"
              style={{ marginTop: '1rem', fontWeight: '800', fontSize: '32px' }}
            >
              <Header.Content>Login</Header.Content>
            </Header>
            <LoginForm isLoading={this.props.isLoading} onSubmit={this.loginAttempt} />
            <hr />
            <RecoveryModal />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  error: state.account.login.error,
  isLoading: state.loading.loading.isVisible,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loginAttempt: accountActions.loginAttempt,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
