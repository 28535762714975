import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { User } from '../../models';
import services from '../../services';
import * as loadingActions from '../loading/actions';
import * as modalActions from '../modal/actions';
import * as actions from './actions';
import * as constants from './constants';

export default function* () {
  yield takeEvery(constants.CHECK_AUTHENTICATION, checkAuthentication);
  yield takeEvery(constants.LOGIN_ATTEMPT, loginAttempt);
  yield takeEvery(constants.RECOVER_ATTEMPT, recoverAttempt);
  yield takeEvery(constants.RESET_PASSWORD_ATTEMPT, resetPasswordAttempt);
  yield takeEvery(constants.FETCH_ADJUSTER_DATA_ATTEMPT, fetchAdjusterDataAttempt);
  yield takeEvery(constants.LOGIN_SUCCESS, loginSuccess);
  yield takeEvery(constants.REGISTER_ATTEMPT, registerAttempt);
  yield takeEvery(constants.REGISTER_SUCCESS, registerSuccess);
  yield takeEvery(constants.LOGOUT, logout);
  yield takeEvery(constants.DOWNLOAD_FILE, downloadFile);
  yield takeEvery(constants.DOWNLOAD_PAPERWORK, downloadPaperwork);

  yield takeEvery(constants.EMAIL_VERIFICATION_ATTEMPT, emailVerificationAttempt);

  yield takeEvery(constants.GET_ALL_FOLDERS, getAllFolders);
  yield takeEvery(constants.GET_ASSIGNED_FOLDERS, getAssignedFolders);
  yield takeEvery(constants.SAVE_SELECTED_FOLDER, saveSelectedFolders);
}

export function* checkAuthentication(action: ActionType<typeof actions.checkAuthentication>) {
  const accessToken: string = services.localStorage.get('accessToken') as string;
  if (accessToken) {
    yield put(loadingActions.show());

    const userRequest = yield call(services.api.user.getCurrentUser);
    if (userRequest.isSuccess) {
      const user = userRequest.data;
      if (userRequest.data.avatar === '' || !userRequest.data.avatar) {
        user.avatar = '';
        yield put(actions.loginSuccess(accessToken, User.create(user)));
      } else {
        const avatarCall = yield call(services.api.account.downloadFile, userRequest.data.avatar);
        if (avatarCall.isSuccess) {
          user.avatar = avatarCall.data.downloadURL;
          yield put(actions.loginSuccess(accessToken, User.create(user)));
        } else {
          user.avatar = '';
          yield put(actions.loginSuccess(accessToken, User.create(user)));
        }
      }
    } else {
      if (userRequest.isServerError()) {
        location.reload();
      } else {
        yield put(actions.logout());
      }
    }
  } else {
    yield put(actions.checkAuthenticationFinished());
  }
}

export function* logout(action: ActionType<typeof actions.logout>) {
  yield put(loadingActions.show());
  services.localStorage.remove('accessToken');
  services.router.goto(services.router.url.login);
  yield put(loadingActions.hide());
}

export function* loginAttempt(action: ActionType<typeof actions.loginAttempt>) {
  yield put(loadingActions.show());

  const tokenRequest = yield call(
    services.api.account.login,
    action.payload.email,
    action.payload.password,
    action.payload.redirect,
  );

  if (tokenRequest.isError) {
    toast.dismiss();
    toast.error('Invalid email or password.');
    yield put(loadingActions.hide());
  } else {
    services.localStorage.save('accessToken', tokenRequest.get('token'));

    const userRequest = yield call(services.api.user.getCurrentUser);

    if (userRequest.isSuccess) {
      const user = userRequest.data;
      const avatarCall = yield call(services.api.account.downloadFile, userRequest.data.avatar);
      if (avatarCall.isSuccess) {
        user.avatar = avatarCall.data.downloadURL;
        yield put(actions.loginSuccess(tokenRequest.get('token'), User.create(user)));
      } else {
        user.avatar = '';
        yield put(actions.loginSuccess(tokenRequest.get('token'), User.create(user)));
      }

      if (tokenRequest.data.redirectUrl) {
        return window.location.href = tokenRequest.data.redirectUrl;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const redirectAfterLoginPath = urlParams.get('redirectAfterLogin');
      if (redirectAfterLoginPath) {
        services.router.goto(redirectAfterLoginPath);
      } else {
        services.router.goto(services.router.url.homepage);
      }
    } else {
      yield put(actions.logout());
    }
  }
}

export function* recoverAttempt(action: ActionType<typeof actions.recoverAttempt>) {
  yield put(loadingActions.show());

  const tokenRequest = yield call(services.api.account.recover, action.payload.email);
  if (tokenRequest.isError) {
    toast.dismiss();
    toast.error('Error! ' + tokenRequest.data.errors[0].msg);
    yield put(loadingActions.hide());
  } else {
    toast.dismiss();
    toast.success('Password reset email was successfully sent!');
    yield put(loadingActions.hide());
  }
}

export function* resetPasswordAttempt(action: ActionType<typeof actions.resetPasswordAttempt>) {
  yield put(loadingActions.show());

  const request = yield call(services.api.account.reset, action.payload);
  if (request.isError) {
    toast.error(request.data.errors[0].msg);
    yield put(loadingActions.hide());
  } else {
    services.localStorage.save('accessToken', request.get('token'));

    const userRequest = yield call(services.api.user.getCurrentUser);

    if (userRequest.isSuccess) {
      const user = userRequest.data;
      const avatarCall = yield call(services.api.account.downloadFile, userRequest.data.avatar);

      if (avatarCall.isSuccess) {
        user.avatar = avatarCall.data.downloadURL;
        yield put(loadingActions.hide());
        yield put(actions.loginSuccess(request.get('token'), User.create(user)));
        services.router.goto(services.router.url.homepage);
      } else {
        user.avatar = '';
        yield put(loadingActions.hide());
        yield put(actions.loginSuccess(request.get('token'), User.create(user)));
        services.router.goto(services.router.url.homepage);
      }
    } else {
      yield put(loadingActions.hide());
      yield put(actions.logout());
    }
  }
}

export function* loginSuccess(action: ActionType<typeof actions.loginSuccess>) {
  yield put(loadingActions.hide());
}

export function* fetchAdjusterDataAttempt(
  action: ActionType<typeof actions.fetchAdjusterDataAttempt>
) {
  yield put(loadingActions.show());

  const userRequest = yield call(services.api.admin.fetchAdjusterData, action.payload);
  if (userRequest.isSuccess) {
    const skillsRequest = yield call(services.api.admin.fetchAdjusterSkills, action.payload);
    if (skillsRequest.isSuccess) {
      const deploymentRequest = yield call(
        services.api.admin.fetchAdjusterDeployment,
        action.payload
      );
      if (deploymentRequest.isSuccess) {
        const obj = Object.assign(
          {},
          { ...userRequest.data, ...skillsRequest.data, deployments: deploymentRequest.data }
        );
        yield put(actions.fetchAdjusterData(obj));
      }
    }
  }
  yield put(loadingActions.hide());
}

export function* registerAttempt(action: ActionType<typeof actions.registerAttempt>) {
  yield put(loadingActions.show());

  const tokenRequest = yield call(services.api.admin.registerAdmin, action.payload);
  if (tokenRequest.isError) {
    yield put(loadingActions.hide());
    yield put(
      modalActions.alertModalDisplayError({ message: tokenRequest.status + ' Error occured' })
    );
    yield put(actions.registerFailed(tokenRequest.getErrorList()));
  } else {
    services.localStorage.save('accessToken', tokenRequest.get('token'));
    const userRequest = yield call(services.api.user.getCurrentUser);

    if (userRequest.isSuccess) {
      const user = userRequest.data;
      const avatarCall = yield call(services.api.account.downloadFile, userRequest.data.avatar);

      if (avatarCall.isSuccess) {
        user.avatar = avatarCall.data.downloadURL;

        yield put(actions.registerSuccess(tokenRequest.get('token'), User.create(user)));
        services.router.goto(services.router.url.homepage);
      } else {
        user.avatar = '';
        yield put(actions.registerSuccess(tokenRequest.get('token'), User.create(user)));
        services.router.goto(services.router.url.homepage);
      }
    } else {
      yield put(actions.logout());
    }
  }
}

export function* registerSuccess(action: ActionType<typeof actions.registerSuccess>) {
  yield put(actions.loginSuccess(action.payload.accessToken, action.payload.user));
}

export function* emailVerificationAttempt(
  action: ActionType<typeof actions.emailVerificationAttempt>
) {
  yield put(loadingActions.show());

  const tokenRequest = yield call(
    services.api.account.emailVerificationConfirm,
    action.payload.userId,
    action.payload.verificationToken
  );

  if (tokenRequest.isError) {
    yield put(actions.emailVerificationFailed(tokenRequest.getErrorList()));
    yield put(loadingActions.hide());
  } else {
    services.localStorage.save('accessToken', tokenRequest.get('token'));

    const userRequest = yield call(services.api.user.getCurrentUser);

    if (userRequest.isSuccess) {
      const user = userRequest.data;
      const avatarCall = yield call(services.api.account.downloadFile, userRequest.data.avatar);

      if (avatarCall.isSuccess) {
        user.avatar = avatarCall.data.downloadURL;
        yield put(actions.loginSuccess(tokenRequest.get('token'), User.create(user)));
        services.router.goto(services.router.url.homepage);
        yield put(
          modalActions.alertModalDisplaySuccess({
            message: 'Your email was successfully verified.',
          })
        );
      } else {
        user.avatar = '';
        yield put(actions.loginSuccess(tokenRequest.get('token'), User.create(user)));
        services.router.goto(services.router.url.homepage);
        yield put(
          modalActions.alertModalDisplaySuccess({
            message: 'Your email was successfully verified.',
          })
        );
      }
    } else {
      yield put(actions.logout());
    }
  }
}

function* downloadPaperwork(action: ActionType<typeof actions.downloadFile>) {
  const download = yield call(
    services.api.file.downloadPaperwork,
    action.payload.file.id,
    action.payload.file.name
  );
  if (download.error) {
    toast.dismiss();
    toast.error('Error on download attempt!');
  }
}

function* downloadFile(action: ActionType<typeof actions.downloadFile>) {
  const { file } = action.payload;

  try {
    const fileUrl = yield call(services.api.file.getDownloadFileUrl, file.key);
    console.log(fileUrl);
    if (!fileUrl) {
      toast.error(`Failed to get file download url for "${file.name}"!`);
      return;
    }
    console.log(action.payload);
    services.api.file.downloadFile(fileUrl, file.name);
  } catch (err) {
    console.error('downloadFinanceSaga err', err);
    toast.error(`Failed to download file "${file.name}"!`);
  }
}

export function* getAllFolders(action: ActionType<typeof actions.getAllFolders>) {
  const requestList = yield call(services.api.folder.getFolderList);
  if (requestList.isSuccess) {
    yield put(actions.setAllFolders(requestList.data));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* getAssignedFolders(action: ActionType<typeof actions.getAssignedFolders>) {
  const { adjusterId } = action.payload;
  const requestList = yield call(services.api.folder.getAssignedFolderForAdjuster, { id: adjusterId });
  if (requestList.isSuccess) {
    const set = new Set(requestList.data.data);
    yield put(actions.setAssignedFolders(Array.from(set)));
    yield put(actions.setSelectedFolders(Array.from(set)));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* saveSelectedFolders(action: ActionType<typeof actions.saveSelectedFolders>) {
  const { data, adjusterId } = action.payload;
  const requestList = yield call(services.api.folder.saveSelectedFolder, { id: adjusterId, data: data });
  if (requestList.isSuccess) {
    toast.success(`Assigned folder for adjuster has been updated!`);
    yield put(actions.setAssignedFolders(data));
    yield put(actions.setSelectedFolders(data));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}
