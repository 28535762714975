import classnames from 'classnames';
import React from 'react';
import { Dropdown, DropdownOnSearchChangeData, Image } from 'semantic-ui-react';
import noAvatar from '../../assets/images/no-avatar.svg';
import './styles.css';

interface Props {
  value: any;
  className: string;
  options: object[];
  disabled?: boolean;
  loading?: boolean;
  onChange: (value: any) => void;
  onSearchChange?: (value: any) => void;
  onClose?: () => void;
}

interface State {}

class PersonSelectionInput extends React.Component<Props, State> {
  public static defaultProps = {
    value: null,
    options: [],
    disabled: false,
  };

  public handleChange = (event: any, data: any) => {
    const { value } = data;
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  public onSearchChange = (event: any, data: DropdownOnSearchChangeData) => {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(data.searchQuery);
    }
  };

  public onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  public render() {
    const { className, options, value, disabled } = this.props;
    const avatar = this.getSelectedPerson().image.src;
    return (
      <div className={classnames('personselection-container', className)}>
        <Image className="person-avatar" avatar={true} src={avatar} />
        <Dropdown
          lazyLoad={true}
          inline={true}
          className="personselection-input"
          search={true}
          selection={true}
          options={options}
          onChange={this.handleChange}
          defaultValue={value}
          disabled={disabled}
          onSearchChange={this.onSearchChange}
          loading={this.props.loading}
          onClose={this.onClose}
        />
      </div>
    );
  }

  private getSelectedPerson(): any {
    const { value, options } = this.props;

    const option = options.find((p: any) => p.value === value);
    if (!option || !options.length) {
      return { image: { src: noAvatar } };
    }

    return option;
  }
}

export default PersonSelectionInput;
