import React from 'react';
import { Confirm, Image } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import './styles.css';

interface Props {
  visible: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

interface State {}

class ConfirmModal extends React.Component<Props, State> {
  public render() {
    const { onClose, visible, message, onConfirm } = this.props;

    return (
      <Confirm
        size="tiny"
        closeIcon={<Image src={closeIcon} className="icon" onClick={onClose} />}
        open={visible}
        onCancel={onClose}
        header="Confirm"
        content={message}
        cancelButton="NO"
        confirmButton="YES"
        onConfirm={onConfirm}
        className="confirm-modal-custom"
        closeOnDimmerClick={false}
      />
    );
  }
}

export default ConfirmModal;
