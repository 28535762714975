import c from 'classnames';
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { Button, Checkbox } from 'semantic-ui-react';
import '../styles.css';

const Content = ({
  options,
  closePopup,
  active,
  $active,
  name,
  dataSource,
  $dataSource,
  setFieldBool,
  onApply,
  onChange,
  resetFieldBool,
  filterLogs,
  filter,
}) => {
  const getClass = customClass => c('btn', customClass, { active: active === customClass });
  Content.handleClickOutside = () => closePopup();

  const setActiveHeader = tab => {
    $active(tab);

    if (dataSource.text) {
      const obj = { ...dataSource, value: !dataSource.value };
      $dataSource(obj);
      setFieldBool(name, obj);
    }
  };

  return (
    <div className="wrapperContentMultiple">
      <div className="header flex">
        <div onClick={() => setActiveHeader('include')} className={getClass('include')}>
          include
        </div>
        <div onClick={() => setActiveHeader('exclude')} className={getClass('exclude')}>
          exclude
        </div>
      </div>

      <div className="body">
        {options.map(i => (
          <div className="item" key={i.value}>
            <Checkbox
              label={i.text}
              onChange={() => onChange(i)}
              checked={dataSource.text ? true : false}
            />
          </div>
        ))}
      </div>

      <div className="actions flex">
        <Button
          primary={true}
          disabled={!dataSource.text}
          className="btn btnApply"
          onClick={() => {
            onApply();
            closePopup();
          }}
        >
          apply
        </Button>
        <div
          className="btn btnCancel"
          onClick={() => {
            $dataSource({});
            const dataModel = { ...Object.assign({}, filter), [name]: '' };
            filterLogs(dataModel);
            resetFieldBool();
          }}
        >
          clear
        </div>
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Content.handleClickOutside,
};

export default onClickOutside(Content, clickOutsideConfig);
