import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { uploads } from '../../config';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.GET_ADMIN_LIST, getAdminList);
  yield takeEvery(constants.GET_ALL_QUESTIONNARIES, getAllQuestionnaries);
  yield takeEvery(constants.GET_ALL_MSG_TEMPLATES, getAllMsgTemplates);
}

export function* getAdminList(action: ActionType<typeof actions.getAdminList>) {
  const listRequest = yield call(services.api.admin.getAdminList, action.payload.params);
  if (listRequest.isSuccess) {
    yield put(actions.resetAdminList());
    const admins = listRequest.data.result.map((admin: any) => {
      if (admin.avatar === '' || !admin.avatar) {
        return admin;
      } else {
        return {
          ...admin,
          avatar: uploads.s3BucketAddress + admin.avatar,
        };
      }
    });
    yield put(actions.getAdminListSuccess(admins, listRequest.data.count));
  } else {
    toast.dismiss();
    toast.error('Error! ' + listRequest.data.errors[0].msg);
  }
}

export function* getAllQuestionnaries(action: ActionType<typeof actions.getAllQuestionnarie>) {
  yield put(actions.saveQuestionnaries([]));

  const questionsRequest = yield call(services.api.settings.getAllQuestionnarie);
  if (questionsRequest.isSuccess) {
    yield put(actions.saveQuestionnaries(questionsRequest.data.list));
  } else {
    toast.dismiss();
    toast.error('Error! ' + questionsRequest.data.errors[0].msg);
  }
}

export function* getAllMsgTemplates(action: ActionType<typeof actions.getAllMsgTemplates>) {
  const msgTemplateRequest = yield call(services.api.settings.getAllMsgTemplates);

  if (msgTemplateRequest.isSuccess) {
    yield put(actions.saveMsgTemplates(msgTemplateRequest.data));
  } else {
    toast.dismiss();
    toast.error('Error! ' + msgTemplateRequest.data.errors[0].msg);
  }
}
