import { isObject } from 'lodash';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Pagination, Dropdown } from 'semantic-ui-react';
import { standbyActions } from '../../features/standby';
import services from '../../services';
import * as helper from '../../utils/helper';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import StandbyCreatedMessage from './modals/StandbyCreatedMessage';
import StandbyInviteModal from './modals/StandbyInviteModal';
import SBDetailsHeader from './SBDetailsHeader';
import SBDetailsTable from './SBDetailsTable';
import SBDetailsTableHeader from './SBDetailsTableHeader';
import SBMessageItem from './SBMessageItem';
import TwilioModal from './TwilioModal';
import MapView from '../adjuster-list/MapView';

interface Props {
  activeStandbyId: any;
  standbyAdjusters: object[];
  standbyList: object[];
  editStandbyList: typeof standbyActions.editStandbyList;
  export: typeof standbyActions.exportStandbyAdjusters;
  exportDetails: typeof standbyActions.exportStandbyAdjustersApplied;
  getStandbyAdjusters: typeof standbyActions.getStandbyAdjusters;
  getMapStandbyAdjusters: typeof standbyActions.getMapStandbyAdjusters;
  getStandbyMessages: typeof standbyActions.getStandbyMessages;
  showInviteModal: typeof standbyActions.showInviteModal;
  setStandbyAdjustersStatus: typeof standbyActions.setStandbyAdjustersStatus;
  setStandbyAdjustersPage: typeof standbyActions.setStandbyAdjustersPage;
  setStandbyAdjustersPerPage: typeof standbyActions.setStandbyAdjustersPerPage;
  showTwilioModalFunc: typeof standbyActions.showTwilioModal;
  setStandbyMessagesSearch: typeof standbyActions.setStandbyMessagesSearch;
  setStandbyMessagesPage: typeof standbyActions.setStandbyMessagesPage;
  setInviteMode: typeof standbyActions.setInviteMode;
  showTwilioModal: boolean;
  msgObj: any;
  messages: object[];
  user: any;
  totalMessages: number;
  activePage: number;
  activeAdjPage: number;
  adjustersPerPage: number;
  distances: object;
  mapAdjusters: object[];
}

interface State {
  confirmModal: boolean;
  activeList: object;
  activeTab: string;
  adjusters: object[];
  messages: any;
  listView: boolean;
}

class SBDetails extends React.Component<Props, State> {
  public state = {
    confirmModal: false,
    activeList: {},
    activeTab: 'Applied',
    adjusters: [],
    messages: [],
    listView: true,
  };

  public changeListView = (show: boolean) => {
    this.setState({ listView: show }, () => {
      this.getAdjusters(location.hash !== '' ? JSON.parse(decodeURI(location.hash.slice(1))).filter : undefined)
    });
  };

  public componentDidMount = () => {
    this.updateData();
    this.setState({ adjusters: this.props.standbyAdjusters, messages: this.props.messages });
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.standbyAdjusters !== this.props.standbyAdjusters) {
      this.setState({ adjusters: this.props.standbyAdjusters });
    }
    if (prevProps.messages !== this.props.messages) {
      this.setState({ messages: this.props.messages });
    }
  };

  public updateData = () => {
    this.selectActiveList();
  };

  public getMessages = async () => {
    this.props.getStandbyMessages(this.props.match.params.id);
  };

  public getAdjusters = async (filter?: object) => {
    if (this.state.listView) {
      this.props.getStandbyAdjusters(this.props.match.params.id, filter);
    } else {
      this.props.getMapStandbyAdjusters(this.props.match.params.id, filter);
    }
  };

  public selectActiveList = async () => {
    const response = await services.api.standby.getStandbyListDetails(this.props.match.params.id);
    if (response.isSuccess) {
      this.setState({ activeList: response.data }, () =>
        this.getActiveTab(
          this.state.activeTab,
          location.hash !== '' ? JSON.parse(decodeURI(location.hash.slice(1))).filter : undefined
        )
      );
    } else {
      toast.dismiss();
      toast.error(response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`);
    }
  };

  public getActiveTab = (name: string, filter?: object) => {
    if (name !== 'Messages') {
      this.props.setStandbyAdjustersStatus(name);
      this.setState({ activeTab: name }, () => {
        let newFilterProps: { [key: string]: any } = { ...filter };

        for (const key in newFilterProps) {
          if (newFilterProps[key] === '') {
            delete newFilterProps[key];
          } else if (key === 'standbyListDeployments' || key === 'eventDeployments') {
            if (isObject(newFilterProps[key][0])) {
              newFilterProps[key] = newFilterProps[key].map((f: any) =>
                f === 'exclude' ? 'exclude' : f.id
              );
            }
          }
        }

        const keys = [
          'state',
          'licenseStates',
          'certificationTypes',
          'experience',
          'position',
          'equipment',
          'expertise',
          'softwareSkill',
          'catStateProvince',
          'language',
          'registrationType',
        ];

        for (const key of keys) {
          if (newFilterProps[key]) {
            newFilterProps = {
              ...newFilterProps,
              [key]: newFilterProps[key],
            };
          }
        }

        if (this.state.listView) {
          this.props.getStandbyAdjusters(this.props.match.params.id, newFilterProps);
        } else {
          this.props.getMapStandbyAdjusters(this.props.match.params.id, newFilterProps);
        }
      });
    } else {
      this.setState({ activeTab: name }, this.getMessages);
    }
  };

  public handleExport = async () => {
    const response = await services.api.finance.exportAdjustersStandbyList(
      this.props.match.params.id
    );
    if (response.isError) {
      console.log(response);
    }
  };

  public handlePagination = (e: any, data: any) => {
    this.props.setStandbyMessagesPage(data.activePage);
    this.getMessages();
  };

  public resetPaginationPage = () => {
    const {
      activePage,
      activeAdjPage,
      setStandbyAdjustersPage,
      setStandbyMessagesPage,
    } = this.props;
    if (activeAdjPage !== 1) {
      setStandbyAdjustersPage(1);
    }
    if (activePage !== 1) {
      setStandbyMessagesPage(1);
    }
  };

  public setAdjustersPerPage = (perPage: number, filter: any) => {
    this.resetPaginationPage();
    this.props.setStandbyAdjustersPerPage(perPage);
    this.props.getStandbyAdjusters(this.props.match.params.id, filter);
  };

  public handleItemsPerPageChange = (e: any, { value }: any) => {
    this.setAdjustersPerPage(value, this.parseFilters(this.state.filterState));
  };

  public render() {
    const { activeList, activeTab, adjusters, messages, listView } = this.state;
    const { activePage, setStandbyAdjustersPage } = this.props;
    const totalPages = helper.getTotalPage(this.props.totalAdjusters, 10);
    const filter = location.hash !== '' ? JSON.parse(decodeURI(location.hash.slice(1))).filter : {}

    console.log('SBDetails 194', adjusters);
    return (
      <>
        <div className="standby-details-page">
          <SBDetailsHeader
            showModal={this.props.showInviteModal}
            user={this.props.user}
            item={activeList}
            editList={this.props.editStandbyList}
            match={this.props.match}
            updateList={this.updateData}
          />
          <SBDetailsTableHeader
            listView={listView}
            toogleView={this.changeListView}
            hasUnread={activeList.hasUnreadMessages}
            getTab={this.getActiveTab}
            id={this.props.match.params.id}
            export={this.props.export}
            exportDetails={this.props.exportDetails}
            disableExport={adjusters && adjusters.length === 0}
            adjustersPerPage={this.props.adjustersPerPage}
            getAdjusters={this.getAdjusters}
            setAdjustersPerPage={this.setAdjustersPerPage}
            searchMessages={this.props.setStandbyMessagesSearch}
            updateMessages={this.getMessages}
            resetActivePage={this.resetPaginationPage}
            resetMessageActivePage={this.resetPaginationPage}
          />
          <div>
            <LoadingSpiner area="details-adjusters-table" />
            {activeTab === 'Messages' && (
              <div>
                {messages && messages.length !== 0 ? (
                  messages.map((el: any, index: number) => {
                    return (
                      <SBMessageItem standby={this.props.match.params.id} item={el} key={index} />
                    );
                  })
                ) : (
                  <NoResults />
                )}

                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <div className="pagination-items-counter">
                    <Dropdown
                      inline={true}
                      options={[
                        { text: '20', value: 20 },
                        { text: '50', value: 50 },
                        { text: '100', value: 100 },
                        { text: '200', value: 200 },
                      ]}
                      defaultValue={this.props.adjustersPerPage}
                      onChange={this.handleItemsPerPageChange}
                    />
                  </div>
                  {totalPages > 1 && (
                    <Pagination
                      totalPages={2}
                      boundaryRange={1}
                      activePage={activePage}
                      ellipsisItem={null}
                      firstItem={null}
                      lastItem={null}
                      prevItem={
                        activePage === 1
                          ? false
                          : {
                              'aria-label': 'Previous item',
                              content: 'PREVIOUS',
                            }
                      }
                      nextItem={
                        activePage === totalPages
                          ? false
                          : {
                              'aria-label': 'Next item',

                              content: 'NEXT',
                            }
                      }
                      siblingRange={1}
                      onPageChange={this.handlePagination}
                    />
                  )}
                </div>
              </div>
            )}
            {(listView ? (
                activeTab !== 'Messages' && (!adjusters || adjusters.length === 0) ?
                <NoResults /> :
                <SBDetailsTable
                  setStandbyAdjustersPage={setStandbyAdjustersPage}
                  setAdjustersPerPage={this.setAdjustersPerPage}
                  getAdjusters={this.getAdjusters}
                  activeList={activeList}
                  activeTab={activeTab}
                  standbyId={activeList.id}
                  adjusters={adjusters}
                  updateData={this.updateData}
                />
              )
            :
              <MapView
                key={activeTab}
                adjustersMap={this.props.mapAdjusters}
                eventLocation={filter.eventLocation}
                // eventLocationCoords={this.props.eventLocationCoords}
                distances={this.props.distances}
              />
            )}
          </div>
        </div>
        <StandbyCreatedMessage standby={activeList} />
        <StandbyInviteModal standby={activeList} global={false} updateData={this.updateData} />
        <TwilioModal
          stbId={this.props.match.params.id}
          show={this.props.showTwilioModal}
          showModal={this.props.showTwilioModalFunc}
          showInviteModal={this.props.showInviteModal}
          msgObj={this.props.msgObj}
          updateData={this.updateData}
        />
      </>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  activeStandbyId: state.standby.standby.activeStandbyId,
  standbyList: state.standby.standby.standbyList,
  standbyAdjusters: state.standby.adjusters.adjusters,
  showTwilioModal: state.standby.standby.showTwilioModal,
  msgObj: state.standby.inviteModal.msgObj,
  messages: state.standby.messages.messages,
  totalMessages: state.standby.messages.totalMessages,
  activePage: state.standby.messages.activePage,
  activeAdjPage: state.standby.adjusters.activePage,
  adjustersPerPage: state.standby.adjusters.adjustersPerPage,
  user: state.account.account.user,
  distances: state.adjusters.adjusters.distances,
  mapAdjusters: state.standby.adjusters.mapAdjusters,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editStandbyList: standbyActions.editStandbyList,
      getStandbyAdjusters: standbyActions.getStandbyAdjusters,
      getMapStandbyAdjusters: standbyActions.getMapStandbyAdjusters,
      getStandbyMessages: standbyActions.getStandbyMessages,
      setStandbyAdjustersPage: standbyActions.setStandbyAdjustersPage,
      setStandbyAdjustersPerPage: standbyActions.setStandbyAdjustersPerPage,
      setStandbyAdjustersStatus: standbyActions.setStandbyAdjustersStatus,
      showInviteModal: standbyActions.showInviteModal,
      showTwilioModalFunc: standbyActions.showTwilioModal,
      setStandbyMessagesSearch: standbyActions.setStandbyMessagesSearch,
      setStandbyMessagesPage: standbyActions.setStandbyMessagesPage,
      setInviteMode: standbyActions.setInviteMode,
      export: standbyActions.exportStandbyAdjusters,
      exportDetails: standbyActions.exportStandbyAdjustersApplied,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SBDetails);
