import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';
import { PaginationInfo } from './trainingCenterReducer';

export default function* () {
  yield takeEvery(constants.GET_FOLDER_LIST, getFolderList);
  yield takeEvery(constants.SET_FOLDER_ACTIVE, setFolderActive);
  yield takeEvery(constants.CREATE_FOLDER, createFolder);
  yield takeEvery(constants.EDIT_FOLDER, editFolder);
  yield takeEvery(constants.UPDATE_FOLDER_POSITION, updateFolderPosition);
  yield takeEvery(constants.DELETE_FOLDER, deleteFolder);
  yield takeEvery(constants.SHOW_ASSIGN_ACCESS_MODAL, getAssignAccess);
  yield takeEvery(constants.ASSIGN_ACCESS_SEARCH, searchAssignAccess);
  yield takeEvery(constants.SAVE_ASSIGN_ACCESS, saveAssignAccess);
  yield takeEvery(constants.GET_CUSTOM_EVENT, getCustomEventList);

  //Access
  yield takeEvery(constants.SEARCH_ACCESS, searchAccess);
}

export function* getFolderList(_: ActionType<typeof actions.getFolderList>) {
  const requestList = yield call(services.api.folder.getFolderList);
  if (requestList.isSuccess) {
    yield put(actions.setFolderList(requestList.data));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* setFolderActive(action: ActionType<typeof actions.setFolderActive>) {
  const folder = action.payload.folder;
  let folderIds = folder.id.toString();
  if (folder.children.length > 0) {
    folderIds = folderIds + ',' + folder.children.map((f) => f.id).join(',');
  }

  const requestList = yield call(
    services.api.material.searchMaterial,
    { activePage: 1, pageSize: 15 } as PaginationInfo,
    { folderIds }
  );
  if (requestList.isSuccess) {
    yield put(
      actions.setMaterialSearchResult(requestList.data.data.list, requestList.data.data.count)
    );
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* createFolder(action: ActionType<typeof actions.createFolder>) {
  const { formData } = yield select((state) => state.trainingCenter.createFolder);
  const response = yield call(services.api.folder.createFolder, formData);
  if (response.isSuccess) {
    if (formData.parentId != null) {
      toast.success(`SubFolder ${formData.name} was successfully created!`);
    } else {
      toast.success(`Folder ${formData.name} was successfully created!`);
    }
    yield put(actions.getFolderList());
    yield put(actions.toggleCreateFolderModal());
  } else {
    toast.dismiss();
    toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}

export function* updateFolderPosition(action: ActionType<typeof actions.updateFolderPosition>) {
  const { folderID, position } = action.payload;

  yield put(actions.setFolderList([]));

  const response = yield call(services.api.folder.updateFolderPosition, folderID, position);
  if (response.isSuccess) {
    toast.success(`Folder position was successfully updated!`);
    yield put(actions.getFolderList());
  } else {
    toast.dismiss();
    toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}

export function* editFolder(action: ActionType<typeof actions.editFolder>) {
  const { formData } = yield select((state) => state.trainingCenter.editFolder);
  const response = yield call(services.api.folder.editFolder, formData);
  if (response.isSuccess) {
    if (formData.parentId != null) {
      toast.success(`SubFolder ${formData.name} was successfully updated!`);
    } else {
      toast.success(`Folder ${formData.name} was successfully updated!`);
    }

    yield put(actions.getFolderList());
    yield put(actions.toggleEditFolderModel());
  } else {
    toast.dismiss();
    toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}

export function* deleteFolder(action: ActionType<typeof actions.deleteFolder>) {
  const { folder } = yield select((state) => state.trainingCenter.deleteFolder);
  const response = yield call(services.api.folder.deleteFolder, folder);

  console.log(response)
  if (response.isSuccess) {
    const { pagingInfo } = yield select((state) => state.trainingCenter.material);
    yield put(actions.searchMaterial({ ...pagingInfo, resetSelect: true }));

    if (folder.parentId != null) {
      toast.success(`SubFolder ${folder.name} was successfully deleted!`);
    } else {
      toast.success(`Folder ${folder.name} was successfully deleted!`);
    }

    yield put(actions.getFolderList());
    yield put(actions.toggleDeleteFolderModel());
  } else {
    // toast.dismiss();
    toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}

export function* getAssignAccess(action: ActionType<typeof actions.showAssignAccessModal>) {
  const { folderId } = yield select((state) => state.trainingCenter.assignAccess);

  const response = yield call(services.api.folder.getAssignedAdjusterFoFolder, { id: folderId });
  if (response.isSuccess) {
    yield put(actions.setAssignedAdjusters(response.data.data));
  }
  const responseCustomEvents = yield call(services.api.folder.getAssignedCustomEvents, {
    id: folderId,
  });
  if (responseCustomEvents.isSuccess) {
    yield put(actions.setAssignedCustomEvents(responseCustomEvents.data.data));
  }
}

export function* saveAssignAccess(action: ActionType<typeof actions.saveAssignedAccess>) {
  const { selectedAdjusters, selectedCustomEvents, folderId } = yield select(
    (state) => state.trainingCenter.assignAccess
  );
  const response = yield call(services.api.folder.assignAccessToAdjusterAndEvents, {
    folderId: folderId,
    adjusters: selectedAdjusters,
    events: selectedCustomEvents,
  });
  if (response.isSuccess) {
    toast.success(`Assigned Access is saved!`);
    yield put(actions.hideAssignAccessModal());
  } else {
    toast.dismiss();
    toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}

export function* searchAssignAccess(action: ActionType<typeof actions.searchAssignAccess>) {
  const filters = action.payload.filters;
  const { activeTab, pagingInfo } = yield select((state) => state.trainingCenter.assignAccess);
  const currentPaging = pagingInfo[activeTab];
  console.log("filtering saga", pagingInfo, filters)
  if (activeTab === 'Adjusters') {
    const apiProps: { [key: string]: any } = {
      search: currentPaging.search,
      page: currentPaging.activePage - 1,
      pageSize: currentPaging.pageSize,
      status: 'Active',
      isAdmin: false,
      ...filters
    };

    const adjRequest = yield call(
      services.api.adjuster.fetchAdjusterListCustom,
      apiProps,
      'invite-modal-users'
    );

    if (adjRequest.isError) {
      toast.dismiss();
      toast.error(
        adjRequest.data.errors ? adjRequest.data.errors[0].msg : `Error ${adjRequest.status}`
      );
    }
    if (adjRequest.isSuccess) {
      yield put(actions.setAssignAccessSearchResult(adjRequest.data.list, adjRequest.data.count));
    }

    if (currentPaging.activePage === 1) {
      apiProps.noPagination = true;

      const apiAllRequest = yield call(
        services.api.adjuster.fetchAdjusterListCustom,
        apiProps,
        'invite-modal-users'
      );

      yield put(actions.setAllSearchResult(apiAllRequest.data.list));
    }
    return;
  }
  if (activeTab === 'CustomEvents') {
    const apiProps: { [key: string]: any } = {
      name: currentPaging.search,
      page: currentPaging.activePage - 1,
      pageSize: currentPaging.pageSize,
    };

    const eventRequest = yield call(services.api.event.fetchEvent, apiProps);

    if (eventRequest.isError) {
      toast.dismiss();
      toast.error(
        eventRequest.data.errors ? eventRequest.data.errors[0].msg : `Error ${eventRequest.status}`
      );
    }
    if (eventRequest.isSuccess) {
      yield put(
        actions.setAssignAccessSearchResult(
          eventRequest.data.data.list,
          eventRequest.data.data.count
        )
      );
    }

    if (currentPaging.activePage === 1) {
      const apiAllRequest = yield call(services.api.event.getAllEvents);

      yield put(actions.setAllSearchResult(apiAllRequest.data.list));
    }
  }
}

export function* searchAccess(action: ActionType<typeof actions.searchAccess>) {
  const { pagingInfo } = action.payload;

  const { filter } = pagingInfo;
  filter.search = pagingInfo.search;

  const requestList = yield call(services.api.material.searchAccess, pagingInfo, filter);
  if (requestList.isSuccess) {
    yield put(
      actions.setAccessSearchResult(requestList.data.data.list, requestList.data.data.count)
    );
    if (pagingInfo.resetSelect) {
      // search all with no pagination = false
      filter.noPagination = false;
      const apiAllRequest = yield call(services.api.material.searchAccess, pagingInfo, filter);
      yield put(actions.setAccessAllSearchResult(apiAllRequest.data.data.list));
    }
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}

export function* getCustomEventList(action: ActionType<typeof actions.getCustomEventList>) {
  const requestList = yield call(services.api.folder.getCustomEventList, action.payload.search);
  if (requestList.isSuccess) {
    yield put(actions.setCustomEventList(requestList.data));
  } else {
    toast.dismiss();
    toast.error(requestList.data ? requestList.data.errors[0].msg : `Error ${requestList.status}`);
  }
}
