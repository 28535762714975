import { ok } from 'assert';
import { trackPromise } from 'react-promise-tracker';
import { ApiResponse, request } from './base';

export const assignNumber = (numbers: object[], standbyListId: any) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/standby-list/${standbyListId}/assign-numbers`,
      data: {
        numbers,
      },
    }),
    'standby-area'
  );
};

export const getStandbyList = (model: any, sort: any, closed: string, search?: string) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/standby-list/list?page=${model.page}&pageSize=${10}`,
      data: {
        order: { ...sort },
        title: search === '' ? undefined : search,
      },
      params: {
        isClosed: closed === 'Active' ? undefined : true,
      },
    }),
    'standby-list'
  );
};

export const getStandbyListNoteAdjuster = (model: any) => {
  return trackPromise(
    request({
      method: 'post',
      url: `/standby-list/list/${model.id}`,
      data: {
        onlyWhenNoteExist: true,
      }
    }),
    'standby-list-note-adjuster'
  );
};

export const getAdjusterAnswers = (standbyId: any, adjusterId: any) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/standby-list/${standbyId}/answers/${adjusterId}`,
    }),
    'standby-list'
  );
};

export const createStandby = (model: any) => {
  return request({
    method: 'post',
    url: '/standby-list',
    data: {
      ...model,
    },
  });
};

export const editStandby = (model: any, id: any) => {
  return request({
    method: 'patch',
    url: `/standby-list/${id}`,
    data: {
      ...model,
    },
  });
};

export const editStandbyDeploy = (data: any, id: any, userId: any) => {
  return request({
    method: 'post',
    url: `/standby-list/${id}/deployment-status/${userId}`,
    data,
  });
};

export const reopenStandby = (model: any, id: any) => {
  return request({
    method: 'post',
    url: `/standby-list/${id}/reopen`,
    data: {
      ...model,
    },
  });
};

export const closeStandby = (id: any) => {
  return request({
    method: 'patch',
    url: `/standby-list/${id}/close`,
  });
};

export const deleteStandby = (id: any) => {
  return request({
    method: 'delete',
    url: `/standby-list/${id}`,
  });
};

export const getStandbyAdjusters = (
  id: any,
  page: number,
  inviteModal: boolean,
  status?: any,
  filter?: any,
  spinnerArea?: string,
  pageSize?: number
) => {
  // tslint:disable-next-line
  pageSize = pageSize == null ? 20 : pageSize;
  return trackPromise(
    request({
      method: 'get',
      url: `/standby-list/${id}/adjusters?page=${page}&pageSize=${pageSize}`,
      params: {
        status,
        unreadStatus: 1,
        ...filter,
      },
    }),
    spinnerArea ? spinnerArea : 'details-adjusters-table'
  );
};

export const getMapStandbyAdjuster = async (
  id: any,
  page: number,
  status?: any,
  filter?: any,
) => {
  const response = await request({
    method: 'get',
    url: `/standby-list/${id}/adjusters?page=${page}&pageSize=${100}`,
    params: {
      status,
      unreadStatus: 1,
      ...filter,
    },
  });

  return response;
};

export const getMapStandbyAdjusters = async (
  id: any,
  status?: any,
  filter?: any,
  spinnerArea?: string,
) => {
  let page = 0;
  let adjusters: any[] = [];

  while (true) {
    const response = await trackPromise(
      getMapStandbyAdjuster(
        id,
        page,
        status,
        filter,
      ),
      spinnerArea ? spinnerArea : 'details-adjusters-table'
    );
    if (!response.isSuccess || response.data.count === 0) {
      return response;
    }

    if (!response.data.list || response.data.list.length === 0) {
      break;
    }

    adjusters = [...adjusters, ...response.data.list];
    if (adjusters.length === response.data.count) {
      break;
    }

    page++;
  }

  return {
    isSuccess: true,
    data: {
      count: adjusters.length,
      list: adjusters,
    }
  }
};

export const getStandbyAdjustersNoPagination = (params: object) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/standby-list/${params.id}/adjusters`,
      params: {
        ...params,
        unreadStatus: 1,
      },
    }),
    'invite-modal-users'
  );
};

export const markContacted = (standbyId: any, adjusterId: any, note: string, status?: string) => {
  return request({
    method: 'patch',
    url: `/standby-list/${standbyId}/note/${adjusterId}`,
    data: {
      note,
      status,
    },
  });
};

export const deleteStandbyNote = (standbyId: any, adjusterId: any, noteIndex: number) => {
  return request({
    method: 'delete',
    url: `/standby-list/${standbyId}/note/${adjusterId}`,
    data: {
      noteIndex,
    },
  });
};

export const deleteNote = (standbyId: any, noteIndex: number) => {
  return request({
    method: 'delete',
    url: `/adjuster/${standbyId}/notes/${noteIndex}`,
    data: {
      noteIndex,
    },
  });
};

export const getStandbyListDetails = (id: any) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/standby-list/${id}`,
    }),
    'standby-list'
  );
};
