import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import services from '../services';

interface PrivateRouteProps {
  isAuthenticated: boolean;
  path: string;
  component: any;
  exact?: boolean;
}

class PrivateRouteClass extends React.Component<PrivateRouteProps> {
  public render() {
    const { isAuthenticated, component, path, exact } = this.props as any;
    const Component = component;

    const redirectRouteIfNoAuth = '/login?redirectAfterLogin=' + window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    const redirectURL = params.get('redirect_url');
    const token = params.get('token');

    if(redirectURL && token && isAuthenticated){
      // services.router.goto(redirectURL)
      window.location.href = redirectURL.includes('http') ? redirectURL : `https://${redirectURL}`
    }

    return (
      <Route
        path={path}
        exact={exact}
        render={props => {
          return isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            services.router.goto(redirectRouteIfNoAuth)
          );
        }}
      />
    );
  }
}

const stp = (state: MyTypes.RootState) => ({
  isAuthenticated: state.account.account.isAuthenticated,
});

const PrivateRoute = connect(stp)(PrivateRouteClass);

export { PrivateRouteClass, PrivateRoute };
