import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { uploads } from '../../config';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.GET_DASHBOARD_ADJUSTERS, getDashboardAdjusters);
}

export function* getDashboardAdjusters(action: ActionType<typeof actions.getDashboardAdjusters>) {
  const response = yield call(services.api.dashboard.fetchDashboardData, 13);
  if (response.isSuccess) {
    yield put(actions.submitDashboardData(response.data));
  } else {
    toast.dismiss();
    toast.error(response.data.errors ? response.data.errors[0].msg : `Error ${response.status}`);
  }
}
