import MyTypes from 'MyTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid } from 'semantic-ui-react';
import '../../assets/styles/Dashboard.css';
import services from '../../services';

interface Props {
  activeAdj: number;
  pendingAdj: number;
  inactiveAdj: number;
  setTab: (name: string) => void;
}

interface State {}

class DashboardHeader extends React.Component<Props, State> {
  public render() {
    return (
      <Grid stackable={true} divided={true} columns={4} className="dashboard-header-grid">
        <Grid.Row>
          <Grid.Column width={5}>
            <span className="dashboard-title">Active Adjusters</span>
            <span style={{ fontSize: '32px', color: '#28cb0a' }}>{this.props.activeAdj}</span>
          </Grid.Column>
          <Grid.Column width={5}>
            <span className="dashboard-title">Inactive Adjusters</span>
            <span style={{ fontSize: '32px', color: '#8497b0' }}>{this.props.inactiveAdj}</span>
          </Grid.Column>
          <Grid.Column width={4}>
            <span className="dashboard-title">Pending Adjusters</span>
            <span style={{ fontSize: '32px', color: '#ff2626' }}>{this.props.pendingAdj}</span>
          </Grid.Column>
          <Grid.Column width={2} className="dashboard-header-action-column">
            <Button
              color="blue"
              onClick={() => {
                this.props.setTab('All');
                services.router.goto('/adjusters');
              }}
            >
              View all
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardHeader);
