import React from 'react';
import GroupMessage from '../message-layout/GroupMessage';

class SBMessageDetails extends React.Component {
  public render() {
    return (
      <div>
        <GroupMessage match={this.props.match} />
      </div>
    );
  }
}

export default SBMessageDetails;
