import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Modal, Segment } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import noAvatar from '../../../assets/images/no-avatar.svg';
import ErrorLabel from '../../../components/error-label/ErrorLabel';
import { uploads } from '../../../config';
import services from '../../../services';

interface Props {
  user: object;
  show: boolean;
  showContactedModal: (show: boolean) => void;
  standbyId: any;
  status: string;
  updateData: () => void;
  customTitle?: string;
  fullUrlAvatar?: boolean;
}

interface State {}

class ContactedModal extends React.Component<Props, State> {
  public state = {};

  public render() {
    const errorLabel = <ErrorLabel />;
    const { user, status } = this.props;
    console.log({ user });
    return (
      <Modal
        className="mark-contacted-modal"
        open={this.props.show}
        closeIcon={
          <Image
            src={closeIcon}
            className="icon"
            onClick={() => this.props.showContactedModal(false)}
          />
        }
      >
        <Modal.Header>
          {status === 'contacted'
            ? 'Mark As Contacted'
            : status === 'removed'
            ? 'Mark As Removed'
            : 'Add Note'}
            {this.props.customTitle}
        </Modal.Header>
        <Modal.Content>
          <Segment className="mark-contacted-user-container">
            <div className="mark-contacted-avatar">
              <Image src={user.avatar ? (this.props.fullUrlAvatar ? '' : uploads.s3BucketAddress) + user.avatar : noAvatar} />
              {user.preferredName ? user.preferredName : user.firstName} {user.lastName}
            </div>
            {status !== 'note' && (
              <div className="contacted-button">
                {status === 'contacted' ? 'Contacted' : 'Removed'}
              </div>
            )}
          </Segment>

          <div>
            <div style={{ marginBottom: '0.5rem' }}>Note</div>
            <Form
              id="mark-contacted-form"
              onValidSubmit={async (model: any) => {
                if (status === 'note') {
                  const response = await services.api.standby.markContacted(
                    this.props.standbyId,
                    user.id,
                    model.note
                  );
                  if (response.isSuccess) {
                    this.props.updateData();
                    toast.dismiss();
                    toast.success(`Note was successfully added!`);
                    this.props.showContactedModal(false);
                  } else {
                    console.log(response);
                    toast.dismiss();
                    toast.error(
                      response.data.errors
                        ? response.data.errors[0].msg
                        : `Error ${response.status}`
                    );
                  }
                } else {
                  const response = await services.api.standby.markContacted(
                    this.props.standbyId,
                    user.id,
                    model.note,
                    this.props.status
                  );
                  if (response.isSuccess) {
                    this.props.updateData();
                    toast.dismiss();
                    toast.success(`Adjuster ${user.firstName} ${user.lastName} was ${status}!`);
                    this.props.showContactedModal(false);
                    services.router.goto(`/stand-by-lists/${this.props.standbyId}${location.hash}`);
                  } else {
                    console.log(response);
                    toast.dismiss();
                    toast.error(
                      response.data.errors
                        ? response.data.errors[0].msg
                        : `Error ${response.status}`
                    );
                  }
                }
              }}
            >
              <Form.TextArea
                name="note"
                maxLength={1024}
                placeholder="Please Enter"
                instantValidation={false}
                required={true}
                validationErrors={{
                  isDefaultRequiredValue: '* Note is required field.',
                }}
                errorLabel={errorLabel}
                className="form-input"
              />
            </Form>
          </div>
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            style={{ width: '115px' }}
            className="secondary-button"
            secondary={true}
            onClick={() => this.props.showContactedModal(false)}
          >
            CANCEL
          </Button>
          <Button style={{ width: '115px' }} primary={true} form="mark-contacted-form">
            OK
          </Button>
        </div>
      </Modal>
    );
  }
}
export default ContactedModal;
