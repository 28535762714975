import { action } from 'typesafe-actions';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models';
import { Folder } from '../training-center/trainingCenterReducer';
import * as constants from './constants';

export const checkAuthentication = () => action(constants.CHECK_AUTHENTICATION);

export const fetchAdjusterData = (user: AdjusterUser) =>
  action(constants.FETCH_ADJUSTER_DATA, user);

export const clearAdjusterData = () => action(constants.CLEAR_ADJUSTER_DATA);

export const fetchAdjusterDataAttempt = (id: number) =>
  action(constants.FETCH_ADJUSTER_DATA_ATTEMPT, id);

export const updateUserData = (user: IUser) => action(constants.UPDATE_USER_DATA, user);

export const loginAttempt = (email: string, password: string, redirect?: { url: string, token: string }) =>
  action(constants.LOGIN_ATTEMPT, {
    email,
    password,
    redirect
  });

export const loginFailed = (error: string) =>
  action(constants.LOGIN_ATTEMPT_FAILED, {
    error,
  });

export const recoverAttempt = (email: string) =>
  action(constants.RECOVER_ATTEMPT, {
    email,
  });

export const recoverFailed = (error: string) =>
  action(constants.RECOVER_ATTEMPT_FAILED, {
    error,
  });

export interface ResetPasswordModel {
  token: string;
  password: string;
  passwordConfirm: string;
}

export const resetPasswordAttempt = (model: ResetPasswordModel) =>
  action(constants.RESET_PASSWORD_ATTEMPT, model);

export const loginSuccess = (accessToken: string, user: IUser) =>
  action(constants.LOGIN_SUCCESS, {
    accessToken,
    user,
  });

export interface RegisterAttemptModel {
  password: string;
  passwordConfirm: string;
}

export const registerAttempt = (model: RegisterAttemptModel) =>
  action(constants.REGISTER_ATTEMPT, model);

export const registerFailed = (errors: string[]) =>
  action(constants.REGISTER_ATTEMPT_FAILED, {
    errors,
  });

export const registerSuccess = (accessToken: string, user: IUser) =>
  action(constants.REGISTER_SUCCESS, {
    accessToken,
    user,
  });

export interface EmailVerificationAttemptModel {
  userId: string;
  verificationToken: string;
}

export const emailVerificationAttempt = (model: EmailVerificationAttemptModel) =>
  action(constants.EMAIL_VERIFICATION_ATTEMPT, model);

export const emailVerificationFailed = (errors: string[]) =>
  action(constants.EMAIL_VERIFICATION_ATTEMPT_FAILED, {
    errors,
  });

export const checkAuthenticationFinished = () => action(constants.CHECK_AUTHENTICATION_FINISH);

export const logout = () => action(constants.LOGOUT);

export const downloadFile = (file: any) =>
  action(constants.DOWNLOAD_FILE, {
    file,
  });

export const downloadPaperwork = (file: any) =>
  action(constants.DOWNLOAD_PAPERWORK, {
    file,
  });

export const getAllFolders = () =>
  action(constants.GET_ALL_FOLDERS, {
  });

export const setAllFolders = (data: Folder[]) => {

  const allFolder = new Array<any>();

  for (const currentFolder of (data || [])) {
    allFolder.push({ id: currentFolder.id, name: currentFolder.name, parentId: null, parentName: null });
    for (const child of currentFolder.children) {
      allFolder.push({ id: child.id, name: child.name, parentId: currentFolder.id, parentName: currentFolder.name })
    }
  }
  return action(constants.SET_ALL_FOLDERS, {
    data: allFolder
  });
}
export const getAssignedFolders = (adjusterId: any) =>
  action(constants.GET_ASSIGNED_FOLDERS, {
    adjusterId
  });

export const setAssignedFolders = (data: any[]) => {

  return action(constants.SET_ASSIGNED_FOLDERS, {
    data
  });
}

export const setSelectedFolders = (data: any[]) =>
  action(constants.SET_SELECTED_FOLDERS, {
    data
  });

export const saveSelectedFolders = (data: any[], adjusterId: number) =>
  action(constants.SAVE_SELECTED_FOLDER, {
    data,
    adjusterId
  });

export const selectFolder = (folderId: number) =>
  action(constants.SELECT_FOLDER, {
    folderId
  });
