import React from 'react';
import { Button, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import services from '../../services';

interface Props {
  showScaled: (show: boolean) => void;
  show: boolean;
  avatar: any;
}

const AvatarScaled = (props: Props) => {
  const downloadAvatar = async () => {
    await services.api.file.downloadFile(props.avatar, 'avatar');
  };

  return (
    <Modal
      size="tiny"
      className="standby-created-message avatar-scaled-modal"
      open={props.show}
      closeIcon={<Image src={closeIcon} className="icon" onClick={() => props.showScaled(false)} />}
    >
      <Modal.Header>Avatar</Modal.Header>
      <Modal.Content>
        <div className="avatar-scaled-container">
          <Image src={props.avatar} />
          <Button primary={true} onClick={downloadAvatar}>
            Download
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AvatarScaled;
