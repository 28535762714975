import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import AdjusterProfile from '../../components/adjuster-profile/AdjusterProfile';
import CreateAdjuster from '../account/create-adjuster';
import AdjustersList from '../adjusters-list/AdjustersList';
import AdminList from '../admin-list/AdminList';
import Dashboard from '../dashboard/dashboard';
import FinanceList from '../finance-list/FinanceList';
import Messages from '../messages/MessagesList';
import ProfilePage from '../profile/profile';
import SettingsPage from '../settings/settings';
import AccountLayoutHeader from './../../components/layout-header/accountLayoutHeader';
import Sidebar from './../../components/layout-sidebar/sidebar';

import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import '../../assets/styles/Sidebar.css';
import RoomMessages from '../../components/message-layout/RoomMessages';
import { accountActions } from '../../features/account';
import { standbyActions } from '../../features/standby';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models';
import services from '../../services';
import { PrivateRoute } from '../PrivateRoute';
import StandbyLists from '../standby-lists/standbyLists';
import StandbyListsForm from '../standby-lists/StandbyListsForm';
import MessageDetails from './../../components/message-layout/MessageDetails';
import StandbyAnswers from './../../components/standby-layout/SBAnswers';
import SBDetails from './../../components/standby-layout/SBDetails';
import SBMessageDetails from './../../components/standby-layout/SBMessageDetails';
import TrainingCenter from '../training-center/trainingCenter';
import Preview from '../preview/preview';

interface Props {
  isAuthenticationChecked: boolean;
  pathname: string;
  user: IUser;
  adjuster: AdjusterUser;
  checkAuthentication: () => void;
  logout: () => void;
  standbyHeader: string;
  activeStandbyId: any;
  setActiveStandby: typeof standbyActions.setActiveStandby;
}

interface State {}

class Homepage extends React.Component<Props, State> {
  public componentWillMount(): void {
    this.props.checkAuthentication();
  }

  public render() {
    const { user, adjuster } = this.props;
    if (!this.props.isAuthenticationChecked) {
      return (
        <Dimmer active={true} page={true}>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      );
    }

    return (
      <React.Fragment>
        <div style={{ margin: '0 8px' }}>
          <AccountLayoutHeader
            activeStandbyId={this.props.activeStandbyId}
            user={user}
            adjuster={adjuster}
            logout={this.props.logout}
            pathname={this.props.pathname}
            standbyHeader={this.props.standbyHeader}
            setActiveStandby={this.props.setActiveStandby}
            hideWarningMessage={
              -1 !==
              [
                services.router.url.register,
                services.router.url.login,
                services.router.url.emailVerification,
                services.router.url.profile,
              ].indexOf(window.location.pathname)
            }
          />
        </div>

        <Grid stackable={true} style={{ margin: '0 8px', paddingBottom: '20px' }}>
          <Grid.Row style={{ width: '100%' }}>
            <Grid.Column className="sidebar-container-column">
              <Sidebar user={user} />
            </Grid.Column>

            <Grid.Column className="content-container-column">
              <PrivateRoute
                exact={true}
                path={services.router.url.homepage}
                component={Dashboard}
              />
              <PrivateRoute path={services.router.url.profile} component={ProfilePage} />
              <PrivateRoute path={services.router.url.staff} component={AdminList} />
              <PrivateRoute
                exact={true}
                path={services.router.url.adjusters}
                component={AdjustersList}
              />
              <PrivateRoute
                path={services.router.url.adjusterProfile}
                component={AdjusterProfile}
              />
              <PrivateRoute path={services.router.url.settings} component={SettingsPage} />
              <PrivateRoute
                path={services.router.url.standby}
                exact={true}
                component={StandbyLists}
              />
              <PrivateRoute
                path={services.router.url.stand_by_list_form}
                component={StandbyListsForm}
              />
              <PrivateRoute
                path={services.router.url.stand_by_list_form_edit}
                component={StandbyListsForm}
              />
              <PrivateRoute
                path={services.router.url.stand_by_list_details}
                component={SBDetails}
              />
              <PrivateRoute
                path={services.router.url.stand_by_list_answers}
                component={StandbyAnswers}
              />
              <PrivateRoute
                exact={true}
                path={services.router.url.stand_by_list_message}
                component={SBMessageDetails}
              />
              <PrivateRoute
                exact={true}
                path={services.router.url.messages_room}
                component={RoomMessages}
              />
              <PrivateRoute exact={true} path={services.router.url.messages} component={Messages} />
              <PrivateRoute
                exact={true}
                path={services.router.url.training_center}
                component={TrainingCenter}
              />
              <PrivateRoute
                path={services.router.url.messages_details}
                component={MessageDetails}
              />
              <PrivateRoute path={services.router.url.finances} component={FinanceList} />
              <PrivateRoute path={services.router.url.createAdjuster} component={CreateAdjuster} />
              {/* <PrivateRoute path={services.router.url.preview} component={Preview} /> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  pathname: state.router.location.pathname,
  user: state.account.account.user,
  adjuster: state.account.account.adjuster,
  isAuthenticationChecked: state.account.account.isAuthenticationChecked,
  activeStandbyId: state.standby.standby.activeStandbyId,
  standbyHeader: state.standby.messages.standbyHeader,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkAuthentication: accountActions.checkAuthentication,
      setActiveStandby: standbyActions.setActiveStandby,
      logout: accountActions.logout,
    },
    dispatch
  );

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(Homepage);
