import { action } from 'typesafe-actions';
import * as constants from './constants';
import {
  AccessModel,
  CreateFolderModel,
  EditFolderModel,
  Folder,
  MaterialModel,
  PaginationInfo,
} from './trainingCenterReducer';
import {
  DocumentData,
  EmbeddedVideoData,
} from '../../components/training-center-layout/shared/types';

export const getFilesList = (filterOptions?: object) =>
  action(constants.GET_FILES_LIST, { filterOptions });

export const getFolderList = () => action(constants.GET_FOLDER_LIST, {});

export const setFolderList = (list: object[]) => action(constants.SET_FOLDER_LIST, { list });

export const updateFolderPosition = (folderID: number, position: number) =>
  action(constants.UPDATE_FOLDER_POSITION, { folderID, position });

export const setFolderToggle = (id: number) => action(constants.SET_FOLDER_TOGGLE, { id });

// sets the active folder inside the folders list
export const setFolderActive = (folder: Folder) => action(constants.SET_FOLDER_ACTIVE, { folder });

export const removeFolderActive = () => action(constants.REMOVE_FOLDER_ACTIVE, {});

export const setFolderEnter = (folder: Folder) => action(constants.SET_FOLDER_ENTER, { folder });

export const setFolderLeave = (folder: Folder) => action(constants.SET_FOLDER_LEAVE, { folder });

/* Start create folder related action */
export const toggleCreateFolderModal = (parentId?: number) =>
  action(constants.TOGGLE_CREATE_FOLDER_MODAL, { parentId });

export const createFolder = (data: CreateFolderModel) => action(constants.CREATE_FOLDER, { data });

/* End create folder related action */

/* Start update folder related action */
export const toggleEditFolderModel = (data?: EditFolderModel) =>
  action(constants.TOGGLE_EDIT_FOLDER_MODAL, { data: data });

export const editFolder = (data: EditFolderModel) => action(constants.EDIT_FOLDER, { data });

/* End update folder related action */

/* Start delete folder related action */
export const toggleDeleteFolderModel = (data?: EditFolderModel) =>
  action(constants.TOGGLE_DELETE_FOLDER_MODAL, { data: data });

export const deleteFolder = (data: EditFolderModel) => action(constants.DELETE_FOLDER, { data });

/* End delete folder related action */

/* start assign access*/
export const showAssignAccessModal = (folderId: number) =>
  action(constants.SHOW_ASSIGN_ACCESS_MODAL, { folderId });

export const hideAssignAccessModal = () => action(constants.HIDE_ASSIGN_ACCESS_MODAL, {});

export const saveAssignedAccess = (
  folderId: number,
  adjusters: Array<number>,
  customEvents: Array<number>
) => action(constants.SAVE_ASSIGN_ACCESS, { folderId, adjusters, customEvents });

export const setAssignedAdjusters = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.SET_ASSIGNED_ADJUSTERS, { data });
};

export const removeAssignedAdjusters = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.REMOVE_ASSIGNED_ADJUSTERS, { data });
};

export const setPageSizeAssignAccess = (size: number) => {
  return action(constants.SET_PAGE_SIZE_ASSIGN_ACCESS, { size });
};

export const setAssignedCustomEvents = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.SET_ASSIGNED_CUSTOM_EVENTS, { data });
};

export const setAssign = (activeTab: string, data: Array<any> = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.SET_ASSIGN, { activeTab, data });
};
export const removeAssignedCustomEvents = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.REMOVE_ASSIGNED_CUSTOM_EVENTS, { data });
};

export const setActiveTab = (activeTab: string) => {
  return action(constants.ASSIGN_ACCESS_SET_ACTIVE_TAB, { activeTab });
};

export const searchAssignAccess = (page = 0, pageSize = 15, keyword = '', filters?: any) => {
  return action(constants.ASSIGN_ACCESS_SEARCH, { page, pageSize, keyword, filters });
};

export const setAssignAccessSearchResult = (data: Array<any> = [], total: number) => {
  return action(constants.ASSIGN_ACCESS_SET_SEARCH_RESULT, { data, total });
};

export const setAllSearchResult = (data: Array<any> = []) => {
  data = data.map(t => t.id);
  return action(constants.ASSIGN_ACCESS_SET_ALL_SEARCH_RESULT, { data });
};

export const setViewTypeList = () => {
  return action(constants.SET_VIEW_TYPE_LIST, {});
};

export const setViewTypeGrid = () => {
  return action(constants.SET_VIEW_TYPE_GRID, {});
};

export const openMaterialFilter = () => {
  return action(constants.OPEN_MATERIAL_FILTER, {});
};

export const closeMaterialFilter = () => {
  return action(constants.CLOSE_MATERIAL_FILTER, {});
};

export const searchMaterial = (pagingInfo: PaginationInfo) => {
  return action(constants.SEARCH_MATERIAL, { pagingInfo });
};

export const editMaterial = (id: number) => {
  return action(constants.MATERIAL_EDIT, { id });
};

export const deleteMaterial = (material: MaterialModel | null) => {
  return action(constants.MATERIAL_DELETE, { material });
};

export const downloadMaterial = (id: number) => {
  return action(constants.MATERIAL_DOWNLOAD, { id });
};

export const selectMaterial = (id: number) => {
  return action(constants.MATERIAL_SELECT, { id });
};

export const selectAllMaterial = () => {
  return action(constants.MATERIAL_SELECT_ALL, {});
};

export const bulkDeleteMaterial = (ids: Array<number>) => {
  return action(constants.MATERIAL_BULK_DELETE, { ids });
};

export const bulkDownloadMaterial = (ids: Array<number>) => {
  return action(constants.MATERIAL_BULK_DOWNLOAD, { ids });
};

export const setMaterialSearchResult = (data: Array<any> = [], total: number) => {
  return action(constants.MATERIAL_SET_SEARCH_RESULT, { data, total });
};

export const setMaterialAllSearchResult = (data: Array<MaterialModel> = []) => {
  return action(constants.MATERIAL_SET_ALL_SEARCH_RESULT, { data: data });
};

export const setSelectedMaterial = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.SET_SELECTED_MATERIALS, { data });
};

export const removeSelectedMaterials = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.REMOVE_SELECTED_MATERIALS, { data });
};

export const removeAllSelectedMaterials = () => {
  return action(constants.REMOVE_ALL_SELECTED_MATERIALS, { data: [] });
};
/* end assign access*/

/* start upload material */
export const toggleUploadMaterialModal = () => action(constants.TOGGLE_UPLOAD_MATERIAL_MODAL);

export const uploadEmbeddedVideoMaterial = (data: EmbeddedVideoData) => {
  return action(constants.CREATE_EMBEDDED_VIDEO_MATERIAL, { data });
};

export const uploadDocumentMaterial = (data: DocumentData[]) => {
  return action(constants.CREATE_DOCUMENT_MATERIAL, { data });
};

export const uploadEmbeddedVideoMaterialDone = () => {
  return action(constants.CREATE_EMBEDDED_VIDEO_MATERIAL_DONE, {});
};

export const uploadDocumentMaterialDone = () => {
  return action(constants.CREATE_DOCUMENT_MATERIAL_DONE, {});
};

/* start edit material */
export const toggleEditMaterialModal = (material: EmbeddedVideoData | DocumentData) => {
  return action(constants.TOGGLE_EDIT_MATERIAL_MODAL, { material });
};

export const editEmbeddedVideoMaterial = (id: number, data: EmbeddedVideoData) => {
  return action(constants.EDIT_EMBEDDED_VIDEO_MATERIAL, { id, data });
};

export const editDocumentMaterial = (id: number, data: DocumentData) => {
  return action(constants.EDIT_DOCUMENT_MATERIAL, { id, data });
};

export const editEmbeddedVideoMaterialDone = () => {
  return action(constants.EDIT_EMBEDDED_VIDEO_MATERIAL_DONE, {});
};

export const editDocumentMaterialDone = () => {
  return action(constants.EDIT_DOCUMENT_MATERIAL_DONE, {});
};

//start custom events

export const getCustomEventList = (search?: string) =>
  action(constants.GET_CUSTOM_EVENT, { search });

export const setCustomEventList = (list: object[]) => action(constants.SET_CUSTOM_EVENT, { list });

export const setSearchInputCustomEvent = (keyword: string) => {
  return action(constants.SET_SEARCH_INPUT_CUSTOM_EVENTS, keyword);
};

//Access tab

export const searchAccess = (pagingInfo: PaginationInfo) => {
  return action(constants.SEARCH_ACCESS, { pagingInfo });
};

export const setAccessSearchResult = (data: Array<any> = [], total: number) => {
  return action(constants.ACCESS_SET_SEARCH_RESULT, { data, total });
};

export const setAccessAllSearchResult = (data: Array<AccessModel> = []) => {
  return action(constants.ACCESS_SET_ALL_SEARCH_RESULT, { data: data });
};

export const setSelectedAccess = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.SET_SELECTED_ACCESS, { data });
};

export const removeSelectedAccess = (data: Array<number> | number = []) => {
  if (typeof data === 'number') {
    data = [data];
  }
  return action(constants.REMOVE_SELECTED_ACCESS, { data });
};

export const setSearchInputAccess = (keyword: string) => {
  return action(constants.SET_SEARCH_INPUT_ACCESS, keyword);
};
