import MyTypes from 'MyTypes';
import React, { } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Checkbox } from 'semantic-ui-react';
import { accountActions } from '../../../features/account';
import { IUser } from '../../../models/user';
import './styles.css';

interface Props {
    user: IUser;
    options: Array<any>,
    valueSearch: string,
    selectFolder: typeof accountActions.selectFolder;
    dataSource: Array<number>;
    assignedFolders: Array<number>;
    selectedFolders: Array<number>;
    save: () => void;
    close: () => void;
}

interface State {
}

class AssignFolderAdjusterContent extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    wrapperRef: any;
    public state = {
        open: false,
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    handleClickOutside(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.close();
        }
    }

    public onChange(checkBoxValue: any) {
        this.props.selectFolder(checkBoxValue.id);
    }

    public onSave() {
        this.props.save();
    }

    public render() {
        const { options, valueSearch, dataSource, assignedFolders } = this.props;

        const placeHolder = assignedFolders.length >= 2 ? `${assignedFolders.length} Categories assigned` : `${assignedFolders.length} Category assigned`;
        return (
            <div className="wrapperContentMultiple" ref={this.setWrapperRef}>

                {!!options.length && (
                    <div key={valueSearch.toLowerCase()} className="body">
                        {options.map(
                            i =>
                                i.text.toLowerCase().includes(valueSearch.toLowerCase()) && (
                                    <div className={`item${i.isCustomEvent ? ' customEvent' : ''}`} key={i.id}>
                                        <Checkbox
                                            label={i.text}
                                            onChange={() => {
                                                this.onChange(i);
                                            }}
                                            checked={dataSource.includes(i.id)}
                                        />
                                    </div>
                                )
                        )}
                    </div>
                )}
                <div className="actions flex">
                    <div>
                        {placeHolder}
                    </div>
                    <Button
                        primary={true}
                        className="btn btnApply"
                        onClick={() => {
                            this.onSave();
                        }}
                    >
                        Save
                  </Button>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect<Props, State>(
    mapStateToProps,
    mapDispatchToProps
)(AssignFolderAdjusterContent);
