import { request } from './base';

export const login = (email: string, password: string, redirect: { url: string, token: string }) => {
  return request({
    method: 'post',
    url: 'account/admin/login',
    data: {
      email,
      password,
      redirect,
    },
  });
};

export const register = (model: {}) => {
  return request({
    method: 'post',
    url: 'account/register',
    data: {
      ...model,
    },
  });
};

export const recover = (email: string) => {
  return request({
    method: 'post',
    url: 'account/admin/forgot-password',
    data: {
      email,
    },
  });
};

export const reset = (model: {}) => {
  return request({
    method: 'post',
    url: 'account/admin/reset-password',
    data: {
      ...model,
    },
  });
};

export const emailVerificationConfirm = (userId: string, verificationToken: string) => {
  return request({
    method: 'post',
    url: 'account/email-address-verify',
    data: {
      userId,
      verificationToken,
    },
  });
};

export const getSignedUrl = (data: object) => {
  return request({
    method: 'post',
    url: '/file',
    data,
  });
};

export const getPaperworkSignedUrl = (data: object) => {
  return request({
    method: 'post',
    url: '/file/paperwork',
    data,
  });
};

export const downloadFile = (key: string) => {
  return request({
    method: 'post',
    url: `file/download`,
    data: {
      fileObjectKey: key,
    },
  });
};

export const getSettingsData = (type: string) => {
  return request({
    method: 'get',
    url: `/public/ability/${type}?noPagination=1`,
  });
};

export const getStandbyListLocations = () => {
  return request({
    method: 'get',
    url: '/standby-list/locations',
  });
};

export const getDeploymentsFilters = () => {
  return request({
    method: 'get',
    url: 'adjuster/deployments-filters',
  });
};

export const updateCompassEmail = (id: any, email: string) => {
  return request({
    method: 'patch',
    url: `/adjuster/${id}/compass-email`,
    data: {
      email,
    },
  });
};
