import { Form } from 'formsy-semantic-ui-react';
import moment from 'moment';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Button, Checkbox, Confirm, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { uploads } from '../../config';
import { AdjusterUser } from '../../models';
import services from '../../services';
import { parseDateToDateStringWithFormat } from '../../utils/datesHelper';
import DatePickerInput from '../datepicker-input/DatePickerInput';
import ErrorLabel from '../error-label/ErrorLabel';
import { MessageSpiner } from '../loading-spinner/MessageSpinner';
import CustomIconicButton from './../adjuster-list/CustomIconicButton';
import UploadZone from './../file-uploader/UploadZone';

interface Props {
  getPaperwork: () => void;
  user: AdjusterUser;
}

interface State {
  showModal: boolean;
  showConfirm: boolean;
  showDate: boolean;
  file: string | ArrayBuffer | null;
  fileError: boolean;
  acknowledge: boolean;
  fileType: string;
  statesOptions: object[];
  files: any[];
  filesError: boolean;
}

class AddLicenseModal extends React.Component<Props, State> {
  public state = {
    showModal: false,
    showConfirm: false,
    statesOptions: [],
    showDate: false,
    file: '',
    fileType: '',
    fileError: false,
    files: [],
    acknowledge: false,
    filesError: false,
  };

  public fileInputRef = React.createRef();

  public componentDidMount = async () => {
    const request = await services.api.account.getSettingsData('paperwork');
    if (request.isSuccess) {
      const statesOptions = request.data.list.map((el: any, index: any) => {
        const element = { text: el.name, value: el.name };
        return element;
      });
      this.setState({ statesOptions });
    }
  };

  public deleteFile = (id: number) => {
    const { files } = this.state;
    files.splice(id, 1);
    this.setState({ files });
  };

  public handleFileDrop = (items: any) => {
    const { files } = this.state;
    items.forEach(async (file: any) => {
      if (file === undefined) {
        this.setState({ fileError: true });
      } else {
        if (file.size > uploads.maxFileSizeForPaperworks) {
          toast.warn('Please make sure the files are under 10MB.');
        } else {
          const resp = await services.api.account.getPaperworkSignedUrl({
            name: file.name,
            type: file.type,
            size: file.size,
            public: true,
          });
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async (e: any) => {
            if (resp.isSuccess) {
              files.push({
                file: file,
                fields: resp.data.url,
              });
            }
            this.setState({
              files,
              filesError: files.length === 0,
            });
          };
        }
      }
    });
  };

  public closeModal = () => {
    this.setState(
      {
        showModal: false,
        showDate: false,
        files: [],
        file: '',
        fileType: '',
        filesError: false,
      },
      this.props.getPaperwork
    );
  };

  public manageDocumentType = (e: any, data: any) => {
    if (data.value === 'BG Check Completed') {
      this.setState({ showDate: true });
    } else {
      this.setState({ showDate: false });
    }
  };

  public render() {
    const errorLabel = <ErrorLabel />;

    const trigger = (
      <div className="add-question">
        <Button
          type="button"
          onClick={() => this.setState({ showModal: true, acknowledge: false })}
        >
          + ADD
        </Button>
      </div>
    );
    return (
      <Modal
        size="tiny"
        className="licenses-modal paperwork-file-uploader"
        trigger={trigger}
        open={this.state.showModal}
        closeIcon={<Image src={closeIcon} className="icon" onClick={this.closeModal} />}
      >
        <Modal.Header>Add document</Modal.Header>
        <Form
          onInvalidSubmit={(model: any) => {
            const { files } = this.state;
            if (files.length === 0) {
              this.setState({ filesError: true });
              return;
            }
          }}
          onValidSubmit={async (model: any) => {
            const { files } = this.state;
            const { user } = this.props;
            if (files.length === 0) {
              this.setState({ filesError: true });
              return;
            }
            files.forEach(async (file: any) => {
              const data = file.fields.fields;
              const obj = Object.assign({}, data, {
                file: file.file,
              });
              const formData = new FormData();
              for (const key in obj) {
                if (undefined === obj[key]) {
                  continue;
                }
                formData.append(key, obj[key]);
              }
              await services.api.file.postFile(file.fields.url, formData);
            });

            const objectsArray = files.map((file: any) => file.fields.fields.key);
            const addPaperwork = await trackPromise(
              services.api.adjuster.addPaperwork(
                user.id,
                model.type,
                this.state.acknowledge,
                objectsArray,
                model.runDate ? parseDateToDateStringWithFormat(model.runDate) : undefined
              ),
              'paperwork-area'
            );

            // Mark files as uploaded
            await trackPromise(
              services.api.file.markFilesAsUploaded(objectsArray),
              'paperwork-mark-uploaded'
            );

            if (addPaperwork.isSuccess) {
              this.closeModal();
            }
          }}
        >
          <Modal.Content style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <MessageSpiner area="paperwork-area" />
            <div className="input-field-container">
              <div className="input-field-label">Document Type</div>
              <Form.Dropdown
                search={true}
                name="type"
                options={this.state.statesOptions}
                placeholder="Please Select"
                instantValidation={false}
                required={true}
                onChange={this.manageDocumentType}
                validationErrors={{
                  isDefaultRequiredValue: `* Document Type is required field.`,
                }}
                className="form-input-dropdown"
                errorLabel={errorLabel}
              />
            </div>

            {this.state.showDate && (
              <div className="input-field-container">
                <div className="input-field-label">Run Date</div>
                <DatePickerInput
                  name="runDate"
                  defaultValue=""
                  required={true}
                  value=""
                  validations={{ isValidMax: moment(), isValidDate: true }}
                  validationErrors={{
                    isDefaultRequiredValue: `* Run Date is required field.`,
                    isValidMax: `* Run Date should not be later than current date.`,
                    isValidDate: '* Date format is invalid.',
                  }}
                  errorLabel={errorLabel}
                />
              </div>
            )}

            <div className="files-paperwork-zone">
              {this.state.files.map((el: any, index: number) => {
                return (
                  <div className="paperwork-uloaded-file" key={index}>
                    <span className="uploaded-file-ttl">{el.file.name}</span>
                    <span>
                      <CustomIconicButton
                        image={closeIcon}
                        popup="Remove"
                        onPress={() => this.deleteFile(index)}
                      />
                    </span>
                  </div>
                );
              })}
              <UploadZone onDrop={this.handleFileDrop} accept=".jpg, .jpeg, .png, .pdf" />
            </div>
            <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
              {this.state.filesError && <ErrorLabel>* Paperwork file is required.</ErrorLabel>}
            </div>
            <div style={{ marginTop: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
              <Checkbox
                label="I acknowledged that all files were received"
                checked={this.state.acknowledge}
                onChange={() => this.setState({ acknowledge: !this.state.acknowledge })}
              />
            </div>
          </Modal.Content>

          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={this.closeModal}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              ADD
            </Button>
          </div>
        </Form>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content="Are you sure you want to delete this file?"
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={this.closeModal}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </Modal>
    );
  }
}

export default AddLicenseModal;
