import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { confirmationModalConfirm, ConformationModel } from '../../features/modal/actions';

interface Props {
  isVisible: boolean;
  model?: ConformationModel;
  okAction: () => void;
}

class ConfirmationModal extends React.Component<Props> {
  public render() {
    if (!this.props.isVisible || !this.props.model) {
      return null;
    }

    return (
      <Modal open={true} size="small">
        <Header icon="warning sign" content={this.props.model.title || 'Warning'} />
        <Modal.Content>
          <p>{this.props.model.message}</p>
          <p>Are you sure you wan't to continue?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red">
            <Icon name="close" /> No
          </Button>
          <Button color="green" onClick={() => this.props.okAction()}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  isVisible: state.modal.confirmation.isVisible,
  model: state.modal.confirmation.model,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      okAction: confirmationModalConfirm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationModal);
