import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Image, Modal, Pagination, Segment } from 'semantic-ui-react';
import closeIcon from '../../../assets/images/Blue/cancel.svg';
import { trainingCenterActions } from '../../../features/training-center';
import { PaginationInfo } from '../../../features/training-center/trainingCenterReducer';
import { LoadingSpiner } from '../../loading-spinner/LoadingSpinner';
import { MessageSpiner } from '../../loading-spinner/MessageSpinner';
import { NoResults } from '../../no-results-placeholder/NoResults';
import AssignAccessTableAdj from './AssignAccessTableAdj';
import AssignAccessTableHeader from './AssignAccessTableHeader';

interface Props {
  show: boolean;
  hideAssignAccessModal: typeof trainingCenterActions.hideAssignAccessModal;
  saveAssignedAccess: typeof trainingCenterActions.saveAssignedAccess;
  searchAssignAccess: typeof trainingCenterActions.searchAssignAccess;
  adjusters: object[];
  customEvents: Array<any>;
  selectedAdjusters: number[];
  selectedCustomEvents: number[];
  folderId: any;
  activeTab: 'Adjusters' | 'CustomEvents';
  pagingInfo: { Adjusters: PaginationInfo; CustomEvents: PaginationInfo };
}

interface State {
  filter?: any;
  loading: boolean;
  selectedTabs: any;
}

class AssignAccessModal extends React.Component<Props, State> {
  public state = {
    filter: {},
    selectedTabs: [],
    loading: false,
  };

  public handleClose = () => {
    this.props.hideAssignAccessModal();
  };

  public handlePaginationAdj = (e: any, data: any) => {
    const activePage = data.activePage;
    const getPagingInfo = this.getPagingInfo();

    console.log(activePage, getPagingInfo.pageSize, getPagingInfo.search, this.state.filter);
    this.props.searchAssignAccess(
      activePage,
      getPagingInfo.pageSize,
      getPagingInfo.search,
      this.state.filter
    );
  };

  public handleSubmit = async () => {
    const { folderId, selectedAdjusters, selectedCustomEvents } = this.props;
    this.props.saveAssignedAccess(folderId, selectedAdjusters, selectedCustomEvents);
  };

  public handleSelectedTabs = (selectedTabs: any) => this.setState({ selectedTabs });

  public getPagingInfo(): PaginationInfo {
    return this.props.pagingInfo[this.props.activeTab];
  }

  public filterAdjusters = (params: any, reset: boolean = false) => {
    console.log('filtering 69', { params });

    if (params && params.standbyListDeployments) {
      params.eventDeployments = params.eventDeployments.map((item: any) => item.id);
      params.standbyListDeployments = params.standbyListDeployments.map((item: any) => item.id);
    }

    this.setState(
      prevState => {
        const filter = reset ? params : { ...prevState.filter, ...params };
        return { filter };
      },
      () => this.handlePaginationAdj(null, { activePage: 1 })
    );
  };

  public render() {
    const { loading } = this.state;
    const pagingInfo = this.getPagingInfo();
    return (
      <Modal
        className="standby-invite-modal"
        open={this.props.show}
        closeIcon={<Image src={closeIcon} className="icon" onClick={this.handleClose} />}
      >
        <Modal.Header>Assign Access</Modal.Header>
        <Modal.Content>
          <Segment loading={loading} disabled={loading} basic={true}>
            <MessageSpiner area="message-sending-area" />

            <AssignAccessTableHeader
              getPagingInfo={() => this.getPagingInfo()}
              filterAdjusters={this.filterAdjusters}
            />
            <div className="adjusters-table-invite-modal">
              {pagingInfo.records.length === 0 ? (
                <NoResults />
              ) : (
                <div>
                  <LoadingSpiner area="invite-modal-users" />
                  <AssignAccessTableAdj
                    adjusters={pagingInfo.records}
                    getPagingInfo={() => this.getPagingInfo()}
                  />
                </div>
              )}
              {pagingInfo.totalPages > 1 && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Pagination
                    totalPages={pagingInfo.totalPages}
                    boundaryRange={1}
                    activePage={pagingInfo.activePage}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    prevItem={
                      pagingInfo.activePage === 1
                        ? false
                        : {
                            'aria-label': 'Previous item',
                            content: 'PREVIOUS',
                          }
                    }
                    nextItem={
                      pagingInfo.activePage === pagingInfo.totalPages
                        ? false
                        : {
                            'aria-label': 'Next item',
                            content: 'NEXT',
                          }
                    }
                    siblingRange={1}
                    onPageChange={this.handlePaginationAdj}
                  />
                </div>
              )}
            </div>
          </Segment>
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            type="button"
            disabled={loading}
            style={{ width: '115px' }}
            className="secondary-button"
            secondary={true}
            onClick={this.handleClose}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => this.handleSubmit()}
            style={{ width: '115px' }}
            form="send-message-form"
            disabled={loading}
            loading={loading}
            primary={true}
          >
            SAVE
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  show: state.trainingCenter.assignAccess.showModal,
  adjusters: state.standby.inviteModal.adjusters,
  customEvents: state.trainingCenter.assignAccess.selectedCustomEvents,
  selectedAdjusters: state.trainingCenter.assignAccess.selectedAdjusters,
  selectedCustomEvents: state.trainingCenter.assignAccess.selectedCustomEvents,
  pageSize: state.standby.inviteModal.pageSize,
  folderId: state.trainingCenter.assignAccess.folderId,
  activeTab: state.trainingCenter.assignAccess.activeTab,
  pagingInfo: state.trainingCenter.assignAccess.pagingInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideAssignAccessModal: trainingCenterActions.hideAssignAccessModal,
      saveAssignedAccess: trainingCenterActions.saveAssignedAccess,
      searchAssignAccess: trainingCenterActions.searchAssignAccess,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssignAccessModal);
