export const CONFIRMATION_MODAL_DISPLAY = 'modal/CONFIRMATION_MODAL_DISPLAY';

export const CONFIRMATION_MODAL_HIDE = 'modal/CONFIRMATION_MODAL_HIDE';

export const CONFIRMATION_MODAL_CONFIRM = 'modal/CONFIRMATION_MODAL_CONFIRM';

export const CONFIRMATION_MODAL_CANCEL = 'modal/CONFIRMATION_MODAL_CANCEL';

export const ALERT_MODAL_DISPLAY = 'modal/ALERT_MODAL_DISPLAY';

export const ALERT_MODAL_HIDE = 'modal/ALERT_MODAL_HIDE';
