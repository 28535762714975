export const CHECK_AUTHENTICATION = 'account/CHECK_AUTHENTICATION';

export const CHECK_AUTHENTICATION_FINISH = 'account/CHECK_AUTHENTICATION_FINISH';

export const UPDATE_USER_DATA = 'account/UPDATE_USER_DATA';

export const LOGIN_ATTEMPT = 'account/LOGIN_ATTEMPT';

export const LOGIN_ATTEMPT_FAILED = 'account/LOGIN_ATTEMPT_FAILED';

export const LOGIN_SUCCESS = 'account/LOGIN_SUCCESS';

export const FETCH_ADJUSTER_DATA = 'account/FETCH_ADJUSTER_DATA';

export const CLEAR_ADJUSTER_DATA = 'account/CLEAR_ADJUSTER_DATA';

export const FETCH_ADJUSTER_DATA_ATTEMPT = 'account/FETCH_ADJUSTER_DATA_ATTEMPT';

export const RECOVER_ATTEMPT = 'account/RECOVER_ATTEMPT';

export const RECOVER_ATTEMPT_FAILED = 'account/RECOVER_ATTEMPT_FAILED';

export const RESET_PASSWORD_ATTEMPT = 'account/RESET_PASSWORD_ATTEMPT';

export const REGISTER_ATTEMPT = 'account/REGISTER_ATTEMPT';

export const REGISTER_ATTEMPT_FAILED = 'account/REGISTER_ATTEMPT_FAILED';

export const REGISTER_SUCCESS = 'account/REGISTER_SUCCESS';

export const EMAIL_VERIFICATION_ATTEMPT = 'account/EMAIL_VERIFICATION_ATTEMPT';

export const EMAIL_VERIFICATION_ATTEMPT_FAILED = 'account/EMAIL_VERIFICATION_ATTEMPT_FAILED';

export const EMAIL_VERIFICATION_SUCCESS = 'account/EMAIL_VERIFICATION_SUCCESS';

export const LOGOUT = 'account/LOGOUT';

export const DOWNLOAD_FILE = 'account/DOWNLOAD_FILE';

export const DOWNLOAD_PAPERWORK = 'account/DOWNLOAD_PAPERWORK';

export const SET_ALL_FOLDERS = 'account/SET_ALL_FOLDERS';

export const GET_ALL_FOLDERS = 'account/GET_ALL_FOLDERS';

export const GET_ASSIGNED_FOLDERS = 'account/GET_ASSIGNED_FOLDERS';

export const SET_ASSIGNED_FOLDERS = 'account/SET_ASSIGNED_FOLDERS';

export const SET_SELECTED_FOLDERS = 'account/SET_SELECTED_FOLDERS';

export const SAVE_SELECTED_FOLDER = 'account/SAVE_SELECTED_FOLDER';

export const SELECT_FOLDER = 'account/SELECT_FOLDER';