import React from 'react';
import { Button, Dimmer, Dropdown, Icon, List, ListItem, Loader } from 'semantic-ui-react';
import { Folder, PaginationInfo } from '../../features/training-center/trainingCenterReducer';
import services from '../../services';
import MyTypes from 'MyTypes';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IUser } from '../../models';
import { TrainingCenterIcons } from './trainingCenterIcons';
import './trainingCenterFolderList.css';

import { trainingCenterActions } from '../../features/training-center';
import { DropResult, DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const options = [
  { key: 'add', icon: 'add', text: 'Add Subfolder', value: 'add' },
  { key: 'eit', icon: 'edit', text: 'Edit', value: 'edit' },
  { key: 'assign', icon: 'user', text: 'Assign Access', value: 'assign' },
  { key: 'delete', icon: 'delete', text: 'Delete', value: 'delete' },
];

const optionsSub = [
  { key: 'add', icon: 'add', text: 'Add Subfolder', value: 'add' },
  { key: 'eit', icon: 'edit', text: 'Edit', value: 'edit' },
  { key: 'assign', icon: 'user', text: 'Assign Access', value: 'assign' },
  { key: 'delete', icon: 'delete', text: 'Delete', value: 'delete' },
];

interface IProps {
  user: IUser;
  init?: boolean;
  folderList: Folder[];
  subFolders: Folder[];
  showDeleteModal: boolean;
  folderToDelete: Folder;
  pagingInfo: PaginationInfo;
  searchMaterial: typeof trainingCenterActions.searchMaterial;
  getFolderList: typeof trainingCenterActions.getFolderList;
  setFolderToggle: typeof trainingCenterActions.setFolderToggle;
  setFolderActive: typeof trainingCenterActions.setFolderActive;
  removeFolderActive: typeof trainingCenterActions.removeFolderActive;
  setFolderEnter: typeof trainingCenterActions.setFolderEnter;
  setFolderLeave: typeof trainingCenterActions.setFolderLeave;
  toggleCreateFolderModal: typeof trainingCenterActions.toggleCreateFolderModal;
  toggleEditFolderModel: typeof trainingCenterActions.toggleEditFolderModel;
  toggleDeleteFolderModel: typeof trainingCenterActions.toggleDeleteFolderModel;
  showAssignAccessModal: typeof trainingCenterActions.showAssignAccessModal;
  updateFolderPosition: typeof trainingCenterActions.updateFolderPosition;
  deleteFolder: typeof trainingCenterActions.deleteFolder;
  createFolder?: typeof trainingCenterActions.createFolder;
  folderId?: number;
  isOpen?: boolean;
}

interface IState {
  open: any;
  folderList: Folder[];
  openCreateModal: any;
  openEditModal: any;
  openDeleteModal: any;
  children: Folder[];
  childrenCount: number;
  loading: boolean;
  activeFolder: number | null;
}

class FolderListTreeRecursive extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      open: {},
      openCreateModal: {},
      openEditModal: {},
      openDeleteModal: {},
      children: [],
      childrenCount: 0,
      loading: false,
      activeFolder: null,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  async openModal(folderId: number, type: string) {
    const data = await (await services.api.folder.getById(folderId)).data;

    switch (type) {
      case 'add':
        this.props.toggleCreateFolderModal(folderId);
        break;
      case 'edit':
        this.props.toggleEditFolderModel(data);
        break;
      case 'delete':
        this.props.toggleDeleteFolderModel(data);
        break;
      case 'assign':
        this.props.showAssignAccessModal(folderId);
        break;
    }
  }

  setActiveFolder(id: number) {
    this.setState({ activeFolder: id });
  }

  showAll() {
    this.setState({ open: {} });

    const { pagingInfo, subFolders } = this.props;
    this.props.searchMaterial({
      ...pagingInfo,
      activePage: 1,
      resetSelect: true,
      filter: { noPagination: true },
    });
  }

  toggleOpen(folder: Folder) {
    const isRoot = !this.props.subFolders;

    const value = this.state.open[folder.id];
    const { pagingInfo } = this.props;
    console.log('toggleOpen', { pagingInfo });
    const filter = {
      ...pagingInfo.filter,
      folderIds: folder.id,
    };

    const folderData = { id: folder.id, children: folder.children };
    const folderIds = this.getChildrenIds(folderData);

    console.log('search filter', {
      ...pagingInfo,
      filter: { ...filter, folderIds: folderIds.join(',') },
      activePage: 1,
      resetSelect: true,
    });
    // get files
    this.props.searchMaterial({
      ...pagingInfo,
      filter: { ...filter, folderIds: folderIds.join(',') },
      activePage: 1,
      resetSelect: true,
    });

    // set active root
    if (isRoot) this.setActiveFolder(folder.id);
    if (!isRoot) this.props.setRootActive();

    if (folder.children.length === 0) return;
    // toggle open folder
    this.setState(prev => ({
      open: {
        ...prev.open,
        [folder.id]: !!!value,
      },
    }));
  }

  getChildrenIds(o: any): number[] {
    const ids: number[] = [];

    const recursion = (o: any) => {
      if (o.children.length === 0) {
        ids.push(o.id);
      } else {
        ids.push(o.id);

        o.children.forEach(recursion);
      }
    };

    recursion(o);

    return ids;
  }

  componentDidMount(): void {
    if (this.props.init) this.props.getFolderList();
  }

  public getClassForFolder(folder: Folder) {
    if (
      folder.children.length > 0 &&
      (this.state.open[folder.id] || folder.childActive || folder.childEnter)
    ) {
      return `folder-item open-with-child ${
        folder.childActive || (folder.childEnter && !folder.active) ? 'child-active' : ''
      }`;
    }

    return 'folder-item';
  }

  public renderOpenFolderIcon(folder: Folder, isActive: boolean) {
    const { folderId, folderList, subFolders, init, pagingInfo } = this.props;
    const { open } = this.state;

    const folderIds = pagingInfo.filter.folderIds ? pagingInfo.filter.folderIds.split(',') : null;
    const focusedFolder = folderIds && parseInt(folderIds[0]);
    const isRoot = !this.props.subFolders;

    if (folder.children.length === 0) {
      return null;
    }

    const FolderIcon = this.state.open[folder.id]
      ? TrainingCenterIcons.CollapseFolder
      : TrainingCenterIcons.ExpandFolder;

    if (open[folder.id] && isRoot) return <FolderIcon className="open-collapse-folder-icon" />;
    if (focusedFolder === folder.id) return <FolderIcon className="open-collapse-folder-icon" />;

    return <FolderIcon className="open-collapse-folder-icon" style={{ fill: 'black' }} />;
  }

  public renderRootFolderDropdown(folder: Folder) {
    const { rootFolderValue } = this.state;
    const MoreIcon = TrainingCenterIcons.MoreFolder;
    return (
      <Dropdown
        floating
        style={{ margin: 0, float: 'right' }}
        icon={<MoreIcon className="training-center-icon" />}
        options={this.getMainFolderOptions(folder)}
        key={folder.id}
        onChange={this.handleFolderOptions}
        value={rootFolderValue}
        selectOnBlur={false}
        trigger={<></>}
      />
    );
  }

  public renderRootFolderIcon() {
    const FolderIcon = this.state.open
      ? TrainingCenterIcons.CollapseFolder
      : TrainingCenterIcons.ExpandFolder;

    return <FolderIcon className="open-collapse-folder-icon" />;
  }

  setRootActive(folderId: number) {
    this.setState({ activeFolder: folderId });
  }

  renderList() {
    const { open } = this.state;
    const { folderId, folderList, subFolders, init, pagingInfo } = this.props;

    const data = subFolders ? subFolders : folderList;
    const MoreIcon = TrainingCenterIcons.MoreFolder;

    const activeFolder = subFolders ? this.props.isRootActive : this.state.activeFolder;
    const folderIds = pagingInfo.filter.folderIds ? pagingInfo.filter.folderIds.split(',') : null;
    const focusedFolder = folderIds && parseInt(folderIds[0]);

    console.log('folderIds', folderIds);
    return (
      <ul style={{ paddingInlineStart: 2, paddingTop: !folderId ? '1rem' : undefined }}>
        {!folderId && (
          <div className="all" onClick={() => this.setRootFolderToggle()}>
            {this.renderRootFolderIcon()} All Files
          </div>
        )}
        {this.state.open &&
          data.map(({ id, name, ...item }: any, i: number) => (
            <Draggable key={id} draggableId={id as number} index={i}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  className={`root-folder${
                    activeFolder === id && (open[id] || item.children.length === 0) ? ' active' : ''
                  }`}
                >
                  <div {...provided.dragHandleProps}>
                    <div>
                      <div
                        id={id}
                        className={`folder-item ${
                          focusedFolder === id ? 'open-with-child active' : ''
                        } ${
                          item.children.length > 0 &&
                          activeFolder === id &&
                          (open[id] || item.children.length === 0)
                            ? ' open-with-child active'
                            : ''
                        }`}
                        onClick={() => this.toggleOpen({ id, name, ...item })}
                      >
                        <div onClick={() => this.toggleOpen({ id, name, ...item })}>
                          {this.renderOpenFolderIcon(
                            { id, name, ...item },
                            item.children.length > 0 &&
                              activeFolder === id &&
                              (open[id] || item.children.length === 0)
                          )}
                          <span>{name}</span>
                        </div>
                        <Dropdown
                          defaultValue={``}
                          selectOnBlur={false}
                          autofocus={false}
                          floating
                          icon={<MoreIcon className="training-center-icon" />}
                          value={''}
                          onChange={(e, data) => this.openModal(id, data.value || '')}
                          options={folderId ? optionsSub : options}
                          trigger={<></>}
                        />
                      </div>
                      {open[id] && (
                        <FolderListTreeRecursive
                          {...this.props}
                          folderId={id}
                          subFolders={item.children}
                          rootId={subFolders ? this.props.rootId : id}
                          isRootActive={subFolders ? this.props.isRootActive : activeFolder}
                          setRootActive={
                            subFolders ? this.props.setRootActive : () => this.setRootActive(id)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Draggable>
          ))}
      </ul>
    );
  }

  public onFoldersDragEnd = async (result: DropResult) => {
    try {
      this.setState({ loading: true });

      if (!result.destination) {
        return;
      }

      // check if they have the same index
      if (result.source.index === result.destination.index) {
        return;
      }

      if (result.source.droppableId === 'root') {
        // we can't move root folder into subfolder
        if (result.destination.droppableId !== 'root') {
          return;
        }

        await this.props.updateFolderPosition(
          result.draggableId as number,
          result.destination.index
        );
        return;
      }

      // we can't move a subfolder into a root position or other subfolder
      if (result.source.droppableId !== result.destination.droppableId) {
        return;
      }

      await this.props.updateFolderPosition(result.draggableId as number, result.destination.index);
    } finally {
      this.setState({ loading: false });
    }
  };

  public setRootFolderToggle() {
    this.setState({ open: !this.state.open });
    const { pagingInfo } = this.props;
    this.props.removeFolderActive();
    delete pagingInfo.filter.folderIds;

    console.log({ pagingInfo });
    this.props.searchMaterial({ ...pagingInfo, activePage: 1, resetSelect: true });
  }

  render() {
    const { folderId, folderList, pagingInfo } = this.props;
    console.log('paginInfo', { pagingInfo });
    const folderIds = pagingInfo.filter.folderIds;

    return (
      <div className="folder-list-container" style={!folderId ? { padding: 10 } : undefined}>
        <div className="list">
          <div style={{ visibility: folderList.length === 0 ? 'hidden' : 'visible' }}>
            {this.props.init ? (
              <DragDropContext onDragEnd={this.onFoldersDragEnd}>
                <Droppable droppableId="root" type="droppable-root">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.renderList()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Droppable type={`subfolder-${folderId}`} droppableId={`subfolder-${folderId}`}>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="subfolder">
                    {this.renderList()}
                  </div>
                )}
              </Droppable>
            )}
          </div>
        </div>
        {!folderId && !this.props.user.isReadOnlyAdmin && (
          <a className="add-folder-button" onClick={() => this.props.toggleCreateFolderModal()}>
            + Add Folder
          </a>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pagingInfo: state.trainingCenter.material.pagingInfo,
  folderList: state.trainingCenter.folderList,
  folderToDelete: state.trainingCenter.deleteFolder.folder,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchMaterial: trainingCenterActions.searchMaterial,
      getFolderList: trainingCenterActions.getFolderList,
      setFolderList: trainingCenterActions.setFolderList,
      setFolderToggle: trainingCenterActions.setFolderToggle,
      setFolderActive: trainingCenterActions.setFolderActive,
      removeFolderActive: trainingCenterActions.removeFolderActive,
      setFolderEnter: trainingCenterActions.setFolderEnter,
      setFolderLeave: trainingCenterActions.setFolderLeave,
      toggleCreateFolderModal: trainingCenterActions.toggleCreateFolderModal,
      toggleEditFolderModel: trainingCenterActions.toggleEditFolderModel,
      toggleDeleteFolderModel: trainingCenterActions.toggleDeleteFolderModel,
      deleteFolder: trainingCenterActions.deleteFolder,
      showAssignAccessModal: trainingCenterActions.showAssignAccessModal,
      updateFolderPosition: trainingCenterActions.updateFolderPosition,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FolderListTreeRecursive);
