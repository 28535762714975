import classnames from 'classnames';
import React from 'react';
import { Dimmer, Loader, Placeholder, Segment } from 'semantic-ui-react';

export default (props: any) => {
  const { className } = props;

  return (
    <div className={classnames('table-spinner list-loading-spinner', className)}>
      <Segment style={{ marginTop: '1rem' }}>
        <Dimmer active={true} inverted={true}>
          <Loader />
        </Dimmer>
        <Placeholder fluid={true}>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
          <Placeholder.Header image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
        </Placeholder>
      </Segment>
    </div>
  );
};
