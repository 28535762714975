export const FETCH_FINANCE_LIST = 'finance/FETCH_FINANCE_LIST';
export const FETCH_FINANCE_LIST_DONE = 'admin/finance/FETCH_FINANCE_LIST_DONE';
export const ADD_UPLOAD_FINANCIAL_FILES = 'admin/finance/ADD_UPLOAD_FILES';
export const REMOVE_UPLOAD_FINANCIAL_FILE = 'admin/finance/REMOVE_UPLOAD_FILE';
export const REMOVE_UPLOAD_FINANCIAL_FILE_DONE = 'admin/finance/REMOVE_UPLOAD_FILE_DONE';
export const DELETE_FINANCIAL_FILE = 'admin/finance/DELETE_FILE';
export const DELETE_FINANCIAL_FILE_DONE = 'admin/finance/DELETE_FINANCIAL_FILE_DONE';
export const UPLOAD_FINANCIAL_FILES_DONE = 'admin/finance/UPLOAD_FILES_DONE';
export const SET_FINANCIAL_FILE_ADJUSTER = 'admin/finance/SET_FILE_ADJUSTER';
export const SET_FINANCIAL_FILE_ID = 'admin/finance/SET_FINANCIAL_ID';
export const RESET_FINANCIAL_FILES = 'admin/finance/RESET_FINANCIAL_FILES';
export const UPLOAD_FINANCIAL_FILE = 'admin/finance/UPLOAD_FINANCIAL_FILE';
export const UPLOAD_FINANCIAL_FILE_DONE = 'admin/finance/UPLOAD_FINANCIAL_FILE_DONE';
export const FETCH_FINANCE_ADJUSTER_LIST = 'admin/finance/FETCH_FINANCE_ADJUSTER_LIST';
export const FETCH_FINANCE_ADJUSTER_LIST_DONE = 'admin/finance/FETCH_FINANCE_ADJUSTER_LIST_DONE';
export const FETCH_FINANCE_ADMIN_LIST = 'admin/finance/FETCH_FINANCE_ADMIN_LIST';
export const FETCH_FINANCE_ADMIN_LIST_DONE = 'admin/finance/FETCH_FINANCE_ADMIN_LIST_DONE';
export const EXPORT_FINANCE_LIST = 'admin/finance/EXPORT_FINANCE_LIST';
export const EXPORT_FINANCE_LIST_DONE = 'admin/finance/EXPORT_FINANCE_LIST_DONE';
export const MATCH_FINANCES_ADJUSTER = 'admin/finance/MATCH_FINANCES_ADJUSTER';
export const MATCH_FINANCES_ADJUSTER_DONE = 'admin/finance/MATCH_FINANCES_ADJUSTER_DONE';
export const FETCH_INITIAL_ADJUSTERS = 'admin/finance/LOAD_INITIAL_ADJUSTERS';
export const FETCH_INITIAL_ADJUSTERS_DONE = 'admin/finance/LOAD_INITIAL_ADJUSTERS_DONE';
export const SAVE_FINANCES = 'admin/finance/SAVE_FINANCES';
export const SAVE_FINANCES_DONE = 'admin/finance/SAVE_FINANCES_DONE';
export const DOWNLOAD_FINANCE = 'admin/finance/DOWNLOAD_FINANCE';
export const DOWNLOAD_ADJUSTER_FINANCE = 'admin/finance/DOWNLOAD_ADJUSTER_FINANCE';
