export enum DeploymentStatus {
  DEPLOYED = 'deployed',
  LEFT_MESSAGE = 'left message',
  NOT_ELIGIBLE = 'not eligible',
  READY_TO_ROLL = 'ready to roll',
  WAITING_ON_CONFIRMATION = 'waiting on confirmation',

  READY_TO_ROLL_NO_LOCIENSE = 'ready to roll – no license',
  EMAILED_FREE_SCHEDULES = 'emailed free schedules',
  CLAIMS_IN_HAND = 'claims in hand',
  CONFIRMED_ORIENTATION_ATTENDANCE = 'confirmed orientation attendance',
  WAITING_ON_CLAIMS = 'waiting on claims',
  WANTS_DESK_ASSIGNMENT = 'wants desk assignment',
  WANTS_FILE_REVIEW = 'wants file review',
}

export const DeploymentStatusToLabel: { [status: string]: string } = {
  [DeploymentStatus.DEPLOYED]: 'Deployed',
  [DeploymentStatus.LEFT_MESSAGE]: 'Left Message',
  [DeploymentStatus.NOT_ELIGIBLE]: 'Not Eligible',
  [DeploymentStatus.READY_TO_ROLL]: 'Ready to Roll',
  [DeploymentStatus.WAITING_ON_CONFIRMATION]: 'Waiting on Confirmation',

  [DeploymentStatus.READY_TO_ROLL_NO_LOCIENSE]: 'Ready to Roll – No License',
  [DeploymentStatus.EMAILED_FREE_SCHEDULES]: 'Emailed Fee Schedules',
  [DeploymentStatus.CLAIMS_IN_HAND]: 'Claims In Hand',
  [DeploymentStatus.CONFIRMED_ORIENTATION_ATTENDANCE]: 'Confirmed Orientation Attendance',
  [DeploymentStatus.WAITING_ON_CLAIMS]: 'Waiting on Claims',
  [DeploymentStatus.WANTS_DESK_ASSIGNMENT]: 'Wants Desk Assignment',
  [DeploymentStatus.WANTS_FILE_REVIEW]: 'Wants File Review',
};


export default [
  {
    text: 'Deployed',
    value: DeploymentStatus.DEPLOYED,
  },
  {
    text: 'Left Message',
    value: DeploymentStatus.LEFT_MESSAGE,
  },
  {
    text: 'Not Eligible',
    value: DeploymentStatus.NOT_ELIGIBLE,
  },
  {
    text: 'Ready to Roll',
    value: DeploymentStatus.READY_TO_ROLL,
  },
  {
    text: 'Waiting on Confirmation',
    value: DeploymentStatus.WAITING_ON_CONFIRMATION,
  },


  {
    text: 'Ready to Roll – No License',
    value: DeploymentStatus.READY_TO_ROLL_NO_LOCIENSE,
  },
  {
    text: 'Emailed Fee Schedules',
    value: DeploymentStatus.EMAILED_FREE_SCHEDULES,
  },
  {
    text: 'Claims In Hand',
    value: DeploymentStatus.CLAIMS_IN_HAND,
  },
  {
    text: 'Confirmed Orientation Attendance',
    value: DeploymentStatus.CONFIRMED_ORIENTATION_ATTENDANCE,
  },
  {
    text: 'Waiting on Claims',
    value: DeploymentStatus.WAITING_ON_CLAIMS,
  },
  {
    text: 'Wants Desk Assignment',
    value: DeploymentStatus.WANTS_DESK_ASSIGNMENT,
  },
  {
    text: 'Wants File Review',
    value: DeploymentStatus.WANTS_FILE_REVIEW,
  },
];
