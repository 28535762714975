import React from 'react';
import { toast } from 'react-toastify';
import { Confirm, Dropdown, Image } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import services from '../../services';

interface Props {
  trigger: React.ReactNode;
  showEmailModal: (show: boolean) => void;
  user: any;
  callback?: (params?: any) => void;
  filter?: any;
  activate: (status: number, id: any, name: string) => void;
}

interface State {
  showConfirm: boolean;
  action: string;
  status: string;
}

const adjStatus: { [id: string]: string[] } = {
  Pending: ['Activate', 'Deactivate', 'Delete'],
  Active: ['Deactivate', 'Archive', 'Delete'],
  Inactive: ['Activate', 'Archive', 'Delete'],
  'To Review': ['Activate', 'Keep as Inactive', 'Delete'],
  Archived: ['Un-Archive', 'Delete'],
  Deleted: ['Restore'],
  Quick: ['Delete'],
};

class StatusActions extends React.Component<Props, State> {
  public state = {
    showConfirm: false,
    action: '',
    status: '',
  };

  public handleStatus = (status: string) => {
    switch (status) {
      case 'Activate':
        this.props.user.compassEmail
          ? this.props.activate(1, this.props.user.id, this.props.user.firstName)
          : this.props.showEmailModal(true);
        return;
      case 'Deactivate':
        this.setState({ action: 'deactivate', status: 'Inactive', showConfirm: true });
        break;
      case 'Delete':
        this.setState({ action: 'delete', status: 'Deleted', showConfirm: true });
        break;
      case 'Archive':
        this.setState({ action: 'archive', status: 'Archived', showConfirm: true });
        break;
      case 'Keep as Inactive':
        this.setState({ action: 'keep as inactive', status: 'Inactive', showConfirm: true });
        break;
      case 'Un-Archive':
        this.setState({ action: 'un-archive', status: 'To Review', showConfirm: true });
        break;
      case 'Restore':
        this.setState({ action: 'restore', status: 'To Review', showConfirm: true });
        break;
    }
  };

  public submitStatus = async () => {
    const { user } = this.props;
    const { action, status } = this.state;

    const adjusterId = user.signupId || user.id;

    console.log('submitStatus', user);
    const response = await services.api.adjuster.changeAdjusterStatus(
      adjusterId,
      status,
      user.status
    );
    if (response.isSuccess) {
      const actionString = action === 'keep as inactive' ? 'kept as inactive' : `${action}d`;
      this.setState({ showConfirm: false });
      toast.dismiss();
      toast.success(
        `Adjuster ${
          user ? `${user.firstName} ${user.lastName}` : ''
        } was successfully ${actionString}!`
      );
      // if (this.props.callback) {
      this.props.callback(this.props.filter);
      // }
    } else {
      toast.dismiss();
      toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
    }
  };

  public render() {
    const { trigger, user } = this.props;
    console.log({ user });
    return (
      <>
        <Dropdown trigger={trigger} icon={false} className="segment-admin-dropdown">
          <Dropdown.Menu>
            {user &&
              adjStatus[user.status] &&
              adjStatus[user.status].map((el: any, index: number) => {
                return (
                  <Dropdown.Item key={index} onClick={() => this.handleStatus(el)}>
                    <span>{el}</span>
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={`Are you sure you want to ${this.state.action} adjuster "${
            this.props.user ? `${this.props.user.firstName} ${this.props.user.lastName}` : ''
          }"?`}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={this.submitStatus}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </>
    );
  }
}

export default StatusActions;
