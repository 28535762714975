import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';
import eyeIcon from '../../assets/images/ui-show-pass.svg';
import '../../assets/styles/Login.css';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  isLoading: boolean;
  onSubmit: (email: string, password: string) => void;
}

interface State {
  showPass: boolean;
}

class LoginForm extends React.Component<Props, State> {
  public form: any;

  public state = {
    showPass: false,
  };

  public onValidSubmit = (model: any) => {
    this.props.onSubmit(model.email, model.password);
  };

  public render() {
    const errorLabel = <ErrorLabel />;

    return (
      <div className="login-form-container">
        <Form
          onValidSubmit={this.onValidSubmit}
          ref={(ref: any) => (this.form = ref)}
          className="login-form"
        >
          <Form.Input
            name="email"
            placeholder="Email"
            instantValidation={false}
            required={true}
            validations="isEmail"
            validationErrors={{
              isDefaultRequiredValue: '* Email is required field.',
              isEmail: 'Email format is invalid.',
            }}
            errorLabel={errorLabel}
          />

          <Form.Input
            name="password"
            placeholder="Password"
            icon={
              <Popup
                trigger={
                  <i
                    className={`icon link custom-icon`}
                    onClick={() => this.setState({ showPass: !this.state.showPass })}
                  >
                    <Image
                      src={eyeIcon}
                      className={this.state.showPass ? 'show-pass-icon' : 'hide-pass-icon'}
                    />
                  </i>
                }
                size="mini"
                basic={true}
                content={this.state.showPass ? 'Hide Password' : 'View Password'}
              />
            }
            type={this.state.showPass ? 'text' : 'password'}
            instantValidation={false}
            required={true}
            validations="minLength:8"
            validationErrors={{
              isDefaultRequiredValue: '* Password is required field.',
              minLength: 'Password must be at least 8 symbols long.',
            }}
            errorLabel={errorLabel}
          />
          <Button content="Login" primary={true} />
        </Form>
      </div>
    );
  }
}

export default LoginForm;
