import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Confirm, Image, Label, Popup, Segment } from 'semantic-ui-react';
import addNumbers from '../../assets/images/Blue/button-plus-blue.svg';
import cancelIconB from '../../assets/images/Blue/cancel.svg';
import trashIconB from '../../assets/images/Blue/trash.svg';
import sblIcon from '../../assets/images/Other/icn-stdby-list.svg';
import arrowIconW from '../../assets/images/White/arrow-r.svg';
import services from '../../services';
import CopySBLToClipboard from './CopySBLToClipboard';
import SBPrivateLabel from './SBPrivateLabel';

interface Props {
  item: object;
  updateList: () => void;
  setActive: (id: any) => void;
  setTwilio: (phone: any) => void;
  user: any;
  addNumbers: (id: number) => void;
}

enum ConfirmModalType {
  CLOSE,
  DELETE,
}

interface State {
  confirmModal: boolean;
  confirmModalType: ConfirmModalType;
  loading: boolean;
}

class SBListItem extends React.Component<Props, State> {
  public state = {
    confirmModal: false,
    confirmModalType: ConfirmModalType.CLOSE,
    loading: false,
  };

  public onCloseModal = () => !this.state.loading && this.setState({ confirmModal: false });

  public handleClose = async () => {
    this.setState({ loading: true });
    const apiEndpoint =
      this.state.confirmModalType === ConfirmModalType.DELETE
        ? services.api.standby.deleteStandby
        : services.api.standby.closeStandby;
    const response = await apiEndpoint(this.props.item.id);
    if (response.isSuccess) {
      toast.dismiss();
      toast.success(
        `Standby List ${this.props.item.title} was ${
          this.state.confirmModalType === ConfirmModalType.DELETE ? 'deleted' : 'closed'
        } successfully!`
      );
      this.props.updateList();
    } else {
      console.log(response);
      toast.dismiss();
      toast.error(response.data ? response.data.errors[0].msg : `Error ${response.status}`);
    }
    this.setState({ loading: false });
    this.onCloseModal();
  };

  public render() {
    const { item, user }: any = this.props;
    const { loading } = this.state;
    return (
      <>
        <Segment className="sbl-item-container">
          <div style={{ flex: 1 }}>
            <div className="standby-list-item-icon">
              <Image src={sblIcon} />
              {item && item.hasUnreadMessages && (
                <Label color="red" floating={true} circular={true} empty={true} />
              )}
            </div>
            <div className="sbl-item-title">
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '16px',
                  display: 'flex',
                  flexGrow: 2,
                  alignItems: 'center',
                }}
              >
                <div className="ellipsisText" style={{ flexGrow: 1 }}>
                  <Link to={`/stand-by-lists/${item.id}`}>{item.title}</Link>
                </div>
                {item.privacy === 'private' && <SBPrivateLabel />}
              </div>
              <div style={{ fontSize: '13px' }}>{item.location ? item.location : ''}</div>
            </div>
          </div>

          <div>
            <div className="sbl-item-dates-section">
              {item.closedAt ? (
                <div>Closed On: {moment(item.closedAt).format('MM/DD/YYYY, hh:mm A ')}</div>
              ) : (
                <div>Expires On: {moment(item.expiresAt).format('MM/DD/YYYY')}</div>
              )}
              <div>Created On: {moment(item.createdAt).format('MM/DD/YYYY, hh:mm A ')}</div>
            </div>
            <div className="sbl-item-title" style={{ textAlign: 'center', marginLeft: '3rem' }}>
              <div style={{ fontWeight: 700, fontSize: '16px' }}>{item.adjusters.invited}</div>
              <div style={{ fontSize: '13px' }}>Sent</div>
            </div>
            <div
              className="sbl-item-title"
              style={{ textAlign: 'center', marginLeft: '3rem', marginRight: '3rem' }}
            >
              <div style={{ fontWeight: 700, fontSize: '16px' }}>
                {item.adjusters.applied + item.adjusters.contacted}
              </div>
              <div style={{ fontSize: '13px' }}>Applied/Contacted</div>
            </div>

            <CopySBLToClipboard publicId={item.publicId} />

            {user.role !== 'Read Only Office Admin' && (
              <Popup
                trigger={
                  <Button
                    onClick={() => this.props.addNumbers(item.id)}
                    className="sbl-action-button"
                  >
                    <Image src={addNumbers} />
                  </Button>
                }
                basic={true}
                size="mini"
                content="Add Numbers"
              />
            )}
            {user.role !== 'Read Only Office Admin' && (
              <Popup
                trigger={
                  <Button
                    onClick={() =>
                      this.setState({
                        confirmModal: true,
                        confirmModalType: ConfirmModalType.DELETE,
                      })
                    }
                    className="sbl-action-button"
                  >
                    <Image src={trashIconB} />
                  </Button>
                }
                basic={true}
                size="mini"
                content="Delete"
              />
            )}
            {user.role !== 'Read Only Office Admin' && (
              <Popup
                trigger={
                  <Button
                    disabled={!!item.closedAt}
                    onClick={() =>
                      this.setState({
                        confirmModal: true,
                        confirmModalType: ConfirmModalType.CLOSE,
                      })
                    }
                    className="sbl-action-button"
                  >
                    <Image src={cancelIconB} />
                  </Button>
                }
                basic={true}
                size="mini"
                content="Close"
              />
            )}
            <Popup
              trigger={
                <Link to={`/stand-by-lists/${item.id}`}>
                  <Button primary={true} className="sbl-action-button">
                    <Image src={arrowIconW} />
                  </Button>
                </Link>
              }
              basic={true}
              size="mini"
              content="Details"
            />
          </div>
        </Segment>
        <Confirm
          size="tiny"
          closeIcon={<Image src={cancelIconB} className="icon" onClick={this.onCloseModal} />}
          open={this.state.confirmModal}
          onCancel={this.onCloseModal}
          header="Confirm"
          content={`Are you sure you want to ${
            this.state.confirmModalType === ConfirmModalType.DELETE ? 'delete' : 'close'
          } standby list ${item.title}?`}
          cancelButton={{
            content: 'NO',
            disabled: loading,
          }}
          confirmButton={{
            content: 'YES',
            disabled: loading,
            loading,
          }}
          onConfirm={this.handleClose}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </>
    );
  }
}

export default SBListItem;
