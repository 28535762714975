import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Dropdown, Form } from 'semantic-ui-react';
import arrowIcon from '../../assets/images/ui-dropdown.svg';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { toast } from 'react-toastify';

const items = [
  { label: 'SMS', key: 'twilio' },
  { label: 'Personal Email', key: 'email' },
  { label: 'Compass Email', key: 'compassEmail' },
]

function RSendMessage({
  adjuster,
  match,
  userId,
  user,
  updateData,
  standby,
  setMsgObj,
  showTwilioModal,
  setRoomPage
}: {
  adjuster: any,
  match: any;
  userId: any;
  user: any;
  updateData: () => void;
  standby: any;
  setMsgObj: (obj: object) => void;
  showTwilioModal: (show: boolean) => void;
  setRoomPage: (page: number) => void;
}) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      twilio: false,
      email: true,
      compassEmail: false,
      title: '',
      text: '',
      cc: '',
      bcc: '',
    },
  });
  const watchTwilio = watch("twilio", false);
  const watchEmail = watch("email", true);
  const watchCompassEmail = watch("compassEmail", false);
  const watchAllFields: any = watch();
  const [templates, setTemplates] = useState<any[]>()
  const [templateOptions, setTemplateOptions] = useState<any[]>()
  const [showFullDescription, setShowFullDescription] = useState(false)
  const [templateId, setTemplateId] = useState<number>()
  const messagesEndBottom = useRef<HTMLDivElement>(null)

  function validateEmail() {
    return (watchAllFields.cc || "").split(',').length + (watchAllFields.bcc || "").split(',').length <= 20
  }

  async function init() {
    const response = await services.api.settings.getAllMsgTemplates();
    if (response.isSuccess) {
      const optionsArray = response.data.map((el: any) => {
        return { text: el.name, value: el.id };
      });

      setTemplateOptions(optionsArray)
      setTemplates(response.data)
    } else {
      console.log(response);
    }
  };

  function resetForm() {
    const activeTemplate = templates && templates.find((el: any) => el.id === templateId);

    reset({
      ...watchAllFields,
      title: (activeTemplate && activeTemplate.title) || '',
      text: (activeTemplate && activeTemplate.text) || '',
    })

    setRoomPage(0)
  };

  async function onSubmit() {
    console.log('watchAllFields', watchAllFields)

    const model = {
      ...watchAllFields,
      provider: [
        watchAllFields.twilio && 'twilio',
        watchAllFields.email && 'email',
        watchAllFields.compassEmail && 'compassEmail',
      ].filter(Boolean)
    }

    const obj = Object.assign({}, model, {
      recipients: { adjusters: [userId], admins: [] },
    });
    if (match.params.sbid) {
      if (!standby.twilioNumber && model.twilio) {
        setMsgObj(obj);
        showTwilioModal(true);
      } else {
        const response = await services.api.messages.sendGlobalMessage(
          obj,
          match.params.gpid,
          match.params.sbid
        );
        resetForm();
        if (response.isSuccess) {
          updateData();
          toast.dismiss();
          toast.success('The message to the standby recipient was sent successfully!');
        } else {
          toast.dismiss();
          toast.error(
            response.data.errors
              ? response.data.errors[0].msg
              : `Error ${response.status}`
          );
        }
      }
    } else {
      const responseGlobal = await services.api.messages.sendGlobalMessage(
        obj,
        match.params.gpid
      );
      resetForm();
      if (responseGlobal.isSuccess) {
        updateData();
        toast.dismiss();
        toast.success('The message was sent successfully!');
      } else {
        toast.dismiss();
        toast.error(
          responseGlobal.data.errors
            ? responseGlobal.data.errors[0].msg
            : `Error ${responseGlobal.status}`
        );
      }
    }
  }

  function onTemplateChange(e: any, data: any) {
    if (!templates) return;
    const activeTemplate = templates.find((el: any) => el.id === data.value);

    setTemplateId(data.value)
    setValue("title", activeTemplate.title)
    setValue("text", activeTemplate.text)
  };

  useEffect(() => {
    init();
  }, [])

  return (
    <>
      <div
        className={`send-message-container room-send-message ${
          showFullDescription ? 'active' : ''
        }`}
      >
        <div
          className="standby-description-toggle"
          onClick={() => {
            setShowFullDescription(!showFullDescription)
            setTimeout(() => {
              messagesEndBottom.current &&
              messagesEndBottom.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
              })
            }, 100);
          }}
        >
          Add Message
          <Image
            src={arrowIcon}
            className={
              showFullDescription
                ? 'standby-description-details-arrow active'
                : 'standby-description-details-arrow'
            }
          />
        </div>
        <hr />
        <Form
          id="send-message-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="send-message-form-container">
            <div className="input-field-container">
              <div className="input-field-label">Message Template</div>
              <div className="field-input-container">
                <div className='standby-dropdown'>
                  <Dropdown
                    name="template"
                    search={true}
                    selectOnBlur={false}
                    placeholder="Please Select"
                    className="standby-dropdown"
                    options={templateOptions}
                    onChange={onTemplateChange}
                  />
                </div>
              </div>
            </div>

            <div className="input-field-container">
              <div className="input-field-label">Title</div>
              <div className="field-input-container">
                <div className="form-input">
                  <input
                    name="title"
                    ref={register({ required: true, maxLength: 128 })}
                    maxLength={128}
                    placeholder="Enter please..."
                  />
                  {errors.title && <ErrorLabel>
                    * Title is required field.
                  </ErrorLabel>}
                </div>
              </div>
            </div>

            <div className="input-field-container">
              <div className="input-field-label">Message</div>
              <div className="field-input-container">
                <textarea
                  name="text"
                  ref={register({ required: true, maxLength: 1024 })}
                  maxLength={1024}
                  placeholder="Enter please..."
                  className="form-input"
                />
                 {!!(errors.text) && <ErrorLabel>
                  * Message is required field.
                </ErrorLabel>}
              </div>
            </div>
            <div
              className="input-field-container"
              style={{ display: !!adjuster.firstName ? 'flex' : 'none' }}
            >
              <div className="input-field-label">Send via</div>
              <div className="field-input-container">
                <div className="provider-group-container">
                  {items.map((el, index: number) => (
                    <div className={classNames("ui checkbox", {
                      checked: watchAllFields[el.key]
                    })} key={index} >
                      <input className="hidden" id={el.key} name={el.key} tabIndex={0} type="checkbox" ref={register()} disabled={
                        el.label === 'SMS' && standby && standby.closedAt
                          ? true
                          : false
                      }/>
                      <label htmlFor={el.key}>{el.label}</label>
                    </div>
                  ))}
                </div>
                {!(watchTwilio || watchEmail || watchCompassEmail) && <ErrorLabel>
                  * Send via is required field.
                </ErrorLabel>}
              </div>
            </div>
            <div className="input-field-container">
              <div className={`input-field-label ${watchEmail || watchCompassEmail ? '' : 'disabled'}`}>CC</div>
              <div className="field-input-container">
                <div className="form-input">
                  <input
                    name="cc"
                    disabled={!(watchEmail || watchCompassEmail)}
                    placeholder="Enter please..."
                    ref={register({
                      validate: validateEmail,
                    })}
                    className={classNames("ui input", {
                      disabled: !(watchEmail || watchCompassEmail)
                    })}
                  />
                  {!!(errors.cc) && <ErrorLabel>
                  * Only 20 emails are allowed in CC + BCC.
                </ErrorLabel>}
                </div>
              </div>
            </div>
            <div className="input-field-container">
              <div className={`input-field-label ${watchEmail || watchCompassEmail ? '' : 'disabled'}`}>BCC</div>
              <div className="field-input-container">
                <div className="form-input">
                  <input
                    name="bcc"
                    disabled={!(watchEmail || watchCompassEmail)}
                    placeholder="Enter please..."
                    ref={register({
                      validate: validateEmail,
                    })}
                    className={classNames("ui input", {
                      disabled: !(watchEmail || watchCompassEmail)
                    })}
                  />
                  {!!(errors.bcc) && <ErrorLabel>
                  * Only 20 emails are allowed in CC + BCC.
                </ErrorLabel>}
                </div>
              </div>
            </div>
            <div className="input-field-container">
              <div className={`input-field-label`} />
              <div className="field-input-container">
                <Button primary={true} style={{ width: '156px', height: '32px' }} type="submit">
                  Send
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div style={{ float: 'left', clear: 'both' }} ref={messagesEndBottom} />
    </>
  );
}

export default RSendMessage;
