import { ConnectedRouter } from 'connected-react-router';
import React, { Component } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/Layout.css';
import Homepage from './routes/homepage/homepage';
import services from './services';
import store, { history } from './store';

import '../node_modules/leaflet/dist/leaflet.css';

import ToasterCloseIcon from './components/system-toaster-message/ToasterCloseIcon';
import { Layout } from './routes';

toast.configure({
  transition: Slide,
  hideProgressBar: true,
  autoClose: 5000,
  closeButton: <ToasterCloseIcon />,
});

declare global {
  interface Window {
    ENV: any;
  }
}

window.ENV = window.ENV || {};

class App extends Component {
  public componentWillMount() {
    this.ssoRedirect();
  }

  public ssoRedirect = () => {
    const params = new URLSearchParams(window.location.search as string);
    const redirect_url = params.get('redirect_url');
    const token = params.get('token');
    const accessToken = services.localStorage.get('accessToken');
    if (redirect_url && token && accessToken) {
      let url;
      if (redirect_url.includes('?')) {
        url = `${redirect_url}&token=${accessToken}`;
      } else {
        url = `${redirect_url}?token=${accessToken}`;
      }
      window.open(url, '_self');
    }
  };

  public render() {
    return (
      <Provider store={store} context={ReactReduxContext}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <Router history={history}>
            <Switch>
              <Route exact={true} path="/" component={Homepage} />
              <Route path={services.router.url.profile} component={Homepage} />
              <Route path={services.router.url.staff} component={Homepage} />
              <Route path={services.router.url.createAdjuster} component={Homepage} />
              <Route path={services.router.url.adjusters} component={Homepage} />
              <Route path={services.router.url.adjusterProfile} component={Homepage} />
              <Route path={services.router.url.settings} component={Homepage} />
              <Route path={services.router.url.standby} component={Homepage} />
              <Route path={services.router.url.finances} component={Homepage} />
              <Route path={services.router.url.messages} component={Homepage} />
              <Route path={services.router.url.training_center} component={Homepage} />
              <Route path={services.router.url.messages_details} component={Homepage} />
              {/* <Route path={services.router.url.preview} component={Homepage} /> */}
              <Route path={services.router.url.login} component={Layout} />
              <Route path={services.router.url.password} component={Layout} />
              <Route path={services.router.url.resetPasswordPage} component={Layout} />
              <Route render={() => <div>404. Missed page.</div>} />
            </Switch>
          </Router>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
