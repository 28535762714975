import React from 'react';
import { Button, Image, Input, Popup, Table } from 'semantic-ui-react';
import cancelIcon from '../../../assets/images/Blue/cancel.svg';
import checkIcon from '../../../assets/images/Blue/check.svg';
import { FileFinancialIDStatus } from '../../../features/finance/reducer';
import AdjusterSelectionInput from './AdjusterSelectionInput';

interface Props {
  adjusters: any[];
  file: any;
  handleAdjusterChange: (file: any, adjuster: any) => any;
  getDefaultFinancialIdValueFromFile: (file: any) => any;
  handleFinancialIdChange: (file: any, val: any) => any;
  handleDeleteFile: (file: any) => any;
}

function FinanceFilesAdjusterFinanceSelectorCells(props: Props) {
  return (
    <React.Fragment>
      <Table.Cell className="col-adjuster">
        <AdjusterSelectionInput
          adjusters={props.adjusters}
          matchedAdjuster={props.file.matchedAdjuster}
          matchedStatus={props.file.matchedStatus}
          disabled={props.file.matchedStatus === FileFinancialIDStatus.MATCHING}
          onChange={(adjuster: any) => props.handleAdjusterChange(props.file, adjuster)}
        />
      </Table.Cell>
      <Table.Cell className="col-finacial-id" collapsing={true}>
        <Input
          placeholder="Please Enter..."
          defaultValue={props.getDefaultFinancialIdValueFromFile(props.file)}
          disabled={props.file.matchedStatus === FileFinancialIDStatus.MATCHING}
          onChange={(e: any, data: any) => props.handleFinancialIdChange(props.file, data.value)}
        />
      </Table.Cell>
      <Table.Cell className="action-col" collapsing={true}>
        <Popup
          basic={true}
          size="mini"
          content={props.file.data ? 'Uploaded' : (props.file.uploading || props.file.removing ? 'Loading' : 'Delete')}
          position="left center"
          trigger={
            props.file.data ? (
              <Image src={checkIcon} />
            ) :
            (
            <Button
              className="delete"
              loading={props.file.uploading || props.file.removing}
              onClick={() => {if (!props.file.uploading && !props.file.removing) props.handleDeleteFile(props.file)}}
            >
              {!props.file.uploading && !props.file.removing && <Image src={cancelIcon} />}
            </Button>
            )

          }
        />
      </Table.Cell>
    </React.Fragment>
  );
}

export default React.memo(FinanceFilesAdjusterFinanceSelectorCells);
