import React from 'react';
import { Image, Popup, Button, Grid, Segment } from 'semantic-ui-react';
import {
  MaterialModel,
  PaginationInfo,
} from '../../../features/training-center/trainingCenterReducer';
import { IUser } from '../../../models';
import moment from 'moment';
import downloadIcon from '../../../assets/images/White/download.svg';
import editIcon from '../../../assets/images/Blue/edit.svg';
import trashIcon from '../../../assets/images/Blue/trash.svg';
import './MaterialGridView.css';
import checkIconW from '../../../assets/images/White/check.svg';
import checkIconB from '../../../assets/images/Blue/check.svg';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as MyTypes from 'MyTypes';
import DocumentLabel from '../shared/DocumentLabel';
import { trackPromise } from 'react-promise-tracker';
import services from '../../../services';
import { api } from '../../../config';
import DocumentIcon from '../shared/DocumentIcon';
import { truncateString } from '../../../utils/helper';

const endpoint = api.endpoint;

interface Props {
  user: IUser;
  pagingInfo: PaginationInfo;
  selectedMaterials: Array<number>;
  selectMaterial: (material: MaterialModel) => void;
  downloadMaterial: (material: MaterialModel) => void;
  editMaterial: (material: MaterialModel) => void;
  deleteMaterial: (material: MaterialModel) => void;
  assignAccessMaterial: (material: MaterialModel) => void;
  onMaterialClick: (material: MaterialModel) => void;
}

interface State {}

class MaterialGridView extends React.Component<Props, State> {
  public state = {};

  public renderSelectCheckBox = (material: MaterialModel) => {
    const { selectedMaterials } = this.props;
    return (
      <div className="checkbox">
        <Popup
          trigger={
            <Button
              type="button"
              className={
                selectedMaterials.find(el => el === material.id)
                  ? 'check-button active iconic-button-custom'
                  : 'check-button iconic-button-custom'
              }
              onClick={event => {
                event.stopPropagation();
                this.props.selectMaterial(material);
              }}
            >
              <Image
                src={selectedMaterials.find(el => el === material.id) ? checkIconW : checkIconB}
              />
            </Button>
          }
          basic={true}
          size="mini"
          content={selectedMaterials.find(el => el === material.id) ? 'Deselect' : 'Select'}
        />
      </div>
    );
  };

  public renderDeleteButton(material: MaterialModel) {
    const { user } = this.props;
    if (user.isReadOnlyAdmin) {
      return null;
    }

    return (
      <div>
        <Popup
          trigger={
            <Button
              className="material-grid-view-button"
              onClick={event => {
                event.stopPropagation();
                this.props.deleteMaterial(material);
              }}
            >
              <Image src={trashIcon} />
            </Button>
          }
          basic={true}
          size="mini"
          content={'Delete file'}
        />
      </div>
    );
  }

  public renderActionRow(material: MaterialModel) {
    const { user } = this.props;
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <div style={{ width: '100%', display: 'flex' }}>{this.renderDeleteButton(material)}</div>
        <div style={{ justifyContent: 'felx-end', display: 'flex' }}>
          {!user.isReadOnlyAdmin && (
            <Popup
              trigger={
                <Button
                  className="material-grid-view-button"
                  onClick={event => {
                    event.stopPropagation();
                    this.props.editMaterial(material);
                  }}
                >
                  <Image src={editIcon} />
                </Button>
              }
              basic={true}
              size="mini"
              content={'Edit'}
            />
          )}
          {material.type === 'document' && (
            <Popup
              trigger={
                <Button
                  className="material-grid-view-button"
                  primary={true}
                  onClick={async event => {
                    event.stopPropagation();
                    await this.props.downloadMaterial(material);
                  }}
                >
                  <Image src={downloadIcon} />
                </Button>
              }
              basic={true}
              size="mini"
              content={'Download File'}
            />
          )}
        </div>
      </div>
    );
  }

  public async openFile(material: MaterialModel) {
    const url = await services.api.file.getDownloadFileUrl(material.file.key);

    if (material.mimetype.includes('image')) {
      window.open(url, '_blank');
      // location.assign(url);
    } else {
      const previewLink = `https://docs.google.com/gview?url=${url}&embedded=true`;
      window.open(previewLink, '_blank');
      // location.assign(previewLink);
    }
  }

  public viewVideoUrl = (url: string) => {
    window.open(url, '_blank');
  };

  public renderGridColumn(material: MaterialModel) {
    const thumbnailUrl =
      material.thumbnailUrl || 'https://react.semantic-ui.com/images/wireframe/paragraph.png';

    return (
      <Grid.Column key={material.id}>
        <Segment>
          <Segment onClick={() => this.props.onMaterialClick(material)}>
            <div className="material-gridView-image-container">
              {material.type === 'document' &&
                (material.mimetype.includes('image') ? (
                  <Image
                    src={`${endpoint}public/thumbnail?fileKey=${material.file.key}`}
                    className="material-thumbnail"
                  />
                ) : (
                  <DocumentIcon type={material.mimetype} />
                ))}
              {material.type === 'embedded-video' && (
                <ReactPlayer url={material.videoUrl} className="material-thumbnail" />
              )}
              {this.renderSelectCheckBox(material)}
              <DocumentLabel type={material.mimetype} />
            </div>
          </Segment>
          <div className="gridview-materialName">
            {material.type === 'document' ? (
              <a
                style={{
                  cursor: 'pointer',
                }}
                onClick={e => {
                  // e.stopPropagation()
                  this.openFile(material);
                }}
              >
                {truncateString(material.name, 20)}
              </a>
            ) : (
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.viewVideoUrl(material.videoUrl);
                }}
              >
                {truncateString(material.name, 20)}
              </a>
            )}
          </div>
          <div className="gridview-materialDescription">{material.description}</div>
          <div className="gridView-materialTime">
            {moment(material.updatedAt).format('MM/DD/YYYY, hh:mm A')}
          </div>
          <div>{this.renderActionRow(material)}</div>
        </Segment>
      </Grid.Column>
    );
  }

  public render() {
    const { pagingInfo } = this.props;
    const { records } = pagingInfo;
    if (!records || records.length === 0) {
      return null;
    }
    return (
      <Grid stackable columns={4}>
        {records.map(t => this.renderGridColumn(t))}
      </Grid>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pagingInfo: state.trainingCenter.material.pagingInfo,
  selectedMaterials: state.trainingCenter.material.pagingInfo.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MaterialGridView);
