const REACT_APP_S3_ADDRESS = window.ENV
  ? window.ENV.REACT_APP_S3_ADDRESS
  : process.env.REACT_APP_S3_ADDRESS;

export default {
  maxFileSize: 5 * 1024 * 1024,
  maxFileSizeForPaperworks: 10 * 1024 * 1024,
  maxFileSizeErrorMsg: 'Max size limit per file is 5MB!',
  s3BucketAddress: REACT_APP_S3_ADDRESS,
};
