import React from 'react';
import { Form } from 'formsy-semantic-ui-react';
import { Button } from 'semantic-ui-react';
import ErrorLabel from '../../error-label/ErrorLabel';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import UploadDocumentItem from '../shared/UploadDocumentItem';
import { DocumentData, MAX_FILE_SIZE } from '../shared/types';
import { toast } from 'react-toastify';
import FolderInputTags from '../shared/FolderInputTags';

interface Props {
  onFinish(data: DocumentData[]): void;
  setSubmitRef(ref: any): void;
}

interface State {
  documents: any[];
  files: File[];
  dragging: boolean;
  searchValue: string;
  isLoading: boolean;
  selectedFolders: any[];
  allowedTypes: string[];
}

const maxSizeInMB = (MAX_FILE_SIZE / 1024 / 1024).toFixed(2);

class UploadDocument extends React.Component<Props, State> {
  public submitRef: any = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      files: [],
      dragging: false,
      documents: [],
      isLoading: false,
      searchValue: '',
      selectedFolders: [],
      allowedTypes: [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/rtf',
        'image/webp',
        'application/zip',
        'application/zip-compressed',
        'application/x-zip-compressed',
      ],
    };
  }

  public componentDidMount() {
    this.props.setSubmitRef(this.submitRef);
  }

  public setFolders = (folders: any[]) => {
    this.setState({ selectedFolders: folders });
  };

  public handleDrop = (files: any[]) => {
    this.setState({ dragging: false });
    const docs = [];
    let startingIndex = 0;
    let endingIndex = files.length;

    if (this.state.documents.length > 0) {
      startingIndex = this.state.documents.length;
      endingIndex = startingIndex + files.length;
    }

    for (let i = startingIndex; i < endingIndex; i++) {
      docs.push({
        index: i,
        filename: files[i - startingIndex].name,
        name: '',
        description: '',
      });
    }

    this.setState({
      files: [...this.state.files, ...files],
      documents: [...this.state.documents, ...docs],
    });
  };

  public handleDropRejected = (files: any[]) => {
    const fileToLargeCode = 'file-too-large';
    for (const file of files) {
      if (file.errors[0].code === fileToLargeCode) {
        toast.error(`${file.file.name}: File is larger than ${maxSizeInMB}MB`);
      } else {
        toast.error(`${file.file.name}: ${file.errors[0].message}`);
      }
    }
  };

  public handleFileListClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  public handleDragEnter = () => {
    this.setState({
      dragging: true,
    });
  };

  public handleDragLeave = () => {
    this.setState({
      dragging: false,
    });
  };

  public onFileDelete = (index: number) => {
    this.setState((prevState: State) => {
      return {
        files: prevState.files.filter((_, i) => {
          return i !== index;
        }),
        documents: prevState.documents.filter((_, i) => {
          return i !== index;
        }),
      };
    });
  };

  public onValidFormSubmit = (model: any) => {
    console.log({
      state: this.state,
    });

    if (this.state.documents.length === 0) {
      toast.error('No document to upload was provided');
      return;
    }

    if (this.state.selectedFolders.length === 0) {
      toast.error('No folders assigned');
      return;
    }

    const documents: DocumentData[] = [];
    for (let i = 0; i < this.state.documents.length; i++) {
      documents.push({
        name: model['filename_' + i],
        description: model['description_' + i],
        file: this.state.files[i],
        folderIds: this.state.selectedFolders.map(f => f.id),
      } as DocumentData);
    }

    let isValid = true;

    for (let doc of documents) {
      console.error({ error: doc.file.type });
      if (!this.state.allowedTypes.includes(doc.file.type)) {
        toast.error(`${doc.name}(${doc.file.name}) of type ${doc.file.type} is not allowed`);
        isValid = false;
        break;
      }

      if (doc.file.size > MAX_FILE_SIZE) {
        toast.error(`${doc.file.name}: File is larger than ${maxSizeInMB}MB`);
        isValid = false;
        break;
      }
    }

    if (isValid) {
      this.props.onFinish(documents);
    }
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    const { files } = this.state;

    return (
      <Form className="upload-document" onValidSubmit={this.onValidFormSubmit}>
        <Dropzone
          onDrop={this.handleDrop}
          onDropRejected={this.handleDropRejected}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          maxFiles={10}
          maxSize={MAX_FILE_SIZE}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: classnames('main-content', { 'empty-files': files.length === 0 }),
              })}
            >
              <input {...getInputProps()} />
              {files.length > 0 && (
                <div className="file-list" onClick={this.handleFileListClick}>
                  {files.map((file: any, index: number) => (
                    <UploadDocumentItem
                      key={file.name + '_' + index}
                      name={file.name}
                      onDelete={() => this.onFileDelete(index)}
                    />
                  ))}
                </div>
              )}
              <div className="description">
                <div>Drop file(s) here or</div>
                <div style={{ cursor: 'pointer' }}>UPLOAD</div>
              </div>
            </div>
          )}
        </Dropzone>
        <React.Fragment>
          {this.state.documents.map((d, i) => {
            return (
              <React.Fragment key={i}>
                {<div className={i === 0 ? 'small-spacer' : 'spacer'} />}
                <div className="input-field-container">
                  <div
                    className="input-field-label"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Filename ({d.filename})
                  </div>
                  <Form.Input
                    className="form-input"
                    name={`filename_${d.index}`}
                    instantValidation={false}
                    required={true}
                    placeholder="Please Enter..."
                    validationErrors={{
                      isDefaultRequiredValue: 'Please provide the file name',
                    }}
                    errorLabel={errorLabel}
                    value={d.name}
                    defaultValue={d.filename}
                  />
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Description</div>
                  <Form.Input
                    className="form-input"
                    name={`description_${d.index}`}
                    placeholder="Please Enter..."
                    instantValidation={false}
                    required={true}
                    validationErrors={{
                      isDefaultRequiredValue: 'Please provide the document description',
                    }}
                    errorLabel={errorLabel}
                    value={d.description}
                    defaultValue={d.filename}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </React.Fragment>
        <div className="small-spacer" />
        <div className="input-field-container">
          <FolderInputTags
            title="Folder"
            folders={this.state.selectedFolders}
            setFolders={this.setFolders}
          />
        </div>
        <Button
          ref={this.submitRef}
          type="submit"
          formNoValidate={true}
          style={{ display: 'none' }}
        />
      </Form>
    );
  }
}

export default UploadDocument;
