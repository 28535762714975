import config from '../config';

const version = config.common.version;
const STORAGE_KEY = `__SERIALIZED_STATE_TREE_v${version}__`;

export function save<T = object>(key: string, value: T): boolean {
  if (!localStorage) {
    return false;
  }
  try {
    if (null === value || undefined === value) {
      return false;
    }
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
    return true;
  } catch (error) {
    throw new Error('save serialization failed');
  }
}

export function get<T = object>(key: string): T | undefined {
  if (!localStorage) {
    return;
  }

  try {
    const serialized = localStorage.getItem(key);
    if (serialized === null || serialized === undefined) {
      return;
    }
    return JSON.parse(serialized);
  } catch (error) {
    // localStorage.removeItem(key);
    console.log(new Error('get deserialization failed'));
    return;
  }
}

export function remove<T = object>(key: string): boolean {
  if (!localStorage) {
    return false;
  }
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    throw new Error('remove failed');
  }
}

export function saveState<T = object>(storeState: T): boolean {
  if (!localStorage) {
    return false;
  }

  try {
    const serializedState = JSON.stringify(storeState);
    localStorage.setItem(STORAGE_KEY, serializedState);
    return true;
  } catch (error) {
    throw new Error('store serialization failed');
  }
}

export function loadState<T = object>(): T | undefined {
  if (!localStorage) {
    return;
  }

  try {
    const serializedState = localStorage.getItem(STORAGE_KEY);
    if (serializedState === null || serializedState === undefined) {
      return;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}
