import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import '../../assets/styles/Messages.css';
import MessagesListHeader from '../../components/message-layout/MessageListHeader';
import MessagesList from '../../components/message-layout/MessagesList';
import { NoResults } from '../../components/no-results-placeholder/NoResults';
import { IUser } from '../../models';

interface Props {
  user: IUser;
}

interface State {}

class Messages extends React.Component<Props, State> {
  public state = {};

  public render() {
    return (
      <div>
        <MessagesListHeader />
        <MessagesList />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(Messages);
