import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Dropdown, Icon, Image, Menu, MenuItemProps, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import '../../assets/styles/Staff.css';
import { standbyActions } from '../../features/standby';
import services from '../../services';

interface Props {
  getStandbyList: typeof standbyActions.getStandbyList;
  setStandbyTab: typeof standbyActions.setStandbyTab;
  setStandbySort: typeof standbyActions.setStandbySort;
  setStandbySearch: typeof standbyActions.setStandbySearch;
  editStandbyList: typeof standbyActions.editStandbyList;
  setActiveStandbyTwilio: typeof standbyActions.setActiveStandbyTwilio;
  setActivePage: typeof standbyActions.setActivePage;
  activeTab: string;
  activePage: number;
  sort: string;
  user: any;
  search: string;
}

interface State {}

const sortOptions = [
  {
    key: 'Creation Date',
    text: 'Creation Date',
    value: 'createdAt,DESC',
  },
  {
    key: 'Title Asc',
    text: 'Title',
    value: 'title,ASC',
  },
];

class StandByHeader extends React.Component<Props, State> {
  public componentDidMount = () => {
    this.props.getStandbyList({ page: 0, pageSize: 10 });
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.props.getStandbyList({ page: 0, pageSize: 10 });
    }
    if (prevProps.sort !== this.props.sort) {
      this.props.getStandbyList({ page: 0, pageSize: 10 });
    }
    if (prevProps.search !== this.props.search) {
      this.props.getStandbyList({ page: 0, pageSize: 10 });
    }
    if (prevProps.activePage !== this.props.activePage) {
      this.props.getStandbyList({ page: 0, pageSize: 10 });
    }
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.props.setStandbyTab(name);
    this.props.setActivePage(1);
  };

  public handleSort = (e: any, data: any) => {
    this.props.setStandbySort(data.value);
  };

  public handleSearch = (e: any, data: any) => {
    this.props.setStandbySearch(data.value);
    this.props.setActivePage(1);
  };

  public render() {
    const { activeTab, user } = this.props;

    return (
      <div className="adjusters-list-header sbl-details-table-header">
        <Menu text={true} className="tabs-menu">
          <Menu.Item name="Active" active={activeTab === 'Active'} onClick={this.handleItemClick}>
            Active
          </Menu.Item>
          <Menu.Item
            name="Completed"
            active={activeTab === 'Completed'}
            onClick={this.handleItemClick}
          >
            Completed
          </Menu.Item>
        </Menu>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className="sort-field">
            Sort by:{' '}
            <Dropdown
              inline={true}
              options={sortOptions}
              value={this.props.sort}
              onChange={this.handleSort}
            />
          </span>
          <Search
            input={{ iconPosition: 'left' }}
            icon={
              <Icon>
                <Image src={searchIcon} />
              </Icon>
            }
            placeholder="Search..."
            onSearchChange={this.handleSearch}
            value={this.props.search}
            showNoResults={false}
          />
          {user.role !== 'Read Only Office Admin' && (
            <Button
              className="add-admin-button"
              onClick={() => {
                this.props.editStandbyList(false);
                this.props.setActiveStandbyTwilio(undefined);
                services.router.goto(services.router.url.stand_by_list_form);
              }}
            >
              + Create
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  activeTab: state.standby.standby.activeTab,
  sort: state.standby.standby.standbySort,
  search: state.standby.standby.standbySearch,
  activePage: state.standby.standby.activeListPage,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getStandbyList: standbyActions.getStandbyList,
      setStandbyTab: standbyActions.setStandbyTab,
      setStandbySort: standbyActions.setStandbySort,
      setStandbySearch: standbyActions.setStandbySearch,
      editStandbyList: standbyActions.editStandbyList,
      setActiveStandbyTwilio: standbyActions.setActiveStandbyTwilio,
      setActivePage: standbyActions.setActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandByHeader);
