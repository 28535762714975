import React from 'react';

interface Props {
  question: any;
}

interface State {}

class SBQuestion extends React.Component<Props, State> {
  public render() {
    const { question } = this.props;
    return (
      <div className="question-container standby-question-adjuster">
        <div className="question-order">{question.question.order + 1}</div>
        <div className="standby-question-title">{question.question.text}</div>
        <div className="standby-question-answer">{question.text}</div>
      </div>
    );
  }
}

export default SBQuestion;
