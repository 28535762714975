import * as account from './api/account';
import * as adjuster from './api/adjuster';
import * as admin from './api/admin';
import * as aws from './api/aws';
import * as dashboard from './api/dashboard';
import * as file from './api/file';
import * as finance from './api/finance';
import * as map from './api/map';
import * as messages from './api/messages';
import * as settings from './api/settings';
import * as standby from './api/standby';
import * as user from './api/user';
import * as folder from './api/folderApi';
import * as event from './api/eventApi';
import * as material from './api/material.api';

export {
  account,
  admin,
  aws,
  user,
  standby,
  file,
  dashboard,
  settings,
  finance,
  adjuster,
  map,
  messages,
  folder,
  event,
  material
};
