import moment from 'moment';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Image } from 'semantic-ui-react';
import noAvatar from '../../assets/images/no-avatar.svg';
import { uploads } from '../../config';
import { messagesActions } from '../../features/messages';
import * as helper from '../../utils/helper';
import { NewMsgSeparator } from './NewMsgSeparator';

interface Props {
  setActiveRoomPage: typeof messagesActions.setActiveRoomPage;
  activeRoomPage: number;
  roomMessagesCount: number;
  // getMessages: () => void;
  loadMessages: () => void;
  roomMessages: object[];
  adjuster: object;
}

interface State {}

class RMessagesList extends React.Component<Props, State> {
  public state = {};

  public el = {};

  public messagesEnd = React.createRef();

  public scrollAtBottom = true;
  public topMessage = null;

  constructor(props: Props) {
    super(props);

    this.messageListRef = element => {
      this.messageList = element;
    };
  }

  public componentDidUpdate = (prevProps: Props) => {
    if (this.scrollAtBottom) {
      this.scrollToBottom();
    }

    if (prevProps !== this.props) {
      const { messageList } = this;
      const scrollPos = messageList.scrollTop;
      const scrollBottom = messageList.scrollHeight - messageList.clientHeight;
      this.scrollAtBottom = scrollBottom <= 0 || scrollPos === scrollBottom;
    }
  };

  public scrollToBottom = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  public handleScroll = (e: any) => {
    const { roomMessagesCount, loadMessages, activeRoomPage, setActiveRoomPage } = this.props;
    const totalPages = helper.getTotalPage(roomMessagesCount, 15);
    if (e.target.scrollTop === 0 && activeRoomPage !== totalPages - 1) {
      setActiveRoomPage(activeRoomPage + 1);
      loadMessages();
    }
  };

  public render() {
    const { adjuster, roomMessages } = this.props;
    const firstUnread = roomMessages.findIndex(el => el.unread);

    return (
      <div
        className="room-messages-list-container"
        ref={this.messageListRef}
        onScroll={this.handleScroll}
      >
        {roomMessages.map((message: any, index: number) => {
          return (
            <React.Fragment key={index}>
              {index === firstUnread ? <NewMsgSeparator /> : null}
              <div className={`room-message-container ${message.sender ? 'from' : ''}`} key={index}>
                <div>
                  {message.sender && (
                    <Image
                      circular={true}
                      src={
                        message.sender.avatar
                          ? uploads.s3BucketAddress + message.sender.avatar
                          : noAvatar
                      }
                      avatar={true}
                    />
                  )}
                  {message.admin && !message.sender && (
                    <Image
                      src={
                        message.admin.avatar
                          ? uploads.s3BucketAddress + message.admin.avatar
                          : noAvatar
                      }
                      avatar={true}
                    />
                  )}
                  {!message.admin && !message.sender && <Image src={noAvatar} avatar={true} />}
                </div>
                <div className={`room-message-body ${message.sender ? 'from' : ''}`}>
                  <div className={`room-msg-title ${message.sender ? 'from' : ''}`}>
                    {message.sender
                      ? `${
                          message.sender.preferredName
                            ? message.sender.preferredName
                            : message.sender.firstName
                        } ${message.sender.lastName} `
                      : message.admin
                      ? `${message.admin.firstName} ${message.admin.lastName}`
                      : 'System'}
                    <span className="msg-info-room">
                      {moment(message.updatedAt).format('MM/DD/YYYY, hh:mm A')}{' '}
                      {message.sender ? 'from' : 'to'}{' '}
                      {message.provider.includes('twilio')
                        ? `Phone: ${
                            adjuster.mobilePhone
                              ? helper.formatPhone(adjuster.mobilePhone, '(###) ###-####')
                              : 'N/A'
                          }, `
                        : ''}
                      {message.provider.includes('email')
                        ? `Personal Email: ${
                            message.sender && message.sender.email
                              ? message.sender.email
                              : adjuster.email
                              ? adjuster.email
                              : 'N/A'
                          }, `
                        : ''}
                      {message.provider.includes('compassEmail')
                        ? `Compass Email: ${
                            message.sender && message.sender.compassEmail
                              ? message.sender.compassEmail
                              : adjuster.compassEmail
                              ? adjuster.compassEmail
                              : 'N/A'
                          }, `
                        : ''}
                    </span>
                  </div>
                  <div className={`room-msg-text ${message.sender ? 'from' : ''}`}>
                    <pre>{message.text}</pre>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
        <div style={{ float: 'left', clear: 'both' }} ref={this.messagesEnd} />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  activeRoomPage: state.messages.messages.activeRoomPage,
  roomMessagesCount: state.messages.messages.roomMessagesCount,
  roomMessages: state.messages.messages.roomMessages,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveRoomPage: messagesActions.setActiveRoomPage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RMessagesList);
