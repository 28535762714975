import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { User } from '../../models';
import services from '../../services';
import * as accountActions from '../account/actions';
import * as loadingActions from '../loading/actions';
import * as modalActions from '../modal/actions';
import * as actions from './actions';
import * as constants from './constants';

export default function*() {
  yield takeEvery(constants.PROFILE_SAVE_ATTEMPT, profileSaveAttempt);
  yield takeEvery(constants.EMAIL_VERIFICATION_REQUEST_ATTEMPT, emailVerificationAttempt);
  yield takeEvery(constants.EMAIL_VERIFICATION_REQUEST_SEND, emailVerificationSend);
}

export function* profileSaveAttempt(action: ActionType<typeof actions.profileSaveAttempt>) {
  yield put(loadingActions.show());

  const userRequest = yield call(services.api.user.updateProfile, action.payload);
  if (userRequest.isSuccess) {
    const user = userRequest.data;
    if (userRequest.data.avatar !== '' && userRequest.data.avatar) {
      const avatarCall = yield call(services.api.account.downloadFile, userRequest.data.avatar);
      if (avatarCall.isSuccess) {
        user.avatar = avatarCall.data.downloadURL;

        yield put(accountActions.updateUserData(User.create(user)));

        if (user.emailConfirmed) {
          toast.dismiss();
          toast.success('The account details were saved successfully!');
        } else {
          toast.dismiss();
          toast.success(
            'The account details were saved successfully! Check your mailbox to verify your email.'
          );
        }
      } else {
        toast.dismiss();
        toast.error(avatarCall.data.errors[0].msg);
      }
    } else {
      yield put(accountActions.updateUserData(User.create(user)));

      if (user.emailConfirmed) {
        toast.dismiss();
        toast.success('The account details were saved successfully!');
      } else {
        toast.dismiss();
        toast.success(
          'The account details were saved successfully! Check your mailbox to verify your email.'
        );
      }
    }
  } else {
    toast.dismiss();
    toast.error(userRequest.data.errors[0].msg);
  }

  yield put(loadingActions.hide());
}

export function* emailVerificationAttempt(
  action: ActionType<typeof actions.emailVerificationRequestAttempt>
) {
  yield put(
    modalActions.confirmationModalDisplay({
      message: 'When you send a new message, the code from the old one is no longer valid.',
      okAction: () => actions.emailVerificationRequestSend(),
    })
  );
}

export function* emailVerificationSend(
  action: ActionType<typeof actions.emailVerificationRequestSend>
) {
  yield put(loadingActions.show());

  const request = yield call(services.api.user.sendEmailVerificationRequest);

  if (request.isSuccess) {
    yield put(
      modalActions.alertModalDisplaySuccess({
        message:
          "We sent a message to your mailbox. If you don't receive it in 10 minutes, please check in SPAM folder.",
      })
    );
  } else {
    yield put(
      modalActions.alertModalDisplayError({
        message: request.getError(),
      })
    );
  }

  yield put(loadingActions.hide());
}
