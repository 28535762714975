export default {
    'AL': "Alabama",
    "Alabama": "AL",
    'AK': "Alaska",
    "Alaska": "AK",
    'AS': "American Samoa",
    "American Samoa": "AS",
    'AZ': "Arizona",
    "Arizona": "AZ",
    'AR': "Arkansas",
    "Arkansas": "AR",
    'CA': "California",
    "California": "CA",
    'CO': "Colorado",
    "Colorado": "CO",
    'CT': "Connecticut",
    "Connecticut": "CT",
    'DE': "Delaware",
    "Delaware": "DE",
    'DC': "District Of Columbia",
    "District Of Columbia": "DC",
    'FM': "Federated States Of Micronesia",
    "Federated States Of Micronesia": "FM",
    'FL': "Florida",
    "Florida": "FL",
    'GA': "Georgia",
    "Georgia": "GA",
    'GU': "Guam",
    "Guam": "GU",
    'HI': "Hawaii",
    "Hawaii": "HI",
    'ID': "Idaho",
    "Idaho": "ID",
    'IL': "Illinois",
    "Illinois": "IL",
    'IN': "Indiana",
    "Indiana": "IN",
    'IA': "Iowa",
    "Iowa": "IA",
    'KS': "Kansas",
    "Kansas": "KS",
    'KY': "Kentucky",
    "Kentucky": "KY",
    'LA': "Louisiana",
    "Louisiana": "LA",
    'ME': "Maine",
    "Maine": "ME",
    'MH': "Marshall Islands",
    "Marshall Islands": "MH",
    'MD': "Maryland",
    "Maryland": "MD",
    'MA': "Massachusetts",
    "Massachusetts": "MA",
    'MI': "Michigan",
    "Michigan": "MI",
    'MN': "Minnesota",
    "Minnesota": "MN",
    'MS': "Mississippi",
    "Mississippi": "MS",
    'MO': "Missouri",
    "Missouri": "MO",
    'MT': "Montana",
    "Montana": "MT",
    'NE': "Nebraska",
    "Nebraska": "NE",
    'NV': "Nevada",
    "Nevada": "NV",
    'NH': "New Hampshire",
    "New Hampshire": "NH",
    'NJ': "New Jersey",
    "New Jersey": "NJ",
    'NM': "New Mexico",
    "New Mexico": "NM",
    'NY': "New York",
    "New York": "NY",
    'NC': "North Carolina",
    "North Carolina": "NC",
    'ND': "North Dakota",
    "North Dakota": "ND",
    'MP': "Northern Mariana Islands",
    "Northern Mariana Islands": "MP",
    'OH': "Ohio",
    "Ohio": "OH",
    'OK': "Oklahoma",
    "Oklahoma": "OK",
    'OR': "Oregon",
    "Oregon": "OR",
    'PW': "Palau",
    "Palau": "PW",
    'PA': "Pennsylvania",
    "Pennsylvania": "PA",
    'PR': "Puerto Rico",
    "Puerto Rico": "PR",
    'RI': "Rhode Island",
    "Rhode Island": "RI",
    'SC': "South Carolina",
    "South Carolina": "SC",
    'SD': "South Dakota",
    "South Dakota": "SD",
    'TN': "Tennessee",
    "Tennessee": "TN",
    'TX': "Texas",
    "Texas": "TX",
    'UT': "Utah",
    "Utah": "UT",
    'VT': "Vermont",
    "Vermont": "VT",
    'VI': "Virgin Islands",
    "Virgin Islands": "VI",
    'VA': "Virginia",
    "Virginia": "VA",
    'WA': "Washington",
    "Washington": "WA",
    'WV': "West Virginia",
    "West Virginia": "WV",
    'WI': "Wisconsin",
    "Wisconsin": "WI",
    'WY': "Wyoming",
    "Wyoming": "WY"
}