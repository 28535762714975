import { push } from 'connected-react-router';
import store from '../store';

export const url = {
  homepage: '/',

  login: '/login',
  password: '/password',
  register: '/create-free-account',

  createAdjuster: '/create-adjuster',

  resetPasswordPage: '/reset-password',

  emailVerification: '/account/verify',

  adjusterProfile: '/adjusters/:id(\\d+)',

  profile: '/profile',
  staff: '/staff',
  adjusters: '/adjusters',
  settings: '/settings',
  standby: '/stand-by-lists',
  finances: '/finances',
  messages: '/messages',
  training_center: '/training-center',
  messages_details: '/messages/:id(\\d+)',
  messages_room: '/messages/room/:id(\\d+)/:sbid(\\d+)?',

  terms_of_use: '/terms',
  privacy_policy: '/privacy-policy',

  stand_by_list_form: '/stand-by-lists/create',
  stand_by_list_form_edit: '/stand-by-lists/edit/:id(\\d+)',

  stand_by_list_details: '/stand-by-lists/:id(\\d+)',
  stand_by_list_answers: '/stand-by-lists/answers/:id(\\d+)',
  stand_by_list_message: '/stand-by-lists/message/:sbid(\\d+)/:id(\\d+)',

  // preview: '/preview  '
};

export const goto = (path: string) => {
  store.dispatch(push(path));
};

export const gotoWithState = (path: string, state: object) => {
  store.dispatch(push(path, state));
};
