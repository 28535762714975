import MyTypes from 'MyTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';

import { Dimmer, Image, Loader, Progress } from 'semantic-ui-react';
import LayoutHeader from '../components/layout-header/LayoutHeader';
import { accountActions } from '../features/account';
import { IUser } from '../models';
import services from '../services';

import AlertModal from '../containers/alert-modal/AlertModal';
import ConfirmationModal from '../containers/confirmation-modal/ConfirmationModal';

import EmailVerificationPage from './account/email-verify';
import LoginPage from './account/login';
import ResetPassword from './account/reset-password';

interface Props {
  isAuthenticationChecked: boolean;
  isLoading: boolean;
  pathname: string;
  loadingProgress: number;
  user?: IUser;
  checkAuthentication: () => void;
  logout: () => void;
}

class LayoutComponent extends React.Component<Props> {
  public componentWillMount(): void {
    this.props.checkAuthentication();
  }

  public render() {
    const { user } = this.props;

    if (!this.props.isAuthenticationChecked) {
      return (
        <Dimmer active={true} page={true}>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      );
    }

    return (
      <div>
        <LayoutHeader
          user={user}
          logout={this.props.logout}
          pathname={this.props.pathname}
          hideWarningMessage={
            -1 !==
            [
              services.router.url.register,
              services.router.url.login,
              services.router.url.emailVerification,
              services.router.url.profile,
            ].indexOf(window.location.pathname)
          }
        />
        <AlertModal />
        <ConfirmationModal />

        <Dimmer active={this.props.isLoading} page={true}>
          {this.props.loadingProgress ? (
            <Progress
              percent={this.props.loadingProgress}
              active={true}
              progress={true}
              style={{ width: '200px' }}
              inverted={true}
            >
              Please wait
            </Progress>
          ) : (
            <Loader size="massive">Please wait</Loader>
          )}
        </Dimmer>

        <Route path={services.router.url.login} component={LoginPage} />
        <Route path={services.router.url.resetPasswordPage} component={ResetPassword} />

        <Route path={services.router.url.emailVerification} component={EmailVerificationPage} />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  isLoading: state.loading.loading.isVisible,
  loadingProgress: state.loading.loading.progress,
  pathname: state.router.location.pathname,
  user: state.account.account.user,
  isAuthenticationChecked: state.account.account.isAuthenticationChecked,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkAuthentication: accountActions.checkAuthentication,
      logout: accountActions.logout,
    },
    dispatch
  );

export const Layout = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutComponent);
