export const GET_ADMIN_LIST = 'admin/GET_ADMIN_LIST';

export const GET_ADMIN_LIST_SUCCESS = 'admin/GET_ADMIN_LIST_SUCCESS';

export const RESET_ADMIN_LIST = 'admin/RESET_ADMIN_LIST';

export const GET_ALL_QUESTIONNARIES = 'admin/GET_ALL_QUESTIONNARIES';

export const SAVE_QUESTIONNARIES = 'admin/SAVE_QUESTIONNARIES';

export const GET_ALL_MSG_TEMPLATES = 'admin/GET_ALL_MSG_TEMPLATES';

export const SAVE_MSG_TEMPLATES = 'admin/SAVE_MSG_TEMPLATES';
