import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button, Grid, Image, Popup } from 'semantic-ui-react';
import trashIcon from '../../assets/images/Blue/trash.svg';
import handleIcon from '../../assets/images/ui-handle.svg';
import { IUser } from '../../models';
import ErrorLabel from '../error-label/ErrorLabel';

import { withFormsy } from 'formsy-react';

interface QuestionModel {
  id: number;
  text: string;
  answerType: string;
  createdAt: string;
  updatedAt: string;
  questionnaireId: number;
  order: number;
  isNew: boolean;
}

interface Props {
  question: QuestionModel;
  ref: any;
  index: number;
  deleteQuestion: (id: number) => void;
  editQuestion: (id: number, question: object) => void;
  user: IUser;
}

interface State {
  id: number;
  text: string;
  answerType: string;
  createdAt: string;
  updatedAt: string;
  questionnaireId: number;
  order: number;
  isNew: boolean;
}

class QuestionFormsy extends React.Component<Props, State> {
  public state = {
    isNew: true,
    id: 0,
    text: '',
    answerType: '',
    createdAt: '',
    updatedAt: '',
    questionnaireId: 0,
    order: 0,
  };

  public componentDidMount() {
    this.setState({
      id: this.props.question.id,
      text: this.props.question.text,
      answerType: this.props.question.answerType,
      createdAt: this.props.question.createdAt,
      updatedAt: this.props.question.updatedAt,
      questionnaireId: this.props.question.questionnaireId,
      order: this.props.question.order,
      isNew: this.props.question.isNew,
    });
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.question !== this.props.question) {
      this.setState({
        id: this.props.question.id,
        text: this.props.question.text,
        answerType: this.props.question.answerType,
        createdAt: this.props.question.createdAt,
        updatedAt: this.props.question.updatedAt,
        questionnaireId: this.props.question.questionnaireId,
        order: this.props.question.order,
      });
    }
    if (prevProps.question.order !== this.props.question.order) {
      this.setState({
        order: this.props.question.order,
      });
    }
  }

  public render() {
    const { id, text, answerType, order, questionnaireId, isNew } = this.state;
    const errorLabel = <ErrorLabel />;
    return (
      <Draggable draggableId={`${this.state.id}`} index={this.props.index}>
        {provided => (
          <div className="question-container" {...provided.draggableProps} ref={provided.innerRef}>
            <div className="question-order">{order + 1}</div>
            <div className="question-text">
              <Form.Input
                name="text"
                maxLength={200}
                required={true}
                placeholder="Please Enter..."
                disabled={this.props.user.role === 'Read Only Office Admin'}
                validations={{
                  isOnlySpaces: true,
                }}
                validationErrors={{
                  isDefaultRequiredValue: '* Question is required field.',
                  isOnlySpaces: '* Question is required field.',
                }}
                errorLabel={errorLabel}
                value={text}
                onChange={(e: any) => {
                  this.setState({ text: e.target.value });
                  this.props.editQuestion(id, {
                    id,
                    text: e.target.value,
                    answerType,
                    questionnaireId,
                    order,
                    isNew,
                  });
                }}
              />
            </div>
            <div>Answer</div>
            <div className="question-type">
              <div className="toggle-block">
                <Button
                  type="button"
                  disabled={this.props.user.role === 'Read Only Office Admin'}
                  className={answerType === 'Yes/No' ? 'yes-btn' : 'no-btn'}
                  onClick={() => {
                    this.setState({ answerType: 'Yes/No' });
                    this.props.editQuestion(id, {
                      id,
                      text,
                      answerType: 'Yes/No',
                      questionnaireId,
                      order,
                      isNew,
                    });
                  }}
                >
                  YES/NO
                </Button>
                <Button
                  type="button"
                  disabled={this.props.user.role === 'Read Only Office Admin'}
                  className={answerType !== 'Yes/No' ? 'yes-btn' : 'no-btn'}
                  onClick={() => {
                    this.setState({ answerType: 'Text' });
                    this.props.editQuestion(id, {
                      id,
                      text,
                      answerType: 'Text',
                      questionnaireId,
                      order,
                      isNew,
                    });
                  }}
                >
                  TEXT
                </Button>
              </div>
            </div>
            <div className="question-icon-container">
              <Grid columns="2" divided={true}>
                <Grid.Row>
                  <Grid.Column>
                    <Popup
                      trigger={
                        <Button
                          type="button"
                          className="iconic-button-custom"
                          disabled={this.props.user.role === 'Read Only Office Admin'}
                          onClick={() => {
                            if (this.props.user.role === 'Read Only Office Admin') {
                              return;
                            }
                            this.props.deleteQuestion(id);
                          }}
                        >
                          <Image className="trash-icon" src={trashIcon} />
                        </Button>
                      }
                      size="mini"
                      basic={true}
                      content="Delete"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      disabled={this.props.user.role === 'Read Only Office Admin'}
                      src={handleIcon}
                      {...provided.dragHandleProps}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default withFormsy(QuestionFormsy);
