import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import '../../assets/styles/Login.css';
import '../../assets/styles/Login.css';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  id: any;
  show: boolean;
  name: string;
  handleStatus: (status: number, id: any, name: string) => void;
  showModal: (show: boolean) => void;
}

interface State {}

class SetEmailModal extends React.Component<Props, State> {
  public state = {};

  public form: any;
  public onValidSubmit = async (model: any) => {
    const emailString = model.email + '@compassadjusters.com';
    const response = await services.api.account.updateCompassEmail(this.props.id, emailString);
    if (response.isSuccess) {
      this.props.handleStatus(1, this.props.id, this.props.name);
    } else {
      this.props.showModal(false);
      toast.dismiss();
      toast.error(response.data.errors ? response.data.errors[0].msg : response.data);
    }
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    return (
      <Modal
        size={'tiny'}
        open={this.props.show}
        closeOnDimmerClick={false}
        className="recovery-modal"
        closeIcon={<Image src={closeIcon} />}
        onClose={() => this.props.showModal(false)}
      >
        <Modal.Header>Assign internal email to user</Modal.Header>
        <Form onValidSubmit={this.onValidSubmit} ref={(ref: any) => (this.form = ref)}>
          <Modal.Content>
            <div className="input-field-container">
              <div>
                <Form.Input
                  name="email"
                  placeholder="Please Enter..."
                  instantValidation={false}
                  required={true}
                  validationErrors={{
                    isDefaultRequiredValue: 'Email is required field.',
                  }}
                  errorLabel={errorLabel}
                  className="recovery-modal-form-input"
                />
              </div>
              <div className="input-field-label">@compassadjusters.com</div>
            </div>
          </Modal.Content>
          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.props.showModal(false)}
            >
              CANCEL
            </Button>
            <Button content="Add" primary={true} />
          </div>
        </Form>
      </Modal>
    );
  }
}

export default SetEmailModal;
