import { action } from 'typesafe-actions';
import * as constants from './constants';

export const setMessagesTab = (tab: string) => action(constants.SET_MESSAGES_TAB, { tab });

export const setMessagesSearch = (search: string) =>
  action(constants.SET_MESSAGES_SEARCH, { search });

export const setMessagesSort = (sort: string) => action(constants.SET_MESSAGES_SORT, { sort });

export const setActivePage = (page: number) => action(constants.SET_ACTIVE_PAGE, { page });

export const getMessagesList = () => action(constants.GET_MESSAGES_LIST);

export const submitMessagesList = (list: object[], count: number) =>
  action(constants.SUBMIT_MESSAGES_LIST, { list, count });

export const setRoomType = (type: string) => action(constants.SET_ROOM_TYPE, { type });

export const getRoomMessages = (model: object) => action(constants.GET_ROOM_MESSAGES, { model });
export const loadRoomMessages = (model: object) => action(constants.LOAD_ROOM_MESSAGES, { model });
export const setRoomMessages = (list: object[], count: number, adjuster: any) =>
  action(constants.SET_ROOM_MESSAGES, { list, count, adjuster });
export const setActiveRoomPage = (page: number) => action(constants.SET_ACTIVE_ROOM_PAGE, { page });

export const resetMessagesFilter = () => action(constants.RESET_MESSAGES_FILTER);
