import React from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';
import classnames from 'classnames';
import cancelIcon from '../../../assets/images/Blue/cancel.svg';

interface Props {
  name: string;
  onDelete: () => void;
}

export default function UploadDocumentItem(props: Props) {
  return (
    <div className={classnames('file-item')}>
      <div className="name">{props.name}</div>
      <div className="icon">
        <Button icon={true} onClick={props.onDelete} className="delete-button">
          <Icon>
            <Image src={cancelIcon} />
          </Icon>
        </Button>
      </div>
    </div>
  );
}
