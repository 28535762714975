export const GET_STANDBY_LIST = 'standby/GET_STANDBY_LIST';
export const GET_SELECTED_ADJUSTERS = 'standby/GET_SELECTED_ADJUSTERS';

export const SUBMIT_STANDBY_LIST = 'standby/SUBMIT_STANDBY_LIST';
export const EDIT_STANDBY_LIST = 'standby/EDIT_STANDBY_LIST';

export const SET_STANDBY_TAB = 'standby/SET_STANDBY_TAB';
export const SET_STANDBY_SORT = 'standby/SET_STANDBY_SORT';
export const SET_STANDBY_SEARCH = 'standby/SET_STANDBY_SEARCH';
export const GET_STANDBY_ADJUSTERS = 'standby/GET_STANDBY_ADJUSTERS';
export const GET_MAP_STANDBY_ADJUSTERS = 'standby/GET_MAP_STANDBY_ADJUSTERS';
export const GET_STANDBY_MESSAGES = 'standby/GET_STANDBY_MESSAGES';
export const SET_STANDBY_ADJUSTERS = 'standby/SET_STANDBY_ADJUSTERS';
export const SET_MAP_STANDBY_ADJUSTERS = 'standby/SET_MAP_STANDBY_ADJUSTERS';
export const SET_STANDBY_MESSAGES = 'standby/SET_STANDBY_MESSAGES';
export const SET_STANDBY_MESSAGES_SEARCH = 'standby/SET_STANDBY_MESSAGES_SEARCH';
export const SET_STANDBY_MESSAGES_PAGE = 'standby/SET_STANDBY_MESSAGES_PAGE';
export const SET_STANDBY_ADJUSTERS_STATUS = 'standby/SET_STANDBY_ADJUSTERS_STATUS';
export const SET_STANDBY_ADJUSTERS_PAGE = 'standby/SET_STANDBY_ADJUSTERS_PAGE';
export const SET_STANDBY_ADJUSTERS_PER_PAGE = 'standby/SET_STANDBY_ADJUSTERS_PER_PAGE';
export const SET_PAGE_SIZE = 'standby/SET_PAGE_SIZE';
export const RESET_STANDBY_FILTER = 'standby/RESET_STANDBY_FILTER';
export const EXPORT_STANDBY_ADJUSTERS = 'standby/EXPORT_STANDBY_ADJUSTERS';
export const EXPORT_STANDBY_ADJUSTERS_APPLIED = 'standby/EXPORT_STANDBY_ADJUSTERS_APPLIED';

export const SET_ACTIVE_STANDBY = 'standby/SET_ACTIVE_STANDBY';
export const SET_ACTIVE_STANDBY_TWILIO = 'standby/SET_ACTIVE_STANDBY_TWILIO';
export const SET_ACTIVE_ADJUSTER = 'standby/SET_ACTIVE_ADJUSTER';

export const SHOW_INVITE_MESSAGE = 'standby/SHOW_INVITE_MESSAGE';
export const SHOW_INVITE_MODAL = 'standby/SHOW_INVITE_MODAL';
export const SHOW_TWILIO_MODAL = 'standby/SHOW_TWILIO_MODAL';
export const SHOW_CUSTOM_EVENTS_MODAL = 'standby/SHOW_CUSTOM_EVENTS_MODAL';

export const SET_ACTIVE_PAGE = 'standby/SET_ACTIVE_PAGE';

export const GET_INVITE_MODAL_USERS = 'standby/GET_INVITE_MODAL_USERS';
export const SET_INVITE_MODAL_USERS = 'standby/SET_INVITE_MODAL_USERS';
export const SET_INVITE_MODAL_TAB = 'standby/SET_INVITE_MODAL_TAB';
export const SET_INVITE_MODAL_ADM_SEARCH = 'standby/SET_INVITE_MODAL_ADM_SEARCH';
export const SET_SELECTED_ADMINS = 'standby/SET_SELECTED_ADMINS';
export const SET_SELECTED_ADJUSTERS = 'standby/SET_SELECTED_ADJUSTERS';
export const RESET_SELECTED_USERS = 'standby/RESET_SELECTED_USERS';
export const SET_ACTIVE_PAGE_ADM = 'standby/SET_ACTIVE_PAGE_ADM';
export const SET_ACTIVE_PAGE_ADJ = 'standby/SET_ACTIVE_PAGE_ADJ';

export const SET_TEMP_MSG_OBJ = 'standby/SET_TEMP_MSG_OBJ';

export const SET_STANDBY_HEADER = 'standby/SET_STANDBY_HEADER';

export const SET_INVITE_MODE = 'standby/SET_INVITE_MODE';
