import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import TrainingCenterFilesTab from '../../components/training-center-layout/TrainingCenterFilesTab';
import TrainingCenterHeader from '../../components/training-center-layout/TrainingCenterHeader';
import { IUser } from '../../models';
import '../../assets/styles/TrainingCenter.css';
import CreateFolderModal from '../../components/training-center-layout/CreateFolderModal';
import EditFolderModal from '../../components/training-center-layout/EditFolderModal';
import { trainingCenterActions } from '../../features/training-center';
import { Folder } from '../../features/training-center/trainingCenterReducer';
import { Confirm, Image } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import AssignAccessModal from '../../components/training-center-layout/assign-access-modal/AssignAccessModal';
import TrainingCenterAccessTab from '../../components/training-center-layout/TrainingCenterAccessTab';
import TrainingCenterCustomEventsTab from '../../components/training-center-layout/TrainingCenterCustomEventsTab';
import services from '../../services';
import { material } from '../../services/api-service';

interface Props {
  user: IUser;
  toggleDeleteFolderModel: typeof trainingCenterActions.toggleDeleteFolderModel;
  deleteFolder: typeof trainingCenterActions.deleteFolder;
  folderToDelete: Folder;
  showDeleteModal: boolean;
}

interface State {
  activeTab: string;
  showCreateModal: boolean;
  deleteFolder: any;
  loading: boolean;
  warning: boolean;
}

class TrainingCenter extends React.Component<Props, State> {
  public state = {
    activeTab: 'Files',
    showCreateModal: false,
    loading: false,
    warning: false,
  };

  public handleTabs = (name: string) => {
    this.setState({ activeTab: name });
  };

  public renderTab = () => {
    const { activeTab } = this.state;

    switch (activeTab) {
      case 'Files':
        return <TrainingCenterFilesTab />;
      case 'Access':
        return <TrainingCenterAccessTab />;
      case 'Custom Events':
        return <TrainingCenterCustomEventsTab />;
      default:
        return <div>No Info</div>;
    }
  };

  public async componentDidUpdate(previousProps: Props, previousState: State) {
    const { folderToDelete } = this.props;

    if (folderToDelete.id !== previousProps.folderToDelete.id) {
      this.setState({
        loading: true,
      });
      const materials = await services.api.material.searchMaterial(
        {
          activePage: 1,
        },
        {
          folderIds: folderToDelete.id,
        }
      );

      this.setState({
        warning: materials.data.data.count > 0,
        loading: false,
      });
    }
  }

  renderDelete() {
    const { showDeleteModal, folderToDelete } = this.props;
    const { loading, warning } = this.state;

    if (loading) return null;

    return warning ? (
      <Confirm
        size="tiny"
        closeIcon={<Image src={closeIcon} className="icon" />}
        open={showDeleteModal}
        onCancel={() => this.props.toggleDeleteFolderModel()}
        header="Confirm"
        content={`Warning! This folder contains uploaded material/files. Deleting it will also delete all the files.
        Are you sure you want to delete this folder?`}
        cancelButton="NO"
        confirmButton="YES"
        onConfirm={() => {
          this.props.deleteFolder(folderToDelete);
        }}
        className="confirm-modal-custom"
        closeOnDimmerClick={false}
      />
    ) : (
      <Confirm
        size="tiny"
        closeIcon={<Image src={closeIcon} className="icon" />}
        open={showDeleteModal}
        onCancel={() => this.props.toggleDeleteFolderModel()}
        header="Confirm"
        content={'Are you sure you want to delete this folder?'}
        cancelButton="NO"
        confirmButton="YES"
        onConfirm={() => {
          this.props.deleteFolder(folderToDelete);
        }}
        className="confirm-modal-custom"
        closeOnDimmerClick={false}
      />
    );
  }

  public render() {
    return (
      <div className="training-center-page">
        <TrainingCenterHeader getTabName={this.handleTabs} />
        {this.renderTab()}
        <CreateFolderModal />
        <EditFolderModal />
        <AssignAccessModal global={true} standby={true} updateData={() => {}} />
        {this.renderDelete()}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  showDeleteModal: state.trainingCenter.deleteFolder.showModal,
  folderToDelete: state.trainingCenter.deleteFolder.folder,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleDeleteFolderModel: trainingCenterActions.toggleDeleteFolderModel,
      deleteFolder: trainingCenterActions.deleteFolder,
    },
    dispatch
  );

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(TrainingCenter);
