import get from 'lodash/get';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Grid, Image, Popup } from 'semantic-ui-react';
import logo from '../../assets/images/compass-logo-vector.svg';
import noAvatar from '../../assets/images/no-avatar.svg';
import arrowIcon from '../../assets/images/White/arrow-l.svg';
import '../../assets/styles/Header.css';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import services from '../../services';

const userPicStyle = {
  overflow: 'hidden',
};

interface Props {
  user: IUser;
  setActiveStandby: (id: any) => void;
  adjuster: AdjusterUser;
  logout: () => void;
  hideWarningMessage?: boolean;
  pathname: string;
  standbyHeader: string;
  activeStandbyId: any;
  loading: boolean;
}

interface State {
  headerTitle: string | undefined;
}

class AccountLayoutHeader extends React.Component<Props, State> {
  public state = {
    headerTitle: '',
  };

  public chooseTitle = (location: string) => {
    if (location.includes('/messages/')) {
      return 'Message Details';
    } else if (location.includes('/stand-by-lists/message/')) {
      return this.props.standbyHeader;
    } else if (location.includes('/stand-by-lists/answers')) {
      return 'Stand By List Answers';
    } else if (location.includes('/stand-by-lists/') && !location.includes('/message/')) {
      return 'Standby Lists';
    } else if (location.includes('/adjusters/')) {
      if (this.props.adjuster) {
        return `${
          this.props.adjuster.preferredName
            ? this.props.adjuster.preferredName
            : this.props.adjuster.firstName || ''
        } ${this.props.adjuster.lastName || ''}`;
      }
    } else {
      switch (location) {
        case '/':
          return 'Dashboard';
        case '/profile':
          return 'Account Details';
        case '/staff':
          return 'Staff';
        case '/settings':
          return 'Settings';
        case '/stand-by-lists':
          return 'Standby Lists';
        case '/finances':
          return 'Finances';
        case '/adjusters':
          return 'Adjusters';
        case '/create-adjuster':
          return 'Create Adjuster';
        case '/messages':
          return 'Messages';
        case services.router.url.stand_by_list_form:
          return 'Create Standby List';
        case services.router.url.stand_by_list_form_edit:
          return 'Edit Standby List';
        case '/training-center':
          return 'Training Center';
        default:
          return '';
      }
    }
  };

  public componentDidMount() {
    this.setState({ headerTitle: this.chooseTitle(this.props.pathname) });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({ headerTitle: this.chooseTitle(this.props.pathname) });
    }
    if (
      prevProps.standbyHeader !== this.props.standbyHeader &&
      this.props.pathname.includes('/stand-by-lists/message/')
    ) {
      this.setState({ headerTitle: this.props.standbyHeader });
    }
    if (prevProps.adjuster !== this.props.adjuster) {
      this.setState({ headerTitle: this.chooseTitle(this.props.pathname) });
    }
  }

  public goBack() {
    const activePage = get(history, 'state.state.activePage', 1);
    const itemsPerPageCount = get(history, 'state.state.itemsPerPageCount', 20);
    const activeTab = get(history, 'state.state.activeTab', 'Pending');
    const prevRoute = get(history, 'state.state.prevPath', `/adjusters${location.hash}`);
    services.router.gotoWithState(prevRoute, { activePage, activeTab, itemsPerPageCount });
  }

  public render() {
    const { user, loading } = this.props;
    if (!user) {
      return null;
    }

    const trigger = (
      <div style={userPicStyle}>
        <Image
          circular={true}
          src={user.avatar === '' || !user.avatar ? noAvatar : user.avatar}
          className="header-user-avatar"
        />
      </div>
    );
    return (
      <div>
        <div className="account-header">
          <Grid style={{ width: '100%' }}>
            <Grid.Row>
              <Grid.Column className="account-header-logo">
                <div onClick={() => services.router.goto('/')}>
                  <Image style={{ height: '87px' }} src={logo} />
                </div>
              </Grid.Column>
              <Grid.Column className="account-header-title-container">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {(this.props.pathname.includes('/adjusters/') ||
                    this.props.pathname === '/create-adjuster') && (
                    <Popup
                      trigger={
                        <Button
                          disabled={loading}
                          primary={true}
                          onClick={this.goBack}
                          className="back-button"
                        >
                          <Image src={arrowIcon} />
                        </Button>
                      }
                      position="bottom center"
                      basic={true}
                      size="mini"
                      content="Return"
                    />
                  )}
                  {this.props.pathname.includes('/stand-by-lists/') &&
                    !this.props.pathname.includes('/stand-by-lists/edit') &&
                    !this.props.pathname.includes('/stand-by-lists/answers') && (
                      <Popup
                        trigger={
                          <Button
                            disabled={loading}
                            primary={true}
                            onClick={() => services.router.goto('/stand-by-lists')}
                            className="back-button"
                          >
                            <Image src={arrowIcon} />
                          </Button>
                        }
                        position="bottom center"
                        basic={true}
                        size="mini"
                        content="Return"
                      />
                    )}
                  {(this.props.pathname.includes('/stand-by-lists/edit/') ||
                    this.props.pathname.includes('/stand-by-lists/answers/')) && (
                    <Popup
                      trigger={
                        <Button
                          disabled={loading}
                          primary={true}
                          onClick={() => {
                            const activePage = get(history, 'state.state.activePage', 1);
                            const activeTab = get(history, 'state.state.activeTab', 'Pending');
                            services.router.gotoWithState(
                              `/stand-by-lists/${this.props.activeStandbyId}${
                                location.hash === '#reopen' ? '' : location.hash
                              }`,
                              { activePage, activeTab }
                            );
                          }}
                          className="back-button"
                        >
                          <Image src={arrowIcon} />
                        </Button>
                      }
                      position="bottom center"
                      basic={true}
                      size="mini"
                      content="Return"
                    />
                  )}
                  {this.props.pathname.includes('/messages/') &&
                    !this.props.pathname.includes('/room') && (
                      <Popup
                        trigger={
                          <Button
                            disabled={loading}
                            primary={true}
                            onClick={() => services.router.goto('/messages')}
                            className="back-button"
                          >
                            <Image src={arrowIcon} />
                          </Button>
                        }
                        position="bottom center"
                        basic={true}
                        size="mini"
                        content="Return"
                      />
                    )}
                  {this.props.pathname.includes('/messages/room/') && this.props.activeStandbyId && (
                    <Popup
                      trigger={
                        <Button
                          disabled={loading}
                          primary={true}
                          onClick={() => {
                            services.router.goto(
                              `/stand-by-lists/${this.props.activeStandbyId}${location.hash}`
                            );
                            this.props.setActiveStandby(undefined);
                          }}
                          className="back-button"
                        >
                          <Image src={arrowIcon} />
                        </Button>
                      }
                      position="bottom center"
                      basic={true}
                      size="mini"
                      content="Return"
                    />
                  )}
                  {this.props.pathname.includes('/messages/room/') && !this.props.activeStandbyId && (
                    <Popup
                      trigger={
                        <Button
                          disabled={loading}
                          primary={true}
                          onClick={() => {
                            const activePage = get(history, 'state.state.activePage', 1);
                            const itemsPerPageCount = get(
                              history,
                              'state.state.itemsPerPageCount',
                              20
                            );
                            const activeTab = get(history, 'state.state.activeTab', 'Pending');
                            const prevRoute = get(
                              history,
                              'state.state.prevPath',
                              `/adjusters${location.hash}`
                            );
                            services.router.gotoWithState(`${prevRoute}${location.hash}`, {
                              activePage,
                              itemsPerPageCount,
                              activeTab,
                            });
                          }}
                          className="back-button"
                        >
                          <Image src={arrowIcon} />
                        </Button>
                      }
                      position="bottom center"
                      basic={true}
                      size="mini"
                      content="Return"
                    />
                  )}
                  <div className="account-header-title">{this.state.headerTitle}</div>
                </div>
                <Dropdown trigger={trigger} icon={false} className="profile-dropdown">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => services.router.goto(services.router.url.profile)}
                    >
                      <span>My Profile</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.props.logout()}>
                      <span>Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state: MyTypes.RootState) => ({
  loading: state.loading.loading.isVisible,
});

export default connect(MapStateToProps)(AccountLayoutHeader);
