import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  showCreateModal: (show: boolean) => void;
  showModal: boolean;
  reloadMsg: () => void;
  selectCreated: (id: number) => void;
}

interface State {
  msgTemplate: object;
  nameInvalid: boolean;
  showLengthError: boolean;
}

Formsy.addValidationRule('isOnlySpaces', (values: any, value: any) => {
  if (/\S/.test(value)) {
    return true;
  } else {
    return false;
  }
});

class CreateMsgTemplate extends React.Component<Props, State> {
  public state = {
    msgTemplate: {},
    nameInvalid: false,
    showLengthError: false,
  };

  public closeModal = () => {
    this.props.showCreateModal(false);
    this.setState({
      msgTemplate: [],
      nameInvalid: false,
    });
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    return (
      <Modal
        size="small"
        open={this.props.showModal}
        onClose={() => this.closeModal()}
        closeOnDimmerClick={false}
        closeIcon={<Image src={closeIcon} className="icon" />}
      >
        <Modal.Header>Create Template</Modal.Header>
        <Modal.Content>
          <Form
            id="create-template-form"
            onValidSubmit={async (model: { name: string; title: string; text: string }) => {
              const response = await services.api.settings.createMsgTemplate({
                name: model.name.trim(),
                title: model.title.trim(),
                text: model.text.trim(),
              });

              if (response.isSuccess) {
                toast.dismiss();
                toast.success(`Message Template ${model.name} was successfully created!`);
                this.props.reloadMsg();
                this.props.selectCreated(response.data.id);
                this.closeModal();
              } else {
                toast.dismiss();
                toast.error(response.data.errors[0].msg);
              }
            }}
          >
            <div className="input-field-container">
              <div className="input-field-label">Name</div>
              <div className="message-template-input-container">
                <Form.Input
                  name="name"
                  placeholder="Please Enter..."
                  instantValidation={false}
                  required={true}
                  maxLength={60}
                  validations={{
                    isOnlySpaces: true,
                  }}
                  validationErrors={{
                    isDefaultRequiredValue: '* Name is required field.',
                    isOnlySpaces: '* Name is required field.',
                  }}
                  className="message-template-input"
                  errorLabel={errorLabel}
                />
              </div>
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Title</div>
              <div className="message-template-input-container">
                <Form.Input
                  name="title"
                  maxLength={128}
                  placeholder="Please Enter..."
                  instantValidation={false}
                  required={true}
                  validations={{
                    isOnlySpaces: true,
                  }}
                  validationErrors={{
                    isDefaultRequiredValue: '* Title is required field.',
                    isOnlySpaces: '* Title is required field.',
                  }}
                  className="message-template-input"
                  errorLabel={errorLabel}
                />
              </div>
            </div>
            <div className="input-field-container">
              <div className="input-field-label">Message</div>
              <div className="message-template-input-container">
                <Form.TextArea
                  name="text"
                  onKeyPress={e => {
                    if (e.target.value.length >= 1024) {
                      this.setState({ showLengthError: true });
                    } else {
                      this.setState({ showLengthError: false });
                    }
                  }}
                  maxLength={1024}
                  placeholder="Please Enter..."
                  instantValidation={false}
                  required={true}
                  validations={{
                    isOnlySpaces: true,
                  }}
                  validationErrors={{
                    isDefaultRequiredValue: '* Message is required field.',
                    isOnlySpaces: '* Message is required field.',
                  }}
                  className="message-template-input"
                  errorLabel={errorLabel}
                />
                {this.state.showLengthError && (
                  <div className="length-error">
                    <p>* Max length of Message is reached.</p>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </Modal.Content>
        <div className="modal-button-footer">
          <Button
            style={{ width: '115px' }}
            className="secondary-button"
            secondary={true}
            onClick={() => this.props.showCreateModal(false)}
          >
            CANCEL
          </Button>
          <Button form="create-template-form" style={{ width: '115px' }} primary={true}>
            CREATE
          </Button>
        </div>
      </Modal>
    );
  }
}

export default CreateMsgTemplate;
