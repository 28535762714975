import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Image, Loader, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import dropdownIcon from '../../assets/images/ui-dropdown.svg';
import '../../assets/styles/Staff.css';
import AvatarInput from '../../components/avatar-input/AvatarInput';
import ErrorLabel from '../../components/error-label/ErrorLabel';
import PhoneInput from '../../components/layout-input/PhoneInput';
import { adminActions } from '../../features/admin';
import { EditAdminModel } from '../../features/admin/actions';
import services from '../../services';

interface Props {
  user: any;
  showModal: boolean;
  closeModal: () => void;
  fetchParams: object;
  getAdminList: typeof adminActions.getAdminList;
  resetAdminList: typeof adminActions.resetAdminList;
}

interface State {
  statusActive: boolean;
  finAcces: boolean;
  avatar: string | ArrayBuffer | null;
}

const rolesOptions = [
  {
    key: 'Super Admin (Full Access)',
    text: 'Super Admin (Full Access)',
    value: 'Super Admin',
  },
  {
    key: 'Office Admin (Edit)',
    text: 'Office Admin (Edit)',
    value: 'Office Admin',
  },
  {
    key: 'Read Only Office Admin (Read Only)',
    text: 'Read Only Office Admin (Read Only)',
    value: 'Read Only Office Admin',
  },
];

class EditAdminModal extends React.Component<Props, State> {
  public form: any;

  public state = {
    statusActive: true,
    finAcces: true,
    avatar: '',
  };

  public getAvatar = (file: string | ArrayBuffer | null) => {
    this.setState({ avatar: file });
  };

  public getAvatatFields = (url: string, data: FormData) => {
    return;
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.user !== this.props.user && this.props.user !== undefined) {
      this.setState({
        statusActive: this.props.user.activeStatus,
        finAcces: this.props.user.financeAccess,
      });
    }
  }

  public render() {
    const errorLabel = <ErrorLabel />;
    const { user, showModal } = this.props;

    if (!user) {
      return <Loader size="massive">Loading</Loader>;
    }

    return (
      <Modal
        className="create-admin-modal"
        open={showModal}
        closeIcon={
          <Image src={closeIcon} className="icon" onClick={() => this.props.closeModal()} />
        }
      >
        <Modal.Header>Edit Admin Account</Modal.Header>
        <Form
          onValidSubmit={async (model: EditAdminModel) => {
            const role = { role: model.role };
            const status = { active_status: this.state.statusActive ? 1 : 0 };
            const access = { finance_access: this.state.finAcces ? 1 : 0 };
            const obj = Object.assign({}, role, status, access);
            const fullName = `${user.firstName} ${user.lastName}`;

            const tokenRequest = await services.api.admin.editAdmin(obj, user.id);
            if (tokenRequest.isError) {
              toast.dismiss();
              toast.error('Error! ' + tokenRequest.data.errors[0].msg);
            } else {
              this.props.resetAdminList();
              this.props.getAdminList(this.props.fetchParams);
              toast.dismiss();
              toast.success(`Admin ${fullName} was successfully updated!`);
              this.props.closeModal();
            }
          }}
          ref={(ref: any) => (this.form = ref)}
        >
          <Modal.Content>
            <Grid>
              <Grid.Column width={3}>
                <AvatarInput
                  getAvatar={this.getAvatar}
                  noEdit={true}
                  getAvatarFields={this.getAvatatFields}
                  avatar={user.avatar}
                />
              </Grid.Column>
              <Grid.Column width={13}>
                <div className="input-field-container">
                  <div className="input-field-label">First Name</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="first_name"
                      placeholder="Please Enter..."
                      instantValidation={false}
                      required={true}
                      disabled={true}
                      validationErrors={{
                        isDefaultRequiredValue: '* First Name is required field.',
                      }}
                      errorLabel={errorLabel}
                      className="form-input"
                      value={user.firstName}
                    />
                  </div>
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Last Name</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="last_name"
                      placeholder="Please Enter..."
                      instantValidation={false}
                      required={true}
                      disabled={true}
                      validationErrors={{
                        isDefaultRequiredValue: '* First Name is required field.',
                      }}
                      errorLabel={errorLabel}
                      className="form-input"
                      value={user.lastName}
                    />
                  </div>
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Email</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="email"
                      placeholder="Please Enter..."
                      instantValidation={false}
                      required={true}
                      disabled={true}
                      validations="isEmail"
                      validationErrors={{
                        isDefaultRequiredValue: '* Email is required field.',
                        isEmail: "* It's not valid Email",
                      }}
                      errorLabel={errorLabel}
                      className="form-input"
                      value={user.email}
                    />
                  </div>
                </div>

                <div className="input-field-container">
                  <div className="input-field-label disabled">Phone</div>
                  <div className="field-input-container">
                    <PhoneInput
                      name="phone"
                      disabled={true}
                      placeholder={user.phone === '' || !user.phone ? 'N/A' : ''}
                      className="disabled-phone-input"
                      instantValidation={false}
                      errorLabel={errorLabel}
                      value={user.phone === '' || !user.phone ? undefined : user.phone}
                    />
                  </div>
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Role</div>
                  <div className="field-input-container">
                    <Form.Dropdown
                      name="role"
                      placeholder="Please Select"
                      icon={<Image src={dropdownIcon} className="icon" />}
                      instantValidation={false}
                      required={true}
                      options={rolesOptions}
                      validationErrors={{
                        isDefaultRequiredValue: '* Role is required field.',
                      }}
                      errorLabel={errorLabel}
                      className="form-input-dropdown long"
                      defaultValue={user.role}
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Status</div>
                  <div style={{ display: 'flex', width: '400px' }}>
                    <div className="toggle-block">
                      <Button
                        type="button"
                        className={this.state.statusActive ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ statusActive: true })}
                      >
                        ACTIVE
                      </Button>
                      <Button
                        type="button"
                        className={!this.state.statusActive ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ statusActive: false })}
                      >
                        INACTIVE
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Finances Access</div>
                  <div style={{ display: 'flex', width: '400px' }}>
                    <div className="toggle-block">
                      <Button
                        type="button"
                        className={this.state.finAcces ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ finAcces: true })}
                      >
                        ENABLED
                      </Button>
                      <Button
                        type="button"
                        className={!this.state.finAcces ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ finAcces: false })}
                      >
                        DISABLED
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <div
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '75px',
              background: '#f7f8fb',
            }}
          >
            <Button
              type="button"
              style={{ width: '115px' }}
              onClick={this.props.closeModal}
              secondary={true}
              className="secondary-button"
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              SAVE
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAdminList: adminActions.getAdminList,
      resetAdminList: adminActions.resetAdminList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAdminModal);
