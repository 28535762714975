import classnames from 'classnames';
import React from 'react';
import { Table } from 'semantic-ui-react';

import './styles.css';

interface Props {
  className: string;
}

interface State {}

class DataTable extends React.Component<Props, State> {
  public render() {
    const { className } = this.props;

    return (
      <Table className={classnames('data-table', className)} compact={true} striped={true}>
        {this.props.children}
      </Table>
    );
  }
}

export default DataTable;
