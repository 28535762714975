import * as Formsy from 'formsy-react';
import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { Button, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import { IUser } from '../../models';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';
import QuestionFormsy from '../layout-question/QuestionFormsy';
// import QuestionFormsy from '../layout-question/PortalQuestionFormsy';

interface Props {
  showCreateModal: (show: boolean) => void;
  setActiveQuestions: (id: number) => void;
  showModal: boolean;
  reloadQuestions: () => void;
  user: IUser;
}

interface State {
  name: string;
  questions: object[];
  nameInvalid: boolean;
}

class CreateQuestionnarie extends React.Component<Props, State> {
  public state = {
    name: '',
    questions: [{ id: 999, order: 0, text: '', answerType: 'Yes/No' }],
    nameInvalid: false,
  };

  public getQuestion = (question: object) => {
    const { questions } = this.state;
    questions.push(question);
    this.setState({ questions });
  };

  public deleteQuestion = (id: number) => {
    const { questions } = this.state;
    const elIndex = questions.findIndex(el => el.id === id);
    questions.splice(elIndex, 1);
    const array = questions.map((el: any, index: number) => {
      el.order = index;
      return el;
    });
    this.setState({ questions: [] }, () => this.setState({ questions: array }));
  };

  public editQuestion = (id: number, question: object) => {
    const { questions } = this.state;
    const elIndex = questions.findIndex(el => el.id === id);
    questions[elIndex] = question;
    this.setState({ questions });
  };

  public closeModal = () => {
    this.props.showCreateModal(false);
    this.props.reloadQuestions();
    this.setState({
      name: '',
      questions: [{ id: 999, order: 0, text: '', answerType: 'Yes/No' }],
      nameInvalid: false,
    });
  };

  public onDragEnd = result => {
    const { destination, source, draggableId } = result;
    const { questions } = this.state;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const element = questions[source.index];
    questions.splice(source.index, 1);
    questions.splice(destination.index, 0, element);
    for (let i = 1; i < questions.length; i++) {
      questions[i].order = i;
    }
    this.setState({ questions });
  };

  public render() {
    Formsy.addValidationRule('hasQuestions', (values: any, value: any) => {
      if (!this.state.nameInvalid) {
        return true;
      } else {
        return false;
      }
    });

    const errorLabel = <ErrorLabel />;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Modal
          open={this.props.showModal}
          onClose={() => this.closeModal()}
          closeOnDimmerClick={false}
          className="create-questionnarie-modal-admin"
          closeIcon={<Image src={closeIcon} className="icon" />}
        >
          <Modal.Header>Create Questionnaire</Modal.Header>
          <Modal.Content>
            <div className="create-questionnarie-container">
              <Form
                id="name-form"
                ref={ref => (this.form = ref)}
                onValidSubmit={async (model: any) => {
                  const finalQuestions = this.state.questions.map((el: any) => {
                    delete el.id;
                    return el;
                  });
                  const obj = Object.assign(
                    {},
                    { name: model.list_name.trim() },
                    { questions: finalQuestions }
                  );

                  const response = await services.api.settings.createQuestionnarie(obj);

                  if (response.isSuccess) {
                    toast.dismiss();
                    toast.success(
                      `Questionnaire ${model.list_name.trim()} was successfully created!`
                    );
                    this.props.setActiveQuestions(response.data.id);
                    this.closeModal();
                  } else {
                    toast.dismiss();
                    toast.error(response.data.errors[0].msg);
                  }
                }}
              >
                <div className="name-input-container">
                  <div>Name</div>

                  <Form.Input
                    name="list_name"
                    maxLength={60}
                    placeholder="Please Enter..."
                    required={true}
                    instantValidation={false}
                    value={this.state.name}
                    validations={{
                      hasQuestions: true,
                      isOnlySpaces: true,
                    }}
                    validationErrors={{
                      isDefaultRequiredValue: '* Name is required field.',
                      isOnlySpaces: '* Name is required field.',
                      hasQuestions: '* At least one question is required to be defined.',
                    }}
                    errorLabel={errorLabel}
                    onChange={(e: any) => this.setState({ name: e.target.value })}
                  />
                </div>
                <Droppable droppableId="create-question-droppable-unique-id">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {this.state.questions.map((question: any, index: number) => (
                        <QuestionFormsy
                          name={`${question.id}`}
                          key={question.id}
                          index={index}
                          question={question}
                          deleteQuestion={this.deleteQuestion}
                          editQuestion={this.editQuestion}
                          user={{ role: 'Super Admin' }}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Form>
              <div className="add-question">
                <Button
                  disabled={this.props.user.role === 'Read Only Office Admin'}
                  type="button"
                  onClick={() =>
                    this.getQuestion({
                      text: '',
                      answerType: 'Yes/No',
                      order: this.state.questions.length,
                      id: this.state.questions.length - 1,
                    })
                  }
                >
                  + ADD QUESTION
                </Button>
              </div>
            </div>
          </Modal.Content>
          <div className="modal-button-footer">
            <Button
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.props.showCreateModal(false)}
            >
              CANCEL
            </Button>
            <Button
              form="name-form"
              style={{ width: '115px' }}
              primary={true}
              onClick={() => {
                const inputBuff = this.state.name;
                this.setState({ name: '' }, () => this.setState({ name: inputBuff }));

                if (this.state.questions.length === 0) {
                  this.setState({ nameInvalid: true });
                } else {
                  this.setState({ nameInvalid: false });
                }
              }}
            >
              CREATE
            </Button>
          </div>
        </Modal>
      </DragDropContext>
    );
  }
}

export default CreateQuestionnarie;
