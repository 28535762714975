export const GET_FILES_LIST = 'trainingCenter/GET_FILES_LIST';
export const GET_SELECTED_ADJUSTERS = 'trainingCenter/GET_SELECTED_ADJUSTERS';

export const GET_FOLDER_LIST = 'trainingCenter/GET_FOLDER_LIST';
export const SET_FOLDER_LIST = 'trainingCenter/SET_FOLDER_LIST';
export const UPDATE_FOLDER_POSITION = 'trainingCenter/UPDATE_FOLDER_POSITION';
export const SET_FOLDER_TOGGLE = 'trainingCenter/SET_FOLDER_TOGGLE';
export const SET_FOLDER_ACTIVE = 'trainingCenter/SET_FOLDER_ACTIVE';
export const REMOVE_FOLDER_ACTIVE = 'trainingCenter/REMOVE_FOLDER_ACTIVE';
export const SET_FOLDER_ENTER = 'trainingCenter/SET_FOLDER_ENTER';
export const SET_FOLDER_LEAVE = 'trainingCenter/SET_FOLDER_LEAVE';

export const TOGGLE_CREATE_FOLDER_MODAL = 'trainingCenter/TOGGLE_CREATE_FOLDER_MODAL';
export const CREATE_FOLDER = 'trainingCenter/CREATE_FOLDER';

export const TOGGLE_UPLOAD_MATERIAL_MODAL = 'trainingCenter/TOGGLE_UPLOAD_MATERIAL_MODAL';
export const CREATE_EMBEDDED_VIDEO_MATERIAL = 'trainingCenter/CREATE_EMBEDDED_VIDEO_MATERIAL';
export const CREATE_EMBEDDED_VIDEO_MATERIAL_DONE =
  'trainingCenter/CREATE_EMBEDDED_VIDEO_MATERIAL_DONE';
export const CREATE_DOCUMENT_MATERIAL = 'trainingCenter/CREATE_DOCUMENT_MATERIAL';
export const CREATE_DOCUMENT_MATERIAL_DONE = 'trainingCenter/CREATE_DOCUMENT_MATERIAL_DONE';

export const TOGGLE_EDIT_MATERIAL_MODAL = 'trainingCenter/TOGGLE_EDIT_MATERIAL_MODAL';
export const EDIT_EMBEDDED_VIDEO_MATERIAL = 'trainingCenter/EDIT_EMBEDDED_VIDEO_MATERIAL';
export const EDIT_EMBEDDED_VIDEO_MATERIAL_DONE = 'trainingCenter/EDIT_EMBEDDED_VIDEO_MATERIAL_DONE';
export const EDIT_DOCUMENT_MATERIAL = 'trainingCenter/EDIT_DOCUMENT_MATERIAL';
export const EDIT_DOCUMENT_MATERIAL_DONE = 'trainingCenter/EDIT_DOCUMENT_MATERIAL_DONE';

export const TOGGLE_EDIT_FOLDER_MODAL = 'trainingCenter/TOGGLE_EDIT_FOLDER_MODAL';
export const EDIT_FOLDER = 'trainingCenter/EDIT_FOLDER';

export const TOGGLE_DELETE_FOLDER_MODAL = 'trainingCenter/TOGGLE_DELETE_FOLDER_MODAL';
export const DELETE_FOLDER = 'trainingCenter/DELETE_FOLDER';

export const SHOW_ASSIGN_ACCESS_MODAL = 'trainingCenter/SHOW_ASSIGN_ACCESS_MODAL';
export const HIDE_ASSIGN_ACCESS_MODAL = 'trainingCenter/HIDE_ASSIGN_ACCESS_MODAL';
export const SAVE_ASSIGN_ACCESS = 'trainingCenter/SAVE_ASSIGN_ACCESS';
export const SET_ASSIGNED_ADJUSTERS = 'trainingCenter/SET_ASSIGNED_ADJUSTERS';
export const SET_ASSIGNED_CUSTOM_EVENTS = 'trainingCenter/SET_ASSIGNED_CUSTOM_EVENTS';

export const REMOVE_ASSIGNED_ADJUSTERS = 'trainingCenter/REMOVE_ASSIGNED_ADJUSTERS';
export const REMOVE_ASSIGNED_CUSTOM_EVENTS = 'trainingCenter/REMOVE_ASSIGNED_CUSTOM_EVENTS';

export const ASSIGN_ACCESS_SEARCH = 'trainingCenter/ASSIGN_ACCESS_SEARCH';
export const ASSIGN_ACCESS_SET_ACTIVE_TAB = 'trainingCenter/ASSIGN_ACCESS_SET_ACTIVE_TAB';
export const ASSIGN_ACCESS_SET_SEARCH_RESULT = 'trainingCenter/ASSIGN_ACCESS_SET_SEARCH_RESULT';
export const ASSIGN_ACCESS_SET_ALL_SEARCH_RESULT =
  'trainingCenter/ASSIGN_ACCESS_SET_ALL_SEARCH_RESULT';

export const SET_ASSIGN = 'trainingCenter/SET_ASSIGN';
export const REMOVE_ASSIGN = 'trainingCenter/REMOVE_ASSIGN';

export const SET_PAGE_SIZE_ASSIGN_ACCESS = 'trainingCenter/SET_PAGE_SIZE_ASSIGN_ACCESS';
export const ASSIGN_ACCESS_SET_ADJUSTER_PAGING = 'trainingCenter/ASSIGN_ACCESS_SET_ADJUSTER_PAGING';
export const ASSIGN_ACCESS_SET_CUSTOM_EVENT_PAGING =
  'trainingCenter/ASSIGN_ACCESS_SET_CUSTOM_EVENT_PAGING';

// START MATERIAL REGION

export const SET_VIEW_TYPE_LIST = 'trainingCenter/SET_VIEW_TYPE_LIST';
export const SET_VIEW_TYPE_GRID = 'trainingCenter/SET_VIEW_TYPE_GRID';
export const OPEN_MATERIAL_FILTER = 'trainingCenter/OPEN_MATERIAL_FILTER';
export const CLOSE_MATERIAL_FILTER = 'trainingCenter/CLOSE_MATERIAL_FILTER';

export const SEARCH_MATERIAL = 'trainingCenter/SEARCH_MATERIAL';
export const SEARCH_MATERIAL_SUBFOLDER = 'trainingCenter/SEARCH_MATERIAL_SUBFOLDER';

export const MATERIAL_EDIT = 'trainingCenter/MATERIAL_EDIT';
export const MATERIAL_DELETE = 'trainingCenter/MATERIAL_DELETE';
export const MATERIAL_DOWNLOAD = 'trainingCenter/MATERIAL_DOWNLOAD';

export const MATERIAL_SELECT = 'trainingCenter/MATERIAL_SELECT';
export const MATERIAL_SELECT_ALL = 'trainingCenter/MATERIAL_SELECT_ALL';

export const MATERIAL_BULK_EDIT = 'trainingCenter/MATERIAL_BULK_EDIT';
export const MATERIAL_BULK_DELETE = 'trainingCenter/MATERIAL_BULK_DELETE';
export const MATERIAL_BULK_DOWNLOAD = 'trainingCenter/MATERIAL_BULK_DOWNLOAD';

export const MATERIAL_SET_SEARCH_RESULT = 'trainingCenter/MATERIAL_SET_SEARCH_RESULT';
export const MATERIAL_SET_ALL_SEARCH_RESULT = 'trainingCenter/MATERIAL_SET_ALL_SEARCH_RESULT';

export const SET_SELECTED_MATERIALS = 'trainingCenter/SET_SELECTED_MATERIALS';
export const REMOVE_SELECTED_MATERIALS = 'trainingCenter/REMOVE_SELECTED_MATERIALS';
export const REMOVE_ALL_SELECTED_MATERIALS = 'trainingCenter/REMOVE_ALL_SELECTED_MATERIALS';

// END MATERIAL REGION

//START ACCESS TAB
export const SEARCH_ACCESS = 'trainingCenter/SEARCH_ACCESS';
export const ACCESS_SET_SEARCH_RESULT = 'trainingCenter/ACCESS_SET_SEARCH_RESULT';
export const ACCESS_SET_ALL_SEARCH_RESULT = 'trainingCenter/ACCESS_SET_ALL_SEARCH_RESULT';
export const SET_SELECTED_ACCESS = 'trainingCenter/SET_SELECTED_ACCESS';
export const SET_SEARCH_INPUT_ACCESS = 'trainingCenter/SET_SEARCH_INPUT_ACCESS';

export const REMOVE_SELECTED_ACCESS = 'trainingCenter/REMOVE_SELECTED_ACCESS';

//END ACCESS TAB

//START CUSTOM EVENTS
export const GET_CUSTOM_EVENT = 'trainingCenter/GET_CUSTOM_EVENT';
export const SET_CUSTOM_EVENT = 'trainingCenter/SET_CUSTOM_EVENT';
export const SET_SEARCH_INPUT_CUSTOM_EVENTS = 'trainingCenter/SET_SEARCH_INPUT_CUSTOM_EVENTS';
