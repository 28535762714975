import { fork, put } from 'redux-saga/effects';
import accountSaga from '../features/account/saga';
import adjustersSaga from '../features/adjusters/saga';
import adminSaga from '../features/admin/saga';
import dashboardSaga from '../features/dashboard/saga';
import financeSaga from '../features/finance/saga';
import messagesSaga from '../features/messages/saga';
import modalSaga from '../features/modal/saga';
import profileSaga from '../features/profile/saga';
import standbySaga from '../features/standby/saga';
import trainingCenterSaga from '../features/training-center/trainingCenterSaga';
import materialSaga from '../features/training-center/material.saga';

export default function*() {
  const sagas = [
    modalSaga,
    accountSaga,
    profileSaga,
    adminSaga,
    financeSaga,
    adjustersSaga,
    dashboardSaga,
    standbySaga,
    messagesSaga,
    trainingCenterSaga,
    materialSaga
  ];

  for (const saga of sagas) {
    try {
      yield put({ type: 'SAGA_RUN' });
      yield fork(saga);
    } catch (e) {
      yield put({ type: 'SAGA_FAILED', e });
    }
  }
}
