import moment from 'moment';
import React from 'react';
import { Button, Image, Pagination, Popup, Table } from 'semantic-ui-react';
import noAvatar from '../../assets/images/no-avatar.svg';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import services from '../../services';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { NoResults } from './../no-results-placeholder/NoResults';
import LogsFilter from './../table-filters/LogsFilter';

import * as helper from '../../utils/helper';

interface Props {
  getSubmitRef: (ref: any) => void;
  admin: IUser;
  user: AdjusterUser;
}

interface State {
  logs: object[];
  initialLogs: object[];
  totalLogs: number;
  activePage: number;
  showFilters: boolean;
  filterState: any;
}

const initialFilter = {
  from: '',
  to: '',
  category: '',
  description: '',
  updatedBy: '',
  sort: 'log.createdAt,desc',
};

class AdjusterLogs extends React.Component<Props, State> {
  public state = {
    logs: [],
    initialLogs: [],
    totalLogs: 0,
    activePage: 1,
    showFilters: false,
    filterState: { sort: 'log.createdAt,desc', page: 0 },
  };

  public componentDidMount = async () => {
    this.getLogs(this.state.filterState);
  };

  public getLogs = async (params?: any) => {
    if (params) {
      const dataModel = Object.assign({}, params);
      for (const key in dataModel) {
        if (dataModel[key] === '') {
          dataModel[key] = undefined;
        }
      }
      dataModel.pageSize = 20;
      params = dataModel;
    }
    const response = await services.api.admin.listAdjusterLogs(this.props.user.id, false, params);
    if (response.isSuccess) {
      await services.api.adjuster.getLogsUploaderAvatarUrl(response.data.logs);
      this.setState({ logs: [], totalLogs: response.data.count }, () =>
        this.setState({ logs: response.data.logs })
      );
    } else {
      console.log(response);
    }

    const initialParams = {
      page: params ? params.page : 0,
      pageSize: 20,
    };
    const response2 = await services.api.admin.listAdjusterLogs(
      this.props.user.id,
      true,
      initialParams
    );
    if (response2.isSuccess) {
      this.setState({ initialLogs: response2.data.logs });
    }
  };

  public handleFilterReset = () => {
    this.setState(
      {
        filterState: {
          ...initialFilter,
          page: 0,
        },
        activePage: 1,
        logs: [],
      },
      () => this.getLogs(this.state.filterState)
    );
  };

  public handleFilterChange = (data: any) => {
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          ...data,
          page: 0,
        },
        activePage: 1,
        logs: [],
      },
      () => {
        this.getLogs(this.state.filterState);
      }
    );
  };

  public handlePagination = (e: any, data: any) => {
    this.changePageAndRefresh(data.activePage);
  };

  public render() {
    const { logs, showFilters, totalLogs, initialLogs } = this.state;
    const totalPages = helper.getTotalPage(totalLogs, 20);
    return (
      <div className="admin-adjusters-finances-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            primary={true}
            style={{ height: '32px' }}
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          >
            Filter
          </Button>
        </div>
        {showFilters && (
          <LogsFilter
            reset={this.handleFilterReset}
            filter={this.state.filterState}
            filterLogs={this.handleFilterChange}
            logs={initialLogs}
          />
        )}
        <LoadingSpiner area="logs-adjuster-list" />
        {logs.length === 0 ? (
          <div className="no-results-logs">
            <NoResults />
          </div>
        ) : (
          <Table compact={true} striped={true} className="data-table adjusters-table logs-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="table-date">Date</Table.HeaderCell>
                <Table.HeaderCell className="table-category">Category</Table.HeaderCell>
                <Table.HeaderCell id="table-description">Logs</Table.HeaderCell>
                <Table.HeaderCell className="table-user">Updated By</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {logs.map((log: any, i: number) => (
                <Table.Row key={log.id}>
                  <Table.Cell className="table-date">
                    {moment(log.createdAt).format('MM/DD/YYYY, hh:mm A ')}
                  </Table.Cell>
                  <Table.Cell className="table-category">{log.category}</Table.Cell>
                  <Table.Cell className="table-description" collapsing={true}>
                    <Popup
                      wide={true}
                      trigger={<span>{log.description}</span>}
                      basic={true}
                      size="mini"
                      content={log.description}
                    />
                  </Table.Cell>
                  <Table.Cell className="table-user">
                    <Image
                      circular={true}
                      src={log.user.avatar ? log.user.avatar : noAvatar}
                      avatar={true}
                    />
                    {log.user.firstName} {log.user.lastName}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        {totalPages > 1 && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Pagination
              totalPages={totalPages}
              boundaryRange={1}
              activePage={this.state.activePage}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                this.state.activePage === 1
                  ? false
                  : {
                      'aria-label': 'Previous item',
                      content: 'PREVIOUS',
                    }
              }
              nextItem={
                this.state.activePage === totalPages
                  ? false
                  : {
                      'aria-label': 'Next item',

                      content: 'NEXT',
                    }
              }
              siblingRange={1}
              onPageChange={this.handlePagination}
            />
          </div>
        )}
      </div>
    );
  }

  private changePageAndRefresh(page: number) {
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          page: page - 1,
        },
        activePage: page,
      },
      () => this.getLogs(this.state.filterState)
    );
  }
}

export default AdjusterLogs;
