export interface DocumentData {
  name: string;
  description: string;
  file: File;
  folderIds: string[];
}

export interface EmbeddedVideoData {
  url: string;
  name: string;
  description: string;
  folderIds: string[];
}

// 50MB
export const MAX_FILE_SIZE = 50 * 1024 * 1024;
