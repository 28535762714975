import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Icon, Image, Menu, Pagination, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import { IUser } from '../../models';
import services from '../../services';
import profDataArray from '../../utils/profDataArray';
import { LoadingSpiner } from '../loading-spinner/LoadingSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import CreateProfDataItem from './../layout-professional-data/CreateProfDataItem';
import ProfDataItem from './../layout-professional-data/ProfDataItem';

interface Props {
  user: IUser;
}

interface State {
  activeListItem: string;
  activeItems: object[];
  initialActiveItems: object[];
  searchString: string;
  pagginationPage: number;
  totalPages: number;
  showPagination: boolean;
  totalPageItems: number;
}

class ProfessionalData extends React.Component<Props, State> {
  public state = {
    activeListItem: 'Certification Types',
    activeItems: [],
    initialActiveItems: [],
    searchString: '',
    pagginationPage: 0,
    totalPages: 1,
    showPagination: false,
    totalPageItems: 0,
  };

  public handleItemClick = async (name: string) => {
    if (
      this.props.user.role !== 'Read Only Office Admin' &&
      this.props.user.role !== 'Office Admin'
    ) {
      document.forms['new-prof-data-form'].reset();
    }
    this.setState({ activeListItem: name });
    const type = this.checkType(name);
    const responseBuff = await services.api.settings.getActiveItems(type, {
      name: '',
      page: 0,
      pageSize: 20,
    });
    if (responseBuff.isSuccess) {
      this.setState(
        {
          totalPages: Math.ceil(responseBuff.data.count / 20),
          showPagination: responseBuff.data.count > 20,
          pagginationPage: 0,
          searchString: '',
        },
        () => this.getItems()
      );
    }
  };

  public handleSearchList = (event?: React.SyntheticEvent, data: any) => {
    this.setState({ searchString: data.value, pagginationPage: 0 }, () => this.getItems());
  };

  public async componentDidMount() {
    this.getItems();
  }

  public getPagination = async () => {
    const type = this.checkType(this.state.activeListItem);
    const responseBuff = await services.api.settings.getActiveItems(type, {
      name: this.state.searchString,
      page: this.state.pagginationPage,
      pageSize: 20,
    });
    if (responseBuff.isSuccess) {
      this.setState({
        totalPages: Math.ceil(responseBuff.data.count / 20),
        showPagination: responseBuff.data.count > 20,
        totalPageItems: responseBuff.data.list.length,
      });
    }
  };

  public getItems = async () => {
    const type = this.checkType(this.state.activeListItem);
    const params = Object.assign(
      {},
      { name: this.state.searchString },
      { page: this.state.pagginationPage },
      { pageSize: 20 }
    );
    const response = await services.api.settings.getActiveItems(type, params);
    if (response.isSuccess) {
      this.getPagination();
      this.setState({
        activeItems: response.data.list,
        initialActiveItems: response.data.list,
        totalPageItems: response.data.list.length,
      });
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public deleteItem = async (id: number, name: string) => {
    const response = await services.api.settings.deleteProfData(id);
    if (response.isSuccess) {
      toast.dismiss();
      toast.success(`Item ${name} was successfully deleted!`);
      if (this.state.totalPageItems === 1) {
        this.setState({ pagginationPage: 0 }, () => this.getItems());
      } else {
        this.getItems();
        this.handleSearchList(undefined, { value: this.state.searchString });
      }
    } else {
      toast.dismiss();
      toast.error(response.data.errors[0].msg);
    }
  };

  public checkType = (type: string) => {
    switch (type) {
      case 'Certification Types':
        return 'certification';
      case 'Experience':
        return 'experience';
      case 'Expertise Loss Type':
        return 'lossType';
      case 'Equipment':
        return 'equipment';
      case 'Languages':
        return 'language';
      case 'License States':
        return 'state';
      case 'Paperwork':
        return 'paperwork';
      case 'Positions':
        return 'position';
      case 'School Types':
        return 'school';
      case 'Software Skills':
        return 'softwareSkill';
      default:
        return;
    }
  };

  public handlePagination = (e: any, data: any) => {
    this.setState({ pagginationPage: data.activePage - 1 }, () => this.getItems());
  };

  public editItem = (id: number, name: string) => {
    if (this.props.user.role === 'Read Only Office Admin') {
      return;
    }
    const { activeListItem, activeItems } = this.state;
    const type = this.checkType(activeListItem);
    const nameBuff = activeItems[id].name;
    activeItems[id].name = name;
    this.setState({ activeItems }, async () => {
      if (nameBuff !== name) {
        const obj = Object.assign({}, { type }, { name });
        const response = await services.api.settings.editProfData(activeItems[id].id, obj);
        if (response.isSuccess) {
          toast.dismiss();
          toast.success(`Item was successfuly updated!`);
        } else {
          toast.dismiss();
          toast.error(response.data.errors[0].msg);
        }
      }
    });
  };

  public render() {
    const { activeListItem } = this.state;

    return (
      <React.Fragment>
        <Grid>
          <LoadingSpiner area="message-templates-area" />

          <Grid.Row className="questions-grid-row">
            <Grid.Column className="questionnarie-list-column">
              <Menu secondary={true} vertical={true} className="questionnarie-list-container">
                {profDataArray.map((el: any, index: number) => {
                  return (
                    <Menu.Item
                      name={el}
                      active={activeListItem === el}
                      onClick={(e: any, { name }: any) => this.handleItemClick(el)}
                      key={index}
                    />
                  );
                })}
              </Menu>
            </Grid.Column>

            <Grid.Column className="questions-list-container">
              <div className="manage-questions-header">
                <div>List</div>
                <div className="questions-actions-buttons">
                  <Search
                    input={{ iconPosition: 'left' }}
                    icon={
                      <Icon>
                        <Image src={searchIcon} />
                      </Icon>
                    }
                    placeholder="Search..."
                    onSearchChange={this.handleSearchList}
                    value={this.state.searchString}
                    showNoResults={false}
                  />
                </div>
              </div>
              <LoadingSpiner area="prof-items-area" />

              {this.props.user.role !== 'Read Only Office Admin' && (
                <CreateProfDataItem
                  user={this.props.user}
                  type={this.state.activeListItem}
                  getItems={this.getItems}
                />
              )}

              {this.state.activeItems.length !== 0 ? (
                this.state.activeItems.map((el: any, index: number) => {
                  return (
                    <ProfDataItem
                      user={this.props.user}
                      item={el}
                      key={index}
                      index={index}
                      deleteItem={this.deleteItem}
                      editItem={this.editItem}
                    />
                  );
                })
              ) : (
                <NoResults />
              )}

              {this.state.showPagination && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginTop: '1rem',
                  }}
                >
                  <Pagination
                    totalPages={this.state.totalPages}
                    boundaryRange={1}
                    activePage={this.state.pagginationPage + 1}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    prevItem={
                      this.state.pagginationPage === 0
                        ? false
                        : {
                            'aria-label': 'Previous item',
                            content: 'PREVIOUS',
                          }
                    }
                    nextItem={
                      this.state.pagginationPage === this.state.totalPages - 1
                        ? false
                        : {
                            'aria-label': 'Next item',
                            content: 'NEXT',
                          }
                    }
                    siblingRange={1}
                    onPageChange={this.handlePagination}
                  />
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalData);
