import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Icon, Image, Menu, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import { adminActions } from '../../features/admin';
import { IUser } from '../../models';
import { NoResults } from '../no-results-placeholder/NoResults';
import { LoadingSpiner } from './../../components/loading-spinner/LoadingSpinner';
import ManageQuestions from './../layout-question/ManageQuestions';
import CreateQuestionnarie from './CreateQuestionnarie';

interface Props {
  getQuestions: typeof adminActions.getAllQuestionnarie;
  showCreateModal: (show: boolean) => void;
  showModal: boolean;
  questionnaries: object[];
  user: IUser;
}

interface State {
  questNameList: object[];
  initialList: object[];
  activeListItem: number;
  activeQuestions: object[];
  activeQuestionsIndex: number;
  activeName: string;
  searchString: string;
}

class Questionnaries extends React.Component<Props, State> {
  public state = {
    questNameList: [],
    initialList: [],
    activeListItem: 0,
    activeQuestions: [],
    activeQuestionsIndex: 0,
    activeName: '',
    searchString: '',
  };

  public componentDidMount() {
    this.props.getQuestions();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.questionnaries !== this.props.questionnaries) {
      if (this.props.questionnaries.length === 0) {
        this.setState({ questNameList: [], activeQuestions: [] });
      } else if (prevProps.questionnaries.length === 0 && this.props.questionnaries.length !== 0) {
        const list = this.props.questionnaries.map(
          (el: any) => (el = { name: el.name, id: el.id, questions: el.questions, order: el.order })
        );
        this.setState({
          questNameList: list.filter((el: any) =>
            el.name.toLowerCase().includes(this.state.searchString.toLowerCase())
          ),
          initialList: list,
          activeListItem: list[0].id,
          activeQuestions: list[0].questions,
          activeName: list[0].name,
        });
      } else {
        const list = this.props.questionnaries.map(
          (el: any) => (el = { name: el.name, id: el.id, questions: el.questions, order: el.order })
        );
        this.setState({
          questNameList: list.filter((el: any) =>
            el.name.toLowerCase().includes(this.state.searchString.toLowerCase())
          ),
          initialList: list,
          activeListItem: list[this.state.activeQuestionsIndex].id,
          activeQuestions: list[this.state.activeQuestionsIndex].questions,
          activeName: list[this.state.activeQuestionsIndex].name,
        });
      }
    }
  }

  public handleItemClick = (index: number) => {
    const questIndex = this.state.questNameList.findIndex((el: any) => el.id === index);
    this.setState({
      activeListItem: index,
      activeQuestions: this.state.questNameList[questIndex].questions,
      activeName: this.state.questNameList[questIndex].name,
    });
  };

  public handleSearchList = (event: React.SyntheticEvent, data: any) => {
    const { initialList } = this.state;
    const filtered = initialList.filter((el: any) =>
      el.name.toLowerCase().includes(data.value.toLowerCase())
    );
    this.setState({ questNameList: filtered, searchString: data.value });
  };

  public setActiveQuestions = (id: number) => {
    const { questNameList } = this.state;
    const index = questNameList.findIndex(el => el.id === id);
    if (index === -1) {
      // this.setState({ activeQuestionsIndex: questNameList.length - 1 });
      this.setState({ activeQuestionsIndex: questNameList.length });
    } else {
      this.setState({ activeQuestionsIndex: index });
    }
  };

  public setFirstAsActive = () => {
    this.setState({ activeQuestionsIndex: 0 });
  };

  public render() {
    const { activeListItem, questNameList } = this.state;

    return (
      <React.Fragment>
        <Grid>
          <LoadingSpiner area="questions-area" />

          <Grid.Row className="questions-grid-row">
            <Grid.Column className="questionnarie-list-column">
              <Menu secondary={true} vertical={true} className="questionnarie-list-container">
                <Search
                  input={{ iconPosition: 'left' }}
                  icon={
                    <Icon>
                      <Image src={searchIcon} />
                    </Icon>
                  }
                  placeholder="Search..."
                  onSearchChange={this.handleSearchList}
                  value={this.state.searchString}
                  showNoResults={false}
                />

                {questNameList.length !== 0 ? (
                  questNameList.map((el: any, index: number) => {
                    return (
                      <Menu.Item
                        name={el.name}
                        active={activeListItem === el.id}
                        onClick={(e: any, { name }: any) => this.handleItemClick(el.id)}
                        key={index}
                      />
                    );
                  })
                ) : (
                  <NoResults />
                )}
              </Menu>
            </Grid.Column>

            <Grid.Column className="questions-list-container">
              <ManageQuestions
                activeName={this.state.activeName}
                questions={this.state.activeQuestions}
                listId={this.state.activeListItem}
                reloadQuestions={this.props.getQuestions}
                setFirstAsActive={this.setFirstAsActive}
                setActiveQuestions={this.setActiveQuestions}
                user={this.props.user}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <CreateQuestionnarie
          showCreateModal={this.props.showCreateModal}
          showModal={this.props.showModal}
          reloadQuestions={this.props.getQuestions}
          setActiveQuestions={this.setActiveQuestions}
          user={this.props.user}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
  questionnaries: state.admin.questions.questionnaries,
  user: state.account.account.user,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getQuestions: adminActions.getAllQuestionnarie,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Questionnaries);
