import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import MyTypes from "MyTypes";
import {bindActionCreators, Dispatch} from "redux";
import {trainingCenterActions} from "../../features/training-center";
import {Image, Table} from 'semantic-ui-react';
import addEvent from '../../assets/images/add-custom-event.svg';
import editEvent from '../../assets/images/edit-custom-event.svg';
import deleteEvent from '../../assets/images/delete-custom-event.svg';
import Add from "./customEventsModals/Add";
import Edit from "./customEventsModals/Edit";
import Delete from "./customEventsModals/Delete";
import './trainingCenterCustomEventsTab.css';

interface TrainingCenterCustomEventsTabProps {
  customEventList: object[];
  getCustomEventList(): void;
}

const TrainingCenterCustomEventsTab = (props: TrainingCenterCustomEventsTabProps) => {
  const {records, totalRecords} = props.customEventList;

  enum typeModal {
    ADD = 'add',
    EDIT = 'edit',
    DELETE = 'delete'
  }

  const [modalsStatus, setModalsStatus] = useState<string | null>(null);
  const [currentClickedEvent, setCurrentClickedEvent] = useState<object | undefined>(undefined);

  const loadCustomEventList = () => {
    props.getCustomEventList();
  }

  useEffect(() => {
    loadCustomEventList();
  }, []);

  const renderModal = () => {
    switch (modalsStatus) {
      case typeModal.ADD:
        return <Add setModalsStatus={setModalsStatus} getCustomEventList={loadCustomEventList}/>
      case typeModal.EDIT:
        return <Edit setModalsStatus={setModalsStatus} currentEvent={currentClickedEvent}
                     getCustomEventList={loadCustomEventList}/>
      case  typeModal.DELETE:
        return <Delete setModalsStatus={setModalsStatus} currentEvent={currentClickedEvent}/>
      default:
        return null
    }
  }

  return (
    <>
      <Table
        compact={true}
        striped={false}
        stackable={true}
        singleLine={true}
        className="custom-events-table"
      >
        <Table.Body>
          <Table.Row>
            <Table.Cell className="table-name">New Item...</Table.Cell>
            <Table.Cell className="table-action">
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Image
                  src={addEvent}
                  onClick={() => setModalsStatus(typeModal.ADD)}
                  style={{cursor: 'pointer'}}
                />
              </div>
            </Table.Cell>
          </Table.Row>
          {!!totalRecords &&
            records.map((item: any) => (
              <Table.Row key={item.id}>
                <Table.Cell className="table-name">{item.name}</Table.Cell>
                <Table.Cell className="table-action">
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Image
                      src={editEvent}
                      onClick={() => {
                        setModalsStatus(typeModal.EDIT)
                        setCurrentClickedEvent(item)
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <Image
                      src={deleteEvent}
                      onClick={() => {
                        setModalsStatus(typeModal.DELETE)
                        setCurrentClickedEvent(item)
                      }}
                      style={{cursor: 'pointer'}}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {renderModal()}
    </>
  );
};

const mapStateToProps = (state: MyTypes.RootState) => ({
  customEventList: state.trainingCenter.customEventList
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCustomEventList: trainingCenterActions.getCustomEventList,
    }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TrainingCenterCustomEventsTab);
