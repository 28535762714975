import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Profile {
  errors: string[];
}

export const ProfileDefaultState: Profile = {
  errors: [],
};

export interface State {
  profile: Profile;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  profile: (state = ProfileDefaultState, action) => {
    switch (action.type) {
      case constants.PROFILE_SAVE_ATTEMPT_FAILED:
        return { ...state, errors: action.payload.errors };

      default:
        return state;
    }
  },
});
