import { ReactComponent as TrainingCenterIcon } from '../../assets/images/SideIcons/icn-training-center.svg';
import { ReactComponent as MoreOnFile } from '../../assets/images/more-on-file.svg';
import { ReactComponent as ExpandFolder } from '../../assets/images/expand-folder.svg';
import { ReactComponent as CollapseFolder } from '../../assets/images/collapse-folder.svg';

export const TrainingCenterIcons = {
  SideIcon: TrainingCenterIcon,
  MoreFolder: MoreOnFile,
  CollapseFolder: CollapseFolder,
  ExpandFolder: ExpandFolder
}
