import React from 'react';
import moment from 'moment';
import FolderTags from '../shared/FolderTags';
import ReactPlayer from 'react-player';

interface Props {
  url: string;
  description: string;
  lastUpdatedOn: string;
  folders: string[];
  name: string;
}

export default function EmbeddedVideoDetails(props: Props) {
  return (
    <div className="embedded-video-details">
      <div className="video-container">
        <ReactPlayer url={props.url} className="video-player" />
      </div>
      <div className="content">
        <div className="label-container">
          <div className="label">Folders</div>
          <div className="value-container tags">
            <FolderTags tags={props.folders} />
          </div>
        </div>
        <div className="label-container">
          <div className="label">Name</div>
          <div className="value-container tags">
            <div className="value">{props.name}</div>
          </div>
        </div>
        <div className="label-container">
          <div className="label">Description</div>
          <div className="value-container description">
            <div className="value">{props.description}</div>
          </div>
        </div>
        <div className="label-container">
          <div className="label">Last updated on</div>
          <div className="value-container">
            <div className="value">{moment(props.lastUpdatedOn).format('DD/MM/YYYY, h:mm A')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
