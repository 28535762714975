import MyTypes from 'MyTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Image, Popup, Segment } from 'semantic-ui-react';
import noAvatar from '../../assets/images/no-avatar.svg';
import '../../assets/styles/Dashboard.css';
import { accountActions } from '../../features/account';
import services from '../../services';
import { NoResults } from '../no-results-placeholder/NoResults';

interface Props {
  pendingAdj: object[];
  fetchAdjusterDataAttempt: typeof accountActions.fetchAdjusterDataAttempt;
  setTab: (name: string) => void;
}

class PendingAdjusters extends React.Component<Props> {
  public onNavigate(adjusterId: string) {
    services.router.goto(`adjusters/${adjusterId}`);
  }

  public render() {
    return (
      <div className="dashboard-block adjusters-block-dashboard">
        <div className="dashboard-block-header">
          Pending Adjusters
          <Button
            className="secondary-button"
            onClick={() => {
              this.props.setTab('Pending');
              services.router.goto('/adjusters');
            }}
            style={{ margin: '0 0 0.5rem 0' }}
          >
            View all
          </Button>
        </div>
        {this.props.pendingAdj && this.props.pendingAdj.length !== 0 ? (
          this.props.pendingAdj.map((el: any, index: number) => {
            return (
              <Segment key={index} onClick={() => this.onNavigate(el.id)}>
                <div className="segment-layout">
                  <Image avatar={true} src={el.avatar ? el.avatar : noAvatar} />
                  <div className="segment-user-info">
                    <p className="segment-title">
                      {el.preferredName ? el.preferredName : el.firstName} {el.lastName}
                    </p>
                    {el.positions && (
                      <p className="segment-description">{el.positions.join(', ')}</p>
                    )}
                  </div>
                </div>
              </Segment>
            );
          })
        ) : (
          <NoResults />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchAdjusterDataAttempt: accountActions.fetchAdjusterDataAttempt,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingAdjusters);
