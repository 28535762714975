export default [
    {
        label: 'Address (Line 1)',
        name: 'addressLine1',
        type: 'text',
        required: true,
        disabled: false,
        className: 'form-input',
    },
    {
        label: 'Address (Line 2)',
        name: 'addressLine2',
        type: 'text',
        required: false,
        disabled: false,
        className: 'form-input',
    },
    {
        label: 'City',
        type: 'text',
        name: 'city',
        required: true,
        disabled: false,
        className: 'form-input',
    },
]