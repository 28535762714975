import { action } from 'typesafe-actions';
import * as constants from './constants';

export interface CreateAdminModel {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role: string;
  active_status: number;
  finance_access: number;
  avatar: string;
}

export interface EditAdminModel {
  role: string;
  active_status: number;
  finance_access: number;
}

export const getAdminList = (params: object) =>
  action(constants.GET_ADMIN_LIST, {
    params,
  });

export const getAdminListSuccess = (admins: object[], count?: number) =>
  action(constants.GET_ADMIN_LIST_SUCCESS, {
    admins,
    count,
  });

export const resetAdminList = () => action(constants.RESET_ADMIN_LIST, {});

export const getAllQuestionnarie = () => action(constants.GET_ALL_QUESTIONNARIES, {});

export const saveQuestionnaries = (questionnaries: any) =>
  action(constants.SAVE_QUESTIONNARIES, {
    questionnaries,
  });

export const getAllMsgTemplates = () => action(constants.GET_ALL_MSG_TEMPLATES, {});

export const saveMsgTemplates = (msgTemplates: any) =>
  action(constants.SAVE_MSG_TEMPLATES, {
    msgTemplates,
  });
