import moment from 'moment';
import MyTypes from 'MyTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Image, Segment } from 'semantic-ui-react';
import noAvatar from '../../assets/images/no-avatar.svg';
import groupMsgIcon from '../../assets/images/Other/icn-group-message.svg';
import '../../assets/styles/Dashboard.css';
import { uploads } from '../../config';
import services from '../../services';
import { NoResults } from '../no-results-placeholder/NoResults';

interface Props {
  messages: object[];
}

class UnreadMessages extends React.Component<Props> {
  public handleMessageClick = (item: any) => {
    item.standByList && item.standByList.id
      ? services.router.goto(`/messages/room/${item.adjuster.id}/${item.standByList.id}`)
      : services.router.goto(`/messages/room/${item.adjuster.id}/`);
  };

  public render() {
    const { messages } = this.props;
    return (
      <div className="dashboard-block unread-message-block">
        <div className="dashboard-block-header">Unread Messages</div>
        {messages &&
          messages.map((el: any, index: number) => {
            return (
              <Segment key={index} onClick={() => this.handleMessageClick(el)}>
                <div className="segment-layout">
                  <Image
                    src={
                      el.adjuster.avater ? uploads.s3BucketAddress + el.adjuster.avater : noAvatar
                    }
                    avatar={true}
                  />
                  <div className="segment-user-info">
                    <p className="segment-title">{el.messages[0].text}</p>
                    <p className="segment-description">
                      <span style={{ marginRight: '1rem' }}>
                        {moment(el.messages[0].createdAt).format('MM/DD/YYYY, hh:mm A')}
                      </span>
                      <span>
                        {el.adjuster.preferredName
                          ? el.adjuster.preferredName
                          : el.adjuster.firstName}{' '}
                        {el.adjuster.lastName}
                      </span>
                    </p>
                  </div>
                </div>
              </Segment>
            );
          })}
        {messages.length === 0 && <NoResults />}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnreadMessages);
