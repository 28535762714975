import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Loading {
  isVisible: boolean;
  progress: number;
}

export const LoadingDefaultState: Loading = {
  isVisible: false,
  progress: 0,
};

export interface State {
  loading: Loading;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  loading: (state = LoadingDefaultState, action) => {
    switch (action.type) {
      case constants.SHOW:
        return { ...state, isVisible: true, progress: 0 };
      case constants.SET_PROGRESS:
        console.log('SET_PROGRESS', action.payload.progress);
        return { ...state, progress: action.payload.progress };
      case constants.ADD_PROGRESS:
        return { ...state, progress: state.progress + action.payload.progress };
      case constants.HIDE:
        return { ...state, isVisible: false, progress: 0 };
      default:
        return state;
    }
  },
});
