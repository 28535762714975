import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';
import icon from '../../assets/images/Common/icn-search-grey.svg';
import { UserGender, UserRace } from '../../models';
import services from '../../services';
import {
  DEFAULT_ADJUSTERS_SORT_OPTIONS,
  DEFAULT_ADJUSTERS_SORT_OPTIONS_QUICK,
} from '../../utils/defaultSortOptions';
import deploymentStatuses from '../../utils/deploymentStatuses';
import statesUS from '../../utils/statesUS';
import SearchMultipleInput from '../form/SearchMultipleInput/SearchMultipleInput';
import SearchSelectInput from '../form/SearchSelectInput/SearchSelectInput';
import SortBySelectionInput from '../form/sortby-selection-input/SortBySelectionInput';

interface Props {
  filter: any;
  activePage: string;
  sortOptions?: Array<{ text: string; value: string }>;
  reset: () => void;
  filterLogs: (params?: any) => void;
}

interface State {
  gender: object[];
  race: object[];
  position: object[];
  experience: object[];
  language: object[];
  licenseStates: object[];
  softwareSkill: object[];
  lossType: object[];
  certificationTypes: object[];
  state: object[];
  paperwork: object[];
  school: object[];
  equipment: object[];
  standbyListsLocations: object[];
  deploymentsFilters: object[];
}

const registrationTypeOpt = [{ text: 'Individual Only', value: 'individual' }];

const availableValues = [{ text: 'Available for claims', value: true }];

const paperworkTypeOpt = [{ text: 'Complete Paperwork Only', value: true }];

const isAdminOpt = [{ text: 'Admin Only', value: true }];

const deployedValues = [{ text: 'Deployed Only', value: true }];

const largeValues = [{ text: 'Large Loss Team', value: true }];

const teamValues = [{ text: 'Commercial Team', value: true }];

class AdjustersFilter extends React.Component<Props, State> {
  public form = React.createRef();

  public state = {
    position: [],
    experience: [],
    language: [],
    softwareSkill: [],
    lossType: [],
    certification: [],
    state: [],
    paperwork: [],
    school: [],
    equipment: [],
    standbyListsLocations: [],
    deploymentsFilters: [],
    open: '',
    formFields: {
      gender: [],
      race: [],
      state: [],
      licenseStates: [],
      certificationTypes: [],
      experience: [],
      position: [],
      equipment: [],
      expertise: [],
      softwareSkill: [],
      catStateProvince: [],
      language: [],
      registrationType: [],
      standbyListLocations: [],
      deploymentsFilters: [],
      standbyListDeployments: [],
      eventDeployments: [],
      deploymentStatus: [],
    },
    formFieldsBool: {
      deployed: {},
      availableForClaims: {},
      largeLossTeam: {},
      commercialTeam: {},
      isAdmin: {},
      allPaperworkCompleted: {},
    },
  };

  public setField = (name, obj) => {
    const { formFields } = this.state;
    if (obj === '') {
      this.setState({ formFields: { ...formFields, [name]: [] } });
    } else {
      const newValue = Array.isArray(obj.value)
        ? obj.included
          ? obj.value
          : ['exclude', ...obj.value]
        : obj.value;
      this.setState({ formFields: { ...formFields, [name]: newValue } });
    }
  };

  public setFieldBool = (name, obj) => {
    const { formFieldsBool } = this.state;
    this.setState({
      formFieldsBool: { ...formFieldsBool, [name]: obj },
    });
  };

  public resetField = (name = '') => {
    const { formFields } = this.state;

    const newFormFields = { ...formFields, [name]: [] };

    this.setState({ formFields: newFormFields });
  };

  public resetFieldBool = (name = '') => {
    const { formFieldsBool } = this.state;

    const newFormFieldsBool = { ...formFieldsBool, [name]: {} };

    this.setState({ formFieldsBool: newFormFieldsBool });
  };

  public componentDidMount = async () => {
    const { formFieldsBool, formFields } = this.state;
    const { filter } = this.props;
    let obj = { ...formFieldsBool };

    Object.keys(formFieldsBool).forEach(i => {
      if (typeof filter[i] !== 'string') {
        switch (i) {
          case 'deployed':
            if (filter.deployed !== undefined) {
              obj = { ...obj, [i]: { ...deployedValues[0], value: filter.deployed } };
            }
            break;
          case 'availableForClaims':
            if (filter.availableForClaims !== undefined) {
              obj = { ...obj, [i]: { ...availableValues[0], value: filter.availableForClaims } };
            }
            break;
          case 'largeLossTeam':
            if (filter.largeLossTeam !== undefined) {
              obj = { ...obj, [i]: { ...largeValues[0], value: filter.largeLossTeam } };
            }
            break;
          case 'commercialTeam':
            if (filter.commercialTeam !== undefined) {
              obj = { ...obj, [i]: { ...teamValues[0], value: filter.commercialTeam } };
            }
            break;
          case 'isAdmin':
            if (filter.isAdmin !== undefined) {
              obj = { ...obj, [i]: { ...isAdminOpt[0], value: filter.isAdmin } };
            }
            break;
          case 'allPaperworkCompleted':
            if (filter.allPaperworkCompleted !== undefined) {
              obj = {
                ...obj,
                [i]: { ...paperworkTypeOpt[0], value: filter.allPaperworkCompleted },
              };
            }
            break;
        }
      }

      let newFormFields = { ...formFields };
      const newDeploymentsFilters = [];

      Object.keys(formFields).forEach(name => {
        if (filter[name] && filter[name].length) {
          if (name === 'registrationType') {
            if (filter[name][0] === 'individual') {
              newFormFields = { ...newFormFields, registrationType: ['individual'] };
            }
            if (filter[name][0] === 'business') {
              newFormFields = { ...newFormFields, registrationType: ['exclude', 'individual'] };
            }
          } else if (
            name === 'eventDeployments' ||
            (name === 'standbyListDeployments' && filter[name][0])
          ) {
            const { eventDeployments, standbyListDeployments } = filter;
            newFormFields = {
              ...newFormFields,
              deploymentsFilters: { eventDeployments, standbyListDeployments },
            };
          } else {
            newFormFields = { ...newFormFields, [name]: filter[name] };
          }
        }
      });

      this.setState({ formFieldsBool: obj, formFields: newFormFields });
    });

    const skillsArray = [
      'position',
      'experience',
      'language',
      'softwareSkill',
      'lossType',
      'certification',
      'state',
      'paperwork',
      'school',
      'equipment',
      'standbyListsLocations',
      'deploymentsFilters',
    ];
    for (const skill of skillsArray) {
      let response;
      let optionsArray;
      if (skill === 'standbyListsLocations') {
        response = await services.api.account.getStandbyListLocations();
        if (response.isSuccess) {
          optionsArray = response.data.list.map((el: any) => ({
            text: el.locationName,
            value: el.location,
          }));
        }
      } else if (skill === 'deploymentsFilters') {
        response = await services.api.account.getDeploymentsFilters();
        if (response.isSuccess) {
          optionsArray = response.data.map((el: any) => ({
            text: el.name || `${el.title} - ${el.location}`,
            id: el.id,
            isStandbyList: el.isStandbyList,
            value: el.name || el.location,
          }));
        }
      } else {
        response = await services.api.account.getSettingsData(skill);
        if (response.isSuccess) {
          optionsArray = response.data.list.map((el: any) => ({
            text: el.name,
            value: el.name,
          }));
        }
      }
      this.setState({ [skill]: optionsArray });
    }

    this.setState({
      gender: Object.values(UserGender).map(key => ({ text: key, value: key })),
      race: Object.values(UserRace).map(key => ({ text: key, value: key })),
    });
  };

  public setOpen = name => this.setState({ open: name });
  public closePopup = () => this.setOpen({ open: '' });

  public render() {
    const {
      position,
      experience,
      language,
      softwareSkill,
      lossType,
      certification,
      state,
      equipment,
      standbyListsLocations,
      deploymentsFilters,
      formFields,
      formFieldsBool,
      gender,
      race,
    } = this.state;
    const { activePage } = this.props;
    const { deploymentsFilters: deploymentValue } = formFields;
    const { standbyListDeployments = [], eventDeployments = [] } = deploymentValue;
    const deploymentFilterValue = this.state.formFields.deploymentsFilters
      ? [
          ...standbyListDeployments.map(i => (i === 'exclude' ? 'exclude' : i.value)),
          ...eventDeployments.map(i => (i === 'exclude' ? 'exclude' : i.value)),
        ]
      : [];

    return (
      <Form
        ref={this.props.formRef || this.form}
        onValidSubmit={(model: any) => {
          const obj = {};
          const objFields = {};
          const deploymentsFilter = {};

          console.log('model', { model });

          Object.keys(formFieldsBool).forEach(i => {
            obj[i] = formFieldsBool[i].value;
          });

          Object.keys(formFields).forEach(i => {
            if (i === 'registrationType') {
              if (formFields[i][0] === 'individual') {
                objFields[i] = ['individual'];
              }
              if (formFields[i][0] === 'exclude') {
                objFields[i] = ['business'];
              }
            } else if (i === 'deploymentsFilters') {
              deploymentsFilter.standbyListDeployments = formFields[i].standbyListDeployments || [];
              deploymentsFilter.eventDeployments = formFields[i].eventDeployments || [];
            } else {
              objFields[i] = formFields[i];
            }
          });

          if (model.phone) {
            model.phone = model.phone.replace(/[^0-9.]/g, '');
          }

          const newModel = {
            stateQuick: model.stateQuick || '',
            ...model,
            ...objFields,
            ...obj,
            eventDeployments: deploymentsFilter.eventDeployments,
            standbyListDeployments: deploymentsFilter.standbyListDeployments,
          };
          this.props.filterLogs(newModel);
        }}
      >
        {activePage !== 'QuickApplied' ? (
          <div className="filters-container adjusters-filters">
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Full Name"
                name="name"
                value={this.props.filter.name}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="gender"
                value={this.state.formFields.gender}
                placeholder="Gender"
                setField={(name, value) => this.setField(name, value)}
                options={gender}
                formFields={this.state.formFields}
                open={this.state.open}
                resetField={() => this.resetField('gender')}
                setOpen={() => this.setOpen('gender')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="race"
                value={this.state.formFields.race}
                placeholder="Race"
                setField={(name, value) => this.setField(name, value)}
                options={race}
                formFields={this.state.formFields}
                open={this.state.open}
                resetField={() => this.resetField('race')}
                setOpen={() => this.setOpen('race')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Email"
                name="email"
                value={this.props.filter.email}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Phone"
                name="phone"
                value={this.props.filter.phone}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="City"
                name="city"
                value={this.props.filter.city}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                value={this.state.formFields.state}
                formFields={this.state.formFields}
                name="state"
                placeholder="State"
                options={statesUS}
                showHeader={true}
                setField={(name, value) => this.setField(name, value)}
                resetField={() => this.resetField('state')}
                open={this.state.open}
                setOpen={() => this.setOpen('state')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                value={this.state.formFields.deploymentStatus}
                formFields={this.state.formFields}
                name="deploymentStatus"
                placeholder="Deployment Status"
                options={[{
                  text: 'Blanks',
                  value: 'blanks',
                }, ...deploymentStatuses]}
                showHeader={true}
                setField={(name, value) => this.setField(name, value)}
                resetField={() => this.resetField('deploymentStatus')}
                open={this.state.open}
                setOpen={() => this.setOpen('deploymentStatus')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Zip"
                name="zip"
                value={this.props.filter.zip}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                showActiveCertifications={true}
                name="certificationTypes"
                value={this.state.formFields.certificationTypes}
                placeholder="Certifications"
                options={certification}
                formFields={this.state.formFields}
                open={this.state.open}
                setField={(name, value) => this.setField(name, value)}
                setOpen={() => this.setOpen('certificationTypes')}
                resetField={() => this.resetField('certificationTypes')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                name="licenseStates"
                formFields={this.state.formFields}
                value={this.state.formFields.licenseStates}
                placeholder="License State"
                options={state}
                formFields={this.state.formFields}
                setField={(name, value) => this.setField(name, value)}
                showHeader={true}
                open={this.state.open}
                resetField={() => this.resetField('licenseStates')}
                setOpen={() => this.setOpen('licenseStates')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="experience"
                value={this.state.formFields.experience}
                placeholder="Experience"
                formFields={this.state.formFields}
                options={experience}
                setField={(name, value) => this.setField(name, value)}
                open={this.state.open}
                resetField={() => this.resetField('experience')}
                setOpen={() => this.setOpen('experience')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="position"
                value={this.state.formFields.position}
                placeholder="Positions"
                options={position}
                formFields={this.state.formFields}
                open={this.state.open}
                setField={(name, value) => this.setField(name, value)}
                setOpen={() => this.setOpen('position')}
                resetField={() => this.resetField('position')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="equipment"
                value={this.state.formFields.equipment}
                placeholder="Equipment"
                options={equipment}
                formFields={this.state.formFields}
                setField={(name, value) => this.setField(name, value)}
                open={this.state.open}
                resetField={() => this.resetField('equipment')}
                setOpen={() => this.setOpen('equipment')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="expertise"
                value={this.state.formFields.expertise}
                placeholder="Expertise"
                setField={(name, value) => this.setField(name, value)}
                options={lossType}
                formFields={this.state.formFields}
                open={this.state.open}
                resetField={() => this.resetField('expertise')}
                setOpen={() => this.setOpen('expertise')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="softwareSkill"
                formFields={this.state.formFields}
                setField={(name, value) => this.setField(name, value)}
                value={this.state.formFields.softwareSkill}
                placeholder="Software Skills"
                options={softwareSkill}
                resetField={() => this.resetField('softwareSkill')}
                open={this.state.open}
                setOpen={() => this.setOpen('softwareSkill')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                formFields={this.state.formFields}
                name="language"
                value={this.state.formFields.language}
                placeholder="Languages"
                options={language}
                setField={(name, value) => this.setField(name, value)}
                open={this.state.open}
                resetField={() => this.resetField('language')}
                setOpen={() => this.setOpen('language')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="XACTNET Address"
                name="xactnetAddress"
                value={this.props.filter.xactnetAddress}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Cat City"
                name="catCity"
                value={this.props.filter.catCity}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="catStateProvince"
                value={this.state.formFields.catStateProvince}
                placeholder="Cat State"
                setField={(name, value) => this.setField(name, value)}
                options={statesUS}
                open={this.state.open}
                formFields={this.state.formFields}
                resetField={() => this.resetField('catStateProvince')}
                setOpen={() => this.setOpen('catStateProvince')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <Form.Input
                type="number"
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Cat Zip Code"
                name="catZip"
                value={this.props.filter.catZip}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <SearchSelectInput
                name="availableForClaims"
                value={this.state.formFieldsBool.availableForClaims}
                placeholder="Available for claims"
                options={availableValues}
                allowEmpty={true}
                resetFieldBool={() => this.resetFieldBool('availableForClaims')}
                setFieldBool={(name, value) => this.setFieldBool(name, value)}
                open={this.state.open}
                setOpen={() => this.setOpen('availableForClaims')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchSelectInput
                name="deployed"
                value={this.state.formFieldsBool.deployed}
                placeholder="Deployed"
                options={deployedValues}
                allowEmpty={true}
                setFieldBool={(name, value) => this.setFieldBool(name, value)}
                open={this.state.open}
                resetFieldBool={() => this.resetFieldBool('deployed')}
                setOpen={() => this.setOpen('deployed')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchSelectInput
                name="largeLossTeam"
                value={this.state.formFieldsBool.largeLossTeam}
                placeholder="Large Loss Team"
                options={largeValues}
                allowEmpty={true}
                setFieldBool={(name, value) => this.setFieldBool(name, value)}
                open={this.state.open}
                resetFieldBool={() => this.resetFieldBool('largeLossTeam')}
                setOpen={() => this.setOpen('largeLossTeam')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchSelectInput
                name="commercialTeam"
                value={this.state.formFieldsBool.commercialTeam}
                placeholder="Commercial Team"
                options={teamValues}
                allowEmpty={true}
                setFieldBool={(name, value) => this.setFieldBool(name, value)}
                open={this.state.open}
                resetFieldBool={() => this.resetFieldBool('commercialTeam')}
                setOpen={() => this.setOpen('commercialTeam')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="registrationType"
                value={this.state.formFields.registrationType}
                placeholder="Registration Type"
                options={registrationTypeOpt}
                setField={(name, value) => this.setField(name, value)}
                open={this.state.open}
                resetField={() => this.resetField('registrationType')}
                formFields={this.state.formFields}
                setOpen={() => this.setOpen('registrationType')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchSelectInput
                name="isAdmin"
                value={
                  activePage === 'Admins'
                    ? { text: 'Admin Only', value: true }
                    : activePage === 'Pending' ||
                      activePage === 'To Review' ||
                      activePage === 'Active' ||
                      activePage === 'Inactive'
                    ? ''
                    : this.state.formFieldsBool.isAdmin
                }
                disabled={
                  activePage === 'Admins' ||
                  activePage === 'Pending' ||
                  activePage === 'To Review' ||
                  activePage === 'Active' ||
                  activePage === 'Inactive'
                }
                placeholder="Is Admin"
                options={isAdminOpt}
                allowEmpty={true}
                setFieldBool={(name, value) => this.setFieldBool(name, value)}
                open={this.state.open}
                resetFieldBool={() => this.resetFieldBool('isAdmin')}
                setOpen={() => this.setOpen('isAdmin')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchSelectInput
                name="allPaperworkCompleted"
                value={this.state.formFieldsBool.allPaperworkCompleted}
                placeholder="Paperwork Type"
                options={paperworkTypeOpt}
                allowEmpty={true}
                setFieldBool={(name, value) => this.setFieldBool(name, value)}
                open={this.state.open}
                resetFieldBool={() => this.resetFieldBool('allPaperworkCompleted')}
                setOpen={() => this.setOpen('allPaperworkCompleted')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="deploymentsFilters"
                value={deploymentFilterValue}
                placeholder="Deployments"
                setField={(name, value) => this.setField(name, value)}
                options={deploymentsFilters}
                formFields={this.state.formFields}
                open={this.state.open}
                resetField={() => this.resetField('deploymentsFilters')}
                setOpen={() => this.setOpen('deploymentsFilters')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <SearchMultipleInput
                showHeader={true}
                name="standbyListLocations"
                value={this.state.formFields.standbyListLocations}
                placeholder="Standby Lists"
                setField={(name, value) => this.setField(name, value)}
                options={standbyListsLocations}
                formFields={this.state.formFields}
                open={this.state.open}
                resetField={() => this.resetField('standbyListLocations')}
                setOpen={() => this.setOpen('standbyListLocations')}
                closePopup={this.closePopup}
                {...this.props}
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Event Location"
                name="eventLocation"
                value={this.props.filter.eventLocation}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                type="number"
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Miles Adjuster Willing to Travel"
                name="milesRange"
                value={this.props.filter.milesRange}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                type="number"
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Certification Score"
                name="certificationScore"
                value={this.props.filter.certificationScore}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                type="number"
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Field Interview Score"
                name="fieldInterviewScore"
                value={this.props.filter.fieldInterviewScore}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                type="number"
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Desk Interview Score"
                name="deskInterviewScore"
                value={this.props.filter.deskInterviewScore}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                type="number"
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="QA Score"
                name="qaScore"
                value={this.props.filter.qaScore}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <div className="filter-label">Authority Level</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Input
                  placeholder="..."
                  name="authorityLevelFrom"
                  value={this.props.filter.authorityLevelFrom}
                  className="form-input-short from-to-input"
                />
                -
                <Form.Input
                  placeholder="..."
                  name="authorityLevelTo"
                  value={this.props.filter.authorityLevelTo}
                  className="form-input-short from-to-input"
                />
              </div>
            </div>
            <div className="filter-block sort-by-filter-container">
              <SortBySelectionInput
                name="sort"
                value={this.props.filter.sort}
                options={this.props.sortOptions || DEFAULT_ADJUSTERS_SORT_OPTIONS}
              />
            </div>
            <div className="filter-block submit-filter-actions">
              <Button
                type="button"
                style={{ width: '86px', height: '32px', marginRight: '0.5rem' }}
                className="secondary-button"
                secondary={true}
                onClick={() => {
                  this.props.reset();
                  if (this.props.formRef && this.props.formRef.current) {
                    this.props.formRef.current.formsyForm.reset();
                  } else {
                    this.form.current.formsyForm.reset();
                  }

                  const newFormFields = {
                    state: [],
                    licenseStates: [],
                    certificationTypes: [],
                    experience: [],
                    position: [],
                    equipment: [],
                    expertise: [],
                    softwareSkill: [],
                    catStateProvince: [],
                    language: [],
                    standbyListLocations: [],
                    deploymentsFilters: [],
                  };

                  const newFormFieldsBool = {
                    deployed: {},
                    availableForClaims: {},
                    largeLossTeam: {},
                    commercialTeam: {},
                    registrationType: {},
                    isAdmin: {},
                    paperwork: {},
                  };

                  this.setState({ formFieldsBool: newFormFieldsBool, formFields: newFormFields });
                }}
              >
                RESET
              </Button>
              <Button style={{ width: '86px', height: '32px' }} primary={true}>
                APPLY
              </Button>
            </div>
          </div>
        ) : (
          <div className="filters-container adjusters-filters">
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Full Name"
                name="name"
                value={this.props.filter.name}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Email"
                name="email"
                value={this.props.filter.email}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="Phone"
                name="phone"
                value={this.props.filter.phone}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="City"
                name="city"
                value={this.props.filter.city}
                className="form-input-short"
              />
            </div>
            <div className="filter-block">
              <Form.Input
                icon={
                  <Icon className="adjuster-filter-icon">
                    <Image src={icon} />
                  </Icon>
                }
                iconPosition="left"
                placeholder="State"
                name="stateQuick"
                value={this.props.filter.stateQuick}
                className="form-input-short"
              />
            </div>
            <div className="filter-block sort-by-filter-container">
              <SortBySelectionInput
                name="sort"
                value={this.props.filter.sort}
                options={DEFAULT_ADJUSTERS_SORT_OPTIONS_QUICK}
              />
            </div>
            <div className="filter-block submit-filter-actions">
              <Button
                type="button"
                style={{ width: '86px', height: '32px', marginRight: '0.5rem' }}
                className="secondary-button"
                secondary={true}
                onClick={() => {
                  this.props.reset();
                  if (this.props.formRef && this.props.formRef.current) {
                    this.props.formRef.current.formsyForm.reset();
                  } else {
                    this.form.current.formsyForm.reset();
                  }

                  const newFormFields = {
                    state: [],
                    licenseStates: [],
                    certificationTypes: [],
                    experience: [],
                    position: [],
                    equipment: [],
                    expertise: [],
                    softwareSkill: [],
                    catStateProvince: [],
                    language: [],
                    standbyListLocations: [],
                    deploymentsFilters: [],
                  };

                  const newFormFieldsBool = {
                    deployed: {},
                    availableForClaims: {},
                    largeLossTeam: {},
                    commercialTeam: {},
                    registrationType: {},
                    isAdmin: {},
                    paperwork: {},
                  };

                  this.setState({ formFieldsBool: newFormFieldsBool, formFields: newFormFields });
                }}
              >
                RESET
              </Button>
              <Button style={{ width: '86px', height: '32px' }} primary={true}>
                APPLY
              </Button>
            </div>
          </div>
        )}
      </Form>
    );
  }
}

export default AdjustersFilter;
