import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image, Label, Popup, Segment } from 'semantic-ui-react';
import noAvatar from '../../assets/images/no-avatar.svg';
import groupMsgIcon from '../../assets/images/Other/icn-group-message.svg';
import msgIconW from '../../assets/images/White/message.svg';
import '../../assets/styles/Messages.css';
import { uploads } from '../../config';
import services from '../../services';

interface Props {
  item: object;
  setType: (type: string) => void;
}

interface State {
  standByListTitle: string;
}

class MessageItem extends React.Component<Props, State> {
  public state = {
    standByListTitle: '',
  };

  public componentDidMount() {
    if (this.props.item.standbyListId) {
      this.getListItem(this.props.item.standbyListId);
    }
  }

  public getListItem(id: string) {
    services.api.standby.getStandbyListDetails(id).then((response: any) => {
      if (response.isSuccess) {
        this.setState({ standByListTitle: response.data.title });
      }
    });
  }

  public handleMessageClick = () => {
    if (this.props.item.recipients.length === 1) {
      return this.props.item.standbyListId
        ? `/messages/room/${this.props.item.recipients[0].id}/${this.props.item.standbyListId}`
        : `/messages/room/${this.props.item.recipients[0].id}`;
    } else {
      return this.props.item.standbyListId
        ? `/stand-by-lists/message/${this.props.item.standbyListId}/${this.props.item.messages[0].id}`
        : `/messages/${this.props.item.messages[0].id}`;
    }
  };

  public render() {
    const { item } = this.props;
    const link = this.handleMessageClick();
    return (
      <Segment className="msg-item-container">
        <div>
          <div className="message-avatar-container">
            <Image
              circular={true}
              src={
                item.recipientsCount !== 1
                  ? groupMsgIcon
                  : item.recipients[0].avatar
                  ? uploads.s3BucketAddress + item.recipients[0].avatar
                  : noAvatar
              }
              avatar={true}
            />
            {item.hasUnreadMessages && (
              <Label color="red" floating={true} circular={true} empty={true} />
            )}
          </div>
          <div style={{ display: 'flex', marginLeft: '12px', flexDirection: 'column' }}>
            <div style={{ fontSize: '16px', fontWeight: 700 }}>{item.messages[0].title}</div>
            <div style={{ fontSize: '13px' }}>
              <span style={{ marginRight: '1rem' }}>
                {moment(item.messages[0].createdAt).format('MM/DD/YYYY, hh:mm A')}
              </span>
              <span>
                {item.recipientsList
                  .map((el, id) => {
                    if (id > 4) {
                      return;
                    } else {
                      const fullName = `${el.preferredName ? el.preferredName : el.firstName} ${
                        el.lastName
                      }`;
                      return fullName;
                    }
                  })
                  .join(', ')}
                {item.recipientsListCount > 5 && (
                  <span className="more-recipients-info">{` +${item.recipientsListCount -
                    5} more`}</span>
                )}
              </span>
            </div>
            {item.standbyListId && (
              <span>
                {'Standby: '}
                <Link className="msg-item-link" to={`/stand-by-lists/${item.standbyListId}`}>
                  {this.state.standByListTitle}
                </Link>
              </span>
            )}
          </div>
        </div>

        <div>
          <div
            className="sbl-item-title"
            style={{ textAlign: 'center', marginLeft: '3rem', marginRight: '3rem' }}
          >
            <div style={{ fontWeight: 700, fontSize: '16px' }}>{item.recipientsCount}</div>
            <div style={{ fontSize: '13px' }}>Sent</div>
          </div>
          <Popup
            trigger={
              <Link to={link}>
                <Button primary={true} className="sbl-action-button">
                  <Image src={msgIconW} />
                </Button>
              </Link>
            }
            basic={true}
            size="mini"
            content="Message"
          />
        </div>
      </Segment>
    );
  }
}

export default MessageItem;
