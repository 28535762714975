import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as constants from './constants';

export interface Admins {
  adminList: object[];
  count: number | undefined;
}

export interface Questions {
  questionnaries: object[];
  msgTemplates: object[];
}

export const createAdminsState: Admins = {
  adminList: [],
  count: 0,
};

export const createQuestionsState: Questions = {
  questionnaries: [],
  msgTemplates: [],
};

export interface State {
  admins: Admins;
  questions: Questions;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
  admins: (state = createAdminsState, action) => {
    switch (action.type) {
      case constants.GET_ADMIN_LIST:
        return { ...state };

      case constants.GET_ADMIN_LIST_SUCCESS:
        return { ...state, adminList: action.payload.admins, count: action.payload.count };

      case constants.RESET_ADMIN_LIST:
        return { ...state, adminList: [] };

      default:
        return state;
    }
  },
  questions: (state = createQuestionsState, action) => {
    switch (action.type) {
      case constants.GET_ALL_QUESTIONNARIES:
        return { ...state };

      case constants.SAVE_QUESTIONNARIES:
        return { ...state, questionnaries: action.payload.questionnaries };

      case constants.GET_ALL_MSG_TEMPLATES:
        return { ...state };

      case constants.SAVE_MSG_TEMPLATES:
        return { ...state, msgTemplates: action.payload.msgTemplates };

      default:
        return state;
    }
  },
});
