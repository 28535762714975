import _ from 'lodash';
import Types from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Icon, Image, Modal } from 'semantic-ui-react';

import FinanceFilesMatcher from './FinanceFilesMatcher';
import UploadZone from './UploadZone';

import cancelIcon from '../../../assets/images/Blue/cancel.svg';
import '../../../assets/styles/Finances.css';

import { financeActions } from '../../../features/finance';

interface Props {
  visible: boolean;
  uploadFiles: any[];
  matchingAdjuster: boolean;
  initialAdjusters: any[];
  saving: boolean;
  savingError: string | null;
  onClose: () => void;
  addUploadFinancialFiles: (files: any[]) => void;
  removeUploadFinancialFile: (index: number) => void;
  resetFinancialFiles: () => void;
  setFinancialFileAdjuster: (index: number, adjuster: any) => void;
  setFinancialFileId: (index: number, financialId: string) => void;
  matchFinancesAdjuster: () => void;
  onSaveDone: () => void;
  saveFinances: () => void;
}

interface State {
  visible: boolean;
  step: string;
  loading: boolean;
}

class FinanceFilesUploader extends React.Component<Props, State> {
  public state = {
    visible: false,
    step: 'uploader',
    loading: false,
  };

  public componentDidUpdate(prevProps: Props) {
    if (this.props.visible !== prevProps.visible) {
      this.reset();
    }

    if (!this.props.matchingAdjuster && prevProps.matchingAdjuster) {
      this.goNextStep();
    }

    if (prevProps.saving && !this.props.saving) {
      if (!this.props.savingError) {
        this.props.onSaveDone();
      } else {
        toast.error(this.props.savingError);
      }
      this.setState({ loading: false });
    }
  }

  public handleNextPress = () => {
    const { uploadFiles, matchFinancesAdjuster } = this.props;
    if (uploadFiles && uploadFiles.length) {
      matchFinancesAdjuster();
    } else {
      this.goNextStep();
    }
  };

  public handleUploadPress = () => {
    if (!this.state.loading) {
      for (const file of this.props.uploadFiles) {
        if (!file.matchedAdjuster || !file.matchedAdjuster.id) {
          toast.error(
            'Please fill in all missing adjusters or exclude these files from the upload.'
          );

          this.setState({ loading: false });
          return;
        }
      }

      this.props.saveFinances();
    }
  };

  public handleAdjusterChange = (index: number, adjuster: any) => {
    this.props.setFinancialFileAdjuster(index, adjuster);
  };

  public onClickUpload = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      this.handleUploadPress();
    }
  };

  public render() {
    const { visible, onClose, matchingAdjuster, initialAdjusters, saving } = this.props;
    const { step } = this.state;
    const files = this.props.uploadFiles;
    const isEmpty = files.length === 0;

    return (
      <Modal
        className="finance-files-uploader"
        open={visible}
        onClose={onClose}
        closeIcon={
          <Icon className="close-button">
            <Image src={cancelIcon} />
          </Icon>
        }
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>Upload Financial records</Modal.Header>
        <Modal.Content>
          {step === 'uploader' ? (
            <UploadZone
              files={files}
              accept=".xlsx, .xls"
              onFileDelete={this.props.removeUploadFinancialFile}
              onDrop={this.props.addUploadFinancialFiles}
            />
          ) : (
            <FinanceFilesMatcher
              files={files}
              onDeleteFile={this.props.removeUploadFinancialFile}
              onAdjusterChange={this.handleAdjusterChange}
              onFinancialIdChange={this.props.setFinancialFileId}
              adjusters={initialAdjusters}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button className="cancel-button" onClick={onClose}>
            CANCEL
          </Button>
          {step === 'uploader' ? (
            <Button
              primary={true}
              loading={matchingAdjuster}
              onClick={this.handleNextPress}
              disabled={isEmpty}
            >
              NEXT
            </Button>
          ) : (
            <Button primary={true} loading={saving} onClick={this.onClickUpload} disabled={isEmpty}>
              UPLOAD
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }

  private reset() {
    this.setState({
      step: 'uploader',
    });
    this.props.resetFinancialFiles();
  }

  private goNextStep() {
    this.setState({
      step: 'matcher',
    });
  }
}

const mapStateToProps = (state: Types.RootState) => ({
  uploadFiles: state.finance.uploadFiles,
  matchingAdjuster: state.finance.matchingAdjuster,
  initialAdjusters: state.finance.initialAdjusters,
  saving: state.finance.saving,
  savingError: state.finance.savingError,
});

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) =>
  bindActionCreators(
    {
      addUploadFinancialFiles: financeActions.addUploadFinancialFiles,
      removeUploadFinancialFile: financeActions.removeUploadFinancialFile,
      resetFinancialFiles: financeActions.resetFinancialFiles,
      setFinancialFileAdjuster: financeActions.setFinancialFileAdjuster,
      setFinancialFileId: financeActions.setFinancialFileId,
      matchFinancesAdjuster: financeActions.matchFinancesAdjuster,
      saveFinances: financeActions.saveFinances,
    },
    dispatch
  );

export default connect<Props, State>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(FinanceFilesUploader);
