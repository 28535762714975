export interface IUserDTO {
  avatar: string;
  id: string;
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  emailConfirmed: boolean;
  createdAt: number;
  updatedAt: number;
  role: string;
  financeAccess: boolean;
}

export interface IUser {
  // constructor: {
  //   create(user: IUserDTO): IUser;
  // };
  avatar: string;
  id: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isReadOnlyAdmin: boolean;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  emailConfirmed: boolean;
  createdAt: number;
  updatedAt: number;
  role: string;
  financeAccess: boolean;

  fullName(): string;

  serialize(): IUserDTO;
}

export class User implements IUser {
  get fullName(): any {
    return `${this.firstName} ${this.lastName}`;
  }

  public static create(dto: IUserDTO): IUser {
    const model = new User(
      dto.avatar,
      dto.isAdmin,
      dto.firstName,
      dto.lastName,
      dto.phone,
      dto.email,
      dto.emailConfirmed,
      dto.createdAt,
      dto.updatedAt,
      dto.role,
      dto.financeAccess
    );
    model.id = dto.id;

    return model;
  }
  // 'constructor': typeof User;

  public id: string = '_';

  constructor(
    public avatar: string,
    public isAdmin: boolean,
    public firstName: string,
    public lastName: string,
    public phone: string,
    public email: string,
    public emailConfirmed: boolean,
    public createdAt: number,
    public updatedAt: number,
    public role: string,
    public financeAccess: boolean
  ) { }

  public serialize(): IUserDTO {
    return {
      avatar: this.avatar,
      id: this.id,
      isAdmin: this.isAdmin,
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      email: this.email,
      emailConfirmed: this.emailConfirmed,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      role: this.role,
      financeAccess: this.financeAccess,
    };
  }
}
