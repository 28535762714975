import c from 'classnames';
import React, { useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Button, Checkbox, Image, Input } from 'semantic-ui-react';
import addIcon from '../../../../assets/images/Blue/add.svg';
import services from '../../../../services';
import '../styles.css';
import { CustomContent } from './CustomContent';

const Content = ({
  options,
  onChange,
  dataSource,
  onApply,
  showHeader,
  $dataSource,
  closePopup,
  active,
  $active,
  setField,
  name,
  filterLogs,
  $valueSearch,
  filter,
  valueSearch,
  onChangeIsFocus,
  resetField,
  isCustom,
  user,
  updateDeployments,
  $showConfirm,
  $itemToRemove,
  onStandbyClick,
  parseDeploymentsFilters,
  showActiveCertifications,
  activeOnly,
}) => {
  const [customOpen, $customOpen] = useState(false);

  const getClass = customClass => c('btn', customClass, { active: active === customClass });

  Content.handleClickOutside = event => {
    const customPopup = document.querySelector('.wrapperContentMultiple.customContent');
    if (customPopup) return
    closePopup();
  };

  const setActiveHeader = tab => {
    if (dataSource.join(',').length) {
      let value = dataSource;
      if (name === 'deploymentsFilters') {
        const { standbyListDeployments, eventDeployments } = parseDeploymentsFilters(
          tab !== 'include',
          dataSource
        );
        value = {
          standbyListDeployments,
          eventDeployments,
        };
      }
      setField(name, { included: tab === 'include', value });
    }
    $active(tab);
  };

  const header = (
    <div>
      <div className="header flex">
        <div onClick={() => setActiveHeader('include')} className={getClass('include')}>
          include
        </div>
        <div onClick={() => setActiveHeader('exclude')} className={getClass('exclude')}>
          exclude
        </div>
      </div>
      {showActiveCertifications &&
        <div className="provider-group-container active-cert">
          <Checkbox
            name={'Active Certifications'}
            label={'Active Certifications'}
            checked={activeOnly}
            onChange={() => onChange({value: 'active-cert'})}
          />
        </div>
      }
    </div>
  );

  const show = options && !!options.length;

  return (
    <div className="wrapperContentMultiple">
      {showHeader && header}

      {show && (
        <div key={valueSearch.toLowerCase()} className="body">
          {options.map(
            i =>
              i.text.toLowerCase().includes(valueSearch.toLowerCase()) && (
                <div className={`item${i.isCustomEvent ? ' customEvent with-delete-button' : ''}`} key={i.value}>
                  <Checkbox
                    label={i.text}
                    onChange={(e, data) => {
                      if (isCustom) {
                        $itemToRemove(i);
                        if (!data.checked) {
                          $showConfirm(true);
                          closePopup();
                        } else {
                          onStandbyClick(i);
                        }
                      } else {
                        onChange(i);
                      }
                      if (valueSearch.length) {
                        onChangeIsFocus();
                        $valueSearch('');
                      }
                    }}
                    checked={isCustom ? i.deployed : dataSource.includes(i.value)}
                  />
                  {i.isCustomEvent && (
                    <Button
                      onClick={() => {
                        $itemToRemove({ userId: user.id, ...i });
                        $showConfirm(true);
                        closePopup();
                      }}
                    >
                      <Image src={addIcon} />
                    </Button>
                  )}
                </div>
              )
          )}
        </div>
      )}

      {isCustom ? (
        <CustomContent
          options={options}
          customOpen={customOpen}
          $customOpen={$customOpen}
          updateDeployments={updateDeployments}
          user={user}
        />
      ) : (
        <div className="actions flex">
          <Button
            primary={true}
            disabled={!dataSource.length && !activeOnly}
            className="btn btnApply"
            onClick={() => {
              onApply(active === 'exclude' && true);
              closePopup();
              $valueSearch('');
            }}
          >
            apply
          </Button>
          <div
            className="btn btnCancel"
            onClick={async () => {
              const dataModel = { ...Object.assign({}, filter) };
              if (name === 'deploymentsFilters') {
                dataModel.standbyListDeployments = '';
                dataModel.eventDeployments = '';
              } else {
                dataModel[name] = '';
              }
              await filterLogs(dataModel);
              $valueSearch('');
              resetField();
              $dataSource([]);
            }}
          >
            clear
          </div>
        </div>
      )}
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Content.handleClickOutside,
};

export default onClickOutside(Content, clickOutsideConfig);
