import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image, Label, Popup, Segment } from 'semantic-ui-react';
import noAvatar from '../../assets/images/no-avatar.svg';
import groupMsgIcon from '../../assets/images/Other/icn-group-message.svg';
import msgIconW from '../../assets/images/White/message.svg';
import { uploads } from '../../config';

interface Props {
  item: object;
  standby: any;
}

interface State {}

class SBMessageItem extends React.Component<Props, State> {
  public state = {};

  public render() {
    const { item, standby } = this.props;
    return (
      <Segment className="msg-item-container">
        <div>
          <div className="message-avatar-container">
            <Image
              circular={true}
              src={
                item.recipientsCount !== 1
                  ? groupMsgIcon
                  : item.recipients[0].avatar
                  ? uploads.s3BucketAddress + item.recipients[0].avatar
                  : noAvatar
              }
              avatar={true}
            />
            {item.hasUnreadMessages && (
              <Label color="red" floating={true} circular={true} empty={true} />
            )}
          </div>
          <div style={{ display: 'flex', marginLeft: '12px', flexDirection: 'column' }}>
            <div style={{ fontSize: '16px', fontWeight: 700 }}>{item.messages[0].title}</div>
            <div style={{ fontSize: '13px' }}>
              <span style={{ marginRight: '1rem' }}>
                {moment(item.messages[0].createdAt).format('MM/DD/YYYY, hh:mm A')}
              </span>
              <span>
                {item.recipientsList
                  .map((el, id) => {
                    if (id > 4) {
                      return;
                    } else {
                      const fullName =
                        `${el.preferredName ? el.preferredName : el.firstName} ${el.lastName}` +
                        `${id === 4 || id === item.recipientsList.length - 1 ? '' : ','}`;
                      return fullName;
                    }
                  })
                  .join(' ')}
                {item.recipientsCount > 5 && (
                  <span className="more-recipients-info">{` +${item.recipientsCount -
                    5} more`}</span>
                )}
              </span>
            </div>
          </div>
        </div>

        <div>
          <div
            className="sbl-item-title"
            style={{ textAlign: 'center', marginLeft: '3rem', marginRight: '3rem' }}
          >
            <div style={{ fontWeight: 700, fontSize: '16px' }}>{item.recipientsCount}</div>
            <div style={{ fontSize: '13px' }}>Sent</div>
          </div>
          <Popup
            trigger={
              <Link to={`/stand-by-lists/message/${standby}/${item.messages[0].id}`}>
                <Button primary={true} className="sbl-action-button">
                  <Image src={msgIconW} />
                </Button>
              </Link>
            }
            basic={true}
            size="mini"
            content="Message"
          />
        </div>
      </Segment>
    );
  }
}

export default SBMessageItem;
