import React from 'react';
import { Button, Icon, Image, Popup } from 'semantic-ui-react';
import downloadIcon from '../../assets/images/White/download.svg';
import '../../assets/styles/Finances.css';

interface Props {
  exporting: boolean;
  onFilterPress: () => void;
  onUploadPress: () => void;
  onExportPress: () => void;
  user: any;
}

interface State {}

class FinanceListHeader extends React.Component<Props, State> {
  public render() {
    const { onFilterPress, onUploadPress, onExportPress, exporting, user } = this.props;

    return (
      <div className="finance-list-header">
        <div className="right-actions">
          <Popup
            basic={true}
            size="mini"
            content="Export"
            trigger={
              <Button
                type="button"
                className="download-button"
                color="blue"
                icon={true}
                loading={exporting}
                onClick={onExportPress}
              >
                {!exporting ? (
                  <Icon>
                    <Image src={downloadIcon} />
                  </Icon>
                ) : (
                  'Loading'
                )}
              </Button>
            }
          />
          <Button type="button" className="filter-button" color="blue" onClick={onFilterPress}>
            FILTER
          </Button>
          {user.role !== 'Read Only Office Admin' && (
            <Button
              type="button"
              className="upload-button add-admin-button"
              color="brown"
              onClick={onUploadPress}
            >
              UPLOAD
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default FinanceListHeader;
