export const SET_MESSAGES_TAB = 'messages/SET_MESSAGES_TAB';
export const SET_MESSAGES_SEARCH = 'messages/SET_MESSAGES_SEARCH';
export const SET_MESSAGES_SORT = 'messages/SET_MESSAGES_SORT';
export const RESET_MESSAGES_FILTER = 'messages/RESET_MESSAGES_FILTER';

export const GET_MESSAGES_LIST = 'messages/GET_MESSAGES_LIST';
export const SUBMIT_MESSAGES_LIST = 'messages/SUBMIT_MESSAGES_LIST';
export const SET_ACTIVE_PAGE = 'messages/SET_ACTIVE_PAGE';

export const SET_ROOM_TYPE = 'messages/SET_ROOM_TYPE';

export const LOAD_ROOM_MESSAGES = 'messages/LOAD_ROOM_MESSAGES';
export const GET_ROOM_MESSAGES = 'messages/GET_ROOM_MESSAGES';
export const SET_ROOM_MESSAGES = 'messages/SET_ROOM_MESSAGES';
export const SET_ACTIVE_ROOM_PAGE = 'messages/SET_ACTIVE_ROOM_PAGE';
