import React from 'react';
import { Menu } from 'semantic-ui-react';
import '../../assets/styles/Header.css';
import { IUser } from '../../models';
import services from '../../services';

interface Props {
  user?: IUser;
  logout: () => void;
  hideWarningMessage?: boolean;
  pathname: string;
}

interface State {}

class LayoutHeader extends React.Component<Props, State> {
  public render() {
    return (
      <div>
        <div className="main-header">
          <Menu borderless={true} className="header-menu">
            <Menu.Item
              header={true}
              link={true}
              onClick={() => window.open('http://www.compassadjusters.com', '_blank')}
            >
              More Info
            </Menu.Item>

            <Menu.Menu position="right">
              <Menu.Item
                className={this.props.pathname === '/login' ? 'active-route-header' : ''}
                as="a"
                onClick={() => services.router.goto(services.router.url.login)}
              >
                Login
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
      </div>
    );
  }
}

export default LayoutHeader;
