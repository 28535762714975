import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, Image, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import dropdownIcon from '../../assets/images/ui-dropdown.svg';
import '../../assets/styles/Staff.css';
import AvatarInput from '../../components/avatar-input/AvatarInput';
import { adminActions } from '../../features/admin';
import { CreateAdminModel } from '../../features/admin/actions';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';
import PhoneInput from '../layout-input/PhoneInput';
import { LoadingSpiner } from './../loading-spinner/LoadingSpinner';

interface Props {
  getAdminList: typeof adminActions.getAdminList;
  resetAdminList: typeof adminActions.resetAdminList;
  fetchParams: object;
}

interface State {
  statusActive: boolean;
  finAcces: boolean;
  avatar: string;
  showModal: boolean;
  fieldsUrl: string;
  formData: FormData | {};
}

const rolesOptions = [
  {
    key: 'Super Admin (Full Access)',
    text: 'Super Admin (Full Access)',
    value: 'Super Admin',
  },
  {
    key: 'Office Admin (Edit)',
    text: 'Office Admin (Edit)',
    value: 'Office Admin',
  },
  {
    key: 'Read Only Office Admin (Read Only)',
    text: 'Read Only Office Admin (Read Only)',
    value: 'Read Only Office Admin',
  },
];

class CreateAdminModal extends React.Component<Props, State> {
  public form: any;

  public state = {
    statusActive: true,
    finAcces: true,
    avatar: '',
    showModal: false,
    fieldsUrl: '',
    formData: {},
  };

  public getAvatar = (key: string, editedAdmin?: boolean) => {
    this.setState({ avatar: key });
  };

  public getAvatatFields = (url: string, data: FormData) => {
    this.setState({ fieldsUrl: url, formData: data });
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    const { fieldsUrl, formData } = this.state;
    const trigger = (
      <Button
        className="add-admin-button"
        onClick={() => this.setState({ showModal: true, avatar: '' })}
      >
        + Account
      </Button>
    );

    return (
      <Modal
        className="create-admin-modal"
        trigger={trigger}
        open={this.state.showModal}
        closeIcon={
          <Image
            src={closeIcon}
            className="icon"
            onClick={() => this.setState({ showModal: false })}
          />
        }
        onClose={() => this.setState({ avatar: '' })}
      >
        <Modal.Header>Create Admin Account</Modal.Header>
        <Form
          onValidSubmit={async (model: CreateAdminModel) => {
            const status = { active_status: this.state.statusActive ? 1 : 0 };
            const access = { finance_access: this.state.finAcces ? 1 : 0 };
            const avatar = { avatar: this.state.avatar };
            for (const key in model) {
              if (model[key] !== undefined) {
                model[key] = model[key].trim();
              }
            }
            const obj = Object.assign({}, model, status, access, avatar);

            const createAdm = async () => {
              const tokenRequest = await services.api.admin.createAdmin(obj);
              if (tokenRequest.isError) {
                toast.dismiss();
                toast.error('Error! ' + tokenRequest.data.errors[0].msg);
              } else {
                this.setState({ showModal: false }, () => {
                  this.props.resetAdminList();
                  this.props.getAdminList(this.props.fetchParams);
                  toast.dismiss();
                  toast.success(`Admin ${obj.email} was successfully invited!`);
                });
              }
            };

            if (this.state.avatar !== '' && this.state.avatar) {
              const resp = await services.api.file.postFile(fieldsUrl, formData);
              if (resp && resp.status === 204) {
                createAdm();
              }
            } else {
              createAdm();
            }
          }}
          ref={(ref: any) => (this.form = ref)}
        >
          <Modal.Content>
            <LoadingSpiner area="create-admin-modal-area" />
            <Grid>
              <Grid.Column width={3}>
                <AvatarInput
                  getAvatar={this.getAvatar}
                  getAvatarFields={this.getAvatatFields}
                  avatar={''}
                  noEdit={false}
                />
              </Grid.Column>
              <Grid.Column width={13}>
                <div className="input-field-container">
                  <div className="input-field-label">First Name</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="first_name"
                      maxLength={60}
                      placeholder="Please Enter..."
                      instantValidation={false}
                      required={true}
                      validations={{
                        isOnlySpaces: true,
                      }}
                      validationErrors={{
                        isDefaultRequiredValue: '* First Name is required field.',
                        isOnlySpaces: '* First Name is required field.',
                      }}
                      className="form-input"
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Last Name</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="last_name"
                      maxLength={60}
                      placeholder="Please Enter..."
                      instantValidation={false}
                      required={true}
                      validations={{
                        isOnlySpaces: true,
                      }}
                      validationErrors={{
                        isDefaultRequiredValue: '* Last Name is required field.',
                        isOnlySpaces: '* Last Name is required field.',
                      }}
                      className="form-input"
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Email</div>
                  <div className="field-input-container">
                    <Form.Input
                      name="email"
                      maxLength={70}
                      placeholder="Please Enter..."
                      instantValidation={false}
                      required={true}
                      validations={{
                        isOnlySpaces: true,
                        isEmail: true,
                      }}
                      validationErrors={{
                        isDefaultRequiredValue: '* Email is required field.',
                        isOnlySpaces: '* Email is required field.',
                        isEmail: '* Email format is invalid.',
                      }}
                      className="form-input"
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>

                <div className="input-field-container phone-field-custom-input-admin">
                  <div className="input-field-label disabled">Phone</div>
                  <div className="field-input-container">
                    <PhoneInput
                      placeholder="Please Enter..."
                      name="phone"
                      instantValidation={false}
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>

                <div className="input-field-container">
                  <div className="input-field-label">Role</div>
                  <div className="field-input-container">
                    <Form.Dropdown
                      name="role"
                      icon={<Image src={dropdownIcon} className="icon" />}
                      placeholder="Please Select"
                      instantValidation={false}
                      required={true}
                      options={rolesOptions}
                      validationErrors={{
                        isDefaultRequiredValue: '* Role is required field.',
                      }}
                      className="form-input-dropdown long"
                      errorLabel={errorLabel}
                    />
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Status</div>
                  <div style={{ display: 'flex', width: '400px' }}>
                    <div className="toggle-block">
                      <Button
                        type="button"
                        className={this.state.statusActive ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ statusActive: true })}
                      >
                        ACTIVE
                      </Button>
                      <Button
                        type="button"
                        className={!this.state.statusActive ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ statusActive: false })}
                      >
                        INACTIVE
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="input-field-container">
                  <div className="input-field-label">Finances Access</div>
                  <div style={{ display: 'flex', width: '400px' }}>
                    <div className="toggle-block">
                      <Button
                        type="button"
                        className={this.state.finAcces ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ finAcces: true })}
                      >
                        ENABLED
                      </Button>
                      <Button
                        type="button"
                        className={!this.state.finAcces ? 'yes-btn' : 'no-btn'}
                        onClick={() => this.setState({ finAcces: false })}
                      >
                        DISABLED
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.setState({ showModal: false })}
            >
              CANCEL
            </Button>
            <Button style={{ width: '115px' }} primary={true}>
              CREATE
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAdminList: adminActions.getAdminList,
      resetAdminList: adminActions.resetAdminList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAdminModal);
