import React from 'react';
import { Dimmer, Loader, Placeholder, Segment } from 'semantic-ui-react';

const PagePlaceholder = ({ size = 10 }) => (
  <Segment className="table-spinner" style={{ marginTop: '1rem' }}>
    <Dimmer active={true} inverted={true}>
      <Loader />
    </Dimmer>
    <Placeholder fluid={true}>
      {Array(size)
        .fill('')
        .map((i, index) => (
          <Placeholder.Header key={index} image={true}>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
        ))}
    </Placeholder>
  </Segment>
);

export default PagePlaceholder;
