import { Form } from 'formsy-semantic-ui-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Popup } from 'semantic-ui-react';
import addIcon from '../../assets/images/Blue/add.svg';
import { IUser } from '../../models';
import services from '../../services';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  type: string;
  getItems: () => void;
  user: IUser;
}

interface State {
  name: string;
  type: string | undefined;
}

class CreateProfDataItem extends React.Component<Props, State> {
  public state = {
    name: '',
    type: '',
  };

  public checkType = (type: string) => {
    switch (type) {
      case 'Certification Types':
        return 'certification';
      case 'Experience':
        return 'experience';
      case 'Expertise Loss Type':
        return 'lossType';
      case 'Equipment':
        return 'equipment';
      case 'Languages':
        return 'language';
      case 'License States':
        return 'state';
      case 'Paperwork':
        return 'paperwork';
      case 'Positions':
        return 'position';
      case 'School Types':
        return 'school';
      case 'Software Skills':
        return 'softwareSkill';
      default:
        return;
    }
  };

  public componentWillMount() {
    this.setState({ type: this.checkType(this.props.type) });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.type !== this.props.type) {
      this.setState({ type: this.checkType(this.props.type) });
    }
  }

  public handleSubmit = async (model: { name: string }, resetForm) => {
    const { onCreate } = this.props;
    if (onCreate) {
      onCreate(model, resetForm);
    } else {
      const obj = Object.assign({}, { name: model.name.trim() }, { type: this.state.type });
      const response = await services.api.settings.createProfData(obj);
      if (response.isSuccess) {
        resetForm();
        toast.dismiss();
        toast.success(`Item ${model.name} was successfully created!`);
        this.props.getItems();
      } else {
        toast.dismiss();
        toast.error(response.data.errors[0].msg);
      }
    }
  };

  public render() {
    const errorLabel = <ErrorLabel />;
    return (
      <div className="prof-item-container">
        <div className="create-prof-data-input">
          <Form
            id="new-prof-data-form"
            ref={ref => (this.form = ref)}
            onValidSubmit={async (model: { name: string }, resetForm) => {
              this.handleSubmit(model, resetForm);
            }}
          >
            <Form.Input
              instantValidation={false}
              name="name"
              maxLength={100}
              formNoValidate={true}
              readOnly={this.props.user.role === 'Read Only Office Admin'}
              placeholder="New item..."
              required={true}
              validations={{
                isOnlySpaces: true,
              }}
              validationErrors={{
                isDefaultRequiredValue: '* Name is required field.',
                isOnlySpaces: '* Name is required field.',
              }}
              errorLabel={errorLabel}
              onChange={e => {
                if (e.currentTarget.value === '') {
                  this.form.formsyForm.inputs[0].resetValue();
                }
              }}
            />
          </Form>
        </div>
        <Popup
          trigger={
            <div style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={this.props.user.role === 'Read Only Office Admin'}
                form="new-prof-data-form"
                className="iconic-button-custom"
              >
                <Image src={addIcon} />
              </Button>
            </div>
          }
          size="mini"
          basic={true}
          content="Create"
        />
      </div>
    );
  }
}

export default CreateProfDataItem;
