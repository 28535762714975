import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import GroupMessage from './GroupMessage';
import RoomMessages from './RoomMessages';

interface Props {
  item: object;
  pathname: string;
}

interface State {}

class MessageDetails extends React.Component<Props, State> {
  public state = {};

  public render() {
    const { item } = this.props;
    return (
      <div>
        <GroupMessage match={this.props.match} />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.user,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<Props, State>(
  mapStateToProps,
  mapDispatchToProps
)(MessageDetails);
