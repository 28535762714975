import { trackPromise } from 'react-promise-tracker';
import { EditFolderModel } from '../../features/training-center/trainingCenterReducer';
import { request } from './base';

export const getFolderChirenCount = (folderId:number) => {
  return trackPromise(
    request({
      method: 'get',
      url: `/folder/children/${folderId}/count`,
    }),
    'folder'
  );
};

export const getFolderFlatList = () => {
  return trackPromise(
    request({
      method: 'get',
      url: `/folder/flat`,
    }),
    'folder'
  );
};

export const getFolderList = () => {
  return trackPromise(
    request({
      method: 'get',
      url: `/folder`,
    }),
    'folder'
  );
};

export const createFolder = (model: any) => {
  return request({
    method: 'post',
    url: '/folder',
    data: {
      ...model,
    },
  });
};

export const updateFolderPosition = (folderID: number, position: number) => {
  return request({
    method: 'patch',
    url: `/folder/${folderID}/position/${position}`,
  });
};

export const editFolder = (model: EditFolderModel) => {
  return request({
    method: 'patch',
    url: `/folder/${model.id}`,
    data: {
      ...model,
    },
  });
};

export const deleteFolder = (model: { id: number }) => {
  return request({
    method: 'delete',
    url: `/folder/${model.id}`,
  });
};

export const getFolderChildren = (id: any) => {
  return request({
    method: 'get',
    url: `/folder/children/${id}`,
  });
};

export const getById = (id: any) => {
  return request({
    method: 'get',
    url: `/folder/${id}`,
  });
};

export const getAssignedAdjusterFoFolder = (model: { id: number }) => {
  return request({
    method: 'get',
    url: `/folder/${model.id}/assigned-adjuster`,
  });
};

export const getAssignedCustomEvents = (model: { id: number }) => {
  return request({
    method: 'get',
    url: `/folder/${model.id}/assigned-event`,
  });
};

export const assignAccessToAdjusterAndEvents = (model: {
  folderId: number;
  adjusters: Array<number>;
  events: Array<number>;
}) => {
  return request({
    method: 'post',
    url: `/folder/${model.folderId}/access`,
    data: {
      ...model,
    },
  });
};

export const getAssignedFolderForAdjuster = (model: { id: number }) => {
  return request({
    method: 'get',
    url: `/folder/adjuster/${model.id}`,
  });
};

export const saveSelectedFolder = (model: { data: number[]; id: number }) => {
  return request({
    method: 'post',
    url: `/folder/adjuster/${model.id}`,
    data: {
      folderIds: model.data,
    },
  });
};

export const searchFolder = (query: string) => {
  return request({
    method: 'GET',
    url: '/folder/search',
    params: {
      name: query,
    },
  });
};

export const getCustomEventList = (search?: string) => {
  return request({
    method: 'GET',
    url: '/event',
    params: {
      search
    }
  });
};

export const createCustomEvent = (name: string) => {
  return request({
    method: 'POST',
    url: '/event',
    data: {
      name
    },
  });
};

export const updateCustomEvent = (name: string, eventID: number) => {
  return request({
    method: 'patch',
    url: `/event/${eventID}`,
    data: {
      name
    },
  });
};

export const deleteCustomEvent = (eventID: number) => {
  return request({
    method: 'delete',
    url: `/event/${eventID}`,
  });
};
