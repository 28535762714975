import { Form } from 'formsy-semantic-ui-react';
import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Image, Label, Modal } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import '../../assets/styles/Login.css';
import '../../assets/styles/Login.css';
import { accountActions } from '../../features/account';
import ErrorLabel from '../error-label/ErrorLabel';

interface Props {
  recoverAttempt: typeof accountActions.recoverAttempt;
  error: string;
}

interface State {
  errorMessage: string;
  showModal: boolean;
}

class RecoveryModal extends React.Component<Props, State> {
  public state = {
    errorMessage: '',
    showModal: false,
  };

  public form: any;
  public onValidSubmit = (model: any) => {
    this.props.recoverAttempt(model.email);
    this.setState({ showModal: false });
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.error !== this.props.error) {
      this.setState({
        errorMessage: this.props.error,
      });
    }
  }

  public render() {
    const errorLabel = <ErrorLabel />;
    return (
      <Modal
        size={'tiny'}
        trigger={
          <p className="forgot-password-trigger" onClick={() => this.setState({ showModal: true })}>
            I forgot my password
          </p>
        }
        open={this.state.showModal}
        closeOnDimmerClick={false}
        className="recovery-modal"
        closeIcon={<Image src={closeIcon} />}
        onClose={() => this.setState({ showModal: false })}
      >
        <Modal.Header>Reset Password</Modal.Header>
        <Form onValidSubmit={this.onValidSubmit} ref={(ref: any) => (this.form = ref)}>
          <Modal.Content>
            <p>Please enter your email address below to initiate password reset.</p>
            <div className="error-label">{this.state.errorMessage}</div>
            <div className="input-field-container">
              <div className="input-field-label">Email</div>
              <div>
                <Form.Input
                  name="email"
                  placeholder="Please Enter..."
                  instantValidation={false}
                  required={true}
                  validations="isEmail"
                  validationErrors={{
                    isDefaultRequiredValue: 'Email is required field.',
                    isEmail: 'Email format is invalid.',
                  }}
                  errorLabel={errorLabel}
                  className="recovery-modal-form-input"
                />
              </div>
            </div>
          </Modal.Content>
          <div className="modal-button-footer">
            <Button
              type="button"
              style={{ width: '115px' }}
              className="secondary-button"
              secondary={true}
              onClick={() => this.setState({ showModal: false })}
            >
              CANCEL
            </Button>
            <Button content="Reset Password" primary={true} />
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  error: state.account.recover.error,
  isLoading: state.loading.loading.isVisible,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      recoverAttempt: accountActions.recoverAttempt,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoveryModal);
