import React from 'react';
import './FolderTags.css';
import { Label } from 'semantic-ui-react';

export default function DocumentLabel(props: { type: string }) {
  let mineTypeMapper = null;
  let labelColor: any = '';
  switch (props.type) {
    case 'application/pdf':
      mineTypeMapper = 'PDF';
      labelColor = 'red';
      break;
    case 'application/vnd.ms-excel':
      mineTypeMapper = 'XLS';
      labelColor = 'green';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      mineTypeMapper = 'XLSX';
      labelColor = 'green';
      break;
    case 'application/msword':
      mineTypeMapper = 'DOC';
      labelColor = 'blue';
      break;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      mineTypeMapper = 'DOCX';
      labelColor = 'blue';
      break;
    case 'application/vnd.ms-powerpoint':
      mineTypeMapper = 'PPT';
      labelColor = 'violet';
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      mineTypeMapper = 'PPTX';
      labelColor = 'violet';
      break;
    case 'image/png':
      mineTypeMapper = 'PNG';
      labelColor = 'teal';
      break;
    case 'image/jpeg':
      mineTypeMapper = 'JPG';
      labelColor = 'teal';
      break;
    case 'image/gif':
      mineTypeMapper = 'GIF';
      labelColor = 'teal';
      break;
  }

  if (!mineTypeMapper) {
    return null;
  }

  return (
    <Label className="badge" color={labelColor}>
      {mineTypeMapper}
    </Label>
  );
}
