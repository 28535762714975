import React from 'react';
import { Button, Confirm, Image, Popup } from 'semantic-ui-react';
import closeIcon from '../../assets/images/Blue/cancel.svg';
import downloadIcon from '../../assets/images/Blue/download.svg';
import * as datesHelper from '../../utils/datesHelper';
import { FilePreview } from '../file-preview/FilePreview';

interface Props {
  item: object;
  editLicense?: (show: boolean, id?: number) => void;
  deleteLicense?: (id: number) => void;
  id: number;
  download: (file: any) => void;
  mode: string;
}

interface State {
  showConfirm: boolean;
}

class LicenseItem extends React.Component<Props, State> {
  public state = {
    showConfirm: false,
  };

  public render() {
    const { item, mode } = this.props;
    return (
      <>
        <div className="license-item-container">
          <div className="licenses-file-preview">
            <FilePreview
              type={item.file ? item.file.type : item.fileType}
              src={item.file && item.file.file ? item.file.file : ''}
            />
          </div>
          <div className="license-item-info">
            <div
              style={{ fontSize: '16px', fontWeight: 'bold' }}
              dangerouslySetInnerHTML={{ __html: mode === 'cert' ? item.type : item.state }}
            />
            <div className="license-date-container">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '13px', marginRight: '10px' }}>Issued Date</div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {datesHelper.parseDateFromApi(item.issuedDate)}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '13px' }}>Expiration Date</div>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {datesHelper.parseDateFromApi(item.expirationDate)}
                </div>
              </div>
            </div>
            <div
              style={{ fontSize: '13px' }}
              dangerouslySetInnerHTML={{
                __html: mode === 'cert' ? item.certification : item.license,
              }}
            />
          </div>
          <div className="buttons-container">
            {item.file !== '' && item.file && (
              <Popup
                trigger={
                  <Button
                    className="iconic-button-custom"
                    onClick={() => this.props.download(this.props.item.file)}
                  >
                    <Image src={downloadIcon} />
                  </Button>
                }
                size="mini"
                basic={true}
                content="Download"
              />
            )}
          </div>
        </div>
        <Confirm
          size="tiny"
          closeIcon={
            <Image
              src={closeIcon}
              className="icon"
              onClick={() => this.setState({ showConfirm: false })}
            />
          }
          open={this.state.showConfirm}
          onCancel={() => this.setState({ showConfirm: false })}
          header="Confirm"
          content={`Are you sure you want to delete this ${
            this.props.mode === 'license' ? 'license' : 'certification'
          }?`}
          cancelButton="NO"
          confirmButton="YES"
          onConfirm={() => {
            this.props.deleteLicense(item.id);
            this.setState({ showConfirm: false });
          }}
          className="confirm-modal-custom"
          closeOnDimmerClick={false}
        />
      </>
    );
  }
}

export default LicenseItem;
