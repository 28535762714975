import { action } from 'typesafe-actions';
import * as constants from './constants';

export const show = () => action(constants.SHOW);

export const setProgress = (progress: number) => action(constants.SET_PROGRESS, { progress });

export const addProgress = (progress: number) => action(constants.ADD_PROGRESS, { progress });

export const hide = () => action(constants.HIDE);
