import get from 'lodash/get';
import React from 'react';
import { Button, Dropdown, Icon, Image, Label, Menu, Popup, Search } from 'semantic-ui-react';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg';
import listBlue from '../../assets/images/Tabs/list-blue.svg';
import listWhite from '../../assets/images/Tabs/list-white.svg';
import mapBlue from '../../assets/images/Tabs/map-blue.svg';
import mapWhite from '../../assets/images/Tabs/map-white.svg';
import downloadIcon from '../../assets/images/White/download.svg';
import { STANDBYLIST_ADJUSTERS_SORT_OPTIONS } from '../../utils/defaultSortOptions';
import AdjustersFilter from './../table-filters/AdjustersFilter';
import CustomPopupButton from '../adjuster-list/CustomPopupButton';

const initialFilter = {
  availableForClaims: '',
  catCity: '',
  catState: '',
  certification: '',
  city: '',
  commercialTeam: '',
  deployed: '',
  equipment: '',
  experience: '',
  expertise: '',
  language: '',
  license: '',
  largeLossTeam: '',
  milesAway: '',
  name: '',
  position: '',
  softwareSkill: '',
  state: '',
  xactnetAddress: '',
  zip: '',
  authorityLevelFrom: '',
  authorityLevelTo: '',
  registrationType: '',
  sort: 'updatedAt,desc',
  allPaperworkCompleted: '',
  isAdmin: '',
};

interface Props {
  getTab: (name: string, filter?: object) => void;
  export: (id: any, filter: any) => void;
  exportDetails: (id: any, filter: any) => void;
  getAdjusters: (filter?: object) => void;
  searchMessages: (search: string) => void;
  resetActivePage: () => void;
  resetMessageActivePage: () => void;
  updateMessages: () => void;
  setAdjustersPerPage: (perPage: number, filter: object) => void;
  adjustersPerPage: number;
  disableExport: boolean;
  hasUnread: boolean;
  id: any;
  listView: boolean;
  toogleView: (show: boolean) => void;
}

interface State {
  activeTab: string;
  showFilters: boolean;
  filter: boolean;
  filterState: object;
  search: string;
}

class SBDetailsTableHeader extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    const activeTab = get(history, 'state.state.activeTab', 'Applied');
    this.state = {
      activeTab: activeTab,
      search: '',
      showFilters: false,
      filter: false,
      filterState: {
        ...initialFilter,
      },
    };
  }

  public componentDidMount = () => {
    this.setState(
      {
        activeTab:
          location.hash !== ''
            ? JSON.parse(decodeURI(location.hash.slice(1))).tab
            : this.state.activeTab,
        filterState:
          location.hash !== ''
            ? {
                ...this.state.filterState,
                ...JSON.parse(decodeURI(location.hash.slice(1))).filter,
              }
            : { ...this.state.filterState },
      },
      () => {
        this.props.getTab(
          location.hash !== ''
            ? JSON.parse(decodeURI(location.hash.slice(1))).tab
            : this.state.activeTab,
          location.hash !== ''
            ? JSON.parse(decodeURI(location.hash.slice(1))).filter
            : this.state.filterState
        );
        this.fetchAdjusters(this.state.filterState);
      }
    );
  };

  public handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    this.props.resetActivePage();
    const filterParsed =
      location.hash !== ''
        ? JSON.parse(decodeURI(location.hash.slice(1))).filter
        : this.state.filterState;
    for (const key in filterParsed) {
      if (filterParsed[key] === '' || !filterParsed[key]) {
        delete filterParsed[key];
      } else if (key === 'standbyListDeployments' || key === 'eventDeployments') {
        filterParsed[key] = filterParsed[key].map(f => (f === 'exclude' ? 'exclude' : f.id));
      }
    }
    this.props.getTab(name, {
      ...filterParsed,
      name: filterParsed.name && filterParsed.name !== '' ? filterParsed.name : this.state.search,
    });
    this.setState(
      {
        activeTab: name,
        showFilters: name === 'Messages' ? false : this.state.showFilters,
      },
      () => {
        const hasHash = location.hash !== '' ? JSON.parse(decodeURI(location.hash.slice(1))) : '';
        if (hasHash !== '') {
          hasHash.tab = name;
          location.hash = JSON.stringify(hasHash);
        }
      }
    );
  };

  public handleSearch = (e: any, data: any) => {
    this.props.resetActivePage();
    this.setState({ search: data.value }, () => this.fetchAdjusters(this.state.filterState));
  };

  public handleItemsPerPageChange = (e: any, { value }: any) => {
    this.props.setAdjustersPerPage(value, this.parseFilters(this.state.filterState));
  };

  public handleSearchMessages = (e: any, data: any) => {
    this.props.resetMessageActivePage();
    this.props.searchMessages(data.value);
    this.props.updateMessages();
  };

  public fetchAdjusters = async (params?: any) => {
    this.props.resetActivePage();
    const filterProps = this.parseFilters(params);
    this.props.getAdjusters(filterProps);
  };

  public handleFilterReset = () => {
    this.setState(
      {
        filterState: {
          ...initialFilter,
        },
      },
      () => {
        location.hash = '';
        this.fetchAdjusters(this.state.filterState);
      }
    );
  };

  public handleFilterChange = (data: any) => {
    this.setState(
      {
        filterState: {
          ...this.state.filterState,
          ...data,
        },
      },
      () => {
        const parsedFilter = this.state.filterState;
        for (const key in parsedFilter) {
          if ((parsedFilter[key] && !parsedFilter[key].length) || parsedFilter[key] === '') {
            delete parsedFilter[key];
          }
        }
        const hashObj = Object.assign({}, { filter: parsedFilter }, { tab: this.state.activeTab });
        location.hash = JSON.stringify(hashObj);
        this.fetchAdjusters(this.state.filterState);
      }
    );
  };

  public exportList = (isQa: boolean) => {
    const filters = this.parseFilters(this.state.filterState);

    if (isQa) {
      return this.props.exportDetails(this.props.id, filters);
    }

    this.props.export(this.props.id, filters);
  };

  public render() {
    const { activeTab } = this.state;
    return (
      <>
        <div className="adjusters-list-header sbl-details-table-header">
          <Menu text={true} className="tabs-menu">
            <Menu.Item
              name="Applied"
              active={activeTab === 'Applied'}
              onClick={this.handleItemClick}
            >
              Applied
            </Menu.Item>
            <Menu.Item
              name="Invited"
              active={activeTab === 'Invited'}
              onClick={this.handleItemClick}
            >
              Invited
            </Menu.Item>
            <Menu.Item
              name="Contacted"
              active={activeTab === 'Contacted'}
              onClick={this.handleItemClick}
            >
              Contacted
            </Menu.Item>
            <Menu.Item
              name="Messages"
              active={activeTab === 'Messages'}
              onClick={this.handleItemClick}
            >
              Messages
              {this.props.hasUnread && (
                <Label color="red" floating={true} circular={true} empty={true} />
              )}
            </Menu.Item>
            <Menu.Item
              name="QuickApplied"
              active={activeTab === 'QuickApplied'}
              onClick={this.handleItemClick}
            >
              Quick Applied
            </Menu.Item>
          </Menu>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {activeTab !== 'Messages' && (
              <div style={{ display: 'none' }} className="pagination-items-counter">
                Items on page:{' '}
                <Dropdown
                  inline={true}
                  options={[
                    { text: '20', value: 20 },
                    { text: '50', value: 50 },
                    { text: '100', value: 100 },
                    { text: '200', value: 200 },
                  ]}
                  defaultValue={this.props.adjustersPerPage}
                  onChange={this.handleItemsPerPageChange}
                />
              </div>
            )}
            {activeTab !== 'Messages' && (
              <Search
                input={{ iconPosition: 'left' }}
                icon={
                  <Icon>
                    <Image src={searchIcon} />
                  </Icon>
                }
                placeholder="Search..."
                onSearchChange={this.handleSearch}
                showNoResults={false}
              />
            )}
            {activeTab === 'Messages' && (
              <Search
                input={{ iconPosition: 'left' }}
                icon={
                  <Icon>
                    <Image src={searchIcon} />
                  </Icon>
                }
                placeholder="Search..."
                onSearchChange={this.handleSearchMessages}
                showNoResults={false}
              />
            )}
             <div className="map-list-toogle">
              <CustomPopupButton
                classNm={this.props.listView ? 'yes-btn' : 'no-btn'}
                onPress={() => {
                  this.props.toogleView(true);
                }}
                popup="List View"
                image={this.props.listView ? listWhite : listBlue}
              />
              <CustomPopupButton
                classNm={!this.props.listView ? 'yes-btn' : 'no-btn'}
                onPress={() => {
                  this.props.toogleView(false);
                }}
                popup="Map View"
                image={!this.props.listView ? mapWhite : mapBlue}
              />
            </div>
            {activeTab !== 'Messages' && (
              <Popup
                trigger={
                  <Button
                    primary={true}
                    className="adjuster-export-button"
                    onClick={() => this.exportList(false)}
                    disabled={this.props.disableExport}
                  >
                    <Image src={downloadIcon} />
                    List
                  </Button>
                }
                basic={true}
                size="mini"
                content={'Export list'}
              />
            )}
            {['Applied', 'Contacted'].includes(activeTab) && (
              <Popup
                trigger={
                  <Button
                    primary={true}
                    className="adjuster-export-button"
                    onClick={() => this.exportList(true)}
                    disabled={this.props.disableExport}
                  >
                    <Image src={downloadIcon} />
                    Q&A
                  </Button>
                }
                basic={true}
                size="mini"
                content={'Export answers'}
              />
            )}
            {activeTab !== 'Messages' && (
              <Button
                primary={true}
                onClick={() => this.setState({ showFilters: !this.state.showFilters })}
              >
                Filter
              </Button>
            )}
          </div>
        </div>
        {this.state.showFilters && (
          <AdjustersFilter
            filter={this.state.filterState}
            filterLogs={this.handleFilterChange}
            reset={this.handleFilterReset}
            activePage={activeTab}
            sortOptions={STANDBYLIST_ADJUSTERS_SORT_OPTIONS}
          />
        )}
      </>
    );
  }

  private parseFilters(params: any = {}) {
    const dataModel = Object.assign({}, params);
    for (const key in dataModel) {
      if (dataModel[key] === '' || dataModel[key] === undefined) {
        delete dataModel[key];
      } else if (key === 'standbyListDeployments' || key === 'eventDeployments') {
        dataModel[key] = dataModel[key].map(f => (f === 'exclude' ? 'exclude' : f.id));
      }
    }

    params = dataModel;

    const keys = [
      'state',
      'licenseStates',
      'certificationTypes',
      'experience',
      'position',
      'equipment',
      'expertise',
      'softwareSkill',
      'catStateProvince',
      'language',
      'registrationType',
    ];

    let filterProps = {
      ...params,
      name: params.name && params.name !== '' ? params.name : this.state.search,
    };

    for (const key of keys) {
      if (filterProps[key] && filterProps[key].length) {
        filterProps = {
          ...filterProps,
          [key]: filterProps[key],
        };
      }
    }

    return filterProps;
  }
}

export default SBDetailsTableHeader;
