import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Pagination } from 'semantic-ui-react';
import { messagesActions } from '../../features/messages';
import { standbyActions } from '../../features/standby';
import services from '../../services';
import * as helper from '../../utils/helper';
import { MessageSpiner } from '../loading-spinner/MessageSpinner';
import { NoResults } from '../no-results-placeholder/NoResults';
import TwilioModal from '../standby-layout/TwilioModal';
import MessageUserHeader from './MUserHeader';
import RMessagesList from './RMessagesList';
import RSendMessage from './RSendMessage';

interface Props {
  roomType: string;
  setActiveStandby: typeof standbyActions.setActiveStandby;
  setActiveRoomPage: typeof messagesActions.setActiveRoomPage;
  setTempMsgObj: typeof standbyActions.setTempMsgObj;
  getRoomMessages: typeof messagesActions.getRoomMessages;
  loadRoomMessages: typeof messagesActions.loadRoomMessages;
  showTwilioModal: typeof standbyActions.showTwilioModal;
  showInviteModal: typeof standbyActions.showInviteModal;
  roomAdjuster: object;
  roomMessages: any;
  roomMessagesCount: any;
  showTwilio: boolean;
  msgObj: any;
  user: any;
}

interface State {
  adjuster: object;
  messages: object[];
  standby: any;
}

class RoomMessages extends React.Component<Props, State> {
  public state = {
    adjuster: {},
    messages: [],
    standby: undefined,
  };

  public componentDidMount = () => {
    this.getMessages();
  };

  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.roomAdjuster !== this.props.roomAdjuster) {
      this.setState({ adjuster: this.props.roomAdjuster });
    }
  };

  public getMessages = async () => {
    if (this.props.match.params.sbid) {
      this.props.getRoomMessages({
        id: this.props.match.params.id,
        sbid: this.props.match.params.sbid,
      });

      // this.props.setActiveStandby(this.props.match.params.sbid);
      const responseSb = await services.api.standby.getStandbyListDetails(
        this.props.match.params.sbid
      );
      if (responseSb.isSuccess) {
        this.setState({ standby: responseSb.data });
      } else {
        console.log(responseSb);
        this.setState({ standby: undefined });
      }
    } else {
      this.props.getRoomMessages({ id: this.props.match.params.id });
    }
  };

  public loadMessages = () => {
    if (this.props.match.params.sbid) {
      this.props.loadRoomMessages({
        id: this.props.match.params.id,
        sbid: this.props.match.params.sbid,
      });
    } else {
      this.props.loadRoomMessages({ id: this.props.match.params.id });
    }
  };

  public render() {
    return (
      <div className="personal-room-page">
        <MessageUserHeader adjuster={this.state.adjuster} standBy={this.state.standby} />
        <MessageSpiner area="message-sending-area" />
        <RMessagesList
          adjuster={this.state.adjuster}
          getMessages={this.getMessages}
          loadMessages={this.loadMessages}
        />
        {this.props.user.role !== 'Read Only Office Admin' && (
          <RSendMessage
            adjuster={this.state.adjuster}
            match={this.props.match}
            userId={this.state.adjuster.id}
            user={this.props.user}
            updateData={this.getMessages}
            standby={this.state.standby}
            setMsgObj={this.props.setTempMsgObj}
            showTwilioModal={this.props.showTwilioModal}
            setRoomPage={this.props.setActiveRoomPage}
          />
        )}
        <TwilioModal
          stbId={this.props.match.params.sbid ? this.props.match.params.sbid : undefined}
          show={this.props.showTwilio}
          showModal={this.props.showTwilioModal}
          showInviteModal={this.props.showInviteModal}
          msgObj={this.props.msgObj}
          updateData={this.getMessages}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  roomType: state.messages.messages.roomType,
  showTwilio: state.standby.standby.showTwilioModal,
  msgObj: state.standby.inviteModal.msgObj,
  user: state.account.account.user,
  roomMessages: state.messages.messages.roomMessages,
  roomMessagesCount: state.messages.messages.roomMessagesCount,
  roomAdjuster: state.messages.messages.roomAdjuster,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveStandby: standbyActions.setActiveStandby,
      setActiveRoomPage: messagesActions.setActiveRoomPage,
      getRoomMessages: messagesActions.getRoomMessages,
      loadRoomMessages: messagesActions.loadRoomMessages,
      setTempMsgObj: standbyActions.setTempMsgObj,
      showTwilioModal: standbyActions.showTwilioModal,
      showInviteModal: standbyActions.showInviteModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomMessages);
