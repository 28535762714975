import axios, { CancelToken } from 'axios';
import { buffers, END, eventChannel } from 'redux-saga';
import { ApiResponse, request } from './base';

export interface UploadedFile {
  originalFile: File;
  preview?: string;
  name: string;
  type: string;
  size: number;
}

export const uploadToS3 = (signedForm: { url: string; fields: any }, file: UploadedFile) => {
  return eventChannel(emitter => {
    const headers: any = {
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();

    Object.keys(signedForm.fields).map((key: string) => {
      formData.append(key, signedForm.fields[key]);
    });

    formData.append('Content-Type', file.type);
    formData.append('file', file.originalFile);

    let cancel: () => void;

    axios
      .post(signedForm.url, formData, {
        headers,
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          emitter({ progress: percentCompleted });
        },
        cancelToken: new CancelToken(function executor(c: any) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      })
      .then(function(response) {
        const { status, data } = response;

        emitter({
          response: new ApiResponse(status, data),
        });
      })
      .catch(function(error) {
        if (error.response) {
          const { status, data } = error.response;
          emitter({
            response: new ApiResponse(status, data),
          });
          emitter(END);
        }

        console.log(error);
        emitter({
          response: new ApiResponse(400, {}),
        });
        emitter(END);
      });

    return () => {
      cancel();
    };
  }, buffers.sliding(2));
};

export const uploadFile = async (signedForm: { url: string; fields: any }, file: UploadedFile) => {
  const headers: any = {
    'Content-Type': 'multipart/form-data',
  };

  const formData = new FormData();

  Object.keys(signedForm.fields).map((key: string) => {
    formData.append(key, signedForm.fields[key]);
  });

  // formData.append('Content-Type', file.type);
  formData.append('file', file.originalFile);

  const { status, data } = await axios.post(signedForm.url, formData, {
    headers,
  });
  return new ApiResponse(status, data);
};

export const getDownloadFileUrl = async (key: string) => {
  if (key === '' || !key) {
    return false;
  }
  const response = await request({
    method: 'post',
    url: `file/download`,
    data: {
      fileObjectKey: key,
    },
  });
  if (response.isSuccess) {
    return response.data.downloadURL;
  }

  return false;
};

export const getDownloadFileUrlForObjects = async (objects: any[], fileKey: string) => {
  return await Promise.all(
    objects.map(async obj => {
      if (!obj) {
        return;
      } else {
        const file = obj[fileKey];
        if (!file) {
          return;
        }
        const url = await getDownloadFileUrl(file);
        if (url) {
          obj[fileKey] = url;
        }
      }
    })
  );
};
