import { trackPromise } from 'react-promise-tracker';
import * as aws from './aws';
import { request } from './base';

export const fetchDashboardData = async (unreadChannelsCount?: number, fetchArea?: string) => {
  const response = await trackPromise(
    request({
      method: 'GET',
      url: '/admin/dashboard',
      params: {
        unreadChannelsCount,
      },
    }),
    fetchArea ? fetchArea : 'dashboard-page'
  );
  if (response.isSuccess) {
    const adjusters = response.data.adjustersToReview.concat(response.data.adjustersPending);
    await aws.getDownloadFileUrlForObjects(adjusters, 'avatar');
  }
  return response;
};
