import { request } from './base';

export const list = () => {
  return request({
    method: 'get',
    url: 'user/list',
  });
};

export const getCurrentUser = () => {
  return request({
    method: 'get',
    url: 'admin/me',
  });
};

export const updateProfile = (model: {}) => {
  return request({
    method: 'post',
    url: 'admin/me',
    data: {
      ...model,
    },
  });
};

export const sendEmailVerificationRequest = () => {
  return request({
    method: 'post',
    url: 'user/me/email-verification-request',
  });
};

export const getPaperwork = () => {
  return request({
    method: 'get',
    url: 'paperwork',
  });
};
