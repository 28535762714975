import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Icon, Image, Pagination, Popup, Table } from 'semantic-ui-react';

import { NoResults } from '../../components/no-results-placeholder/NoResults';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import DataTable from '../data-table/DataTable';

import downloadIcon from '../../assets/images/Blue/download.svg';
import gearIcon from '../../assets/images/Blue/gear.svg';
import noAvatarIcon from '../../assets/images/no-avatar.svg';
import '../../assets/styles/Finances.css';

import * as helper from '../../utils/helper';

interface Props {
  finances: any[];
  user: any;
  totalFinances: number;
  page: number;
  pageSize: number;
  deletingFinance: boolean;
  deleteFinanceError: string | null;
  onPageChange: (nextPage: number) => void;
  onDeleteFinance: (id: number) => void;
  onDeletedFinanceDone: () => void;
  onDownloadFinance: (index: number) => void;
}

interface State {
  deleteConfirmVisible: boolean;
}

const UserName = (props: any) => {
  const { image, name } = props;

  return (
    <>
      <Image className="avatar" circular={true} src={image ? image : noAvatarIcon} avatar={true} />
      <span className="user-name">{name}</span>
    </>
  );
};

const GearButton = (props: any) => (
  <Popup
    basic={true}
    size="mini"
    content="Actions"
    trigger={
      <Dropdown
        className="actions-button"
        inline={true}
        button={true}
        icon={
          <Icon>
            <Image className="gear-icon" src={gearIcon} />
          </Icon>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={props.onDeletePress}>
            <span>Delete</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    }
  />
);

const DownloadButton = (props: any) => {
  const { onPress } = props;
  return (
    <Popup
      trigger={
        <Button icon={true} className="download-button" onClick={onPress}>
          <Icon>
            <Image className="download-icon" src={downloadIcon} />
          </Icon>
        </Button>
      }
      basic={true}
      size="mini"
      content="Download"
    />
  );
};

class FinanceTable extends React.Component<Props, State> {
  public state = {
    deleteConfirmVisible: false,
  };
  private selectedFinance: any = null;

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.deletingFinance && !this.props.deletingFinance) {
      toast.dismiss();
      const filename = this.selectedFinance.file.name;
      if (!this.props.deleteFinanceError) {
        toast.success(`Finance file "${filename}" was successfully deleted!`);
        this.props.onDeletedFinanceDone();
      } else {
        toast.error(`Failed to delete finance file "${filename}"`);
      }

      this.selectedFinance = null;
    }
  }

  public handlePagination = (e: any, data: any) => {
    if (this.props.onPageChange) {
      this.props.onPageChange(data.activePage);
    }
  };

  public handleDeleteFinance = (finance: any) => {
    this.selectedFinance = finance;
    this.setState({
      deleteConfirmVisible: true,
    });
  };

  public handleCloseConfirmDelete = () => {
    this.setState({
      deleteConfirmVisible: false,
    });
    this.selectedFinance = null;
  };

  public handleConfirmDelete = () => {
    this.props.onDeleteFinance(this.selectedFinance.id);
    this.setState({
      deleteConfirmVisible: false,
    });
  };

  public render() {
    const { finances, totalFinances, pageSize, page, onDownloadFinance, user } = this.props;
    const { deleteConfirmVisible } = this.state;
    const totalPage = helper.getTotalPage(totalFinances, pageSize);

    return (
      <>
        <div className="finance-list">
          <DataTable className="finance-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Adjuster</Table.HeaderCell>
                <Table.HeaderCell>File Name</Table.HeaderCell>
                <Table.HeaderCell>Uploaded By</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {finances.map((finance, i) => (
                <Table.Row key={finance.id}>
                  <Table.Cell collapsing={true}>
                    {moment(finance.createdAt).format('MM/DD/YYYY, h:mA')}
                  </Table.Cell>
                  <Table.Cell>
                    {finance.adjuster && (
                      <UserName
                        image={finance.adjuster.avatar}
                        name={`${
                          finance.adjuster.preferredName
                            ? finance.adjuster.preferredName
                            : finance.adjuster.firstName
                        } ${finance.adjuster.lastName}`}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell className="col-name">{finance.file.name}</Table.Cell>
                  <Table.Cell>
                    <UserName
                      image={finance.uploader.avatar}
                      name={`${finance.uploader.firstName} ${finance.uploader.lastName}`}
                    />
                  </Table.Cell>
                  <Table.Cell className="action-col" collapsing={true}>
                    {user.role !== 'Read Only Office Admin' && (
                      <GearButton
                        user={this.props.user}
                        onDeletePress={() => this.handleDeleteFinance(finance)}
                      />
                    )}
                    <DownloadButton user={this.props.user} onPress={() => onDownloadFinance(i)} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </DataTable>

          {finances.length === 0 && <NoResults />}
          {totalPage > 1 && (
            <div className="pagination-container">
              <Pagination
                totalPages={totalPage}
                boundaryRange={1}
                activePage={page}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                prevItem={
                  page === 1
                    ? false
                    : {
                        'aria-label': 'Previous item',
                        content: 'PREVIOUS',
                      }
                }
                nextItem={
                  page === totalPage
                    ? false
                    : {
                        'aria-label': 'Next item',
                        content: 'NEXT',
                      }
                }
                siblingRange={1}
                onPageChange={this.handlePagination}
              />
            </div>
          )}
        </div>
        {deleteConfirmVisible && (
          <ConfirmModal
            visible={deleteConfirmVisible}
            onClose={this.handleCloseConfirmDelete}
            onConfirm={this.handleConfirmDelete}
            message={`Are you sure you want to delete finance file "${this.selectedFinance.file.name}"?`}
          />
        )}
      </>
    );
  }
}

export default FinanceTable;
