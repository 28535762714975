import MyTypes from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import '../../assets/styles/Profile.css';
import '../../assets/styles/Register.css';
import { accountActions } from '../../features/account';
import { financeActions } from '../../features/finance';
import { IUser } from '../../models';
import { AdjusterUser } from '../../models/adjuster';
import PagePlaceholder from '../loading-spinner/PagePlaceholder';
import AdjusterLogs from './AdjusterLogs';
import AdjusterProfileHeader from './AdjusterProfileHeader';
import ProfileCertifications from './ProfileCertifications';
import ProfileDetails from './ProfileDetails';
import ProfileFinances from './ProfileFinances';
import ProfileLicenses from './ProfileLicenses';
import ProfilePaperwork from './ProfilePaperwork';
import ProfileProfessionalSkills from './ProfileProfessionalSkills';
import ProfileResumes from './ProfileResumes';

interface Props {
  downloadFinance: typeof financeActions.downloadAdjusterFinance;
  fetchAdjusterDataAttempt: typeof accountActions.fetchAdjusterDataAttempt;
  clearAdjusterData: typeof accountActions.clearAdjusterData;
  downloadFile: typeof accountActions.downloadFile;
  downloadPaperwork: typeof accountActions.downloadPaperwork;
  fetchData: typeof accountActions.fetchAdjusterData;
  user: AdjusterUser;
  hash: any;
  admin: IUser;
  loading: boolean;
}

interface State {
  activeTab: string;
  submitRef: any;
}

class AdjusterProfile extends React.Component<Props, State> {
  public state = {
    activeTab: 'Profile',
    submitRef: '',
  };

  public componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.fetchAdjusterDataAttempt(this.props.match.params.id);
    }
  };

  public componentWillUnmount = () => {
    this.props.clearAdjusterData();
  };

  public handleTabs = (name: string) => {
    this.setState({ activeTab: name });
  };

  public getSubmitRef = (ref: any) => {
    this.setState({ submitRef: ref });
  };

  public renderTab = () => {
    const { activeTab } = this.state;
    const { user, admin } = this.props;
    switch (activeTab) {
      case 'Profile':
        return (
          <ProfileDetails
            admin={admin}
            update={this.props.fetchAdjusterDataAttempt}
            user={user}
            fetchUser={this.props.fetchData}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Professional Skills':
        return (
          <ProfileProfessionalSkills admin={admin} user={user} getSubmitRef={this.getSubmitRef} />
        );
      case 'Licenses':
        return (
          <ProfileLicenses
            download={this.props.downloadFile}
            admin={admin}
            user={user}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Certifications':
        return (
          <ProfileCertifications
            download={this.props.downloadFile}
            admin={admin}
            user={user}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Resumes':
        return (
          <ProfileResumes
            download={this.props.downloadFile}
            admin={admin}
            user={user}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Paperwork':
        return (
          <ProfilePaperwork
            download={this.props.downloadPaperwork}
            admin={admin}
            user={user}
            getSubmitRef={this.getSubmitRef}
          />
        );
      case 'Logs':
        return <AdjusterLogs admin={admin} user={user} getSubmitRef={this.getSubmitRef} />;
      case 'Finances':
        return (
          <ProfileFinances
            download={this.props.downloadFinance}
            admin={admin}
            user={user}
            getSubmitRef={this.getSubmitRef}
          />
        );
      default:
        return <div>No Info</div>;
    }
  };

  public render() {
    return (
      <div className="adjuster-profile-page">
        {this.props.user && (
          <AdjusterProfileHeader
            user={this.props.user}
            admin={this.props.admin}
            submitRef={this.state.submitRef}
            getTabName={this.handleTabs}
            update={this.props.fetchAdjusterDataAttempt}
          />
        )}
        {this.props.user && !this.props.loading ? this.renderTab() : <PagePlaceholder />}
      </div>
    );
  }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
  user: state.account.account.adjuster,
  admin: state.account.account.user,
  pathname: state.router.location.pathname,
  hash: state.routerHash,
  isAuthenticationChecked: state.account.account.isAuthenticationChecked,
  loading: state.loading.loading.isVisible,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkAuthentication: accountActions.checkAuthentication,
      fetchAdjusterDataAttempt: accountActions.fetchAdjusterDataAttempt,
      clearAdjusterData: accountActions.clearAdjusterData,
      downloadFinance: financeActions.downloadAdjusterFinance,
      downloadFile: accountActions.downloadFile,
      downloadPaperwork: accountActions.downloadPaperwork,
      logout: accountActions.logout,
      fetchData: accountActions.fetchAdjusterData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdjusterProfile);
