import React, { useCallback, useEffect, useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Button, Checkbox, Input, Popup } from 'semantic-ui-react';
import iconArrow from '../../../../assets/images/ui-dropdown.svg';
import services from '../../../../services';

const Trigger = ({
  searchValue,
  $searchValue,
  $customOpen,
  isDisabled,
  createCustomEvent,
  error = false,
}) => (
  <div className="actions flex">
    <Input
      className="inputTrigger customEventInput"
      iconPosition="left"
      placeholder="Enter custom event"
      id="inputCustomMultiply"
      onClick={() => $customOpen(true)}
      name={name}
      maxLength={200}
    >
      <input
        value={searchValue}
        className="inputTr"
        onChange={e => $searchValue(e.target.value)}
        autoComplete="off"
      />
      <div className="iconArrow" onClick={() => $customOpen(true)}>
        <img src={iconArrow} />
      </div>
      {error && <p className="customError">* Event Name is required field.</p>}
    </Input>
    <Button primary={true} disabled={isDisabled} onClick={createCustomEvent}>
      Add
    </Button>
  </div>
);

const Content = ({ customEvents, searchValue, $customOpen, deployCustomEvent }) => {
  Content.handleClickOutside = () => $customOpen(false);

  return (
    <div className="wrapperContentMultiple customContent">
      <div className="body">
        {customEvents.map(
          i =>
            i.text.toLowerCase().includes(
              searchValue
                .toLowerCase()
                .replace(/\s+/g, ' ')
                .trim()
            ) && (
              <div onClick={() => deployCustomEvent(i)} className="item" key={i.value}>
                {i.text}
              </div>
            )
        )}
      </div>
    </div>
  );
};

const getSearchItems = (events, value) => {
  return events.filter(event =>
    event.text.toLowerCase().includes(
      value
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .trim()
    )
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Content.handleClickOutside,
};

const WrappedContent = onClickOutside(Content, clickOutsideConfig);

export const CustomContent = ({ customOpen, $customOpen, user, updateDeployments, options }) => {
  const [customEvents, $customEvents] = useState([]);
  const [searchValue, $searchValue] = useState('');
  const [isDisabled, $isDisabled] = useState(true);
  const [width, $width] = useState('210px');
  const [error, $error] = useState(false);

  const getCustomEventsByKeyword = async () => {
    const deployments = await services.api.admin.fetchAdjusterDeployment(user.id);

    const params = Object.assign({}, { search: searchValue }, { page: 0 }, { pageSize: 100 });

    const response = await services.api.admin.getCustomEvents(params);
    $customEvents(
      response.data.list
        .filter(i => !deployments.data.find(d => i.id === d.id))
        .map(event => ({ value: event.id, text: event.name }))
    );
  };

  useEffect(() => {
    getCustomEventsByKeyword();
  }, []);

  useEffect(() => {
    getCustomEventsByKeyword();
  }, [options]);

  useEffect(() => {
    if (document.querySelector('.popupmultiply .wrapperContentMultiple')) {
      $width(document.querySelector('.popupmultiply .wrapperContentMultiple').offsetWidth);
    }
  }, [document.querySelector('.popupmultiply  .wrapperContentMultiple')]);

  useEffect(() => {
    const func = element =>
      element.text.toLowerCase().includes(
        searchValue
          .toLowerCase()
          .replace(/\s+/g, ' ')
          .trim()
      );
    $isDisabled(!!searchValue ? customEvents.some(func) : true);
    $error(false);
  }, [searchValue]);

  const deployCustomEvent = async event => {
    const deployments = await services.api.admin.fetchAdjusterDeployment(user.id);
    const standbyList = deployments.data.reduce((accumulator, currentValue) => {
      const newList = accumulator;
      if (currentValue.isStandbyList && currentValue.deployed) {
        newList.push({
          isStandbyList: true,
          id: currentValue.id,
        });
      }
      return newList;
    }, []);
    await services.api.admin.setAdjusterDeployment(user.id, [
      { isStandbyList: false, id: event.value },
      ...standbyList,
    ]);
    const newDeployments = await services.api.admin.fetchAdjusterDeployment(user.id);
    updateDeployments(newDeployments.data);
  };

  const createCustomEvent = async () => {
    if (!!searchValue.trim()) {
      const params = {
        name: searchValue.replace(/\s+/g, ' ').trim(),
        adjusterId: user.id,
      };
      await services.api.admin.createEvent(params);
      const deployments = await services.api.admin.fetchAdjusterDeployment(user.id);
      $searchValue('');
      updateDeployments(deployments.data);
    } else {
      $error(true);
    }
  };

  const attr = {
    customEvents,
    searchValue,
    $searchValue,
    customOpen,
    $customOpen,
    isDisabled,
    createCustomEvent,
    deployCustomEvent,
    error,
  };

  return (
    <Popup
      content={<WrappedContent {...attr} />}
      on="click"
      position="bottom left"
      open={isDisabled && !!getSearchItems(customEvents, searchValue).length && customOpen}
      style={{ width, maxWidth: width, minWidth: width }}
      basic={true}
      trigger={<Trigger {...attr} />}
    />
  );
};
